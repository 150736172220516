import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppCoreModule } from '@app/core';
import { SharedModule } from '@shared/modules';
import { MessageRoutingModule } from './message-routing.module';
import { MessageComponent } from './message.component';

const components = [MessageComponent];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, MessageRoutingModule, AppCoreModule, SharedModule],
  exports: [...components]
})
export class MessageModule {}
