import { environment } from "@/src/environments/environment";
import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AppService } from "@app/core";
import { onProfileFormViewChanged$, onUserFormHasBadgeChanged$, onUserFormIsActiveChanged$, onUserFormIsSecondedChanged$, onUserFormReadOnlyChanged$ } from "@app/core/app.subscriptions";
import { UserDispatcher, UserService, userForm } from "@app/core/auth";
import { NotificationType } from "@app/core/notifications";
import { BaseViewComponent, PageView, getViewFromUrl } from "@app/view/base-view.component";
import { delay, distinctUntilChanged, takeWhile, tap } from "rxjs";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent extends BaseViewComponent implements OnInit {
  user: any = {};
  notifications: any = {};

  isAccordionOpened = false;

  constructor(private injector: Injector, private route: ActivatedRoute, private dispatcher: UserDispatcher, private appService: AppService, private userService: UserService) {
    super(injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    const route = this.route,
      router = this.router,
      dispatcher = this.dispatcher;

    if (parseInt(route?.snapshot?.params?.id) !== this.userService.userId) {
      this.appService.goToErrorPage();
      return;
    }

    onProfileFormViewChanged$({ route, router, dispatcher })
      .pipe(takeWhile(() => this.alive))
      .subscribe((user: any) => {
        console.log(user);
        this.user = user;
        dispatcher.clearNotifications();

        this.register$([
          onUserFormHasBadgeChanged$(user.form),
          onUserFormIsSecondedChanged$(user.form),
          onUserFormIsActiveChanged$(user.form),
          onUserFormReadOnlyChanged$(user.form),
          this.onNotificationChange
        ]);

        this.updateView();
      });
  }

  private get onNotificationChange() {
    return this.dispatcher.notifications$?.pipe(
      takeWhile(() => this.alive),
      distinctUntilChanged(),
      tap(notifications => {
        console.log('setting notifications here');
        console.log(notifications);
        this.notifications = {
          error: notifications?.find(n => n?.type === NotificationType.Error),
          success: notifications?.find(n => n?.type === NotificationType.Success)
        };
        console.log(this.notifications);
      })
    );
  }

  sendForm() {
    const payload = this.user.toPayload();
    return this.dispatcher
      .save(payload, { from: this.router.url }, true)
      .pipe(
        takeWhile(() => this.alive),
        delay(2000)
      )
      .subscribe();
  }

  clearForm() {
    this.user = userForm({ validateForm: environment.validateForm });
  }

  printForm() {
    setTimeout(() => {
      window.print();
    }, 100);
  }

  optionItems(name) {
    return this.appCache[`${name}Options`];
  }

  formCtrl(name) {
    return this.user?.form?.get(name) as FormControl;
  }

  field(name) {
    return this.user?.schema && this.user?.schema[name];
  }

  goToProfilePage() {
    this.appService.goToProfilePage({ userId: this.userService.userId });
  }

  get viewType() {
    return getViewFromUrl(this.router.url);
  }

  get isFormView() {
    return this.viewType === PageView.Create;
  }

  get isReadOnlyView() {
    return this.viewType === PageView.Read;
  }

  get isEditView() {
    return this.viewType === PageView.Update;
  }

  get shouldShowErrors() {
    return this.notifications?.error;
  }

  get shouldShowSuccess() {
    return this.notifications?.success;
  }

  get canSubmit() {
    return this.user.validateForm ? this.user?.form?.valid && !this.user?.form?.pristine : true;
  }
}