import { OdsDialogComponent } from "@/src/shared/components/ods";
import { ChangeDetectionStrategy, Component, ElementRef, Injector, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { UserDispatcher, withUserTransformer } from "@app/core/auth";
import { NotificationType } from "@app/core/notifications";
import { BaseViewComponent } from "@app/view/base-view.component";
import { BehaviorSubject, Observable, debounceTime, delay, distinctUntilChanged, forkJoin, fromEvent, merge, switchMap, takeWhile, tap } from "rxjs";

@Component({
  selector: 'app-manage-delegates',
  templateUrl: './manage-delegates.component.html',
  styleUrls: ['./manage-delegates.component.scss']
})

export class ManageDelegatesComponent extends BaseViewComponent implements OnInit {

  @Input()
  packageId : string;

  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;
  @ViewChild('filterInput', { static: true, read: ElementRef }) filterInput: ElementRef;
  @ViewChild('delegatesSort', { static: true }) delegatesSort: MatSort;

  users: any[] = [];
  notifications: any = {};

  displayedColumns = [
    'select',
    'name',
    'email',
    'badge'
  ];
  loadingResults = true;
  resultsLength = 0;
  dataSource = new MatTableDataSource<any>();
  emptyData = new MatTableDataSource<any>([{ empty: "row" }]);

  displayedDelegatesColumns = [
    'name',
    'email',
    'badge',
    'type',
    'actions'
  ];
  loadingDelegatesResults = false;
  delegatesResultsLength = 0;
  delegatesDataSource = new MatTableDataSource<any>([]);


  delegateForm = new FormGroup({
    filterInput: new FormControl(''),
    delegationTypeInput: new FormControl('')
  });

  constructor(
    private injector: Injector,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private dispatcher: UserDispatcher
  ) {
    super(injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.dispatcher.clearNotifications();
    this.route.params
      .pipe(
        tap(x => { if (x && x.packageId) this.packageId = x.packageId; }),
        switchMap(() => this.loadResources()),
        takeWhile(() => this.alive),
        tap(() => this.register$([this.onNotificationChange, this.onUsersChange]))
      )
      .subscribe();
  }

  ngAfterViewInit() {
    if (!this.filterInput || !this.filterInput.nativeElement) return;
    fromEvent(this.filterInput.nativeElement, 'keyup')
      .subscribe((filterInput: any) => this.dataSource.filter = filterInput?.target?.value);
  }

  private loadResources() {
    return forkJoin([this.dispatcher.loadDelegateUsersAssignedToPackage(this.packageId)]);
  }

  private get onNotificationChange() {
    return this.dispatcher.notifications$?.pipe(
      takeWhile(() => this.alive),
      distinctUntilChanged(),
      tap(notifications => {
        this.notifications = {
          error: notifications?.find(n => n?.type === NotificationType.Error),
          success: notifications?.find(n => n?.type === NotificationType.Success)
        };
        this.updateUserTable();
        this.updateDelegatesTable();
      })
    );
  }

  private get onUsersChange() {
    return this.dispatcher.delegatesList$?.pipe(
      takeWhile(() => this.alive),
      distinctUntilChanged(),
      tap(users => {
        this.users = users?.map(s => Object.assign({}, s, withUserTransformer({ state: s }).toTable()));
        this.updateUserTable(users);
      }),
      tap(users => {
        this.updateDelegatesTable(users.filter(x => x?.delegationType != null));
      })
    );
  }

  private updateUserTable(data?) {
    this.loadingResults = false;
    this.delegateForm.get('filterInput').enable();
    if (data && Object.prototype.hasOwnProperty.call(data, 'length')) {
      this.resultsLength = data.length;
      this.dataSource.data = [...data];
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = (data , filterText:string ) => 
        data?.firstName?.includes(filterText) || data?.lastName?.includes(filterText) || data?.loginEmail?.includes(filterText);
    }
  }
  
  formCtrl(name) {
    return this.delegateForm?.get(name) as FormControl;
  }

  optionItems(name) {
    return this.appCache[`${name}Options`];
  }

  selectedUsers : any[] = [];

  selected($event: MouseEvent, user: string) {
    this.isSaving = false;
    if ($event?.target['checked'] != undefined && user){
      if ($event?.target['checked'] ) {
        if (this.selectedUsers.indexOf(user) < 0) {
          this.selectedUsers.push(user);
        }
      } else {
        if (this.selectedUsers.indexOf(user) >= 0) {
          this.selectedUsers.splice(this.selectedUsers.indexOf(user), 1);
        }
      }
    }
  }
    
  delegatesAssignedDelectRow(row: any) {
    this.dialog.open(OdsDialogComponent,{
      width: '500px',
      data: {title: this.appCache.getPropertyItem('notification.dialog.warning.title.html'), 
        content: `<label class="ontario-label">${this.appCache.getPropertyItem('users.delegates.dialog.remove.confirm.title')}</label><br/><span>${this.appCache.getPropertyItem('users.delegates.dialog.remove.confirm.text')} <strong>${row?.firstName}, ${row?.lastName}</strong></span>`,
        yes: this.appCache.getPropertyItem('radio.value.yes'),
        no: this.appCache.getPropertyItem('radio.value.no')
      }
      }).afterClosed().subscribe(result => {
        if (result==='Yes') {
          this.loadingResults = true;
          this.loadingDelegatesResults = true;
          this.dispatcher.deleteDelegateUserAssignedToPackage(this.packageId, row?.email).subscribe();
        } 
    });
  }
    
  get typeValue() {
    return this.delegateForm.get('delegationTypeInput').value;
  }

  private isSaving = false;

  get showSelectErrormessage() {
    return this.isSaving && (this.selectedUsers.length == 0 || this.selectedUsers.length > 5);
  } 

  saveDelegates() {
    this.isSaving = true;
    this.formCtrl('delegationTypeInput').setValidators(Validators.required);
    this.formCtrl('delegationTypeInput').updateValueAndValidity();
    if (this.delegateForm.valid && this.selectedUsers.length > 0 && this.selectedUsers.length <= 5){
      this.loadingResults = true;
      this.loadingDelegatesResults = true;
      const payload = {
        "packageUuid": this.packageId,
        "delegationType": this.typeValue,
        "emailList": []
      }
      this.selectedUsers.forEach(x => payload.emailList.push(x.email));
      this.dispatcher.saveDelegateUsersAssignedToPackage(payload).subscribe(
        () => this.selectedUsers = []
      );
      this.formCtrl('delegationTypeInput').clearValidators();
      this.isSaving = false;
    }
    this.updateView();
  }

  private updateDelegatesTable(data?) {
    this.loadingDelegatesResults = false;
    if (data && Object.prototype.hasOwnProperty.call(data, 'length')) {
      this.delegatesResultsLength = data.length;
      this.delegatesDataSource.data = [...data];
      this.delegatesDataSource.sort = this.delegatesSort;
    }
  }

}