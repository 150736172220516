import { Injectable } from '@angular/core';
import { NotificationService } from '@app/core/notifications';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { API_ENDPOINTS } from '@/src/environments/environment';
import { MessageApiService, MessageService, MessageModel } from '.';

@Injectable({ providedIn: 'root' })
export class MessageDispatcher {
  constructor(private messageApiService: MessageApiService, private messageService: MessageService, private notificationService: NotificationService) { }

  get messages$() {
    return this.messageService?.list$;
  }

  get selected$() {
    return this.messageService?.selected$;
  }

  get notifications$() {
    return this.notificationService?.list$;
  }

  clearNotifications() {
    return this.notificationService?.clear();
  }

  addMessageSuccessNotification(details) {
    return this.notificationService?.addSuccess({
      ...details,
      title: 'submission.notification.create.success.title.text',
      content: 'submission.notification.create.success.content.text'
    });
  }

  addMessageErrorNotification(details) {
    return this.notificationService?.addError({
      ...details,
      title: 'submission.notification.create.error.title.text',
      content: 'submission.notification.create.error.content.invalidFormFields.text'
    });
  }

  load(isAppRequest = false): Observable<any[]> {
    return this.messageApiService?.getAll(isAppRequest).pipe(
      map((messages: any[]) => {
        if (!this.messageService) return [];
        this.messageService.list = cloneDeep(messages.filter(s => !s.isArchived && !s.isDeleted));
        return this.messageService?.list;
      })
    );
  }

  loadById(id, isAppRequest = false): Observable<MessageModel> {
    const toBeLoaded = this.messageService.list.find(s => s?.messageId === id);
    const requestId = API_ENDPOINTS.MESSAGE_GET.indexOf(`{messageId}`) === -1 ? toBeLoaded?.id : toBeLoaded?.messageId;
    return this.messageApiService?.get(requestId || id, isAppRequest).pipe(
      map((message: any) => {
        if (!this.messageService) return null;
        this.messageService.selected = { ...message };
        return this.messageService?.selected;
      })
    );
  }

  save(payload, opts?, isAppRequest?) {
    // const messageSaveRequest = () =>
    //   payload?.id ? this.messageApiService.update(payload, isAppRequest) : this.messageApiService.create(payload, isAppRequest);
    const messageSaveRequest = () => this.messageApiService.create(payload, isAppRequest);

    return messageSaveRequest().pipe(
      tap(message => {
        this.messageService?.add(message);
        this.notificationService?.clear();
        this.addMessageSuccessNotification({ model: 'Message', from: opts.from });
      }),
      catchError(e => {
        this.notificationService?.clear();
        const errors = Array.isArray(e.error) ? e.error : Object.keys(e.error?.errors).map(errKey => e.error.errors[errKey]);
        this.addMessageErrorNotification({ model: 'Message', from: opts.from, data: errors });
        return of(e);
      })
    );
  }
}
