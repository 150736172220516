import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ods-back2top',
  templateUrl: './ods-back-to-top-button.component.html',
  styleUrls: ['./ods-back-to-top-button.component.scss']
})
export class OdsBackToTopButtonComponent implements OnInit {
  @Input() btnName: string;

  ngOnInit(): void {
    window.addEventListener('scroll', function () {
      const scroll = document.getElementById('actual-btt-button');
      scroll.classList.toggle('active', window.scrollY > 200);
    });
  }

  scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 100);
  };
}
