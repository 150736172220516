import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ToPascalCase'
})
export class ToPascalCasePipe implements PipeTransform {
  transform(value: string): string {
    return ToPascalCasePipe.transform2(value);
  }

  static transform2(value: string): string {
    const map = {
      '0': 'a',
      '1': 'b',
      '2': 'c',
      '3': 'd',
      '4': 'e',
      '5': 'f',
      '6': 'g',
      '7': 'h',
      '8': 'i',
      '9': 'j'
    };
    return value.replace(/[0-9]/g, function (x) {
      return map[x];
    }).replace(/-([a-z])/g, function (y) { 
      return y[1].toUpperCase(); });
  }
}
