import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'ods-dialog',
    templateUrl: './ods-dialog.component.html',
    styleUrls: ['./ods-dialog.component.scss'],
})
export class OdsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<OdsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      content: string,
      ok?: string,
      yes?: string,
      no?: string
    }
  ) { }
}