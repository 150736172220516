import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { MessageDispatcher, withMessageTransformer } from "@app/core/messages";
import { NotificationType } from "@app/core/notifications";
import { BaseViewComponent } from "@app/view/base-view.component";
import { distinctUntilChanged, forkJoin, merge, switchMap, takeWhile, tap } from "rxjs";

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MessagesComponent extends BaseViewComponent implements OnInit {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;

  messages: any[] = [];
  notifications: any = {};

  isAccordionOpened = true;

  displayedColumns = [
    'sentDate',
    'courtHouse',
    'package',
    'subject',
    'expand'
  ];
  loadingResults = true;
  resultsLength = 0;
  dataSource = new MatTableDataSource<any>();
  emptyData = new MatTableDataSource<any>([{ empty: "row" }]);

  expandedPackage: { [key: string]: any } = {};

  constructor(
    private injector: Injector,
    private route: ActivatedRoute,
    private dispatcher: MessageDispatcher
  ) {
    super(injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.route.params
      .pipe(
        switchMap(() => this.loadResources()),
        takeWhile(() => this.alive),
        tap(() => this.register$([this.onNotificationChange, this.onMessagesChange])),
        tap(([packages]) => this.updateMessageTable(packages)),
        tap(() => this.updateView())
      )
      .subscribe();
  }

  ngAfterViewInit() {
    if (!this.sort || !this.paginator) return;
    merge(this.sort?.sortChange, this.paginator?.page)
      .pipe(
        switchMap((pagination: any) => {
          this.loadingResults = true;
          return this.dispatcher.load();
        })
      )
      .subscribe(data => this.updateMessageTable(data));
  }

  private loadResources() {
    return forkJoin([this.dispatcher.load()]);
  }

  private get onNotificationChange() {
    return this.dispatcher.notifications$?.pipe(
      takeWhile(() => this.alive),
      distinctUntilChanged(),
      tap(notifications => {
        console.log('setting notifications here');
        console.log(notifications);
        this.notifications = {
          error: notifications?.find(n => n?.type === NotificationType.Error),
          success: notifications?.find(n => n?.type === NotificationType.Success)
        };
        this.updateMessageTable();
      })
    );
  }

  private get onMessagesChange() {
    return this.dispatcher.messages$?.pipe(
      takeWhile(() => this.alive),
      distinctUntilChanged(),
      tap(messages => {
        console.log('setting message here');
        console.log(messages);
        this.messages = messages?.map(m => Object.assign({}, m, withMessageTransformer({ state: m }).toTable()));
        console.log(this.messages);
        this.updateMessageTable(messages);
      })
    );
  }

  private updateMessageTable(data?) {
    this.loadingResults = false;
    if (data && data.length) {
      this.resultsLength = data.length;
      this.dataSource.data = [...data];
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  expendPackage(row) {
    this.expandedPackage[row.messageId] = true;

  }

  collapsePackage(row) {
    this.expandedPackage[row.messageId] = false;
  }

}