import { environment } from "@/src/environments/environment";
import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AppService } from "@app/core";
import { onUserFormErrorsChanged$, onUserFormHasBadgeChanged$, onUserFormIsActiveChanged$, onUserFormIsSecondedChanged$, onUserFormReadOnlyChanged$, onUserFormSuccessChanged$, onUserFormViewChanged$ } from "@app/core/app.subscriptions";
import { UserDispatcher, userForm } from "@app/core/auth";
import { BaseViewComponent, PageView, getViewFromUrl } from "@app/view/base-view.component";
import isEmpty from 'lodash/isEmpty';
import { delay, takeWhile } from "rxjs";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserComponent extends BaseViewComponent implements OnInit {
  user: any = {};
  notifications: any = {};

  isAccordionOpened = false;

  constructor(private injector: Injector, private route: ActivatedRoute, private dispatcher: UserDispatcher, private appService: AppService) {
    super(injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    const route = this.route,
      router = this.router,
      dispatcher = this.dispatcher;

    onUserFormViewChanged$({ route, router, dispatcher })
      .pipe(takeWhile(() => this.alive))
      .subscribe((user: any) => {
        console.log(user);
        this.user = user;
        dispatcher.clearNotifications();

        this.register$([
          onUserFormHasBadgeChanged$(user.form),
          onUserFormIsSecondedChanged$(user.form),
          onUserFormIsActiveChanged$(user.form),
          onUserFormReadOnlyChanged$(user.form),
          onUserFormErrorsChanged$(dispatcher.notifications$, ns => {
            this.notifications.error = ns[0];
          }),
          onUserFormSuccessChanged$(dispatcher.notifications$, ns => {
            if (!isEmpty(ns)) this.appService.goToUsersPage();
          })
        ]);

        this.updateView();
      });
  }

  sendForm() {
    const payload = this.user.toPayload();
    return this.dispatcher
      .save(payload, { from: this.router.url }, true)
      .pipe(
        takeWhile(() => this.alive),
        delay(2000)
      )
      .subscribe();
  }

  clearForm() {
    this.user = userForm({ validateForm: environment.validateForm });
  }

  printForm() {
    setTimeout(() => {
      window.print();
    }, 100);
  }

  optionItems(name) {
    return this.appCache[`${name}Options`];
  }

  formCtrl(name) {
    return this.user?.form?.get(name) as FormControl;
  }

  field(name) {
    return this.user?.schema && this.user?.schema[name];
  }

  goToUsersPage() {
    this.appService.goToUsersPage();
  }

  get viewType() {
    return getViewFromUrl(this.router.url);
  }

  get isFormView() {
    return this.viewType === PageView.Create;
  }

  get isReadOnlyView() {
    return this.viewType === PageView.Read;
  }

  get isEditView() {
    return this.viewType === PageView.Update;
  }

  get shouldShowErrors() {
    return this.notifications?.error;
  }

  get canSubmit() {
    return this.user.validateForm ? this.user?.form?.valid && !this.user?.form?.pristine : true;
  }
}