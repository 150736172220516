import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '@environments/environment';
import { APP_REQUEST_CONTEXT } from '@shared/loading';
import { Observable, map } from 'rxjs';
import { ReferenceData, ServerReferenceData } from './app.constants';

const transformer = {
  toReferenceData: (rd: ServerReferenceData, i: number): ReferenceData => ({
    id: rd?.code,
    name: { en: rd?.descEn, fr: rd?.descFr },
    displayOrder: rd?.order || i + 1,
    parentId: rd?.parentCode || rd?.comment
  })
};

@Injectable({
  providedIn: 'root'
})
export class AppDataLookupService {
  constructor(private httpClient: HttpClient) {}

  getPackageTypeOptions(): Observable<ReferenceData[]> {
    return (this.httpClient.get(API_ENDPOINTS.REF_PACKAGE_TYPES, { context: APP_REQUEST_CONTEXT }) as Observable<ServerReferenceData[]>
    ).pipe(map(rds => rds.map(transformer.toReferenceData)));
  }

  getWarrantTypeOptions(): Observable<ReferenceData[]> {
    return (this.httpClient.get(API_ENDPOINTS.REF_WARRANT_TYPES, { context: APP_REQUEST_CONTEXT }) as Observable<ServerReferenceData[]>
    ).pipe(map(rds => rds.map(transformer.toReferenceData)));
  }

  getCourtRegionOptions(): Observable<ReferenceData[]> {
    return (this.httpClient.get(API_ENDPOINTS.REF_COURT_REGIONS, { context: APP_REQUEST_CONTEXT }) as Observable<ServerReferenceData[]>
    ).pipe(map(rds => rds.map(transformer.toReferenceData)));
  }

  getCourtHouseOptions(): Observable<ReferenceData[]> {
    return (this.httpClient.get(API_ENDPOINTS.REF_COURTHOUSE, { context: APP_REQUEST_CONTEXT }) as Observable<ServerReferenceData[]>).pipe(
      map(rds => rds.map(transformer.toReferenceData))
    );
  }

  getProcessStatusOptions(): Observable<ReferenceData[]> {
    return (this.httpClient.get(API_ENDPOINTS.REF_PROCESS_STATUSES, { context: APP_REQUEST_CONTEXT }) as Observable<ServerReferenceData[]>).pipe(
      map(rds => rds.map(transformer.toReferenceData))
    );
  }

  getPriorityLevelOptions(): Observable<any[]> {
    return (this.httpClient.get(API_ENDPOINTS.REF_PRIORITY_LEVEL, { context: APP_REQUEST_CONTEXT }) as Observable<ServerReferenceData[]>).pipe(
      map(rds => rds.map(transformer.toReferenceData))
    );
  }

  getAdultOrYouthOptions(): Observable<any[]> {
    return (this.httpClient.get(API_ENDPOINTS.REF_ADULT_OR_YOUTH, { context: APP_REQUEST_CONTEXT }) as Observable<ServerReferenceData[]>).pipe(
      map(rds => rds.map(transformer.toReferenceData))
    );
  }

  getProvinceOptions(): Observable<ReferenceData[]> {
    return (this.httpClient.get(API_ENDPOINTS.REF_PROVINCES, { context: APP_REQUEST_CONTEXT }) as Observable<ServerReferenceData[]>).pipe(
      map(rds => rds.map(transformer.toReferenceData))
    );
  }

  getDelegationTypeOptions(): Observable<ReferenceData[]> {
    return (this.httpClient.get(API_ENDPOINTS.REF_DELEGATION, { context: APP_REQUEST_CONTEXT }) as Observable<ServerReferenceData[]>).pipe(
      map(rds => rds.map(transformer.toReferenceData))
    );
  }

}
