/* eslint-disable @typescript-eslint/ban-types */
import { API_DOMAIN, environment } from '@/src/environments/environment';
import { FormControl, FormGroup } from '@angular/forms';
import { SubmissionModel, SubmissionStatus, SubmissionType } from '@app/core/submissions';
import { searchWarrantSchema } from '@app/core/submissions';
import { Submission, SubmissionArguments} from '@app/core/submissions';
import { FormFieldSchema, ValidatorType } from '../models/form.model';
import { cloneDeep as clone } from 'lodash';
import { BaseValidators as baseConfig } from '@app/core/models';
import { v4 as uuidv4 } from 'uuid';
import { Utils } from '../utils';

export interface searchWarrantFormArguments {
  data?: unknown;
  validateForm?: boolean;
  disableForm?: boolean;
}

export const searchWarrantSubmission = function ({ data, schema, options: { validateForm } }: SubmissionArguments) {
  const s = Submission({
    data ,
    schema,
    options: { validateForm }
  });

  s.data = new SW_DATA();
  Object.assign(s.data, data);
  console.log(s.data as SW_DATA_TYPE);
    
  s.recoverValidatorFields = function(){
    return Object.keys(schema).reduce((r, x) => {
      const vConfig = schema[x]?.validators?.reduce((config, v) => {
        config[v.type] = v?.value ?? true;
        return config;
      }, clone(baseConfig));
      r[x] = Object.assign({}, schema[x], vConfig);
      r[x].getValidator = function (vt) {
        return this.validators?.find(v => v.type === vt);
      };
      return r;
    }, {});
  }

  s.toValidate = function(form: FormGroup, schema2: FormFieldSchema, step: number) : boolean{
    Object.keys(schema2).forEach(x => {
      const it : FormControl = form.get(x) as FormControl;
      if (it) {
        it.clearValidators();
        it.clearAsyncValidators();
        it.updateValueAndValidity();
      }
    });

    // step 0 for save draft
    // step 1-4 for normal step
    // step 5 for add another occupant
    // step 6 for start check.
    // step 7 for add to ppa summary
    // step 33 for only check 'assistanceOrderRequired' when ppa list has row(s).
    // step 22 for AddI to ITO Summary
    const fields = step==0? ['courtRegion','courthouse','priorityCode'] : 
                    step==5? ['ownerOccupantGivenName','ownerOccupantSurname','adultOrYouth'] : 
                      step==6? ['itoTypeCode'] : 
                        step==33? ['assistanceOrderRequired']:
                          step==7? Object.keys(schema2).filter(x => schema2[x].step == 3 && schema2[x].name != 'assistanceOrderRequired') :
                            step==22? Object.keys(schema2).filter(x => schema2[x].step == 2 && !['isSORequested','synopsis4SO','requestTermsCond','synopsisTermsCond'].includes(schema2[x].name)):
                              Object.keys(schema2).filter(x => schema2[x].step == step);
    fields.forEach( x => {
      const vConfig = schema[x]?.validators?.reduce((config, v) => {
        config[v.type] = v?.value ?? true;
        return config;
      }, clone(baseConfig));
      //recovered validators setting.
      schema2[x] = Object.assign({}, schema[x], vConfig);
      schema2[x].getValidator = function (vt) {
        return this.validators?.find(v => v.type === vt);
      };
      const validators = schema2[x].validators.filter(v => !v.async).map(v => v.fn);
      const asyncValidators = schema2[x].validators.filter(v => v.async).map(v => v.fn);
      const it : FormControl = form.get(x) as FormControl;
      if (it && !it.disabled) {
        it.setValidators(validators);
        it.setAsyncValidators(asyncValidators)
        it.updateValueAndValidity();
      }
    });
    return form.valid;
  }

  s.toNewPayload = function(formGroup, OccupantsList){
    const occupants : STEP_2_OCCUPANT_TYPE[] = OccupantsList.reduce((x, y) => {
      x.push({ownerOccupantGivenName: y['ownerOccupantGivenName'], ownerOccupantSurname: y['ownerOccupantSurname'], adultOrYouth: y['adultOrYouth']});
      return x;
    }, []);

    const model = s.toModel({ formGroup });
    const sdata = new SW_DATA();

    sdata.courtRegionCode = model['courtRegion'];
    sdata.courtHouseCode = model['courthouse'];
    sdata.wasIFSubmitted = model['wasIFSubmitted'];
    sdata.ifReferenceNum = model['ifReferenceNum'];
    sdata.associatedPreviousApp = model['associatedPreviousApp'];
    sdata.associatedPreviousNum = model['associatedPreviousNum'];
    sdata.priorityCode = model['priorityCode'];
    sdata.synopsis4Priority = model['synopsis4Priority'];
    sdata.isSORequested = model['isSORequested'];
    sdata.synopsis4SO = model['synopsis4SO'];
    sdata.requestTermsCond = model['requestTermsCond'];
    sdata.synopsisTermsCond = model['synopsisTermsCond'];
    sdata.isFreeform = model['isFreeform'] == null || model['isFreeform'] == undefined || model['isFreeform'] == '' ? null : model['isFreeform'] == 'true' ;
    sdata.currentStep2 = {
      itoUUID: model['itoUUID'],
      itoTypeCode: model['itoTypeCode'],
      courtRegionCode: model['courtRegionJurisdiction'],
      courtHouseCode: model['courthouseJurisdiction'],
      areThereOccupants: model['areThereOccupants'],
      currentOccupant: {
        ownerOccupantGivenName: model['ownerOccupantGivenName'],
        ownerOccupantSurname: model['ownerOccupantSurname'],
        adultOrYouth: model['adultOrYouth'],
      },
      occupantList: occupants,
      businessName: model['businessName'],
      what2Searched: model['what2Searched'],
      isVehicle2Searched: model['isVehicle2Searched'],
      vehiclePlateNum: model['vehiclePlateNum'],
      vehicleIdentificationNum: model['vehicleIdentificationNum'],
      address2Searched: model['address2Searched'],
      addressCity: model['addressCity'],
      addressProvinceCode: model['addressProvinceCode'],
      appendixA: model['appendixA'],
      reasonableGroundsToBelieve: model['reasonableGroundsToBelieve'],
      reasonableGroundsToBelieveValue: model['reasonableGroundsToBelieveValue'],
      appendixB: model['appendixB'],
      informantHaveKnowledge: model['informantHaveKnowledge'],
      circumstancesOfPriorApplication: model['circumstancesOfPriorApplication'],
      nightGroundsRequested: model['nightGroundsRequested'],
      groundsToExecuteAtNight: model['groundsToExecuteAtNight'],
      publicOfficerRequired: model['publicOfficerRequired'],
      namesOfPublicOfficers: model['namesOfPublicOfficers'],
      completed: false
    };
    sdata.step2ItoList = (s.data && s.data.step2ItoList)? clone(s.data.step2ItoList) : [];
    sdata.assistanceOrderRequired = model['assistanceOrderRequired'];
    sdata.currentStep3 = {
      ppaUUID: model['ppaUUID'],
      givenName: model['givenName'],
      surname: model['surname'],
      title: model['title'],
      qualifications: model['qualifications'],
      assistanceExplain: model['assistanceExplain'],
      itoUUIDList: model['personProvidingAssistanceChoose']?.split(',')?.filter(x => x != ''),
      completed: false
    };
    sdata.step3AssistanceList = (s.data && s.data.step3AssistanceList)? clone(s.data.step3AssistanceList) : [];
    sdata.step3AssistanceList.forEach(x => x.itoUUIDList = x.itoUUIDList ? x.itoUUIDList.filter(y => y != '') : []);

    console.log(sdata);

    return sdata;
  }

  s.toAddToItoSummary = function(formGroup: FormGroup, schema2: FormFieldSchema, completed:boolean, OccupantsList: any[], fn: Function) : any {
    const occupants : STEP_2_OCCUPANT_TYPE[] = OccupantsList.reduce((x, y) => {
        x.push({ownerOccupantGivenName: y['ownerOccupantGivenName'], ownerOccupantSurname: y['ownerOccupantSurname'], adultOrYouth: y['adultOrYouth']});
        return x;
    }, []);
    const model = s.toModel({ formGroup });
    if (!model['itoUUID'] || model['itoUUID']==''){
      model['itoUUID'] = uuidv4();
      (formGroup.get('itoUUID') as FormControl)?.setValue(model['itoUUID']);
    }
    const currentEditingStep2Value : STEP_2_ITO_TYPE= {
      itoUUID: model['itoUUID'],
      itoTypeCode: model['itoTypeCode'],
      courtRegionCode: model['courtRegionJurisdiction'],
      courtHouseCode: model['courthouseJurisdiction'],
      areThereOccupants: model['areThereOccupants'],
      currentOccupant : {
        ownerOccupantGivenName: model['ownerOccupantGivenName'],
        ownerOccupantSurname: model['ownerOccupantSurname'],
        adultOrYouth: model['adultOrYouth'],
      },
      occupantList: occupants,
      businessName: model['businessName'],
      what2Searched: model['what2Searched'],
      isVehicle2Searched: model['isVehicle2Searched'],
      vehiclePlateNum: model['vehiclePlateNum'],
      vehicleIdentificationNum: model['vehicleIdentificationNum'],
      address2Searched: model['address2Searched'],
      addressCity: model['addressCity'],
      addressProvinceCode: model['addressProvinceCode'],
      appendixA: model['appendixA'],
      reasonableGroundsToBelieve: model['reasonableGroundsToBelieve'],
      reasonableGroundsToBelieveValue: model['reasonableGroundsToBelieveValue'],
      appendixB: model['appendixB'],
      informantHaveKnowledge: model['informantHaveKnowledge'],
      circumstancesOfPriorApplication: model['circumstancesOfPriorApplication'],
      nightGroundsRequested: model['nightGroundsRequested'],
      groundsToExecuteAtNight: model['groundsToExecuteAtNight'],
      publicOfficerRequired: model['publicOfficerRequired'],
      namesOfPublicOfficers: model['namesOfPublicOfficers'],
      completed
    };
    s.data.step2ItoList.push(currentEditingStep2Value);

    const sname = "providingAssistanceChoose"+ Utils.toPascalCase(currentEditingStep2Value.itoUUID);
    if (!schema2[sname]) {
      schema2[sname] = {
        id: `providing-assistance-choose-${currentEditingStep2Value.itoUUID}`,
        name: `providingAssistanceChoose${Utils.toPascalCase(currentEditingStep2Value.itoUUID)}`,
        type: Boolean,
        step: 3,
        db: true,
        form: true,
        defaultValue: null,
        required: false,
        disabled: false,
        label: null,
        description: '',
        validators: []
      }
    }
    if (!schema[sname]) {
      schema[sname] = clone(schema2[sname]);
    }

    if (!formGroup.controls[schema2[sname].name]) {
      formGroup.controls[schema2[sname].name] = new FormControl(
        {
          value: false,
          disabled: false
        },
        []
      );
      formGroup.controls[schema2[sname].name].valueChanges.subscribe(value => {
        if (value === false) {
          formGroup.get('providingAssistanceChooseSelectAll').setValue(false);
        }
        let tmp = formGroup.get('personProvidingAssistanceChoose').value??'';
        tmp = tmp.replace(new RegExp(`,${currentEditingStep2Value.itoUUID}`, 'g'), '');
        if (value === true) {
          tmp = `${tmp},${currentEditingStep2Value.itoUUID}`;
        }
        formGroup.get('personProvidingAssistanceChoose').setValue(tmp);
      })
    }

    s.toClearForm(formGroup, schema2, 2);
    if (fn) fn.call(s, currentEditingStep2Value);
    return currentEditingStep2Value;
  }

  s.toDelFromItoSummary = function (key: string, value: any){
    const anITO = s.data.step2ItoList?.find(x => x[key] === value);
    if (anITO) {
      const rid = s.data.step2ItoList.indexOf(anITO);
      if (rid >=0)
        s.data.step2ItoList.splice(rid, 1);
    }
    return s.data.step2ItoList;
  }

  s.toEditItoInSummary = function (key: string, value: any, form: FormGroup, schema2: FormFieldSchema){
    const anITO = s.data.step2ItoList?.find(x => x[key] === value);
    if (anITO) {
      Object.keys(schema2).filter(x => schema2[x].step == 2).forEach(x => {
        const it : FormControl = form.get(x) as FormControl;
        if (it) {
          it.clearValidators();
          it.clearAsyncValidators();
          it.setValue(x==='courtRegionJurisdiction' ? anITO['courtRegionCode'] : 
                        x === 'courthouseJurisdiction'? anITO['courtHouseCode'] : 
                          x === 'ownerOccupantGivenName'? anITO['currentOccupant']?.ownerOccupantGivenName : 
                            x === 'ownerOccupantSurname'? anITO['currentOccupant']?.ownerOccupantSurname : 
                              x === 'adultOrYouth'? anITO['currentOccupant']?.adultOrYouth : 
                                ''.concat(anITO[x]??''));
        }
      });
    }
    return anITO;
  }
  
  s.toAddToPpaSummary = function(formGroup: FormGroup, schema2: FormFieldSchema, completed:boolean, fn: Function) : any {
    const model = s.toModel({ formGroup });
    if (!model['ppaUUID'] || model['ppaUUID']==''){
      model['ppaUUID'] = uuidv4();
      (formGroup.get('ppaUUID') as FormControl)?.setValue(model['ppaUUID']);
    }
    const currentEditingStep3Value : STEP_3_PPA_TYPE = {
      ppaUUID: model['ppaUUID'],
      givenName: model['givenName'],
      surname: model['surname'],
      title: model['title'],
      qualifications: model['qualifications'],
      assistanceExplain: model['assistanceExplain'],
      itoUUIDList: model['personProvidingAssistanceChoose']?.split(','),
      completed
    }
    s.data.step3AssistanceList.push(currentEditingStep3Value);

    s.toClearForm(formGroup, schema2, 3, ['assistanceOrderRequired']);
    if (fn) fn.call(s, currentEditingStep3Value);
    return currentEditingStep3Value;
  }

  s.toDelFromPpaSummary = function (key: string, value: any){
    const aPPA = s.data.step3AssistanceList?.find(x => x[key] === value);
    if (aPPA) {
      const rid = s.data.step3AssistanceList.indexOf(aPPA);
      if (rid >=0)
        s.data.step3AssistanceList.splice(rid, 1);
    }
    return s.data.step3AssistanceList;
  }

  s.toEditPpaInSummary = function (key: string, value: any, form: FormGroup, schema2: FormFieldSchema){
    const aPPA = s.data.step3AssistanceList?.find(x => x[key] === value);
    if (aPPA) {
      Object.keys(schema2).filter(x => schema2[x].step == 3 && schema2[x].name != 'assistanceOrderRequired').forEach(x => {
        const it : FormControl = form.get(x) as FormControl;
        if (it) {
          it.clearValidators();
          it.clearAsyncValidators();
          it.setValue(aPPA[x]);
        }
      });
      aPPA.itoUUIDList?.forEach(x=>{
        if (x && x != ''){
          const sname = "providingAssistanceChoose"+ Utils.toPascalCase(x);
          const it : FormControl = form.get(sname) as FormControl;
          if (it) {
            it.clearValidators();
            it.clearAsyncValidators();
            it.setValue(true);
          }
        }
      });
    }
    return aPPA;
  }

  s.toClearForm = function(form: FormGroup, schema2: FormFieldSchema, step: number, except: any[] = [], fn: Function){
    Object.keys(schema2).filter(x => schema2[x].step == step && !except.includes(schema2[x].name) ).forEach(x => {
      const it : FormControl = form.get(x) as FormControl;
      if (it) {
        it.clearValidators();
        it.clearAsyncValidators();
        it.setValue(schema2[x].defaultValue);
      }
    });
    if (fn) fn.call(s);
  }

  s.retriveDataToForm = function(form: FormGroup, schema2: FormFieldSchema, data: SW_DATA_TYPE, fn: Function ){
    Object.keys(schema2).filter(x => schema2[x].form).forEach(x => {
      const it : FormControl = form.get(x) as FormControl;
      if (it) {
        it.clearValidators();
        it.clearAsyncValidators();
        it.setValue((data[x] != null && data[x] != undefined)? `${data[x]}` : 
          schema2[x].step==1 && x === 'courtRegion'? data['courtRegionCode'] : schema2[x].step==1 && x === 'courthouse' ? data['courtHouseCode'] : 
          schema2[x].step==2 && data.currentStep2? ( x === 'courtRegionJurisdiction'? data.currentStep2['courtRegionCode'] : x === 'courthouseJurisdiction' ? data.currentStep2['courtHouseCode'] : ''.concat(data.currentStep2[x]??'')) :
          schema2[x].step==3 && data.currentStep3? ''.concat(data.currentStep3[x]??'') : '');
      }
    });

    data.step2ItoList?.forEach(currentEditingStep2Value => {
      const sname = "providingAssistanceChoose"+ Utils.toPascalCase(currentEditingStep2Value.itoUUID);
      if (!schema2[sname]) {
        schema2[sname] = {
          id: `providing-assistance-choose-${currentEditingStep2Value.itoUUID}`,
          name: `providingAssistanceChoose${Utils.toPascalCase(currentEditingStep2Value.itoUUID)}`,
          type: Boolean,
          step: 3,
          db: true,
          form: true,
          defaultValue: null,
          required: false,
          disabled: false,
          label: null,
          description: '',
          validators: []
        }
      }
      if (!schema[sname]) {
        schema[sname] = clone(schema2[sname]);
      }
      if (!form.controls[schema2[sname].name]) {
        form.controls[schema2[sname].name] = new FormControl(
          {
            value: false,
            disabled: false
          },
          []
        );
        form.controls[schema2[sname].name].valueChanges.subscribe(value => {
          if (value === false) {
            form.get('providingAssistanceChooseSelectAll').setValue(false);
          }
          let tmp = form.get('personProvidingAssistanceChoose').value??'';
          tmp = tmp.replace(new RegExp(`,${currentEditingStep2Value.itoUUID}`, 'g'), '');
          if (value === true) {
            tmp = `${tmp},${currentEditingStep2Value.itoUUID}`;
          }
          form.get('personProvidingAssistanceChoose').setValue(tmp);
        })
      }
    });

    if (fn) fn.call(s, form);
  }

  return s;
};

export const searchWarrantForm = function ({ data, validateForm, disableForm }: searchWarrantFormArguments) {
  const s = searchWarrantSubmission({
    data: data ?? { type: SubmissionType.SearchWarrant },
    schema: searchWarrantSchema,
    options: { validateForm: false }
  });
  const form = s.toForm({ disableAll: disableForm });
  const schema = s.recoverValidatorFields();
  return {
    model: s,
    form,
    schema,
    validateForm,
    toModel: s.toModel,
    toPayload: (OccupantsList) => s.toNewPayload(form, OccupantsList),
    toValidate: (step: number) => s.toValidate(form, schema, step),
    toForm: s.toForm,
    toSchema: () => s.recoverValidatorFields(),
    toAddToItoSummary: (completed:boolean, OccupantsList, fn:Function) => s.toAddToItoSummary(form, schema, completed, OccupantsList, fn),
    toDeleteFromItoSummary: (key, value) => s.toDelFromItoSummary(key, value),
    toEditItoInSummary: (key, value) => s.toEditItoInSummary(key, value, form, schema),
    toAddToPpaSummary: (completed:boolean, fn:Function) => s.toAddToPpaSummary(form, schema, completed, fn),
    toDeleteFromPpaSummary: (key, value) => s.toDelFromPpaSummary(key, value),
    toEditPpaInSummary: (key, value) => s.toEditPpaInSummary(key, value, form, schema),
    toClearForm: (step: number, fn: Function) => s.toClearForm(form, schema, step, step==3?['assistanceOrderRequired']:[], fn),
    toRetriveDataToForm: ( fn: Function ) => s.retriveDataToForm(form, schema, s.data, fn)
  };
};

export const withSubmittedSearchWarrantPackageTable = function ({ state }) {
  const VIEW_AS_PDF_STATUSES = [SubmissionStatus[SubmissionStatus.DELIVERED], SubmissionStatus[SubmissionStatus.RESPONSE]];
  state.canViewAsPDF = state?.file?.url && VIEW_AS_PDF_STATUSES.includes(state.currentStatus);
  state.viewLink = `${environment.docEndpoint}/${state?.file?.url}`;

  const DOWNLOAD_AS_PDF_STATUSES = [SubmissionStatus[SubmissionStatus.DELIVERED], SubmissionStatus[SubmissionStatus.RESPONSE]];
  state.canDownloadAsPDF = state?.file?.url && DOWNLOAD_AS_PDF_STATUSES.includes(state.currentStatus);
  state.downloadLink = `${state?.viewLink}/download`;

  state.canViewInputs = [
    SubmissionStatus[SubmissionStatus.DELIVERED],
    SubmissionStatus[SubmissionStatus.RESPONSE],
    SubmissionStatus[SubmissionStatus.WITHDRAW],
    SubmissionStatus[SubmissionStatus.NOTWITHDRAW],
    SubmissionStatus[SubmissionStatus.FAILURE],
    SubmissionStatus[SubmissionStatus.ERROR]
  ].includes(state.currentStatus);

  state.canDelegate = [SubmissionStatus[SubmissionStatus.RESPONSE]].includes(state.currentStatus) || true;

  state.canArchive = [
    SubmissionStatus[SubmissionStatus.RESPONSE],
    SubmissionStatus[SubmissionStatus.WITHDRAW],
    SubmissionStatus[SubmissionStatus.NOTWITHDRAW],
    SubmissionStatus[SubmissionStatus.FAILURE],
    SubmissionStatus[SubmissionStatus.ERROR]
  ].includes(state.currentStatus);

  state.canViewInputs = [SubmissionStatus[SubmissionStatus.DRAFT]].includes(state.currentStatus);

  state.canEditInputs = [SubmissionStatus[SubmissionStatus.DRAFT]].includes(state.currentStatus);

  state.canExpand = state?.packageType === SubmissionType.SearchWarrant && state?.itoTypes && [
    SubmissionStatus[SubmissionStatus.RESPONSE],
    SubmissionStatus[SubmissionStatus.DELIVERED]
  ].includes(state.currentStatus);

  state.highlighted = [SubmissionStatus[SubmissionStatus.NOTWITHDRAW], SubmissionStatus[SubmissionStatus.FAILURE]].includes(state.currentStatus);

  return state;
};

export class SW_DATA implements SW_DATA_TYPE{
  type: string;
  courtRegionCode: string;
  courtHouseCode: string;
  wasIFSubmitted: boolean;
  ifReferenceNum: string;
  associatedPreviousApp: boolean;
  associatedPreviousNum: string;
  priorityCode: string;
  synopsis4Priority: string;
  isFreeform: boolean;
  isSORequested: boolean;
  synopsis4SO: string;
  requestTermsCond: boolean;
  synopsisTermsCond: string;
  currentStep2: STEP_2_ITO_TYPE;
  step2ItoList: STEP_2_ITO_TYPE[] = [];
  assistanceOrderRequired: boolean;
  currentStep3: STEP_3_PPA_TYPE;
  step3AssistanceList: STEP_3_PPA_TYPE[] = [];
  documentList: STEP_4_MUD_TYPE;
}

export interface SW_DATA_TYPE{

  type: string;

  //--step 1--//
  courtRegionCode: string;
  courtHouseCode: string;
  wasIFSubmitted: boolean;
  ifReferenceNum: string;
  associatedPreviousApp: boolean;
  associatedPreviousNum: string;
  priorityCode: string;
  synopsis4Priority: string;
  isFreeform: boolean;

  //--step 2--//
  currentStep2: STEP_2_ITO_TYPE;
  step2ItoList: STEP_2_ITO_TYPE[];
  isSORequested: boolean;
  synopsis4SO: string;
  requestTermsCond: boolean;
  synopsisTermsCond: string;

  //--step 3--//
  assistanceOrderRequired: boolean;
  currentStep3: STEP_3_PPA_TYPE;
  step3AssistanceList: STEP_3_PPA_TYPE[];

  //--step 4--//
  documentList?: STEP_4_MUD_TYPE;
}

export interface STEP_2_OCCUPANT_TYPE {
    ownerOccupantGivenName: string;
    ownerOccupantSurname: string;
    adultOrYouth: string;
}

export interface STEP_2_ITO_TYPE {
  itoUUID: string;
  itoTypeCode: string;
  courtRegionCode: string;
  courtHouseCode: string; 
  areThereOccupants: boolean;
  currentOccupant: STEP_2_OCCUPANT_TYPE;
  occupantList: STEP_2_OCCUPANT_TYPE[];
  businessName: string;
  what2Searched: string;
  isVehicle2Searched: boolean;
  vehiclePlateNum: string;
  vehicleIdentificationNum: string;
  address2Searched: string;
  addressCity: string;
  addressProvinceCode: string;
  appendixA: string;
  reasonableGroundsToBelieve: number;
  reasonableGroundsToBelieveValue: string;
  appendixB: string;
  informantHaveKnowledge: boolean;
  circumstancesOfPriorApplication: string;
  nightGroundsRequested: boolean;
  groundsToExecuteAtNight: string;
  publicOfficerRequired: boolean;
  namesOfPublicOfficers: string;
  completed: boolean;
}

export interface STEP_3_PPA_TYPE {
  ppaUUID: string,
  givenName: string,
  surname: string,
  title: string;
  qualifications: string;
  assistanceExplain: string;
  completed: boolean;
  itoUUIDList: string[];  
}

export interface STEP_4_DOC_TYPE {
  seqno: number;
  itemName: string;
  fileName: string;
  uploaded: boolean;
}

export interface STEP_4_MUD_TYPE {
  itoPackagePdf: STEP_4_DOC_TYPE[];
  appCPlust: STEP_4_DOC_TYPE;
}
