import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { BehaviorSubject, Observable } from 'rxjs';
import { MessageModel } from '.';

@Injectable({ providedIn: 'root' })
export class MessageService {
  private _list: any[] = [];
  private listSubject = new BehaviorSubject(this._list);
  private _list$: Observable<any[]> = this.listSubject.asObservable();

  private _selected: MessageModel = null;
  private selectedSubject = new BehaviorSubject<MessageModel>(this._selected);
  private _selected$: Observable<MessageModel> = this.selectedSubject.asObservable();

  constructor(private httpClient: HttpClient) {}

  add(item) {
    this.list = cloneDeep(this._list).concat(item);
    return this._list$;
  }

  remove(id) {
    this.list = cloneDeep(this._list).filter(s => s?.id !== id);
    return this._list$;
  }

  get list() {
    return this._list;
  }

  set list(list: any[]) {
    this._list = list;
    this.listSubject.next(this._list);
  }

  get list$() {
    return this._list$;
  }

  get selected(): MessageModel {
    return this._selected;
  }

  set selected(item: MessageModel) {
    this._selected = item;
    this.selectedSubject.next(this._selected);
  }

  get selected$(): Observable<MessageModel> {
    return this._selected$;
  }
}
