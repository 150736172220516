import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataLookupService, Utils } from '@app/core';
import { AppCacheService } from '@app/core/cache';
import { environment } from '@environments/environment';
import { APP_REQUEST_CONTEXT } from '@shared/loading';
import { Observable, forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ReferenceData } from '@app/core/app.constants';
import { SubmissionType } from '@app/core/submissions';
import { UserApiService } from '../auth';
import { LanguageService } from '@/src/shared/language';

@Injectable({
  providedIn: 'root'
})
export class APICacheService {
  constructor(
    private httpClient: HttpClient,
    private handler: HttpBackend,
    private appCache: AppCacheService,
    private appDataService: AppDataLookupService,
    private userApiService: UserApiService
  ) {
    this.httpClient = new HttpClient(this.handler);
  }

  create(buildVersion: string): Observable<any> {
    const isNewBuild = buildVersion !== this.appCache.buildVersion;
    if (isNewBuild || this.shouldFetch) {
      return this.run().pipe(
        tap(() => {
          this.appCache.setTimestamp();
          this.appCache.setHash();
        })
      );
    } else {
      return this.cacheI18n(); // need to load i18n files on every reload, do something about this
    }
  }

  get shouldFetch() {
    return this.appCache.empty || this.appCache.expired || this.appCache.adding;
  }

  run(): Observable<any> {
    const cacheables = [
      this.cachePackageTypeOptions(),
      this.cacheWarrantTypeOptions(),
      this.cacheProcessStatusOptions(),
      this.cacheCourtRegionOptions(),
      this.cacheCourtHouseOptions(),
      this.cachePriorityLevelOptions(),
      this.cacheAdultOrYouthOptions(),
      this.cacheProvinceOptions(),
      this.cacheUserServiceRoles(),
      this.cacheIsFreeformOptions(),
      this.cacheDelegationTypeOptions()
    ];
    return forkJoin(cacheables);
  }

  private cacheI18n() {
    const en = environment.i18nDirectory + 'en.json';
    const fr = environment.i18nDirectory + 'fr.json';
    return forkJoin([
      this.httpClient.get(en, { context: APP_REQUEST_CONTEXT }),
      this.httpClient.get(fr, { context: APP_REQUEST_CONTEXT })
    ]).pipe(tap((res: any) => ([this.appCache.en, this.appCache.fr] = res)));
  }

  private cachePackageTypeOptions(): Observable<ReferenceData[]> {
    return this.appDataService.getPackageTypeOptions().pipe(tap((data: ReferenceData[]) => (this.appCache.packageTypeOptions = data)));
  }

  private cacheWarrantTypeOptions(): Observable<ReferenceData[]> {
    return this.appDataService.getWarrantTypeOptions().pipe(tap((data: ReferenceData[]) => {
      this.appCache.warrantTypeOptions = data;
      this.appCache.inquiryTypeOptions = data?.filter(wt => wt.parentId === SubmissionType.Inquiry.toString());
      this.appCache.searchWarrantTypeOptions = data?.filter(wt => wt.parentId === SubmissionType.SearchWarrant.toString());
      this.appCache.typeOfItoOptions = [...this.appCache.searchWarrantTypeOptions];
      this.appCache.typeOfPoOptions = data?.filter(wt => wt.parentId === SubmissionType.ProductionOrder.toString());
    }));
  }

  private cacheCourtRegionOptions(): Observable<ReferenceData[]> {
    return this.appDataService.getCourtRegionOptions().pipe(tap((data: ReferenceData[]) => (this.appCache.courtRegionOptions = data)));
  }

  private cacheCourtHouseOptions(): Observable<ReferenceData[]> {
    return this.appDataService.getCourtHouseOptions().pipe(tap((data: ReferenceData[]) => (this.appCache.courtHouseOptions = data)));
  }

  private cacheProcessStatusOptions(): Observable<ReferenceData[]> {
    return this.appDataService.getProcessStatusOptions().pipe(tap((data: ReferenceData[]) => (this.appCache.processStatusOptions = data)));
  }

  private cachePriorityLevelOptions() {
    return this.appDataService.getPriorityLevelOptions().pipe(tap(data => (this.appCache.priorityLevelOptions = data)));
  }

  private cacheIsFreeformOptions() {
    return this.getIsFreeformOptions().pipe(tap(data => (this.appCache.isFreeformOptions = data)));
  }
  
  private cacheAdultOrYouthOptions(){
    return this.appDataService.getAdultOrYouthOptions().pipe(tap(data => (this.appCache.adultOrYouthOptions = data)));
  }

  private cacheProvinceOptions(){
    return this.appDataService.getProvinceOptions().pipe(tap(data => (this.appCache.provinceOptions = data)));
  }
  
  private cacheUserServiceRoles() {
    return this.userApiService.getServiceRoles(true).pipe(tap(data => (this.appCache.userServiceRoleOptions = data)));
  }

  private cacheDelegationTypeOptions(){
    return this.appDataService.getDelegationTypeOptions().pipe(tap(data => (this.appCache.delegationTypeOptions = data)));

  }

  private getIsFreeformOptions(): Observable<any[]> {
    return of([ 
      { id: 'false' , 
        name: {
          en: this.appCache.getPropertyItem('searchWarrantForm.step1.field.free.form.ito.option.1', 'en'),
          fr: this.appCache.getPropertyItem('searchWarrantForm.step1.field.free.form.ito.option.1', 'fr')  
        }
       } , 
      { id: 'true' , 
        name: {
          en: this.appCache.getPropertyItem('searchWarrantForm.step1.field.free.form.ito.option.2', 'en'),
          fr: this.appCache.getPropertyItem('searchWarrantForm.step1.field.free.form.ito.option.2', 'fr')  
        }
      }]);
  }

}
