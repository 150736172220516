import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppCoreModule } from '@app/core';
import { SharedModule } from '@shared/modules';
import { InquiryFormRoutingModule } from './inquiry-form-routing.module';
import { InquiryFormComponent } from './inquiry-form.component';

const components = [InquiryFormComponent];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, InquiryFormRoutingModule, AppCoreModule, SharedModule],
  exports: [...components]
})
export class InquiryFormModule {}
