
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@app/core/auth';
import { isEmpty, of, tap } from 'rxjs';
import { AppCacheService } from '@app/core/cache';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
    selector: 'choose-login-email-dialog',
    templateUrl: './choose-login-email-dialog.component.html',
    styleUrls: ['./choose-login-email-dialog.component.scss'],
})
export class ChooseLoginEmaildialogComponent {

    constructor(
        private userService: UserService,
        public appCache: AppCacheService,
        public dialogRef: MatDialogRef<ChooseLoginEmaildialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            title: string,
            emails: string[],
            certEmail: string,
            currEmail: string,
            close?: string
        }) { 
            this.selectedEmail =  data.currEmail?.toLowerCase() || data.certEmail?.toLowerCase();
            this.emailsList = this.data.emails.map(x => x?.toLowerCase());
        }

    selectedEmail : string = null;
    emailsList: string[] = [];

    usersListColumns: string[] = ['loginEmail','name','title','rank','agency','region'];
    usersList : any[] = [];

    dataSource = new MatTableDataSource<any>();
    @ViewChild('sort', { static: true }) sort: MatSort;
    
    ngAfterViewInit() {
      this.userService.getAllUsers(this.data.certEmail)
      .subscribe(users => {
            this.usersList = users.reduce((x, y) => {
            if (this.emailsList.includes(y.loginEmail.toLowerCase())) 
                x.push(this.userToObj(y));
            return x;
          }, []);
          this.dataSource.data = this.usersList;
          this.dataSource.sort = this.sort;
        });
    }


    private userToObj(user : any) {
        return {
            loginEmail: user.loginEmail,
            name: user.firstName + " " + user.lastName,
            title:  user.titleCode? (this.appCache.getValueFromCache('title',  user.titleCode)??user.titleCode) : "",
            rank: user.rank? (this.appCache.getValueFromCache('rank',  user.rank)??user.rank) : "",
            agency: user.agency? (this.appCache.getValueFromCache('agency',  user.agency)??user.agency) : "",
            region: user.region? (this.appCache.getValueFromCache('region',  user.region)??user.region) : ""
        }
    }

    onItemChange(e) {
        console.log("Selected Account is : ", e.target?.value );
        this.selectedEmail = e.target?.value;
    }


}