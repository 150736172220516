import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'ods-pdf-viewer',
  templateUrl: './ods-pdf-viewer.component.html',
  styleUrls: ['./ods-pdf-viewer.component.scss']
})
export class OdsPdfViewerComponent implements OnInit  {
  @Input() filesName: string[] = [];
  @Input() dataBase64: string[] = [];
  @Input() data: Observable<unknown>;

  @Output() closed = new EventEmitter();

  @ViewChildren('iframeItem') iframeItems: QueryList<ElementRef>;

  constructor(private _cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.data)
      this.data.subscribe(x => {
        if (x && Array.isArray(x)){
          this.filesName = [];
          this.dataBase64 = [];
          x.forEach(d => {
            if (d.fileName) this.filesName.push(d.fileName);
            if (d.base64Content) this.dataBase64.push(d.base64Content);
          })
          this._cd.markForCheck();
        }
      }) ; 
  }

  iframeLoaded(id: number, pdfDataBase64: string): void {
    if (id >= 0 && this.iframeItems && this.iframeItems.length > 0 && id <= this.iframeItems.length - 1) {
      const iframe : ElementRef = this.iframeItems.get(id);
      if (iframe && iframe.nativeElement && iframe.nativeElement.contentWindow) {
        iframe.nativeElement.contentWindow.PDFViewerApplication.open({ data: atob(pdfDataBase64) });
      }
    }
  }

  clickClosePdfViewer(): void {
    this.closed.emit();
  }
}
