import { Component, Input } from '@angular/core';

@Component({
  selector: 'odsf-error',
  styleUrls: ['./odsf-error.component.scss'],
  templateUrl: './odsf-error.component.html'
})
export class OdsfErrorComponent {
  @Input() formCtrlId: string;
}
