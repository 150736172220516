import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Injector, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NotificationType } from '@app/core/notifications';
import { SubmissionDispatcher, SubmissionType, withSubmittedInquiryPackageTable, withSubmittedSearchWarrantPackageTable } from '@app/core/submissions';
import { BaseViewComponent, ManageDelegatesDialogComponent } from '@app/view';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, distinctUntilChanged, forkJoin, of, switchMap, takeWhile, tap } from 'rxjs';
import { UserService } from '@app/core/auth';
import { MatDialog } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { Utils } from '../../core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent extends BaseViewComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;
  @ViewChild('paginator2', { static: true }) paginator2: MatPaginator;
  @ViewChild('sort2', { static: true }) sort2: MatSort;

  @ViewChild('searchSubmittedPackageType', { static: true, read: ElementRef }) searchSubmittedPackageType: ElementRef;
  @ViewChild('searchSubmittedPackageId', { static: true, read: ElementRef }) searchSubmittedPackageId: ElementRef;
  @ViewChild('searchSubmittedSubmissionType', { static: true, read: ElementRef }) searchSubmittedSubmissionType: ElementRef;
  @ViewChild('searchSubmittedStatus', { static: true, read: ElementRef }) searchSubmittedStatus: ElementRef;
  @ViewChild('searchSubmittedSent', { static: true, read: ElementRef  }) searchSubmittedSent: ElementRef;
  @ViewChild('searchSubmittedReceived', { static: true, read: ElementRef  }) searchSubmittedReceived: ElementRef;

  @ViewChild('searchDelegatedBy', { static: true, read: ElementRef }) searchDelegatedBy: ElementRef;
  @ViewChild('searchDelegatedReceived', { static: true, read: ElementRef  }) searchDelegatedReceived: ElementRef;
  @ViewChild('searchDelegatedPackageType', { static: true, read: ElementRef }) searchDelegatedPackageType: ElementRef;
  @ViewChild('searchDelegatedPackageId', { static: true, read: ElementRef }) searchDelegatedPackageId: ElementRef;
  @ViewChild('searchDelegatedSubmissionType', { static: true, read: ElementRef }) searchDelegatedSubmissionType: ElementRef;
  @ViewChild('searchDelegatedStatus', { static: true, read: ElementRef }) searchDelegatedStatus: ElementRef;

  submissions: any[] = [];
  notifications: any = {};
  delegatedPackages: any[] = [];

  isAccordionOpened = true;
  isAccordion11Opened = false;
  isAccordion2Opened = true;
  isAccordion21Opened = false;

  loadingResults = true;

  emptyData = new MatTableDataSource<any>([{ empty: "row" }]);
  
  mySubmittedPackagesDataSource = new MatTableDataSource<any>();
  mySubmittedPackagesResultsLength = 0;

  mySubmittedPackagesDisplayedColumns = [
    'expand',
    'sentDate',
    'receivedDate',
    'packageType',
    'packageId',
    'submissionType',
    'currentStatus',
    'actions'
  ];

  loadingDelegateResults = true;

  delegatedPackagesDataSource = new MatTableDataSource<any>();
  delegatedPackagesResultsLength = 0;

  delegatedPackagesDisplayedColumns = [
    'expand',
    'delegatedBy',
    'receivedDate',
    'packageType',
    'packageId',
    'submissionType',
    'currentStatus',
    'actions'
  ];


  SubmissionType = SubmissionType;

  constructor(
    private injector: Injector,
    private route: ActivatedRoute,
    private submissionDispatcher: SubmissionDispatcher,
    private authService: OidcSecurityService,
    private dialog: MatDialog,
    private userService: UserService,
    private clipboard: Clipboard
  ) {
    super(injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.route.params
      .pipe(
        takeWhile(() => this.alive),
        tap(() => this.register$([this.onNotificationChange, this.onSubmissionsChange, this.onDelegatedPackagesChange])),
        switchMap(() => this.userService.userEmail$.pipe(
          switchMap(email => {
            if (email) 
              return this.loadResources();
            else return of(null);
          })
        )),
        switchMap(()=>this.userService.currentUser$.pipe(
          tap(user => 
            this.userFirstName = user?.['firstName'] || ""
          )
        ))
      )
      .subscribe(() => this.updateView());
  }

  ngAfterViewInit() {
    this.submissionDispatcher.clearNotifications();
  }

  searchSubmittedPackageForm = new FormGroup({
    searchSubmittedPackageType: new FormControl(''),
    searchSubmittedPackageId: new FormControl(''),
    searchSubmittedSubmissionType: new FormControl(''),
    searchSubmittedStatus: new FormControl(''),
    searchSubmittedSent: new FormControl(''),
    searchSubmittedReceived: new FormControl('')
  });

  submittedPackageFormCtrl(name) {
    return this.searchSubmittedPackageForm?.get(name) as FormControl;
  }

  filterSubmittedPackages() {
    if (!this.searchSubmittedPackageType || !this.searchSubmittedPackageType.nativeElement ||
      !this.searchSubmittedPackageId || !this.searchSubmittedPackageId.nativeElement || 
      !this.searchSubmittedSubmissionType || !this.searchSubmittedSubmissionType.nativeElement ||
      !this.searchSubmittedStatus || !this.searchSubmittedStatus.nativeElement ||
      !this.searchSubmittedSent || !this.searchSubmittedSent.nativeElement ||
      !this.searchSubmittedReceived || !this.searchSubmittedReceived.nativeElement) return;
    this.loadingResults = true;
    this.submissionDispatcher
      .loadAllPackages(false, this.searchSubmittedSentText, this.searchSubmittedReceivedText, 
        this.searchSubmittedPackageTypeText, this.searchSubmittedPackageIdText, this.searchSubmittedSubmissionTypeText, this.searchSubmittedStatusText)
      .subscribe(() => this.updateView());
  }

  clearSubmittedPackageForm() {
    this.searchSubmittedPackageForm.reset({
      searchSubmittedPackageType: '',
      searchSubmittedPackageId: '',
      searchSubmittedSubmissionType: '',
      searchSubmittedStatus: '',
      searchSubmittedSent: '',
      searchSubmittedReceived: ''
    });
    this.filterSubmittedPackages();
  }

  searchDelegatedPackageForm = new FormGroup({
    searchDelegatedBy: new FormControl(''),
    searchDelegatedReceived: new FormControl(''),
    searchDelegatedPackageType: new FormControl(''),
    searchDelegatedPackageId: new FormControl(''),
    searchDelegatedSubmissionType: new FormControl(''),
    searchDelegatedStatus: new FormControl('')
  });

  delegatedPackageFormCtrl(name) {
    return this.searchDelegatedPackageForm?.get(name) as FormControl;
  }

  filterDelegatedPackages() {
    if (!this.searchDelegatedBy || !this.searchDelegatedBy.nativeElement ||
      !this.searchDelegatedReceived || !this.searchDelegatedReceived.nativeElement ||
      !this.searchDelegatedPackageType || !this.searchDelegatedPackageType.nativeElement || 
      !this.searchDelegatedPackageId || !this.searchDelegatedPackageId.nativeElement || 
      !this.searchDelegatedSubmissionType || !this.searchDelegatedSubmissionType.nativeElement ||
      !this.searchDelegatedStatus || !this.searchDelegatedStatus.nativeElement) return;
    this.loadingDelegateResults = true;
    this.submissionDispatcher
      .loadAllDelegatedPackages(false, this.searchDelegatedByText, this.searchDelegatedReceivedText, this.searchDelegatedPackageTypeText,
        this.searchDelegatedPackageIdText, this.searchDelegatedSubmissionTypeText, this.searchDelegatedStatusText)
      .subscribe(() => this.updateView());
  }

  clearDelegatedPackageForm() {
    this.searchDelegatedPackageForm.reset({
      searchDelegatedBy: '',
      searchDelegatedReceived: '',
      searchDelegatedPackageType: '',
      searchDelegatedPackageId: '',
      searchDelegatedSubmissionType: '',
      searchDelegatedStatus: ''
    });
    this.filterDelegatedPackages();
  }

  optionItems(name) {
    return this.appCache[`${name}Options`];
  }

  submissionTypeOptionItems() {
    const inquiryTypeOptions = this.appCache.inquiryTypeOptions || [];
    const warrantTypeOptions = this.appCache.warrantTypeOptions || [];
    return [...inquiryTypeOptions, ...warrantTypeOptions];
  }

  get searchSubmittedPackageTypeText() {
    return this.searchSubmittedPackageForm.get('searchSubmittedPackageType').value;
  }

  get searchSubmittedPackageIdText() {
    return this.searchSubmittedPackageForm.get('searchSubmittedPackageId').value;
  }

  get searchSubmittedSubmissionTypeText() {
    return this.searchSubmittedPackageForm.get('searchSubmittedSubmissionType').value;
  }

  get searchSubmittedStatusText() {
    return this.searchSubmittedPackageForm.get('searchSubmittedStatus').value;
  }

  get searchSubmittedSentText() {
    return this.searchSubmittedPackageForm.get('searchSubmittedSent').value;
  }

  get searchSubmittedReceivedText() {
    return this.searchSubmittedPackageForm.get('searchSubmittedReceived').value;
  }

  get searchDelegatedByText() {
    return this.searchDelegatedPackageForm.get('searchDelegatedBy').value;
  }

  get searchDelegatedReceivedText() {
    return this.searchDelegatedPackageForm.get('searchDelegatedReceived').value;
  }

  get searchDelegatedPackageTypeText() {
    return this.searchDelegatedPackageForm.get('searchDelegatedPackageType').value;
  }

  get searchDelegatedPackageIdText() {
    return this.searchDelegatedPackageForm.get('searchDelegatedPackageId').value;
  }

  get searchDelegatedSubmissionTypeText() {
    return this.searchDelegatedPackageForm.get('searchDelegatedSubmissionType').value;
  }

  get searchDelegatedStatusText() {
    return this.searchDelegatedPackageForm.get('searchDelegatedStatus').value;
  }

  private loadResources() {
    this.loadingResults = true;
    this.loadingDelegateResults = true;
    return forkJoin([this.submissionDispatcher.loadAllPackages(), this.submissionDispatcher.loadAllDelegatedPackages()]);
  }

  private get onNotificationChange() {
    return this.submissionDispatcher.notifications$?.pipe(
      takeWhile(() => this.alive),
      distinctUntilChanged(),
      tap(notifications => {
        this.notifications = {
          error: notifications?.find(n => n?.type === NotificationType.Error),
          success: notifications?.find(n => n?.type === NotificationType.Success)
        };
      })
    );
  }

  private get onSubmissionsChange() {
    return this.submissionDispatcher.submissions$?.pipe(
      takeWhile(() => this.alive),
      distinctUntilChanged(),
      tap(submissions => {
        this.submissions = submissions?.map(s => Object.assign(s, s?.packageType === SubmissionType.Inquiry ? withSubmittedInquiryPackageTable({ state: s }) : withSubmittedSearchWarrantPackageTable({ state: s })));
        this.updatePackageTable(this.submissions);
      })
    );
  }

  private get onDelegatedPackagesChange() {
    return this.submissionDispatcher.delegatedPackages$.pipe(
      takeWhile(() => this.alive),
      distinctUntilChanged(),
      tap(delegatedPackages => {
        this.delegatedPackages = delegatedPackages?.map(s => Object.assign(s, s?.packageType === SubmissionType.Inquiry ? withSubmittedInquiryPackageTable({ state: s }) : withSubmittedSearchWarrantPackageTable({ state: s })));
        this.updateDelegatedPackageTable(this.delegatedPackages);
      })
    );
  }

  private updatePackageTable(data?) {
    this.loadingResults = false;
    //if (data && data.length) {
      this.mySubmittedPackagesResultsLength = data.length;
      this.mySubmittedPackagesDataSource.data = data.map((s, index) => Object.assign({ index }, s));
      this.mySubmittedPackagesDataSource.paginator = this.paginator;
      this.mySubmittedPackagesDataSource.sort = this.sort;
    //}
  }

  private updateDelegatedPackageTable(data?) {
    this.loadingDelegateResults = false;
    //if (data && data.length) {
      this.delegatedPackagesResultsLength = data.length;
      this.delegatedPackagesDataSource.data = data.map((s, index) => Object.assign({ index }, s));
      this.delegatedPackagesDataSource.paginator = this.paginator2;
      this.delegatedPackagesDataSource.sort = this.sort2;
    //}
  }

  findOptionItem(name, id) {
    return this.appCache[`${name}Options`]?.find(o => Number(o?.id) === Number(id));
  }

  archiveSubmission(row) {
    this.loadingResults = true;
    return this.submissionDispatcher.archive(row, { from: this.router.url }).subscribe(
      {complete: ()=> this.loadingResults = false}
    );
  }

  deleteSubmission(row) {
    this.loadingResults = true;
    return this.submissionDispatcher.delete(row, { from: this.router.url }).subscribe(
      {complete: ()=> this.loadingResults = false}
    );
  }

  expendPackage(loadingStr, row) {
    this[loadingStr] = true;
    this.submissionDispatcher.getAllPackageWarrants(row.packageUUID)
    .subscribe({
      next: x => {
        row.itoList = x.map(s => {
          return {
          'itoType': s?.typeCode? this.appCache.getValueFromCache('typeOfIto', s.typeCode):"",
          'address': s?.address,
          'controlNumber': s?.referenceNum,
          'status': s?.decisionStatus,
          'actions': '[download]/[attach]'
        }});
      },
      complete: () => {
        this[loadingStr] = false;
        this.changeDetectorRef.markForCheck();
      }
    });
    this.expandedPackage[row.packageUUID] = true;
  }

  collapsePackage(row) {
    this.expandedPackage[row.packageUUID] = false;
  }

  expandedPackage: { [key: string]: any } = {};
  ViewPackagePDF: { [key: string]: any } = {};

  itoListColumns: string[] = ['itoType', 'address', 'controlNumber', 'status', 'actions'];
  @ViewChildren('innerItosTables') innerItosTables: QueryList<MatTable<any>>;


  logout() {
    this.authService.logoff().subscribe();
  }

  userFirstName  = '';

  manageDelegateUser(packageId) {
    this.dialog.open(ManageDelegatesDialogComponent,{
      width: '80rem',
      data: {
        packageId, 
        close: this.appCache.getPropertyItem('text.close')
      }
      }).afterClosed().subscribe();
  }

  copyToClipboard(text : string) {
    this.clipboard.copy(text);
    alert(this.appCache.getPropertyItem('home.table.packages.packageNumber.copied') + text);
  }

  DownloadSubmission(row: any){
    this.loadingResults = true;
    (row.downloadedData ? of(row.downloadedData) :
    this.submissionDispatcher.download(row, { from: this.router.url }).pipe(
      tap(x => row.downloadedData = x)
    )).subscribe({
      next: x => {
        let msg = "";
        if (x && x?.length > 0){
          x.forEach((element,index )=> {
            const fileName = `${row?.packageType}_${row.packageUUID}_${index+1}_${element.fileName}.pdf`;
            Utils.saveBase64ToFile(element.base64Content, fileName);
            msg += `${fileName} was downloaded. \n`;
          });
        }
        this.loadingResults = false;
        this.changeDetectorRef.markForCheck();
        setTimeout(()=> window.alert(msg), 1000);
      },
      error: () => {
        this.loadingResults = false;
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  ViewSubmission(row: any){
    this.ViewPackagePDF[row.packageUUID] = this.ViewPackagePDF[row.packageUUID] ? !this.ViewPackagePDF[row.packageUUID] : true;
  }

  getViewPackagePDFBase64(row: any) : Observable<any> {
    return row.downloadedData ? of(row.downloadedData) : this.submissionDispatcher.download(row, { from: this.router.url }).pipe(
      tap(x => row.downloadedData = x)
    );
  }

  closePdfViewer(row : any) {
    this.ViewPackagePDF[row.packageUUID] = false;
  }

}