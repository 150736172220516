import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule} from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { OdsBackToTopButtonComponent } from './ods-back-to-top-button/ods-back-to-top-button.component';
import { OdsCalloutComponent } from './ods-callout/ods-callout.component';
import { OdsPageAlertType } from './ods-page-alert/ods-page-alert-type.enum';
import { OdsPageAlertComponent } from './ods-page-alert/ods-page-alert.component';
import { OdsSpinnerComponent } from './ods-spinner/ods-spinner.component';
import { OdsStatusLabelComponent } from './ods-status-label/ods-status-label.component';
import { OdsTableComponent } from './ods-table/ods-table.component';
import { OdsfCheckboxComponent } from './odsf-checkbox/odsf-checkbox.component';
import { OdsfRadioComponent } from './odsf-radio/odsf-radio.component';
import { OdsfErrorComponent } from './odsf-error/odsf-error.component';
import { OdsfInputComponent } from './odsf-input/odsf-input.component';
import { OdsfLabelComponent } from './odsf-label/odsf-label.component';
import { OdsfSelectComponent } from './odsf-select/odsf-select.component';
import { OdsfTextareaComponent } from './odsf-textarea/odsf-textarea.component';
import { OdsDialogComponent } from './ods-dialog/ods-dialog.component';
import { OdsEditorComponent } from './ods-editor/ods-editor.component';
import { NgxEditorModule } from 'ngx-editor';
import { OdsfTextareaPlusComponent } from './odsf-textarea-plus/odsf-textarea-plus.component';
import { OdsfTextareaProComponent } from './odsf-textarea-pro/odsf-textarea-pro.component';
import { OdsAccordionComponent } from './ods-accordion/ods-accordion.component';
import { OdsPdfViewerComponent} from './ods-pdf-viewer/ods-pdf-viewer.component'
import { ContentTransformationModule } from '../../content-transformation';
import { OdsDatepickerComponent } from './ods-datepicker/ods-datepicker.component';
import { AppCoreModule } from "@app/core";

const materialModules = [
  MatTooltipModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatSortModule,
  MatPaginatorModule,
  MatTableModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatIconModule,
  MatDatepickerModule,
  MatNativeDateModule
];

const odsComponents = [
  OdsCalloutComponent,
  OdsPageAlertComponent,
  OdsSpinnerComponent,
  OdsBackToTopButtonComponent,
  OdsStatusLabelComponent,
  OdsTableComponent,
  OdsDialogComponent,
  OdsEditorComponent,
  OdsfTextareaPlusComponent,
  OdsfTextareaProComponent,
  OdsPdfViewerComponent,
  OdsAccordionComponent,
  OdsDatepickerComponent
];

const odsFormComponents = [OdsfLabelComponent, OdsfInputComponent, OdsfTextareaComponent, OdsfSelectComponent, OdsfCheckboxComponent, OdsfRadioComponent, OdsfErrorComponent];

@NgModule({
    declarations: [...odsComponents, ...odsFormComponents],
    exports: [...odsComponents, ...odsFormComponents],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, ...materialModules, NgxEditorModule, ContentTransformationModule, AppCoreModule]
})
class OdsComponentsModule {}

export {
  OdsBackToTopButtonComponent,
  OdsCalloutComponent,
  OdsComponentsModule,
  OdsPageAlertComponent,
  OdsPageAlertType,
  OdsSpinnerComponent,
  OdsStatusLabelComponent,
  OdsTableComponent,
  OdsAccordionComponent,
  OdsfCheckboxComponent,
  OdsfRadioComponent,
  OdsfErrorComponent,
  OdsfInputComponent,
  OdsfLabelComponent,
  OdsfSelectComponent,
  OdsfTextareaComponent,
  OdsDialogComponent,
  OdsEditorComponent,
  OdsfTextareaPlusComponent,
  OdsfTextareaProComponent
};
