import { NgModule } from '@angular/core';
import { InquiryTypePipe } from './inquiry-type.pipe';
import { InquirySchema } from './inquiry.schema';
import { SubmissionStatusPipe } from './submission-status.pipe';
import { SubmissionTypePipe } from './submission-type.pipe';
import { DEFAULT_STATUS, SubmissionConstants, SubmissionStatus, SubmissionType, InquiryType } from './submission.constants';
import { SubmissionDispatcher } from './submission.dispatcher';
import { Submission, SubmissionModel, inquiryForm, withSubmittedInquiryPackageTable, SubmissionArguments } from './submission.model';
import { SubmissionSchema } from './submission.schema';
import { SubmissionService } from './submission.service';
import { searchWarrantForm, withSubmittedSearchWarrantPackageTable, SW_DATA_TYPE} from './submission.searchwarrant.model';
import { searchWarrantSchema} from  './submission.searchwarrant.schema';
import { SubmissionSearchWarrantDispatcher } from './submission.searchwarrant.dispatcher';
import { WarrantTypePipe } from './warrant-type.pipe';
import { SubmissionApiService } from './submission-api.service';
import { productionOrderForm, withSubmittedProductionOrderPackageTable, PO_DATA_TYPE} from './submission.productionorder.model';
import { productionOrderSchema} from  './submission.productionorder.schema';
import { SubmissionProductionOrderDispatcher } from './submission.productionorder.dispatcher';


const mods = [SubmissionTypePipe, SubmissionStatusPipe, InquiryTypePipe, WarrantTypePipe];

@NgModule({
  declarations: [...mods],
  exports: [...mods]
})
class SubmissionModule {}

export {
  DEFAULT_STATUS,
  InquirySchema,
  InquiryTypePipe,
  WarrantTypePipe,
  Submission,
  SubmissionConstants,
  SubmissionDispatcher,
  SubmissionModel,
  SubmissionModule,
  SubmissionSchema,
  SubmissionArguments,
  SubmissionApiService,
  SubmissionService,
  SubmissionStatus,
  SubmissionStatusPipe,
  SubmissionType,
  SubmissionTypePipe,
  inquiryForm,
  InquiryType,
  withSubmittedInquiryPackageTable,
  searchWarrantForm,
  searchWarrantSchema,
  SubmissionSearchWarrantDispatcher,
  SW_DATA_TYPE,
  withSubmittedSearchWarrantPackageTable,
  productionOrderForm,
  productionOrderSchema,
  SubmissionProductionOrderDispatcher,
  PO_DATA_TYPE,
  withSubmittedProductionOrderPackageTable
};
