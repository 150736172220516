<ng-container *ngIf="view.isReady">
  <ng-container>
    <!-- title -->
    <div class="ontario-row">
      <h1>{{ (isFormView?"productionOrderForm.create.title":
              isEditView? "productionOrderForm.update.title": "productionOrderForm.readonly.title") | translate | async }}</h1>
    </div>
    <!-- form input errors from server -->
    <ng-container *ngIf="shouldShowErrors" [ngTemplateOutlet]="errorNotificationTemplate"></ng-container>

    <div *ngIf="productionOrder?.form" class="ontario-row">
      <ng-container *ngTemplateOutlet="formTemplateStepNavigater"></ng-container>
      <container-element [ngSwitch]="currentStep">
          <ng-container *ngSwitchCase=1>
            <ng-container *ngTemplateOutlet="formTemplateStep1;context:{readonly:isReadOnlyView}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase=2>
            <ng-container *ngTemplateOutlet="formTemplateStep2;context:{readonly:isReadOnlyView}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase=3>
            <ng-container *ngTemplateOutlet="formTemplateStep3;context:{readonly:isReadOnlyView}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase=4>
            <ng-container *ngTemplateOutlet="formTemplateStep4;context:{readonly: isReadOnlyView || isEditAsDelegate}"></ng-container>
          </ng-container>
      </container-element>
      <ng-container *ngTemplateOutlet="buttonsDiv"></ng-container>
    </div>        
  </ng-container>
   
  <ng-template #formTemplateStep1 let-readonly="readonly">
    <div class="ontario-row">
      <form novalidate [formGroup]="productionOrder.form" class="ontario-row" autocomplete="off">
        <div class="ontario-row">
          <!-- information -->
          <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-label">
              {{"productionOrderForm.step1.field.courthouse.instruction" | translate | async }}
            </div>
          </div>
        </div>
        
        <div class="ontario-row">
          <!-- court region -->
          <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('courtRegion').required" formCtrlId="{{ field('courtRegion').id }}">
                <span name>{{ field('courtRegion').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
        
              <odsf-error *ngIf="!readonly && field('courtRegion').required && formCtrl('courtRegion')?.hasError('required')" formCtrlId="{{ field('courtRegion').id }}">
                {{ field('courtRegion').getValidator("required").message | translate : [field('courtRegion').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-select formCtrlId="{{ field('courtRegion').id }}" formCtrlName="{{ field('courtRegion').name }}" [formCtrl]="formCtrl('courtRegion')" [required]="field('courtRegion').required">
                <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                <option *ngFor="let item of optionItems('courtRegion') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                  {{ item.name[lang$ | async] }}
                </option>
              </odsf-select>
            </div>
          </div>
        
          <!-- courthouse -->
          <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('courthouse').required  && !formCtrl('courthouse')?.disabled" formCtrlId="{{ field('courthouse').id }}">
                <span name>{{ field('courthouse').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
  
              <odsf-error *ngIf="!readonly && field('courthouse').required && formCtrl('courthouse')?.hasError('required')" formCtrlId="{{ field('courthouse').id }}">
                {{ field('courthouse').getValidator("required").message | translate : [field('courthouse').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-select formCtrlId="{{ field('courthouse').id }}" formCtrlName="{{ field('courthouse').name }}" [formCtrl]="formCtrl('courthouse')" [required]="field('courthouse').required">
                <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                <option *ngFor="let item of (readonly ? optionItems('courtHouse') : courtHouseOptions) | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                  {{ item.name[lang$ | async] }} ({{ item.id }})
                </option>
              </odsf-select>
            </div>
          </div>

          <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>
  
        <div class="ontario-row">
          <!-- Is this ITO associated to previously submitted ITO or on-going project -->
          <div class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('associatedPreviousApp').required" formCtrlId="{{ field('associatedPreviousApp').id }}">
                <span name>{{ field('associatedPreviousApp').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
  
              <odsf-error *ngIf="!readonly && field('associatedPreviousApp').required && formCtrl('associatedPreviousApp')?.hasError('required')" formCtrlId="{{ field('associatedPreviousApp').id }}">
                {{ field('associatedPreviousApp').getValidator("required").message | translate : [field('associatedPreviousApp').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-radio formCtrlId="{{ field('associatedPreviousApp').id }}" formCtrlName="{{ field('associatedPreviousApp').name }}" [formCtrl]="formCtrl('associatedPreviousApp')" [required]="field('associatedPreviousApp').requiredTrue"
                [optionItems]="['radio.value.yes'| translate | async, 'radio.value.no'| translate | async ]" [values]="[true,false]" ></odsf-radio>
            </div>
          </div>

          <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>

        <div class="ontario-row" *ngIf="!formCtrl('associatedPreviousNum')?.disabled || (readonly && formCtrl('associatedPreviousApp')?.value)">
          <!-- Enter one of the following: Control, Reference or Package number -->
          <div class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('associatedPreviousNum').required && !formCtrl('associatedPreviousNum')?.disabled" formCtrlId="{{ field('associatedPreviousNum').id }}">
                <span name>{{ field('associatedPreviousNum').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
  
              <odsf-error *ngIf="!readonly && field('associatedPreviousNum').required && formCtrl('associatedPreviousNum')?.hasError('required')" formCtrlId="{{ field('associatedPreviousNum').id }}">
                {{ field('associatedPreviousNum').getValidator("required").message | translate : [field('associatedPreviousNum').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-error *ngIf="!readonly && field('associatedPreviousNum').maxLength && formCtrl('associatedPreviousNum')?.hasError('maxlength')" formCtrlId="{{ field('associatedPreviousNum').id }}">
                {{ field('associatedPreviousNum').getValidator("maxLength").message | translate : [field('associatedPreviousNum').label | translate | async, formCtrl('associatedPreviousNum')?.errors["maxlength"].requiredLength] | async }}
              </odsf-error>

              <odsf-error *ngIf="!readonly && field('associatedPreviousNum').minLength && formCtrl('associatedPreviousNum')?.hasError('minlength')" formCtrlId="{{ field('associatedPreviousNum').id }}">
                {{ field('associatedPreviousNum').getValidator("minLength").message | translate : [field('associatedPreviousNum').label | translate | async, formCtrl('associatedPreviousNum')?.errors["minlength"].requiredLength] | async }}
              </odsf-error>              
  
              <odsf-error *ngIf="!readonly && field('associatedPreviousNum').pattern && formCtrl('associatedPreviousNum')?.hasError('pattern')" formCtrlId="{{ field('associatedPreviousNum').id }}">
                {{ field('associatedPreviousNum').getValidator("pattern").message | translate : [field('associatedPreviousNum').label | translate | async | lowercase ] | async }}
              </odsf-error>

              <odsf-input formCtrlId="{{ field('associatedPreviousNum').id }}" formCtrlName="{{ field('associatedPreviousNum').name }}" [formCtrl]="formCtrl('associatedPreviousNum')" [required]="field('associatedPreviousNum').required" [maxLength]="field('associatedPreviousNum').maxLength">
                <span *ngIf="!readonly && field('associatedPreviousNum').maxLength && !formCtrl('associatedPreviousNum')?.disabled && (formCtrl('associatedPreviousNum')?.value?.length <= field('associatedPreviousNum')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('associatedPreviousNum')?.maxLength - formCtrl('associatedPreviousNum')?.value?.length] | async }}</span>
              </odsf-input>
            </div>
          </div>

          <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>

        <div class="ontario-row">
          <!-- Select the priority level to be displayed for this ITO submission to the judiciary -->
          <div class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('priorityCode').required" formCtrlId="{{ field('priorityCode').id }}">
                <span name>{{ field('priorityCode').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
  
              <odsf-error *ngIf="!readonly && field('priorityCode').required && formCtrl('priorityCode')?.hasError('required')" formCtrlId="{{ field('priorityCode').id }}">
                {{ field('priorityCode').getValidator("required").message | translate : [field('priorityCode').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <div class="isMaxwidth50Percent">
                <odsf-select formCtrlId="{{ field('priorityCode').id }}" formCtrlName="{{ field('priorityCode').name }}" [formCtrl]="formCtrl('priorityCode')" [required]="field('priorityCode').required" >
                  <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                  <option *ngFor="let item of optionItems('priorityLevel') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                    {{ item.name[lang$ | async] }}
                  </option>
                </odsf-select>
              </div>
            </div>
          </div>

          <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>

        <div class="ontario-row">
          <!-- Enter the offence wording, section number, and status  -->
          <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('enterOffenceWording').required" formCtrlId="{{ field('enterOffenceWording').id }}">
                <span name>{{ field('enterOffenceWording').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>

              <odsf-error *ngIf="!readonly && field('enterOffenceWording').required && formCtrl('enterOffenceWording')?.hasError('required')" formCtrlId="{{ field('enterOffenceWording').id }}">
                {{ field('enterOffenceWording').getValidator("required").message | translate : [field('enterOffenceWording').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-error *ngIf="!readonly && field('enterOffenceWording').maxLength && formCtrl('enterOffenceWording')?.hasError('maxlength')" formCtrlId="{{ field('enterOffenceWording').id }}">
                {{ field('enterOffenceWording').getValidator("maxLength").message | translate : [field('enterOffenceWording').label | translate | async, formCtrl('enterOffenceWording')?.errors["maxlength"].requiredLength] | async }}
              </odsf-error>
  
              <odsf-textarea-pro formCtrlId="{{ field('enterOffenceWording').id }}" formCtrlName="{{ field('enterOffenceWording').name }}" [formCtrl]="formCtrl('enterOffenceWording')" [required]="field('enterOffenceWording').required">
                <span *ngIf="!readonly && field('enterOffenceWording').maxLength && (formCtrl('enterOffenceWording')?.value?.length <= field('enterOffenceWording')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('enterOffenceWording')?.maxLength - formCtrl('enterOffenceWording')?.value?.length] | async }}</span>
              </odsf-textarea-pro>
            </div>
          </div>
          <div class="ontario-columns ontario-large-2 ontario-medium-1 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>

      </form>
    </div>
  </ng-template>
  
  <ng-template #formTemplateStep2 let-readonly="readonly">
    <div class="ontario-row">
      <form novalidate [formGroup]="productionOrder.form" class="ontario-row" autocomplete="off">
        <div class="ontario-row">
          <!-- Select ITO Type -->
          <div class="ontario-columns ontario-large-4 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('poTypeCode').required" formCtrlId="{{ field('poTypeCode').id }}">
                <span name>{{ field('poTypeCode').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
  
              <odsf-error *ngIf="!readonly && field('poTypeCode').required && formCtrl('poTypeCode')?.hasError('required')" formCtrlId="{{ field('poTypeCode').id }}">
                {{ field('poTypeCode').getValidator("required").message | translate : [field('poTypeCode').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-select #selectPoType formCtrlId="{{ field('poTypeCode').id }}" formCtrlName="{{ field('poTypeCode').name }}" [formCtrl]="formCtrl('poTypeCode')" [required]="field('poTypeCode').required" [disabled]="startedANewPO">
                <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                <option *ngFor="let item of optionItems('typeOfPo') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                  {{ item.name[lang$ | async] }}
                </option>
              </odsf-select>
            </div>
          </div>
        
          <!-- button Start -->
          <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-16-!">
            <div class="ontario-form-group">
              <!--
              <button [disabled]="startedANewPO || isReadOnlyView" [ngClass]="{'disabled': startedANewPO || isReadOnlyView}" (click)="startBtnClick()" class="ontario-button step2_button_v">{{ "productionOrderForm.step2.button.ito.type.start" | translate | async }}</button>
              -->
              <button (click)="clearFormForStep2()" class="ontario-button ontario-button--secondary step2_button_v">{{ "productionOrderForm.step2.button.clear.form" | translate | async }}</button>
            </div>
          </div>
          <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>

        <div class="ontario-row">
          <hr>
        </div>

        <ng-container *ngIf="startedANewPO">

          <div class="ontario-row">
            <div class="ontario-label">
              {{"productionOrderForm.step2.field.person.organization" | translate | async }}&NonBreakingSpace;({{ "form.field.required.text" | translate | async }})<br/>
              <span class="ontario-label__flag">{{"productionOrderForm.step2.field.person.organization.hint" | translate | async }}</span>
            </div>
          </div>
    
          <!-- Person or organization in possession or control of the document or data -->
          <div class="ontario-columns ontario-large-12 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-error *ngIf="!readonly && field('personAndOrOrgName').required && formCtrl('personAndOrOrgName')?.hasError('required')" formCtrlId="{{ field('personAndOrOrgName').id }}">
                {{ field('personAndOrOrgName').getValidator("required").message | translate : [field('personAndOrOrgName').label | translate | async | lowercase] | async }}
              </odsf-error>

              <odsf-input formCtrlId="{{ field('personAndOrOrgName').id }}" formCtrlName="{{ field('personAndOrOrgName').name }}" [formCtrl]="formCtrl('personAndOrOrgName')" [required]="field('personAndOrOrgName').required" [hidden]="true">
              </odsf-input>
            </div>
          </div>

          <div class="ontario-row">
            <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-6 ontario-padding-left-0-!">
              <!-- Given Name -->
              <div class="ontario-form-group">
                <odsf-label [required]="field('personGivenName').required && !formCtrl('personGivenName')?.disabled" formCtrlId="{{ field('personGivenName').id }}">
                  <span name>{{ field('personGivenName').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('personGivenName').required && formCtrl('personGivenName')?.hasError('required')" formCtrlId="{{ field('personGivenName').id }}">
                  {{ field('personGivenName').getValidator("required").message | translate : [field('personGivenName').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('personGivenName').maxLength && formCtrl('personGivenName')?.hasError('maxlength')" formCtrlId="{{ field('personGivenName').id }}">
                  {{ field('personGivenName').getValidator("maxLength").message | translate : [field('personGivenName').label | translate | async, formCtrl('personGivenName')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-input formCtrlId="{{ field('personGivenName').id }}" formCtrlName="{{ field('personGivenName').name }}" [formCtrl]="formCtrl('personGivenName')" [required]="field('personGivenName').required">
                  <span *ngIf="!readonly && field('personGivenName').maxLength && !formCtrl('personGivenName')?.disabled && (formCtrl('personGivenName')?.value?.length <= field('personGivenName')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('personGivenName')?.maxLength - formCtrl('personGivenName')?.value?.length] | async }}</span>
                </odsf-input>
              </div>
            </div>
          
            <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-6 ontario-padding-left-0-!">
              <!--Surname -->
              <div class="ontario-form-group">
                <odsf-label [required]="field('personSurname').required && !formCtrl('personSurname')?.disabled" formCtrlId="{{ field('personSurname').id }}">
                  <span name>{{ field('personSurname').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('personSurname').required && formCtrl('personSurname')?.hasError('required')" formCtrlId="{{ field('personSurname').id }}">
                  {{ field('personSurname').getValidator("required").message | translate : [field('personSurname').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('personSurname').maxLength && formCtrl('personSurname')?.hasError('maxlength')" formCtrlId="{{ field('personSurname').id }}">
                  {{ field('personSurname').getValidator("maxLength").message | translate : [field('personSurname').label | translate | async, formCtrl('personSurname')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-input formCtrlId="{{ field('personSurname').id }}" formCtrlName="{{ field('personSurname').name }}" [formCtrl]="formCtrl('personSurname')" [required]="field('personSurname').required">
                  <span *ngIf="!readonly && field('personSurname').maxLength && !formCtrl('personSurname')?.disabled && (formCtrl('personSurname')?.value?.length <= field('personSurname')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('personSurname')?.maxLength - formCtrl('personSurname')?.value?.length] | async }}</span>
                </odsf-input>
              </div>
            </div>

            <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
          </div>

          <div class="ontario-row">
            <!-- Organization name -->
            <div class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('organizationName').required && !formCtrl('organizationName')?.disabled" formCtrlId="{{ field('organizationName').id }}">
                  <span name>{{ field('organizationName').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
                <span>{{ field('organizationName').hint | translate | async}}</span><br/>

                <odsf-error *ngIf="!readonly && field('organizationName').required && formCtrl('organizationName')?.hasError('required')" formCtrlId="{{ field('organizationName').id }}">
                  {{ field('organizationName').getValidator("required").message | translate : [field('organizationName').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('organizationName').maxLength && formCtrl('organizationName')?.hasError('maxlength')" formCtrlId="{{ field('organizationName').id }}">
                  {{ field('organizationName').getValidator("maxLength").message | translate : [field('organizationName').label | translate | async, formCtrl('organizationName')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-textarea-pro formCtrlId="{{ field('organizationName').id }}" formCtrlName="{{ field('organizationName').name }}" [formCtrl]="formCtrl('organizationName')" [required]="field('organizationName').required">
                  <span *ngIf="!readonly && field('organizationName').maxLength && !formCtrl('organizationName')?.disabled && (formCtrl('organizationName')?.value?.length <= field('organizationName')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('organizationName')?.maxLength - formCtrl('organizationName')?.value?.length] | async }}</span>
                </odsf-textarea-pro>
              </div>
            </div>
            <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
          </div>

          <div class="ontario-row">
            <!-- Address -->
            <div class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('address2Searched').required" formCtrlId="{{ field('address2Searched').id }}">
                  <span name>{{ field('address2Searched').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('address2Searched').required && formCtrl('address2Searched')?.hasError('required')" formCtrlId="{{ field('address2Searched').id }}">
                  {{ field('address2Searched').getValidator("required").message | translate : [field('address2Searched').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('address2Searched').maxLength && formCtrl('address2Searched')?.hasError('maxlength')" formCtrlId="{{ field('address2Searched').id }}">
                  {{ field('address2Searched').getValidator("maxLength").message | translate : [field('address2Searched').label | translate | async, formCtrl('address2Searched')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-textarea-pro formCtrlId="{{ field('address2Searched').id }}" formCtrlName="{{ field('address2Searched').name }}" [formCtrl]="formCtrl('address2Searched')" [required]="field('address2Searched').required">
                  <span *ngIf="!readonly && field('address2Searched').maxLength && !formCtrl('address2Searched')?.disabled && (formCtrl('address2Searched')?.value?.length <= field('address2Searched')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('address2Searched')?.maxLength - formCtrl('address2Searched')?.value?.length] | async }}</span>
                </odsf-textarea-pro>
              </div>
            </div>
            <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
          </div>

          <div class="ontario-row">
            <!-- City -->
            <div class="ontario-columns ontario-large-5 ontario-medium-6 ontario-small-7 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('addressCity').required" formCtrlId="{{ field('addressCity').id }}">
                  <span name>{{ field('addressCity').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('addressCity').required && formCtrl('addressCity')?.hasError('required')" formCtrlId="{{ field('addressCity').id }}">
                  {{ field('addressCity').getValidator("required").message | translate : [field('addressCity').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('addressCity').maxLength && formCtrl('addressCity')?.hasError('maxlength')" formCtrlId="{{ field('addressCity').id }}">
                  {{ field('addressCity').getValidator("maxLength").message | translate : [field('addressCity').label | translate | async, formCtrl('addressCity')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-input formCtrlId="{{ field('addressCity').id }}" formCtrlName="{{ field('addressCity').name }}" [formCtrl]="formCtrl('addressCity')" [required]="field('addressCity').required">
                  <span *ngIf="!readonly && field('addressCity').maxLength && !formCtrl('addressCity')?.disabled && (formCtrl('addressCity')?.value?.length <= field('addressCity')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('addressCity')?.maxLength - formCtrl('addressCity')?.value?.length] | async }}</span>
                </odsf-input>
              </div>
            </div>
            
            <!-- Province -->
            <div class="ontario-columns ontario-large-3 ontario-medium-4 ontario-small-5 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('addressProvinceCode').required" formCtrlId="{{ field('addressProvinceCode').id }}">
                  <span name>{{ field('addressProvinceCode').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('addressProvinceCode').required && formCtrl('addressProvinceCode')?.hasError('required')" formCtrlId="{{ field('addressProvinceCode').id }}">
                  {{ field('addressProvinceCode').getValidator("required").message | translate : [field('addressProvinceCode').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-select formCtrlId="{{ field('addressProvinceCode').id }}" formCtrlName="{{ field('addressProvinceCode').name }}" [formCtrl]="formCtrl('addressProvinceCode')" [required]="field('addressProvinceCode').required">
                  <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                  <option *ngFor="let item of optionItems('province') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                    {{ item.name[lang$ | async] }}
                  </option>
                </odsf-select>
              </div>
            </div>

            <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
          </div>

          <div class="ontario-row">
            <!-- What are you requesting to be produced -->
            <div class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('selectionOfDocOrData').required" formCtrlId="{{ field('selectionOfDocOrData').id }}">
                  <span name>{{ field('selectionOfDocOrData').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('selectionOfDocOrData').required && formCtrl('selectionOfDocOrData')?.hasError('required')" formCtrlId="{{ field('selectionOfDocOrData').id }}">
                  {{ field('selectionOfDocOrData').getValidator("required").message | translate : [field('selectionOfDocOrData').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-select formCtrlId="{{ field('selectionOfDocOrData').id }}" formCtrlName="{{ field('selectionOfDocOrData').name }}" [formCtrl]="formCtrl('selectionOfDocOrData')" [required]="field('selectionOfDocOrData').required">
                    <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                    <option value="Document">{{ "productionOrderForm.step2.field.what.be.produced.option.document" | translate | async }}</option>
                    <option value="Data">{{ "productionOrderForm.step2.field.what.be.produced.option.data" | translate | async }}</option>
                    <option value="DocAndData">{{ "productionOrderForm.step2.field.what.be.produced.option.both" | translate | async }}</option>
                  </odsf-select>
              </div>
            </div>
            <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
          </div>

          <div class="ontario-row" *ngIf="!formCtrl('documentCopy')?.disabled || (readonly && formCtrl('documentCopy')?.value)">
            <!-- Document that is a copy of -->
            <div class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('documentCopy').required" formCtrlId="{{ field('documentCopy').id }}">
                  <span name>{{ field('documentCopy').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('documentCopy').required && formCtrl('documentCopy')?.hasError('required')" formCtrlId="{{ field('documentCopy').id }}">
                  {{ field('documentCopy').getValidator("required").message | translate : [field('documentCopy').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('documentCopy').maxLength && formCtrl('documentCopy')?.hasError('maxlength')" formCtrlId="{{ field('documentCopy').id }}">
                  {{ field('documentCopy').getValidator("maxLength").message | translate : [field('documentCopy').label | translate | async, formCtrl('documentCopy')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-textarea-pro formCtrlId="{{ field('documentCopy').id }}" formCtrlName="{{ field('documentCopy').name }}" [formCtrl]="formCtrl('documentCopy')" [required]="field('documentCopy').required">
                  <span *ngIf="!readonly && field('documentCopy').maxLength && !formCtrl('documentCopy')?.disabled && (formCtrl('documentCopy')?.value?.length <= field('documentCopy')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('documentCopy')?.maxLength - formCtrl('documentCopy')?.value?.length] | async }}</span>
                </odsf-textarea-pro>
              </div>
            </div>
            <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
          </div>


          <div class="ontario-row" *ngIf="!formCtrl('containingData')?.disabled || (readonly && formCtrl('containingData')?.value)">
            <!-- Document containing this data -->
            <div class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('containingData').required" formCtrlId="{{ field('containingData').id }}">
                  <span name>{{ field('containingData').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('containingData').required && formCtrl('containingData')?.hasError('required')" formCtrlId="{{ field('containingData').id }}">
                  {{ field('containingData').getValidator("required").message | translate : [field('containingData').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('containingData').maxLength && formCtrl('containingData')?.hasError('maxlength')" formCtrlId="{{ field('containingData').id }}">
                  {{ field('containingData').getValidator("maxLength").message | translate : [field('containingData').label | translate | async, formCtrl('containingData')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-textarea-pro formCtrlId="{{ field('containingData').id }}" formCtrlName="{{ field('containingData').name }}" [formCtrl]="formCtrl('containingData')" [required]="field('containingData').required">
                  <span *ngIf="!readonly && field('containingData').maxLength && !formCtrl('containingData')?.disabled && (formCtrl('containingData')?.value?.length <= field('containingData')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('containingData')?.maxLength - formCtrl('containingData')?.value?.length] | async }}</span>
                </odsf-textarea-pro>
              </div>
            </div>
            <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
          </div>

          <div class="ontario-row">
            <!-- Name of peace officer or public officer the document is delivered to -->
            <div class="ontario-columns ontario-large-5 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('deliveredToInformant').required" formCtrlId="{{ field('deliveredToInformant').id }}">
                  <span name>{{ field('deliveredToInformant').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('deliveredToInformant').required && formCtrl('deliveredToInformant')?.hasError('required')" formCtrlId="{{ field('deliveredToInformant').id }}">
                  {{ field('deliveredToInformant').getValidator("required").message | translate : [field('deliveredToInformant').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-radio formCtrlId="{{ field('deliveredToInformant').id }}" formCtrlName="{{ field('deliveredToInformant').name }}" [formCtrl]="formCtrl('deliveredToInformant')" [required]="field('deliveredToInformant').requiredTrue"
                  [optionItems]="['productionOrderForm.step2.field.document.deliver.to.officer.option.1'| translate | async, 'productionOrderForm.step2.field.document.deliver.to.officer.option.2'| translate | async ]" [values]="[true,false]" ></odsf-radio>
              </div>
            </div>

            <!-- deliver.to.person.name -->
            <div class="ontario-columns ontario-large-3 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!" >
              <div class="ontario-form-group" *ngIf="!formCtrl('deliveredToPersonName')?.disabled || (readonly && formCtrl('deliveredToInformant')?.value)">
                <odsf-label [required]="field('deliveredToPersonName').required && !formCtrl('deliveredToPersonName')?.disabled" formCtrlId="{{ field('deliveredToPersonName').id }}">
                  <span name1>{{ field('deliveredToPersonName').label | translate | async }}</span>
                  <span flag1>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('deliveredToPersonName').required && formCtrl('deliveredToPersonName')?.hasError('required')" formCtrlId="{{ field('deliveredToPersonName').id }}">
                  {{ field('deliveredToPersonName').getValidator("required").message | translate : [field('deliveredToPersonName').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('deliveredToPersonName').maxLength && formCtrl('deliveredToPersonName')?.hasError('maxlength')" formCtrlId="{{ field('deliveredToPersonName').id }}">
                  {{ field('deliveredToPersonName').getValidator("maxLength").message | translate : [field('deliveredToPersonName').label | translate | async, formCtrl('deliveredToPersonName')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-input formCtrlId="{{ field('deliveredToPersonName').id }}" formCtrlName="{{ field('deliveredToPersonName').name }}" [formCtrl]="formCtrl('deliveredToPersonName')" [required]="field('deliveredToPersonName').required">
                  <span *ngIf="!readonly && field('deliveredToPersonName').maxLength && !formCtrl('deliveredToPersonName')?.disabled && (formCtrl('deliveredToPersonName')?.value?.length <= field('deliveredToPersonName')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('deliveredToPersonName')?.maxLength - formCtrl('deliveredToPersonName')?.value?.length] | async }}</span>
                </odsf-input>
              </div>
            </div>
            <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
          </div>


          <div class="ontario-row">
            <!-- Enter the number of days you are requesting to have the document produced within -->
            <div class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('producedWithinDays').required && !formCtrl('producedWithinDays')?.disabled" formCtrlId="{{ field('producedWithinDays').id }}">
                  <span name>{{ field('producedWithinDays').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('producedWithinDays').required && formCtrl('producedWithinDays')?.hasError('required')" formCtrlId="{{ field('producedWithinDays').id }}">
                  {{ field('producedWithinDays').getValidator("required").message | translate : [field('producedWithinDays').label | translate | async | lowercase] | async }}
                </odsf-error>

                <div style="width: 10rem">
                  <odsf-input type="number" formCtrlId="{{ field('producedWithinDays').id }}" formCtrlName="{{ field('producedWithinDays').name }}" [formCtrl]="formCtrl('producedWithinDays')" [required]="field('producedWithinDays').required" [min]="1">
                    <span *ngIf="!readonly && field('producedWithinDays').maxLength && !formCtrl('producedWithinDays')?.disabled && (formCtrl('producedWithinDays')?.value?.length <= field('producedWithinDays')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('producedWithinDays')?.maxLength - formCtrl('producedWithinDays')?.value?.length] | async }}</span>
                  </odsf-input>
                </div>
              </div>
            </div>
            <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
          </div>

          <div class="ontario-row">
            <!-- Enter the place where you are requesting to be produced -->
            <div class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('producedAtPlace').required && !formCtrl('producedAtPlace')?.disabled" formCtrlId="{{ field('producedAtPlace').id }}">
                  <span name>{{ field('producedAtPlace').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>

                <odsf-error *ngIf="!readonly && field('producedAtPlace').required && formCtrl('producedAtPlace')?.hasError('required')" formCtrlId="{{ field('producedAtPlace').id }}">
                  {{ field('producedAtPlace').getValidator("required").message | translate : [field('producedAtPlace').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('producedAtPlace').maxLength && formCtrl('producedAtPlace')?.hasError('maxlength')" formCtrlId="{{ field('producedAtPlace').id }}">
                  {{ field('producedAtPlace').getValidator("maxLength").message | translate : [field('producedAtPlace').label | translate | async, formCtrl('producedAtPlace')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-input formCtrlId="{{ field('producedAtPlace').id }}" formCtrlName="{{ field('producedAtPlace').name }}" [formCtrl]="formCtrl('producedAtPlace')" [required]="field('producedAtPlace').required">
                  <span *ngIf="!readonly && field('producedAtPlace').maxLength && !formCtrl('producedAtPlace')?.disabled && (formCtrl('producedAtPlace')?.value?.length <= field('producedAtPlace')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('producedAtPlace')?.maxLength - formCtrl('producedAtPlace')?.value?.length] | async }}</span>
                </odsf-input>
              </div>
            </div>
            <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
          </div>

          <div class="ontario-row">
            <!-- Specify the form through which you would like to receive the document -->
            <div class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('producedInFormSelection').required" formCtrlId="{{ field('producedInFormSelection').id }}">
                  <span name>{{ field('producedInFormSelection').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('producedInFormSelection').required && formCtrl('producedInFormSelection')?.hasError('required')" formCtrlId="{{ field('producedInFormSelection').id }}">
                  {{ field('producedInFormSelection').getValidator("required").message | translate : [field('producedInFormSelection').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-select formCtrlId="{{ field('producedInFormSelection').id }}" formCtrlName="{{ field('producedInFormSelection').name }}" [formCtrl]="formCtrl('producedInFormSelection')" [required]="field('producedInFormSelection').required">
                    <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                    <option value="Hardcopy">{{ "productionOrderForm.step2.field.specify.receive.document.option.hardcopy" | translate | async }}</option>
                    <option value="Electronic">{{ "productionOrderForm.step2.field.specify.receive.document.option.electronic" | translate | async }}</option>
                    <option value="Other">{{ "productionOrderForm.step2.field.specify.receive.document.option.other" | translate | async }}</option>
                  </odsf-select>
              </div>
            </div>
            <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
          </div>

          <div class="ontario-row" *ngIf="!formCtrl('producedInForm')?.disabled || (readonly && formCtrl('producedInForm')?.value)">
            <!-- Provide document form -->
            <div class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('producedInForm').required && !formCtrl('producedInForm')?.disabled" formCtrlId="{{ field('producedInForm').id }}">
                  <span name>{{ field('producedInForm').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>

                <odsf-error *ngIf="!readonly && field('producedInForm').required && formCtrl('producedInForm')?.hasError('required')" formCtrlId="{{ field('producedInForm').id }}">
                  {{ field('producedInForm').getValidator("required").message | translate : [field('producedInForm').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('producedInForm').maxLength && formCtrl('producedInForm')?.hasError('maxlength')" formCtrlId="{{ field('producedInForm').id }}">
                  {{ field('producedInForm').getValidator("maxLength").message | translate : [field('producedInForm').label | translate | async, formCtrl('producedInForm')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-input formCtrlId="{{ field('producedInForm').id }}" formCtrlName="{{ field('producedInForm').name }}" [formCtrl]="formCtrl('producedInForm')" [required]="field('producedInForm').required">
                  <span *ngIf="!readonly && field('producedInForm').maxLength && !formCtrl('producedInForm')?.disabled && (formCtrl('producedInForm')?.value?.length <= field('producedInForm')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('producedInForm')?.maxLength - formCtrl('producedInForm')?.value?.length] | async }}</span>
                </odsf-input>
              </div>
            </div>
            <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
          </div>


          <div class="ontario-row">
            <!-- Complete this field if you are requesting the Judicial Official -->
            <div class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [optional]="!field('orderConditions').required" formCtrlId="{{ field('orderConditions').id }}">
                  <span name>{{ field('orderConditions').label | translate | async }}</span>
                  <span opflag>({{ "form.field.optional.text" | translate | async }})</span>
                </odsf-label>

                <odsf-error *ngIf="!readonly && field('orderConditions').required && formCtrl('orderConditions')?.hasError('required')" formCtrlId="{{ field('orderConditions').id }}">
                  {{ field('orderConditions').getValidator("required").message | translate : [field('orderConditions').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('orderConditions').maxLength && formCtrl('orderConditions')?.hasError('maxlength')" formCtrlId="{{ field('orderConditions').id }}">
                  {{ field('orderConditions').getValidator("maxLength").message | translate : [field('orderConditions').label | translate | async, formCtrl('orderConditions')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-textarea-pro formCtrlId="{{ field('orderConditions').id }}" formCtrlName="{{ field('orderConditions').name }}" [formCtrl]="formCtrl('orderConditions')" [required]="field('orderConditions').required">
                  <span *ngIf="!readonly && field('orderConditions').maxLength && !formCtrl('orderConditions')?.disabled && (formCtrl('orderConditions')?.value?.length <= field('orderConditions')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('orderConditions')?.maxLength - formCtrl('orderConditions')?.value?.length] | async }}</span>
                </odsf-textarea-pro>
              </div>
            </div>
            <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
          </div>



          <div class="ontario-row"  *ngIf="!isReadOnlyView">
            <!-- button Add to ITO Summary -->
            <button (click)="addToItoSummary()" class="ontario-button ontario-button--primary">
              {{ "productionOrderForm.step2.button.add.to.ito.summary" | translate | async }}
            </button>
            <!-- button clear Form -->
            <button (click)="clearFormForStep2()" class="ontario-button ontario-button--secondary">{{ "productionOrderForm.step2.button.clear.form" | translate | async }}</button>
          </div>
        </ng-container>

        <div class="ontario-row">
          <div class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <ods-table #itoSummaryTable [columns]="itoSummarycolumns"  [dataSourceByArray]="itoSummaryDatasource"
                [tableCaption]="'productionOrderForm.step2.table.itosummary.caption' | translate | async"
                [columnsTitle]="['productionOrderForm.step2.table.itosummary.column.title.1'| translate | async, 
                'productionOrderForm.step2.table.itosummary.column.title.2'| translate | async, 
                'productionOrderForm.step2.table.itosummary.column.title.3'| translate | async ]"
                [noDataText]="'productionOrderForm.step2.table.itosummary.nodata.text' | translate | async"
                (actionDeleteClick)="itoSummaryDelectRow($event)"
                (actionEditClick)="itoSummaryEditRow($event)"
                (actionAddClick)="itoSummaryAddRow($event)"
              ></ods-table>
            </div>
          </div>
          <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>

      </form>
    </div>
  </ng-template>

  <ng-template #formTemplateStep3 let-readonly="readonly">
    <div class="ontario-row">
      <form novalidate [formGroup]="productionOrder.form" class="ontario-row" autocomplete="off">
        <div class="ontario-row">
          <!-- Is an assistance order required for this warrant? -->
          <div class="ontario-columns ontario-large-8 ontario-medium-8 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('assistanceOrderRequired').required" formCtrlId="{{ field('assistanceOrderRequired').id }}">
                <span name>{{ field('assistanceOrderRequired').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
              <span>{{ field('assistanceOrderRequired').hint | translate | async}}</span><br/>

              <odsf-error *ngIf="!readonly && field('assistanceOrderRequired').required && formCtrl('assistanceOrderRequired')?.hasError('required')" formCtrlId="{{ field('assistanceOrderRequired').id }}">
                {{ field('assistanceOrderRequired').getValidator("required").message | translate : [field('assistanceOrderRequired').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-radio formCtrlId="{{ field('assistanceOrderRequired').id }}" formCtrlName="{{ field('assistanceOrderRequired').name }}" [formCtrl]="formCtrl('assistanceOrderRequired')" [required]="field('assistanceOrderRequired').requiredTrue"
                [optionItems]="['radio.value.yes'| translate | async, 'radio.value.no'| translate | async ]" [values]="[true,false]" ></odsf-radio>

            </div>
          </div>
        </div>

        <div *ngIf="formCtrl('assistanceOrderRequired')?.value === 'true'" style="background-color: #E2F0F4; max-width: calc(83.33% - 16px)">
          <div class="ontario-row">
            <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
              <odsf-label>
                <span name>{{ 'productionOrderForm.step3.field.person.providing.assistance' | translate | async }}</span>
                <span flag>:</span>
              </odsf-label>
            </div>
          </div>

          <div class="ontario-row">
            <!-- GivenName of Person providing assistance -->
            <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('givenName').required" formCtrlId="{{ field('givenName').id }}">
                  <span name>{{ field('givenName').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('givenName').required && formCtrl('givenName')?.hasError('required')" formCtrlId="{{ field('givenName').id }}">
                  {{ field('givenName').getValidator("required").message | translate : [field('givenName').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('givenName').maxLength && formCtrl('givenName')?.hasError('maxlength')" formCtrlId="{{ field('givenName').id }}">
                  {{ field('givenName').getValidator("maxLength").message | translate : [field('givenName').label | translate | async, formCtrl('givenName')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-input formCtrlId="{{ field('givenName').id }}" formCtrlName="{{ field('givenName').name }}" [formCtrl]="formCtrl('givenName')" [required]="field('givenName').required">
                  <span *ngIf="!readonly && field('givenName').maxLength && !formCtrl('givenName')?.disabled && (formCtrl('givenName')?.value?.length <= field('givenName')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('givenName')?.maxLength - formCtrl('givenName')?.value?.length] | async }}</span>
                </odsf-input>
              </div>
            </div>
          
            <!-- SurName of Person providing assistance -->
            <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('surname').required" formCtrlId="{{ field('surname').id }}">
                  <span name>{{ field('surname').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('surname').required && formCtrl('surname')?.hasError('required')" formCtrlId="{{ field('surname').id }}">
                  {{ field('surname').getValidator("required").message | translate : [field('surname').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('surname').maxLength && formCtrl('surname')?.hasError('maxlength')" formCtrlId="{{ field('surname').id }}">
                  {{ field('surname').getValidator("maxLength").message | translate : [field('surname').label | translate | async, formCtrl('surname')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-input formCtrlId="{{ field('surname').id }}" formCtrlName="{{ field('surname').name }}" [formCtrl]="formCtrl('surname')" [required]="field('surname').required">
                  <span *ngIf="!readonly && field('surname').maxLength && !formCtrl('surname')?.disabled && (formCtrl('surname')?.value?.length <= field('surname')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('surname')?.maxLength - formCtrl('surname')?.value?.length] | async }}</span>
                </odsf-input>
              </div>
            </div>

            <!-- Title -->
            <div class="ontario-columns ontario-large-3 ontario-medium-3 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('title').required" formCtrlId="{{ field('title').id }}">
                  <span name>{{ field('title').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('title').required && formCtrl('title')?.hasError('required')" formCtrlId="{{ field('title').id }}">
                  {{ field('title').getValidator("required").message | translate : [field('title').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('title').maxLength && formCtrl('title')?.hasError('maxlength')" formCtrlId="{{ field('title').id }}">
                  {{ field('title').getValidator("maxLength").message | translate : [field('title').label | translate | async, formCtrl('title')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-input formCtrlId="{{ field('title').id }}" formCtrlName="{{ field('title').name }}" [formCtrl]="formCtrl('title')" [required]="field('title').required">
                  <span *ngIf="!readonly && field('title').maxLength && !formCtrl('title')?.disabled && (formCtrl('title')?.value?.length <= field('title')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('title')?.maxLength - formCtrl('title')?.value?.length] | async }}</span>
                </odsf-input>
              </div>
            </div>

            <div class="ontario-columns ontario-large-1 ontario-medium-1 ontario-small-0 ontario-padding-left-0-!"></div>
          </div>

          <div class="ontario-row">
            <!-- Qualifications of person -->
            <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('qualifications').required" formCtrlId="{{ field('qualifications').id }}">
                  <span name>{{ field('qualifications').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>

                <odsf-error *ngIf="!readonly && field('qualifications').required && formCtrl('qualifications')?.hasError('required')" formCtrlId="{{ field('qualifications').id }}">
                  {{ field('qualifications').getValidator("required").message | translate : [field('qualifications').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('qualifications').maxLength && formCtrl('qualifications')?.hasError('maxlength')" formCtrlId="{{ field('qualifications').id }}">
                  {{ field('qualifications').getValidator("maxLength").message | translate : [field('qualifications').label | translate | async, formCtrl('qualifications')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-input formCtrlId="{{ field('qualifications').id }}" formCtrlName="{{ field('qualifications').name }}" [formCtrl]="formCtrl('qualifications')" [required]="field('qualifications').required">
                  <span *ngIf="!readonly && field('qualifications').maxLength && !formCtrl('qualifications')?.disabled && (formCtrl('qualifications')?.value?.length <= field('qualifications')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('qualifications')?.maxLength - formCtrl('qualifications')?.value?.length] | async }}</span>
                </odsf-input>
              </div>
            </div>
          </div>

          <div class="ontario-row">
            <!-- Explain how the person may assist -->
            <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('assistanceExplain').required" formCtrlId="{{ field('assistanceExplain').id }}">
                  <span name>{{ field('assistanceExplain').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('assistanceExplain').required && formCtrl('assistanceExplain')?.hasError('required')" formCtrlId="{{ field('assistanceExplain').id }}">
                  {{ field('assistanceExplain').getValidator("required").message | translate : [field('assistanceExplain').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('assistanceExplain').maxLength && formCtrl('assistanceExplain')?.hasError('maxlength')" formCtrlId="{{ field('assistanceExplain').id }}">
                  {{ field('assistanceExplain').getValidator("maxLength").message | translate : [field('assistanceExplain').label | translate | async, formCtrl('assistanceExplain')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-textarea-pro formCtrlId="{{ field('assistanceExplain').id }}" formCtrlName="{{ field('assistanceExplain').name }}" [formCtrl]="formCtrl('assistanceExplain')" [required]="field('assistanceExplain').required">
                  <span *ngIf="!readonly && field('assistanceExplain').maxLength && !formCtrl('assistanceExplain')?.disabled && (formCtrl('assistanceExplain')?.value?.length <= field('assistanceExplain')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('assistanceExplain')?.maxLength - formCtrl('assistanceExplain')?.value?.length] | async }}</span>
                </odsf-textarea-pro>
              </div>
            </div>
          </div>


          <div class="ontario-row">
            <!-- Choose which ITO to assign this person -->
            <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
              <odsf-label [required]="field('personProvidingAssistanceChoose').required" formCtrlId="{{ field('personProvidingAssistanceChoose').id }}">
                <span name>{{ field('personProvidingAssistanceChoose').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
              <odsf-error *ngIf="!readonly && field('personProvidingAssistanceChoose').required && formCtrl('personProvidingAssistanceChoose')?.hasError('required')" formCtrlId="{{ field('personProvidingAssistanceChoose').id }}">
                {{ field('personProvidingAssistanceChoose').getValidator("required").message | translate : [field('personProvidingAssistanceChoose').label | translate | async | lowercase] | async }}
              </odsf-error>


              <div class="ontario-form-group">
                <div class="ontario-row">
                  <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-! span_appendix">
                    <odsf-checkbox formCtrlId="{{ field('providingAssistanceChooseSelectAll').id }}" formCtrlName="{{ field('providingAssistanceChooseSelectAll').name }}" [formCtrl]="formCtrl('providingAssistanceChooseSelectAll')" >
                      {{ field('providingAssistanceChooseSelectAll').label | translate | async }}
                    </odsf-checkbox> 
                  </div>
                  <div *ngFor="let itoItem of itoSummaryDatasource" class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-! span_appendix">
                    <odsf-checkbox formCtrlId="providing-assistance-choose-{{ itoItem.poUUID }}" formCtrlName="providingAssistanceChoose{{itoItem.poUUID | ToPascalCase}}" [formCtrl]="formCtrl('providingAssistanceChoose' + itoItem.poUUID)" >
                      {{ itoItem.ito }} - {{ itoItem.address }}
                    </odsf-checkbox>
                  </div>
                  <div class="ontario-columns ontario-large-2 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="ontario-row" *ngIf="!isReadOnlyView">
            <button (click)="addToPpaSummary()" class="ontario-button ontario-button--primary">
              {{ "productionOrderForm.step3.button.add.to.summary" | translate | async }}
            </button>
            <button (click)="clearFormForStep3()" class="ontario-button ontario-button--secondary">{{ "productionOrderForm.step2.button.clear.form" | translate | async }}</button>
          </div>
        </div>

        <div class="ontario-row">
          <div class="ontario-columns ontario-large-2 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!">&nbsp;</div>
        </div>

        <div class="ontario-row">
          <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <ods-table #personsProvidingAssistanceSummaryTable [columns]="personsProvidingAssistanceSummaryColumns" [dataSourceByArray]="personsProvidingAssistanceSummaryDatasource" 
                [tableCaption]="'productionOrderForm.step3.table.summary.caption' | translate | async"
                [columnsTitle]="['productionOrderForm.step3.table.summary.column.title.1'| translate | async, 
                'productionOrderForm.step3.table.summary.column.title.2'| translate | async, 
                'productionOrderForm.step3.table.summary.column.title.3'| translate | async, 
                'productionOrderForm.step3.table.summary.column.title.4'| translate | async, 
                'productionOrderForm.step3.table.summary.column.title.5'| translate | async]"
                [noDataText]="'table.noData.text' | translate | async"
                (actionDeleteClick)="ppaSummaryDelectRow($event)"
                (actionEditClick)="ppaSummaryEditRow($event)"
                (actionAddClick)="ppaSummaryAddRow($event)"
              ></ods-table>
            </div>
          </div>
        </div>

      </form>
    </div>
  </ng-template>
 
  <ng-template #formTemplateStep4 let-readonly="readonly">
    <div class="ontario-row">
      <form novalidate [formGroup]="productionOrder.form" class="ontario-row" autocomplete="off">
        <div class="ontario-row">
       
          <!-- button Upload Appendix C-Plus -->
          <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label>
                <span name>{{ 'productionOrderForm.step4.label.upload.appendix.c' | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>

              <button (click)="createPackageBtnClick()" class="ontario-button ontario-button--primary">{{ "productionOrderForm.step4.button.upload" | translate | async }}</button>
            </div>
          </div>
          <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>
        <div class="ontario-row">
          <hr>
        </div>
        <div class="ontario-row">
          <div class="ontario-columns ontario-large-12 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
            <span>{{"productionOrderForm.step4.list.of.ito.information" | translate | async}}</span></div>
        </div>

        <div class="ontario-row">
          <div class="ontario-columns ontario-large-12 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <ods-table #manageAndUploadDocumentsTable [dataSourceByArray]="manageAndUploadDocumentsDatasource" [columns]="manageAndUploadDocumentsColumns" 
                [tableCaption]="'productionOrderForm.step4.table.list.of.ito.caption' | translate | async"
                [columnsTitle]="['productionOrderForm.step4.table.list.of.ito.column.title.1'| translate | async, 
                'productionOrderForm.step4.table.list.of.ito.column.title.2'| translate | async, 
                'productionOrderForm.step4.table.list.of.ito.column.title.3'| translate | async, 
                'productionOrderForm.step4.table.list.of.ito.column.title.4'| translate | async]"
                [noDataText]="'table.noData.text' | translate | async"
                (actionDeleteClick)="handleDelectRow($event)"
                (actionEditClick)="handleEditRow($event)"
                (actionAddClick)="handleAddRow($event)"
                (actionUploadClick)="handleUploadFile($event)"
                (actionDownloadClick)="handleDownloadFile($event)"
              ></ods-table>
            </div>
          </div>
        </div>

      </form>
    </div>
  </ng-template>

  <ng-template #formTemplateStepNavigater>
    <div class="ontario-row">
      <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
        <div class="ontario-footer__links-container--inline">
          <span *ngIf="currentStep !==1" (click)="gotoBack()" (keypress)="gotoBack()" tabindex="0" style="cursor: pointer;"><a><h3>{{"productionOrderForm.button.back" | translate | async}}</h3></a></span>
          <span *ngIf="currentStep !==1" style="width: 4rem;">&nbsp;</span>
          <span *ngIf="currentStep ===1 || currentStep ===2" (click)="gotoNext()" (keypress)="gotoNext()" tabindex="0" style="cursor: pointer;"><a><h3>{{"productionOrderForm.button.next" | translate | async}}</h3></a></span>
          <span *ngIf="currentStep ===3 && !(isReadOnlyView || isEditAsDelegate)" (click)="createPackageBtnClick()" (keypress)="createPackageBtnClick()" tabindex="0" style="cursor: pointer;"><a><h3>{{"productionOrderForm.step4.button.create.ackage" | translate | async}}</h3></a></span>
        </div>
      </div>
      <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
        <span style="cursor: pointer; float: right;"><h3>{{"productionOrderForm.step.of.steps" | translate: [currentStep, totalStep] | async}}</h3></span>
      </div>
    </div>
    <hr>
  </ng-template>

  <ng-template #buttonsDiv>
      <!-- buttons -->
      <div class="ontario-row">
          <button *ngIf="currentStep ===1 || currentStep ===2" (click)="gotoNext()" class="ontario-button ontario-button--primary">
            {{ "productionOrderForm.button.continue" | translate | async }}
          </button>
          <button *ngIf="currentStep ===3 && !(isReadOnlyView || isEditAsDelegate)" (click)="createPackageBtnClick()" class="ontario-button ontario-button--primary">
            {{ "productionOrderForm.step4.button.create.ackage" | translate | async }}
          </button>
          <button *ngIf="currentStep ===4" (click)="sendForm()" class="ontario-button ontario-button--primary" [disabled]="!canSubmit">
            {{ "productionOrderForm.button.submit.package" | translate | async }} <span *ngIf="!canSubmit">({{ "text.disabled" | translate | async }})</span>
          </button>
          <button *ngIf="!isReadOnlyView" (click)="saveDraft()" class="ontario-button ontario-button--secondary">{{ "productionOrderForm.button.save.draft" | translate | async }}</button>
      </div>
  </ng-template>

  <ng-template #errorNotificationTemplate>
    <div class="ontario-row">
      <div class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
        <ods-page-alert role="error">
          <ng-container title>{{ notifications?.error?.title??"notification.error.title" | translate | async }}</ng-container>
          <ng-container body>
            <p>{{ notifications?.error?.content??"notification.error.content.invalidFormFields" | translate | async }}</p>
            <ul>
              <li *ngFor="let msg of notifications?.error?.data; let i = index; trackBy: trackByFn">
                <a><div [innerHTML]=" msg | translate | async"></div></a>
              </li>
            </ul>
          </ng-container>
        </ods-page-alert>
      </div>
      
      <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0"></div>
    </div>
  </ng-template>
  
