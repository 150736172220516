import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'odsf-select',
  templateUrl: './odsf-select.component.html',
  styleUrls: ['./odsf-select.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class OdsfSelectComponent {
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() formCtrlId: string;
  @Input() formCtrlName: string;
  @Input() formCtrl: FormControl;
  @Input() value: string;

  @ViewChild('selectComponent') selectComponent: ElementRef;

  get hasError() {
    return this.formCtrl?.touched && this.formCtrl?.invalid;
  }

  get describedBy(): string {
    return this.hasError ? `${this.formCtrlName}-field-error` : null;
  }
}
