import { Component, OnInit } from '@angular/core';
import { AppService } from '@app/core';
import { UserService } from '@app/core/auth';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html'
})
export class UnauthorizedComponent implements OnInit {
  constructor(private userService: UserService, private appService: AppService) { }

  ngOnInit(): void {
    this.userService.loggedIn$
      .subscribe((loggedIn) => loggedIn && this.appService.goToCallbackPage());
  }
}
