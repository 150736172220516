<ng-container *ngIf="view.isReady">
  <ng-container *ngIf="isFormView">
    <!-- title -->
    <h1>{{ "messageForm.create.title" | translate | async }}</h1>

    <!-- back to users button -->
    <ng-container [ngTemplateOutlet]="formTemplateMessagesNavigater"></ng-container>

    <!-- form input errors from server -->
    <ng-container *ngIf="shouldShowErrors" [ngTemplateOutlet]="errorNotificationTemplate"></ng-container>

    <div *ngIf="message?.form" class="ontario-row">
      <ng-container *ngTemplateOutlet="formTemplate;context:{readonly:false}"></ng-container>
    </div>

    <!-- buttons -->
    <div class="ontario-row">
      <button (click)="sendForm()" class="ontario-button ontario-button--primary" [disabled]="!canSubmit">
        {{ "messageForm.submit.btn" | translate | async }} <span *ngIf="!canSubmit">({{ "text.disabled" | translate | async }})</span>
      </button>

      <button (click)="clearForm()" class="ontario-button ontario-button--secondary">{{ "messageForm.clearForm.btn" | translate | async }}</button>
    </div>
  </ng-container>

  <ng-container *ngIf="isReadOnlyView">
    <h1>{{ "messageForm.readonly.title" | translate | async }}</h1>

    <!-- back to users button -->
    <ng-container [ngTemplateOutlet]="formTemplateMessagesNavigater"></ng-container>

    <div *ngIf="message?.form" class="ontario-row">
      <ng-container *ngTemplateOutlet="formTemplate;context:{readonly:true}"></ng-container>
    </div>

    <!-- buttons -->
    <div class="ontario-row">
      <button (click)="printForm()" class="ontario-button ontario-button--secondary">{{ "messageForm.printForm.btn" | translate | async }}</button>
    </div>
  </ng-container>
</ng-container>

<ng-template #formTemplate let-readonly="readonly">
  <div class="ontario-row">
    <form novalidate [formGroup]="message.form" class="ontario-row">
      <div class="ontario-row">
        <!-- court house code -->
        <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('courtHouseCode').required" formCtrlId="{{ field('courtHouseCode').id }}">
              <span name>{{ field('courtHouseCode').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="!readonly && field('courtHouseCode').required && formCtrl('courtHouseCode')?.hasError('required')" formCtrlId="{{ field('courtHouseCode').id }}">
              {{ field('courtHouseCode').getValidator("required").message | translate : [field('courtHouseCode').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-select
              formCtrlId="{{ field('courtHouseCode').id }}"
              formCtrlName="{{ field('courtHouseCode').name }}"
              [formCtrl]="formCtrl('courtHouseCode')"
              [required]="field('courtHouseCode').required">
              <option value=""></option>
              <!-- <option *ngFor="let item of (readonly ? optionItems('courtHouse') : courtHouseOptions) | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id"> -->
              <option *ngFor="let item of optionItems('courtHouse') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                {{ item.name[lang$ | async] }} ({{ item.id }})
              </option>
            </odsf-select>
          </div>
        </div>

        <!-- package number -->
        <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('packageNumber').required" formCtrlId="{{ field('packageNumber').id }}">
              <span name>{{ field('packageNumber').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="!readonly && field('packageNumber').requiredIf && formCtrl('packageNumber')?.hasError('required')" formCtrlId="{{ field('packageNumber').id }}">
              {{ field('packageNumber').getValidator("requiredIf").message | translate : [field('packageNumber').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('packageNumber').existance && formCtrl('packageNumber')?.hasError('existance')" formCtrlId="{{ field('packageNumber').id }}">
              {{ field('packageNumber').getValidator("existance").message | translate : [field('packageNumber').label | translate | async | lowercase, formCtrl('packageNumber')?.value] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('packageNumber').pattern && formCtrl('packageNumber')?.hasError('pattern')" formCtrlId="{{ field('packageNumber').id }}">
              {{ field('packageNumber').getValidator("pattern").message | translate : [field('packageNumber').label | translate | async | lowercase, '(e.g. 12345-12345-12345)'] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('packageNumber').maxLength && formCtrl('packageNumber')?.hasError('maxlength')" formCtrlId="{{ field('packageNumber').id }}">
              {{ field('packageNumber').getValidator("maxLength").message | translate : [field('packageNumber').label | translate | async, formCtrl('packageNumber')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-input
              formCtrlId="{{ field('packageNumber').id }}"
              formCtrlName="{{ field('packageNumber').name }}"
              [formCtrl]="formCtrl('packageNumber')"
              [required]="field('packageNumber').required"
              [disabled]="!formCtrl('courtHouseCode')?.value">
            </odsf-input>
          </div>
        </div>

        <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
      </div>

      <div class="ontario-row">
        <!-- subject -->
        <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('subject').required" formCtrlId="{{ field('subject').id }}">
              <span name>{{ field('subject').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="!readonly && field('subject').required && formCtrl('subject')?.hasError('required')" formCtrlId="{{ field('subject').id }}">
              {{ field('subject').getValidator("required").message | translate : [field('subject').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('subject').maxLength && formCtrl('subject')?.hasError('maxlength')" formCtrlId="{{ field('subject').id }}">
              {{ field('subject').getValidator("maxLength").message | translate : [field('subject').label | translate | async, formCtrl('subject')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-input formCtrlId="{{ field('subject').id }}" formCtrlName="{{ field('subject').name }}" [formCtrl]="formCtrl('subject')" [required]="field('subject').required"> </odsf-input>
          </div>
        </div>
      </div>

      <div class="ontario-row">
        <!-- message -->
        <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('message').required" formCtrlId="{{ field('message').id }}">
              <span name>{{ field('message').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="!readonly && field('message').required && formCtrl('message')?.hasError('required')" formCtrlId="{{ field('message').id }}">
              {{ field('message').getValidator("required").message | translate : [field('message').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('message').maxLength && formCtrl('message')?.hasError('maxlength')" formCtrlId="{{ field('message').id }}">
              {{ field('message').getValidator("maxLength").message | translate : [field('message').label | translate | async, formCtrl('message')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-textarea formCtrlId="{{ field('message').id }}" formCtrlName="{{ field('message').name }}" [formCtrl]="formCtrl('message')" [required]="field('message').required">
              <span *ngIf="!readonly && field('message').required && (formCtrl('message')?.value?.length <= field('message')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('message')?.maxLength - formCtrl('message')?.value?.length] | async }}</span>
            </odsf-textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #errorNotificationTemplate>
  <div class="ontario-row">
    <div class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
      <ods-page-alert role="error">
        <ng-container title>{{ "notification.error.title" | translate | async }}</ng-container>
        <ng-container body>
          <p>{{ "notification.error.content.invalidFormFields" | translate | async }}</p>
          <ul>
            <li *ngFor="let msg of notifications?.error?.data; let i = index; trackBy: trackByFn">
              <a href="#">{{ msg }}</a>
            </li>
          </ul>
        </ng-container>
      </ods-page-alert>
    </div>
    
    <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0"></div>
  </div>
</ng-template>

<ng-template #formTemplateMessagesNavigater>
  <div class="ontario-row">
    <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
      <div class="ontario-footer__links-container--inline">
        <span (click)="goToMessagesPage()" (keypress)="goToMessagesPage()" tabindex="0"><a><h3>{{"messageForm.backToUsers.btn" | translate | async}}</h3></a></span>
      </div>
    </div>
    <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
    </div>
  </div>

  <div class="ontario-row" style="margin-top:20px!important;margin-bottom:20px!important;">
    <div class='ontario-accordion' id='accordion-1'>
      <h3 class='ontario-accordion-heading' [class.ontario-expander--active]="isAccordionOpened">
        <button class='ontario-accordion__button' id='accordion-button-id-1' aria-controls='accordion-content-1'
          [attr.aria-expanded]='isAccordionOpened' data-toggle='ontario-collapse'
          (click)="isAccordionOpened = !isAccordionOpened">
          <span>Raw Data</span>
          <span class='ontario-accordion__button-icon--close'>
            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary"
              viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-up"></use>
            </svg>
          </span>
          <span class='ontario-accordion__button-icon--open'>
            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary"
              viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-down"></use>
            </svg>
          </span>
        </button>
      </h3>
      <section class='ontario-accordion__content' [class.ontario-expander__content--opened]="isAccordionOpened"
        id='accordion-content-1' aria-labelledby='accordion-button-id-1' [attr.aria-hidden]='!isAccordionOpened'
        data-toggle='ontario-expander-content'>
        <pre *ngIf="message && message?.model">{{ message?.model | json }}</pre>
        <br />
      </section>
    </div>
  </div>
  <hr>
</ng-template>