import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackComponent, ErrorComponent, HomeComponent, LoginComponent, LogoutComponent, ManageDelegatesComponent, MessagesComponent, UnauthorizedComponent, UsersComponent } from '@app/view';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [AutoLoginPartialRoutesGuard] },
  { path: 'logout', component: LogoutComponent },
  { path: '', component: CallbackComponent },
  { path: 'home', component: HomeComponent, canActivate: [AutoLoginPartialRoutesGuard] },
  {
    path: 'profile',
    pathMatch: 'prefix',
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      { path: '', loadChildren: () => import('@app/view/profile/profile.module').then(m => m.ProfileModule) },
      { path: ':id', loadChildren: () => import('@app/view/profile/profile.module').then(m => m.ProfileModule) },
      { path: ':id/edit', loadChildren: () => import('@app/view/profile/profile.module').then(m => m.ProfileModule) }
    ]
  },
  {
    path: 'inquiry-form',
    pathMatch: 'prefix',
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      { path: '', loadChildren: () => import('@app/view/inquiry-form/inquiry-form.module').then(m => m.InquiryFormModule) },
      { path: ':id', loadChildren: () => import('@app/view/inquiry-form/inquiry-form.module').then(m => m.InquiryFormModule) },
      { path: ':id/edit', loadChildren: () => import('@app/view/inquiry-form/inquiry-form.module').then(m => m.InquiryFormModule) }
    ]
  },
  {
    path: 'inquiry-form-refactor',
    pathMatch: 'prefix',
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      { path: '', loadChildren: () => import('@app/view/inquiry-form-refactor/inquiry-form-refactor.module').then(m => m.InquiryFormRefactorModule) },
      { path: ':id', loadChildren: () => import('@app/view/inquiry-form-refactor/inquiry-form-refactor.module').then(m => m.InquiryFormRefactorModule) }
    ]
  },
  {
    path: 'search-warrant-form',
    pathMatch: 'prefix',
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      { path: '', loadChildren: () => import('@app/view/search-warrant-form/search-warrant-form.module').then(m => m.SearchWarrantFormModule) },
      { path: ':id', loadChildren: () => import('@app/view/search-warrant-form/search-warrant-form.module').then(m => m.SearchWarrantFormModule) },
      { path: ':id/edit', loadChildren: () => import('@app/view/search-warrant-form/search-warrant-form.module').then(m => m.SearchWarrantFormModule) },
      { path: ':id/draft/step-:stepNo', loadChildren: () => import('@app/view/search-warrant-form/search-warrant-form.module').then(m => m.SearchWarrantFormModule) },
    ]
  },
  {
    path: 'production-order-form',
    pathMatch: 'prefix',
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      { path: '', loadChildren: () => import('@app/view/production-order-form/production-order-form.module').then(m => m.ProductionOrderFormModule) },
      { path: ':id', loadChildren: () => import('@app/view/production-order-form/production-order-form.module').then(m => m.ProductionOrderFormModule) },
      { path: ':id/edit', loadChildren: () => import('@app/view/production-order-form/production-order-form.module').then(m => m.ProductionOrderFormModule) },
      { path: ':id/draft/step-:stepNo', loadChildren: () => import('@app/view/production-order-form/production-order-form.module').then(m => m.ProductionOrderFormModule) },
    ]
  },
  { path: 'messages', component: MessagesComponent, canActivate: [AutoLoginPartialRoutesGuard] },
  {
    path: 'message',
    pathMatch: 'prefix',
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      { path: '', loadChildren: () => import('@app/view/message/message.module').then(m => m.MessageModule) },
      { path: ':id', loadChildren: () => import('@app/view/message/message.module').then(m => m.MessageModule) }
    ]
  },
  { path: 'users', component: UsersComponent, canActivate: [AutoLoginPartialRoutesGuard] },
  {
    path: 'user',
    pathMatch: 'prefix',
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      { path: '', loadChildren: () => import('@app/view/user/user.module').then(m => m.UserModule) },
      { path: ':id', loadChildren: () => import('@app/view/user/user.module').then(m => m.UserModule) },
      { path: ':id/edit', loadChildren: () => import('@app/view/user/user.module').then(m => m.UserModule) }
    ]
  },
  {
    path: 'usersDelegates',
    pathMatch: 'prefix',
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      { path: '', component: HomeComponent },
      { path: ':packageType/:packageId', component: ManageDelegatesComponent },
    ]
  },
  
  { path: 'error', component: ErrorComponent, canActivate: [AutoLoginPartialRoutesGuard] },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'callback', component: CallbackComponent },
  { path: '**', component: ErrorComponent, canActivate: [AutoLoginPartialRoutesGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
