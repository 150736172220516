import { Component, Input } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective
} from '@angular/forms';

@Component({
  selector: 'odsf-textarea',
  templateUrl: './odsf-textarea.component.html',
  styleUrls: ['./odsf-textarea.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    }
  ]
})
export class OdsfTextareaComponent {
  @Input() required: boolean;
  @Input() formCtrlId: string;
  @Input() formCtrlName: string;
  @Input() formCtrl: FormControl;
  @Input() maxLength: number;
  @Input() disabled: boolean;

  get hasError(): boolean {
    return this.formCtrl?.touched && this.formCtrl?.invalid;
  }

  get describedBy(): string {
    return this.hasError ? `${this.formCtrlName}-field-error` : null;
  }
}
