<ng-container *ngIf="formView.isReady()">
  <ng-container *ngIf="formView.isNewForm()">
    <!-- title -->
    <h1>{{ "inquiryForm.create.title" | translate | async }}</h1>

    <!-- form -->
    <div *ngIf="inquiry?.form" class="ontario-row">
      <ng-container [ngTemplateOutlet]="formTemplate"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="formView.isReadOnlyForm()"><!-- title -->
    <h1>{{ "inquiryForm.readonly.title" | translate | async }}</h1>
  </ng-container>
</ng-container>

<ng-template #formTemplate>
  <div class="ontario-row">
    <form novalidate [formGroup]="inquiry.form" class="ontario-row">
      <div class="ontario-row">
        <!-- court region -->
        <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('courtRegion').required" formCtrlId="{{ field('courtRegion').id }}">
              <span name>{{ field('courtRegion').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="field('courtRegion').required && formCtrl('courtRegion')?.hasError('required')" formCtrlId="{{ field('courtRegion').id }}">
              {{ field('courtRegion').getValidator("required").message | translate : [field('courtRegion').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-select formCtrlId="{{ field('courtRegion').id }}" formCtrlName="{{ field('courtRegion').name }}" [formCtrl]="formCtrl('courtRegion')" [required]="field('courtRegion').required">
              <option value=""></option>
              <option *ngFor="let item of optionItems('courtRegion') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                {{ item.name[lang$ | async] }}
              </option>
            </odsf-select>
          </div>
        </div>

        <!-- courthouse -->
        <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('courthouse').required" formCtrlId="{{ field('courthouse').id }}">
              <span name>{{ field('courthouse').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="field('courthouse').required && formCtrl('courthouse')?.hasError('required')" formCtrlId="{{ field('courthouse').id }}">
              {{ field('courthouse').getValidator("required").message | translate : [field('courthouse').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-select
              formCtrlId="{{ field('courthouse').id }}"
              formCtrlName="{{ field('courthouse').name }}"
              [formCtrl]="formCtrl('courthouse')"
              [required]="field('courthouse').required">
              <option value=""></option>
              <option *ngFor="let item of courtHouseOptions; let i = index; trackBy: trackByFn" [value]="item.id">
                {{ item.name[lang$ | async] }}
              </option>
            </odsf-select>
          </div>
        </div>

        <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
      </div>

      <div class="ontario-row">
        <!-- type of inquiry -->
        <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('inquiryType').required" formCtrlId="{{ field('inquiryType').id }}">
              <span name>{{ field('inquiryType').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="field('inquiryType').required && formCtrl('inquiryType')?.hasError('required')" formCtrlId="{{ field('inquiryType').id }}">
              {{ field('inquiryType').getValidator("required").message | translate : [field('inquiryType').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-select formCtrlId="{{ field('inquiryType').id }}" formCtrlName="{{ field('inquiryType').name }}" [formCtrl]="formCtrl('inquiryType')" [required]="field('inquiryType').required">
              <option value=""></option>
              <option *ngFor="let item of optionItems('inquiryType') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                {{ item.name[lang$ | async] }}
              </option>
            </odsf-select>
          </div>
        </div>

        <!-- package number -->
        <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('packageId').required" formCtrlId="{{ field('packageId').id }}">
              <span name>{{ field('packageId').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="field('packageId').pattern && formCtrl('packageId')?.hasError('pattern')" formCtrlId="{{ field('packageId').id }}">
              {{ field('packageId').getValidator("pattern").message | translate : [field('packageId').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error *ngIf="field('packageId').maxLength && formCtrl('packageId')?.hasError('maxlength')" formCtrlId="{{ field('packageId').id }}">
              {{ field('packageId').getValidator("maxLength").message | translate : [field('packageId').label | translate | async, formCtrl('packageId')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-input
              formCtrlId="{{ field('packageId').id }}"
              formCtrlName="{{ field('packageId').name }}"
              [formCtrl]="formCtrl('packageId')"
              [required]="field('packageId').required"
              [disabled]="!formCtrl('inquiryType')?.value">
            </odsf-input>
          </div>
        </div>

        <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
      </div>

      <div class="ontario-row">
        <!-- subject -->
        <div class="ontario-columns ontario-large-10 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('subject').required" formCtrlId="{{ field('subject').id }}">
              <span name>{{ field('subject').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="field('subject').required && formCtrl('subject')?.hasError('required')" formCtrlId="{{ field('subject').id }}">
              {{ field('subject').getValidator("required").message | translate : [field('subject').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error *ngIf="field('subject').maxLength && formCtrl('subject')?.hasError('maxlength')" formCtrlId="{{ field('subject').id }}">
              {{ field('subject').getValidator("maxLength").message | translate : [field('subject').label | translate | async, formCtrl('subject')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-input formCtrlId="{{ field('subject').id }}" formCtrlName="{{ field('subject').name }}" [formCtrl]="formCtrl('message')" [required]="field('subject').required"> </odsf-input>
          </div>
        </div>
      </div>

      <div class="ontario-row">
        <!-- message -->
        <div class="ontario-columns ontario-large-10 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('message').required" formCtrlId="{{ field('message').id }}">
              <span name>{{ field('message').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="field('message').required && formCtrl('message')?.hasError('required')" formCtrlId="{{ field('message').id }}">
              {{ field('message').getValidator("required").message | translate : [field('message').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error *ngIf="field('message').maxLength && formCtrl('message')?.hasError('maxlength')" formCtrlId="{{ field('message').id }}">
              {{ field('message').getValidator("maxLength").message | translate : [field('message').label | translate | async, formCtrl('message')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-textarea formCtrlId="{{ field('message').id }}" formCtrlName="{{ field('message').name }}" [formCtrl]="formCtrl('message')" [required]="field('message').required">
              <span>{{ 'validation.message.characters.remaining' | translate:[field('message')?.maxLength - formCtrl('message')?.value?.length] | async }}</span>
            </odsf-textarea>
          </div>
        </div>
      </div>

      <div class="ontario-row">
        <!-- declare checkbox -->
        <div class="ontario-columns ontario-large-10 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-error *ngIf="field('declareTruth').requiredTrue && formCtrl('declareTruth')?.hasError('required')" formCtrlId="{{ field('declareTruth').id }}">
              {{ field('declareTruth').getValidator("requiredTrue").message | translate | async }}
            </odsf-error>

            <odsf-checkbox formCtrlId="{{ field('declareTruth').id }}" formCtrlName="{{ field('declareTruth').name }}" [formCtrl]="formCtrl('declareTruth')" [required]="field('declareTruth').requiredTrue">
              {{ field('declareTruth').label | translate: [officerName] | async }}
            </odsf-checkbox>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>