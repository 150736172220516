import { userForm } from '@app/core/auth';
import { messageForm } from '@app/core/messages';
import { NotificationType } from '@app/core/notifications';
import { InquiryType, SubmissionType, inquiryForm } from '@app/core/submissions';
import { PageView, getViewFromUrl } from '@app/view';
import { environment } from '@environments/environment';
import isEmpty from 'lodash/isEmpty';
import { Observable, combineLatest, concat, distinctUntilChanged, forkJoin, map, of, pairwise, startWith, switchMap, tap } from 'rxjs';

/* =========================== BASE SUBSCRIPTIONS =========================== */

export const onPageViewChanged$ =
  next =>
    ({ route, router, dispatcher }) => {
      return combineLatest([of(router.url), route.params]).pipe(
        distinctUntilChanged(),
        switchMap(([url, params]: any) => next({ dispatcher, url, params, viewType: getViewFromUrl(url) }))
      );
    };

export const onNotificationsChanged$ = notificationCriteria => notificationType => (notifications$: Observable<any[]>, next) => {
  return notifications$.pipe(
    map(ns => ns?.filter(n => notificationCriteria(n))),
    map(ns => ns?.filter(n => n?.type === notificationType)),
    tap(ns => next(ns))
  );
};

export const onFormInputChanged$ = fieldName => (cond, trueResult, falseResult, complete?) => form => {
  // track prev and curr value using startWith and pairwise
  return concat(form.get(fieldName).valueChanges.pipe(startWith(null), pairwise())).pipe(
    distinctUntilChanged(),
    tap(([prev, curr]: any) => (cond({ form, prev, curr }) ? trueResult({ form, prev, curr }) : falseResult({ form, prev, curr }))),
    tap(() => complete ? complete({ form }) : null)
  );
};

/* =========================== BASE SUBSCRIPTIONS =========================== */

/* =========================== IF SUBSCRIPTIONS =========================== */

export const onInqFormViewChanged$ = onPageViewChanged$(({ dispatcher, params, viewType }) => {
  const validateForm = environment.validateForm;
  if (viewType === PageView.Create) {
    return of(inquiryForm({ validateForm }));
  }

  const isReadOnlyView = viewType === PageView.Read;
  return dispatcher.loadById(params.id, true).pipe(map(s => inquiryForm({ data: s, validateForm, disableForm: isReadOnlyView })));
});

export const onInqFormNotificationsChanged$ = onNotificationsChanged$(n => n?.id?.includes(SubmissionType.Inquiry.toString()));

export const onInqFormErrorsChanged$ = onInqFormNotificationsChanged$(NotificationType.Error);
export const onInqFormSuccessChanged$ = onInqFormNotificationsChanged$(NotificationType.Success);

export const onCourtRegionChanged$ = onFormInputChanged$('courtRegionCode');
export const onInquiryTypeChanged$ = onFormInputChanged$('typeOfInquiryCode');
export const onProvinceChanged$ = onFormInputChanged$('province');

export const onInqFormCourtRegionChanged$ = onCourtRegionChanged$(
  ({ curr }) => !isEmpty(curr),
  ({ form, prev, curr }) => { if (prev!=curr) form.get('courtHouseCode').reset(); form.get('courtHouseCode').enable()},
  ({ form }) => form.get('courtHouseCode').disable()
);

export const onInqFormInquiryTypeChanged$ = onInquiryTypeChanged$(
  ({ curr }) => !isEmpty(curr) && curr !== InquiryType.Urgent,
  ({ form, prev, curr }) => { if (prev!=curr) form.get('packageNumber').reset(); form.get('packageNumber').enable()},
  ({ form }) => {form.get('packageNumber').disable(); form.get('packageNumber').reset()}
);

/* =========================== IF SUBSCRIPTIONS =========================== */

/* =========================== Message SUBSCRIPTIONS =========================== */

export const onMessageFormViewChanged$ = onPageViewChanged$(({ dispatcher, params, viewType }) => {
  const validateForm = environment.validateForm;
  if (viewType === PageView.Create) {
    return of(messageForm({ validateForm }));
  }

  const isReadOnlyView = viewType === PageView.Read;
  return dispatcher.loadById(params.id, true).pipe(map(m => messageForm({ data: m, validateForm, disableForm: isReadOnlyView })));
});

export const onMessageFormNotificationsChanged$ = onNotificationsChanged$(n => n?.id?.includes('Message'));

export const onMessageFormErrorsChanged$ = onMessageFormNotificationsChanged$(NotificationType.Error);
export const onMessageFormSuccessChanged$ = onMessageFormNotificationsChanged$(NotificationType.Success);

export const onCourtHouseChanged$ = onFormInputChanged$('courtHouseCode');

export const onMessageFormCourtHouseChanged$ = onCourtHouseChanged$(
  ({ curr }) => !isEmpty(curr),
  ({ form }) => form.get('packageNumber').enable(),
  ({ form }) => form.get('packageNumber').disable(),
  ({ form }) => form.get('packageNumber').reset()
);

/* =========================== Message SUBSCRIPTIONS =========================== */

/* =========================== User SUBSCRIPTIONS =========================== */

export const onUserFormViewChanged$ = onPageViewChanged$(({ dispatcher, params, viewType }) => {
  const validateForm = environment.validateForm;
  if (viewType === PageView.Create) {
    return of(userForm({ validateForm }));
  }

  const isReadOnlyView = viewType === PageView.Read;
  return dispatcher.load(true).pipe(switchMap(() => dispatcher.loadById(params.id, true).pipe(map(s => userForm({ data: s, validateForm, disableForm: isReadOnlyView })))));
});

export const onProfileFormViewChanged$ = onPageViewChanged$(({ dispatcher, params, viewType }) => {
  const validateForm = environment.validateForm;
  if (viewType === PageView.Create) {
    return of(userForm({ data: { declare: true }, validateForm }));
  }

  const isReadOnlyView = viewType === PageView.Read;
  return dispatcher.load(true).pipe(switchMap(() => dispatcher.loadById(params.id, true).pipe(map((s: any) => userForm({ data: { ...s, declare: true }, validateForm, disableForm: isReadOnlyView })))));
});

export const onUserFormNotificationsChanged$ = onNotificationsChanged$(n => n?.id?.includes('User'));

export const onUserFormErrorsChanged$ = onUserFormNotificationsChanged$(NotificationType.Error);
export const onUserFormSuccessChanged$ = onUserFormNotificationsChanged$(NotificationType.Success);

export const onHasBadgeChanged$ = onFormInputChanged$('hasBadge');
export const onIsSecondedChanged$ = onFormInputChanged$('isSeconded');
export const onIsActiveChanged$ = onFormInputChanged$('isActive');
export const onReadOnlyChanged$ = onFormInputChanged$('readOnly');

export const onUserFormHasBadgeChanged$ = onHasBadgeChanged$(
  ({ prev, curr }) => (curr !== prev && curr === true),
  ({ form }) => form.get('badgeNumber').enable(),
  ({ form }) => form.get('badgeNumber').disable(),
  ({ form }) => form.get('badgeNumber').reset()
);

export const onUserFormIsSecondedChanged$ = onIsSecondedChanged$(
  ({ prev, curr }) => (curr !== prev && curr === true),
  ({ form }) => { form.get('secondedAgencyCode').enable(); form.get('secondedEmail').enable(); },
  ({ form }) => { form.get('secondedAgencyCode').disable(); form.get('secondedEmail').disable(); },
  ({ form }) => { form.get('secondedAgencyCode').reset(); form.get('secondedEmail').reset(); }
);

export const onUserFormIsActiveChanged$ = onIsActiveChanged$(
  ({ prev, curr }) => (curr !== prev && curr === false),
  ({ form }) => form.get('deactivationReason').enable(),
  ({ form }) => form.get('deactivationReason').disable(),
  ({ form }) => form.get('deactivationReason').reset()
);

export const onUserFormReadOnlyChanged$ = onReadOnlyChanged$(
  ({ prev, curr }) => (curr !== prev && curr === true),
  ({ form }) => form.get('readOnlyReason').enable(),
  ({ form }) => form.get('readOnlyReason').disable(),
  ({ form }) => form.get('readOnlyReason').reset()
);