import { API_ENDPOINTS } from "@/src/environments/environment";
import { AbstractControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ValidatorType } from '@app/core/models';
import { Observable, from, map, of } from "rxjs";

function packageNumberRequiredIf(control: AbstractControl): ValidationErrors | null {
  const form = control.parent as FormGroup;
  if (form && form.get('courtHouseCode') && form.get('courtHouseCode').value) {
    return !control.value ? { required: true } : null;
  }
  return null;
}

function packageNumberExistance(control: AbstractControl): Observable<ValidationErrors | null> {
  if (API_ENDPOINTS.SUBMISSION_INQUIRY_GET.indexOf(`{packageUUID}`) === -1) {
    return of(control?.value).pipe(map((pn: string) => pn !== "c014cd92-5b28-43f7-bd1e-0f90e7b1fa8a" ? { existance: true } : null));
  } else {
    const ifByPackageUuidEndpoint = API_ENDPOINTS.SUBMISSION_INQUIRY_GET.replace(`{packageUUID}`, control?.value);
    return from(fetch(ifByPackageUuidEndpoint)).pipe(map((response) => ((!response || response?.status !== 200) ? { existance: true } : null)));
  }
}

export const MessageSchema: any = {
  id: {
    id: 'id',
    name: 'id',
    type: Number,
    db: true,
    form: false,
    defaultValue: null,
    disabled: false,
    label: 'inquiryForm.field.id.name',
    description: '',
    validators: []
  },
  messageId: {
    id: 'message-id',
    name: 'messageId',
    type: String,
    db: true,
    form: false,
    defaultValue: null,
    disabled: false,
    label: 'inquiryForm.field.packageId.name',
    description: '',
    validators: []
  },
  sentDate: {
    id: 'sent-date',
    name: 'sentDate',
    type: Date,
    db: false,
    form: false,
    defaultValue: null,
    disabled: false,
    label: 'inquiryForm.field.sentDate.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  courtHouseCode: {
    id: 'courtHouseCode',
    name: 'courtHouseCode',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'messageForm.field.courtHouseCode.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  packageNumber: {
    id: 'package-number',
    name: 'packageNumber',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'messageForm.field.packageNumber.name',
    description: '',
    validators: [
      {
        type: ValidatorType.RequiredIf,
        fn: packageNumberRequiredIf,
        message: 'validation.message.enter.a'
      },
      {
        type: 'existance',
        async: true,
        fn: packageNumberExistance,
        message: 'validation.message.not.found'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(36),
        value: 36,
        message: 'validation.message.maxLength'
      },
      {
        type: ValidatorType.Pattern,
        fn: Validators.pattern(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i),
        value: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
        message: 'validation.message.pattern'
      }
    ]
  },
  subject: {
    id: 'subject',
    name: 'subject',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'messageForm.field.subject.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(50),
        value: 50,
        message: 'validation.message.maxLength'
      }
    ]
  },
  message: {
    id: 'message',
    name: 'message',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'messageForm.field.message.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(4000),
        value: 4000,
        message: 'validation.message.maxLength'
      }
    ]
  }
};