import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserApiService, UserService } from '@app/core/auth';
import { APICacheService, AppCacheService } from '@app/core/cache';
import { environment } from '@environments/environment';
import { Language, LanguageService } from '@shared/language';
import { APP_REQUEST_CONTEXT } from '@shared/loading';
import { Preference } from '@shared/preference';
import { BehaviorSubject, EMPTY, Observable, forkJoin, of, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppService {
  private _pageReady = false;
  private subject = new BehaviorSubject(this._pageReady);
  private _pageReady$: Observable<boolean> = this.subject.asObservable();

  constructor(
    private apiCacheService: APICacheService,
    private appCache: AppCacheService,
    private languageService: LanguageService,
    private userApiService: UserApiService,
    private userService  : UserService,
    private httpClient: HttpClient,
    private router: Router
  ) { }

  createAPICache(buildVersion: string): Observable<any> {
    return this.apiCacheService.create(buildVersion).pipe(tap(() => this.finalizeCache()));
  }

  loadI18n() {
    const en = environment.i18nDirectory + 'en.json';
    const fr = environment.i18nDirectory + 'fr.json';
    return forkJoin([
      this.httpClient.get(en, { context: APP_REQUEST_CONTEXT }),
      this.httpClient.get(fr, { context: APP_REQUEST_CONTEXT })
    ]).pipe(
      tap(() => this.finalizeCache()),
      tap((res: any) => ([this.appCache.en, this.appCache.fr] = res))
    );
  }

  loadUser() {
    if (this.userService.currentUser) {
      this.appCache.user = this.userService.currentUser;
        this.setLocalPreference(this.userService.currentUser);
        this.publishPreference(this.userService.currentUser);
      return of(null);
    } else 
      return this.userService.currentUser$.pipe(
        tap(user => {
          this.appCache.user = user;
          this.setLocalPreference(user);
          this.publishPreference(user);
        })
      );
  }

  setUserPrefLang(language) {
    this.appCache.setPreferenceLanguage(language);

    if (this.appCache.user) {
      this.appCache.setUserLanguage(language);
      this.languageService.language = language;
    }

    return EMPTY;
  }

  serializeRouterUrl(path) {
    return this.router.serializeUrl(this.router.createUrlTree([path]));
  }

  goToCallbackPage(options?) {
    const path = '/';
    return options?.blank ? window.open(this.serializeRouterUrl(path), '_blank') : this.router.navigate([path]);
  }

  goToProfilePage(options?) {
    const path = `/profile/${options?.userId}` + (options?.editing ? `/edit` : ``);
    return options?.blank ? window.open(this.serializeRouterUrl(path), '_blank') : this.router.navigate([path]);
  }

  goToHomePage(options?) {
    const path = '/home';
    return options?.blank ? window.open(this.serializeRouterUrl(path), '_blank') : this.router.navigate([path]);
  }

  goToMessagesPage(options?) {
    const path = '/messages';
    return options?.blank ? window.open(this.serializeRouterUrl(path), '_blank') : this.router.navigate([path]);
  }

  goToUsersPage(options?) {
    const path = '/users';
    return options?.blank ? window.open(this.serializeRouterUrl(path), '_blank') : this.router.navigate([path]);
  }

  goToErrorPage(options?) {
    const path = '/error';
    return options?.blank ? window.open(this.serializeRouterUrl(path), '_blank') : this.router.navigate([path]);
  }

  goToUnauthorizdPage(options?) {
    const path = '/unauthorized';
    return options?.blank ? window.open(this.serializeRouterUrl(path), '_blank') : this.router.navigate([path]);
  }

  goToLoginPage(options?) {
    const path = '/login';
    return options?.blank ? window.open(this.serializeRouterUrl(path), '_blank') : this.router.navigate([path]);
  }

  goToLogoutPage(options?) {
    const path = '/logout';
    return options?.blank ? window.open(this.serializeRouterUrl(path), '_blank') : this.router.navigate([path]);
  }

  private finalizeCache() {
    this.appCache.initPreference();
    this.languageService.language = this.appCache.preference.language;
  }

  private publishPreference(user) {
    this.languageService.language = user?.langPreference === 'fr' ?  Language.French : Language.English;
  }

  private setLocalPreference(user) {
    const preference = new Preference(user?.langPreference === 'fr' ? Language.French : Language.English);
    this.appCache.preference = preference;
  }

  get pageReady() {
    return this._pageReady;
  }

  set pageReady(pageReady: boolean) {
    this._pageReady = pageReady;
    this.subject.next(this._pageReady);
  }

  get pageReady$() {
    return this._pageReady$;
  }
}
