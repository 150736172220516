import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        // if (error instanceof ErrorEvent) {
        //   console.log('client side error', error);
        // } else {
        //   this.redirect(error);
        // }
        return throwError('error occured in application');
      })
    );
  }

  redirect(error: any) {
    // http status code passed into error component for further processing
    this.router.navigate(['/error', error]);
  }

  redirectToHome() {
    this.router.navigate(['/']);
  }
}
