import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductionOrderFormComponent } from './production-order-form.component';

const routes: Routes = [{ path: '', component: ProductionOrderFormComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)]
})
export class ProductionOrderFormRoutingModule {}
