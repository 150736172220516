import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent, AppCoreModule, AppRoutingModule } from '@app/core';
import { AppFooterComponent, AppTopNavComponent } from '@app/core/components';
import { CallbackComponent, ErrorComponent, HomeComponent, LoginComponent, LogoutComponent, MessagesComponent, UnauthorizedComponent, UsersComponent, ManageDelegatesComponent, ManageDelegatesDialogComponent, ChooseLoginEmaildialogComponent } from '@app/view';
import { SharedModule } from '@shared/modules';
import { NgxEditorModule } from 'ngx-editor';
import { DatePipe } from '@angular/common';


const viewComponents = [AppComponent, CallbackComponent, UnauthorizedComponent, LoginComponent, LogoutComponent, HomeComponent, UsersComponent, ManageDelegatesComponent, ManageDelegatesDialogComponent, ChooseLoginEmaildialogComponent, MessagesComponent, ErrorComponent];
const sharedViewComponents = [AppTopNavComponent, AppFooterComponent];

@NgModule({
  declarations: [...viewComponents, ...sharedViewComponents],
  imports: [BrowserModule, AppRoutingModule, BrowserAnimationsModule, AppCoreModule, SharedModule, MatDialogModule,
    NgxEditorModule.forRoot({
      locals: {
        // menu
        bold: 'Bold',
        italic: 'Italic',
        code: 'Code',
        blockquote: 'Blockquote',
        underline: 'Underline',
        strike: 'Strike',
        bullet_list: 'Bullet List',
        ordered_list: 'Ordered List',
        heading: 'Heading',
        h1: 'Header 1',
        h2: 'Header 2',
        h3: 'Header 3',
        h4: 'Header 4',
        h5: 'Header 5',
        h6: 'Header 6',
        align_left: 'Left Align',
        align_center: 'Center Align',
        align_right: 'Right Align',
        align_justify: 'Justify',
        text_color: 'Text Color',
        background_color: 'Background Color',

        // popups, forms, others...
        url: 'URL',
        text: 'Text',
        openInNewTab: 'Open in new tab',
        insert: 'Insert',
        altText: 'Alt Text',
        title: 'Title',
        remove: 'Remove',
      },
    }),
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
