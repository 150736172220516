import { Pipe, PipeTransform } from '@angular/core';
import { AppCacheService } from '@app/core/cache';
import { LanguageService } from '@shared/language';
import { Observable, map } from 'rxjs';

@Pipe({ name: 'inquirytype' })
export class InquiryTypePipe implements PipeTransform {
  constructor(private appCache: AppCacheService, private languageService: LanguageService) {}

  transform(value: any): Observable<string> {
    return this.languageService.language$.pipe(
      map(lang => this.appCache.inquiryTypeOptions?.find(v => v?.id === value)?.name[lang])
    );
  }
}
