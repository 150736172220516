import { environment } from '@/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MessageDispatcher, MessageService, messageForm, withMessageTransformer } from '@app/core/messages';
import { NotificationService } from '@app/core/notifications';
import { BaseViewComponent, PageView, getViewFromUrl } from '@app/view';
import isEmpty from 'lodash/isEmpty';
import { BehaviorSubject, delay, distinctUntilChanged, forkJoin, from, merge, mergeAll, mergeMap, switchMap, takeWhile, tap } from 'rxjs';
import { onMessageFormCourtHouseChanged$, onMessageFormErrorsChanged$, onMessageFormSuccessChanged$, onMessageFormViewChanged$ } from '../../core/app.subscriptions';
import { AppService } from '@app/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageComponent extends BaseViewComponent implements OnInit {
  message: any = {};
  notifications: any = {};

  isAccordionOpened = true;

  // constructor(
  //   private injector: Injector,
  //   private route: ActivatedRoute,
  //   private dispatcher: MessageDispatcher,
  //   private http: HttpClient,
  //   private sanitizer: DomSanitizer,
  //   private messageService: MessageService,
  //   private notificationService: NotificationService,
  //   private httpClient: HttpClient
  // ) {
  //   super(injector);
  // }
  constructor(private injector: Injector, private route: ActivatedRoute, private dispatcher: MessageDispatcher, private appService: AppService) {
    super(injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    const route = this.route,
      router = this.router,
      dispatcher = this.dispatcher;

    onMessageFormViewChanged$({ route, router, dispatcher })
      .pipe(takeWhile(() => this.alive))
      .subscribe((message: any) => {
        console.log(message);
        this.message = message;
        dispatcher.clearNotifications();

        this.register$([
          onMessageFormCourtHouseChanged$(message.form),
          onMessageFormErrorsChanged$(dispatcher.notifications$, ns => {
            this.notifications.error = ns[0];
          }),
          onMessageFormSuccessChanged$(dispatcher.notifications$, ns => {
            if (!isEmpty(ns)) this.appService.goToMessagesPage();
          })
        ]);

        this.updateView();
      });
  }

  sendForm() {
    const payload = this.message.toPayload();
    return this.dispatcher
      .save(payload, { from: this.router.url }, true)
      .pipe(
        takeWhile(() => this.alive),
        delay(2000)
      )
      .subscribe();
  }

  clearForm() {
    this.message = messageForm({ validateForm: environment.validateForm });
  }

  resetForm() {
    this.dispatcher.selected$
      .pipe(tap(s => (this.message = messageForm({ data: s, validateForm: environment.validateForm }))))
      .subscribe(() => this.updateView());
  }

  printForm() {
    setTimeout(() => {
      window.print();
    }, 100);
  }

  optionItems(name) {
    return this.appCache[`${name}Options`];
  }

  formCtrl(name) {
    return this.message?.form?.get(name) as FormControl;
  }

  field(name) {
    return this.message?.schema && this.message?.schema[name];
  }

  goToMessagesPage() {
    this.appService.goToMessagesPage();
  }

  get viewType() {
    return getViewFromUrl(this.router.url);
  }

  get isFormView() {
    return this.viewType === PageView.Create;
  }

  get isReadOnlyView() {
    return this.viewType === PageView.Read;
  }

  get isEditView() {
    return this.viewType === PageView.Update;
  }

  get shouldShowErrors() {
    return this.notifications?.error;
  }

  get canSubmit() {
    // return this.message.validateForm ? this.message?.form?.valid && !this.message?.form?.pristine : true;
    return false;
  }
}