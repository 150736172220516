import { Injectable } from '@angular/core';
import { Preference } from '@shared/preference';
import { Language } from '@shared/language';
import { environment } from '@environments/environment';
import moment from 'moment';
import { Utils } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class AppCacheService {
  initPreference() {
    if (!this.preference) {
      this.preference = new Preference(environment.defaultLanguage as Language);
    }
  }

  setTimestamp() {
    this.ts = new Date().getTime();
  }

  setHash() {
    this.hash = 0;
  }

  setUserLanguage(language) {
    const user = this.user;
    user.langPreference = language;
    this.user = user;
  }

  setPreferenceLanguage(language) {
    const preference = this.preference;
    preference.language = language;
    this.preference = preference;
  }

  private cacheDurationInHours() {
    const ts = moment(this.ts);
    const now = moment();
    return moment.duration(now.diff(ts)).asHours();
  }

  /**
   * this is the assumption we're making from now, if no timestamp or hash set
   * we assume cache is not loaded yet
   */
  get empty() {
    return !this.ts || !this.hash;
  }

  /**
   * cache expries in 12 hours
   */
  get expired() {
    const hours = this.cacheDurationInHours();
    return hours > environment.cacheLifespanInHours;
  }

  /**
   * hash is calculated base on the concatenation of
   * all api cache names. If a new cache is available,
   * this hash will change and force a reload
   */
  get adding() {
    return true;
  }

  get user() {
    const user = localStorage.getItem('user');
    return user && user !== 'undefined' ? JSON.parse(user.toString()) : undefined;
  }

  set user(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  set preference(preference: Preference) {
    localStorage.setItem('user/preference', JSON.stringify(preference));
  }

  get preference() {
    const pref = localStorage.getItem('user/preference');
    return pref ? JSON.parse(pref.toString()) : undefined;
  }

  get en() {
    const enFile = localStorage.getItem('en.json');
    return enFile ? JSON.parse(enFile.toString()) : undefined;
  }

  set en(en: any) {
    localStorage.setItem('en.json', JSON.stringify(en));
  }

  get fr() {
    const frFile = localStorage.getItem('fr.json');
    return frFile ? JSON.parse(frFile.toString()) : undefined;
  }

  set fr(fr: any) {
    localStorage.setItem('fr.json', JSON.stringify(fr));
  }

  get ts() {
    const ts = localStorage.getItem('ts');
    return ts ? JSON.parse(ts.toString()) : undefined;
  }

  set ts(ts: any) {
    localStorage.setItem('ts', JSON.stringify(ts));
  }

  get hash() {
    const hash = localStorage.getItem('hash');
    return hash ? JSON.parse(hash.toString()) : undefined;
  }

  set hash(hash: any) {
    localStorage.setItem('hash', JSON.stringify(hash));
  }

  get buildVersion() {
    const buildVersion = localStorage.getItem('buildVersion');
    return buildVersion ? JSON.parse(buildVersion.toString()) : undefined;
  }

  set buildVersion(buildVersion: any) {
    localStorage.setItem('buildVersion', JSON.stringify(buildVersion));
  }

  get packageTypeOptions() {
    const packageTypeOptions = localStorage.getItem('packageTypeOptions');
    return packageTypeOptions ? JSON.parse(packageTypeOptions.toString()) : undefined;
  }

  set packageTypeOptions(packageTypeOptions: any) {
    localStorage.setItem('packageTypeOptions', JSON.stringify(packageTypeOptions));
  }

  get warrantTypeOptions() {
    const warrantTypeOptions = localStorage.getItem('warrantTypeOptions');
    return warrantTypeOptions ? JSON.parse(warrantTypeOptions.toString()) : undefined;
  }

  set warrantTypeOptions(warrantTypeOptions: any) {
    localStorage.setItem('warrantTypeOptions', JSON.stringify(warrantTypeOptions));
  }

  get inquiryTypeOptions() {
    const inquiryTypeOptions = localStorage.getItem('inquiryTypeOptions');
    return inquiryTypeOptions ? JSON.parse(inquiryTypeOptions.toString()) : undefined;
  }

  set inquiryTypeOptions(inquiryTypeOptions: any) {
    localStorage.setItem('inquiryTypeOptions', JSON.stringify(inquiryTypeOptions));
  }

  get searchWarrantTypeOptions() {
    const searchWarrantTypeOptions = localStorage.getItem('searchWarrantTypeOptions');
    return searchWarrantTypeOptions ? JSON.parse(searchWarrantTypeOptions.toString()) : undefined;
  }

  set searchWarrantTypeOptions(searchWarrantTypeOptions: any) {
    localStorage.setItem('searchWarrantTypeOptions', JSON.stringify(searchWarrantTypeOptions));
  }

  get processStatusOptions() {
    const processStatusOptions = localStorage.getItem('processStatusOptions');
    return processStatusOptions ? JSON.parse(processStatusOptions.toString()) : undefined;
  }

  set processStatusOptions(processStatusOptions: any) {
    localStorage.setItem('processStatusOptions', JSON.stringify(processStatusOptions));
  }

  get courtRegionOptions() {
    const courtRegionOptions = localStorage.getItem('courtRegionOptions');
    return courtRegionOptions ? JSON.parse(courtRegionOptions.toString()) : undefined;
  }

  set courtRegionOptions(courtRegionOptions: any) {
    localStorage.setItem('courtRegionOptions', JSON.stringify(courtRegionOptions));
  }

  get courtHouseOptions() {
    const courtHouseOptions = localStorage.getItem('courtHouseOptions');
    return courtHouseOptions ? JSON.parse(courtHouseOptions.toString()) : undefined;
  }

  set courtHouseOptions(courtHouseOptions: any) {
    localStorage.setItem('courtHouseOptions', JSON.stringify(courtHouseOptions));
  }

  get statusOptions() {
    const statusOptions = localStorage.getItem('statusOptions');
    return statusOptions ? JSON.parse(statusOptions.toString()) : undefined;
  }

  set statusOptions(statusOptions: any) {
    localStorage.setItem('statusOptions', JSON.stringify(statusOptions));
  }

  get priorityLevelOptions() {
    const priorityLevelOptions = localStorage.getItem('priorityLevelOptions');
    return priorityLevelOptions ? JSON.parse(priorityLevelOptions.toString()) : undefined;
  }

  set priorityLevelOptions(priorityLevelOptions: any) {
    localStorage.setItem('priorityLevelOptions', JSON.stringify(priorityLevelOptions));
  }

  get isFreeformOptions() {
    const isFreeformOptions = localStorage.getItem('isFreeformOptions');
    return isFreeformOptions ? JSON.parse(isFreeformOptions.toString()) : undefined;
  }

  set isFreeformOptions(isFreeformOptions: any) {
    localStorage.setItem('isFreeformOptions', JSON.stringify(isFreeformOptions));
  }

  get typeOfItoOptions(){
    const typeOfItoOptions = localStorage.getItem('typeOfItoOptions');
    return typeOfItoOptions ? JSON.parse(typeOfItoOptions.toString()) : undefined;
  }

  set typeOfItoOptions(typeOfItoOptions: any) {
    localStorage.setItem('typeOfItoOptions', JSON.stringify(typeOfItoOptions));
  }

  get adultOrYouthOptions(){
    const adultOrYouthOptions = localStorage.getItem('adultOrYouthOptions');
    return adultOrYouthOptions ? JSON.parse(adultOrYouthOptions.toString()) : undefined;
  }

  set adultOrYouthOptions(adultOrYouthOptions: any) {
    localStorage.setItem('adultOrYouthOptions', JSON.stringify(adultOrYouthOptions));
  }

  get provinceOptions() {
    const provinceOptions = localStorage.getItem('provinceOptions');
    return provinceOptions ? JSON.parse(provinceOptions.toString()) : undefined;
  }

  set provinceOptions(provinceOptions: any) {
    localStorage.setItem('provinceOptions', JSON.stringify(provinceOptions));
  }

  get itoPackageGroupedOptions() {
    const itoPackageGrouped = localStorage.getItem('itoPackageGrouped');
    return itoPackageGrouped ? JSON.parse(itoPackageGrouped.toString()) : undefined;
  }

  set itoPackageGroupedOptions(itoPackageGrouped: any) {
    localStorage.setItem('itoPackageGrouped', JSON.stringify(itoPackageGrouped));
  }

  get userServiceRoleOptions() {
    const userServiceRoleOptions = localStorage.getItem('userServiceRoleOptions');
    return userServiceRoleOptions ? JSON.parse(userServiceRoleOptions.toString()) : undefined;
  }

  set userServiceRoleOptions(userServiceRoleOptions: any) {
    localStorage.setItem('userServiceRoleOptions', JSON.stringify(userServiceRoleOptions));
  }

  get delegationTypeOptions() {
    const delegationType = localStorage.getItem('delegationType');
    return delegationType ? JSON.parse(delegationType.toString()) : undefined;
  }

  set delegationTypeOptions(delegationType: any) {
    localStorage.setItem('delegationType', JSON.stringify(delegationType));
  }

  get typeOfPoOptions(){
    const typeOfPoOptions = localStorage.getItem('typeOfPoOptions');
    return typeOfPoOptions ? JSON.parse(typeOfPoOptions.toString()) : undefined;
  }

  set typeOfPoOptions(typeOfPoOptions: any) {
    localStorage.setItem('typeOfPoOptions', JSON.stringify(typeOfPoOptions));
  }

  public getPropertyItem(key: string, lang?: string,  tokens?: any[]): string{
    lang = lang?? this.preference.language ?? 'en';
    const json = this[lang];
    const text = json?.[key];
    if (text) {
      if (tokens && tokens.length) {
        return Utils.format(text, tokens);
      } else
      return text;
    } else
      return key;
  }

  public getValueFromCache(cachename: string, code: string, lang?: string) : string {
    lang = lang?? this.preference.language ?? 'en';
    return this[`${cachename}Options`]?.find(x => x.id === code)?.name[lang];
  }

}
