import { ChangeDetectionStrategy, Component, ElementRef, Injector, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  onInqFormCourtRegionChanged$,
  onInqFormErrorsChanged$,
  onInqFormInquiryTypeChanged$,
  onInqFormSuccessChanged$,
  onInqFormViewChanged$
} from '@app/core/app.subscriptions';
import { SubmissionDispatcher, inquiryForm } from '@app/core/submissions';
import { BaseViewComponent, PageView, getViewFromUrl } from '@app/view';
import { environment } from '@environments/environment';
import isEmpty from 'lodash/isEmpty';
import { catchError, delay, takeWhile, tap } from 'rxjs';
import { AppService } from '../../core';
import { UserService } from '../../core/auth';

@Component({
  selector: 'app-inquiry-form',
  templateUrl: './inquiry-form.component.html',
  styleUrls: ['./inquiry-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InquiryFormComponent extends BaseViewComponent implements OnInit {
  inquiry: any = {};
  notifications: any = {};
  private dispatcher$: any = {};

  constructor(private injector: Injector, 
    private route: ActivatedRoute, 
    private dispatcher: SubmissionDispatcher, 
    private userService : UserService,
    private appService: AppService) {
    super(injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    const route = this.route,
      router = this.router,
      dispatcher = this.dispatcher;

    onInqFormViewChanged$({ route, router, dispatcher })
      .pipe(takeWhile(() => this.alive))
      .subscribe((inquiry: any) => {
        this.inquiry = inquiry;
        dispatcher.clearNotifications();

        this.register$([
          onInqFormCourtRegionChanged$(inquiry.form),
          onInqFormInquiryTypeChanged$(inquiry.form),
          onInqFormErrorsChanged$(dispatcher.notifications$, ns => {
            if (ns?.length>0) {
              this.notifications.error = ns[0];
              setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }), 300) ;
            } 
          }),
          onInqFormSuccessChanged$(dispatcher.notifications$, ns => {
            if (!isEmpty(ns)) this.appService.goToHomePage();
          })
        ]);

        this.updateView();
      });

    this.userService.currentUser$.subscribe(x => {
        this.officerName = `"${x?.['lastName']??''}, ${x?.['firstName']??''}"`;
        this.updateView();
    });
  }

  isValiationsSetDone = false;
  isSubmitting = false;

  sendForm(e) {
    Object.values(this.inquiry.form.controls).forEach((control : FormControl)=> {
      control.markAsTouched();
    });
    if (this.canSubmit) {
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      const payload = this.inquiry.toPayload();
      this.dispatcher
        .save(payload, { from: this.router.url }, true)
        .pipe(
          takeWhile(() => this.alive)
        ).subscribe(
          {complete : () => {this.isSubmitting = false; console.log('Submiting Inquiry completed!');}}
        );
    }
  }

  clearForm() {
    this.inquiry = inquiryForm({ validateForm: environment.validateForm });
  }

  resetForm() {
    this.dispatcher.selected$
      .pipe(tap(s => (this.inquiry = inquiryForm({ data: s, validateForm: environment.validateForm }))))
      .subscribe(() => this.updateView());
  }

  printForm() {
    setTimeout(() => {
      window.print();
    }, 100);
  }

  optionItems(name) {
    return this.appCache[`${name}Options`];
  }

  formCtrl(name) {
    return this.inquiry?.form?.get(name) as FormControl;
  }

  field(name) {
    return this.inquiry?.schema && this.inquiry?.schema[name];
  }

  get courtHouseOptions() {
    if (!this.formCtrl('courtRegionCode')?.value) return [];
    return this.appCache?.courtHouseOptions.filter(o => Number(o?.parentId) === Number(this.formCtrl('courtRegionCode')?.value));
  }

  officerName  = `"${this.userService.currentUser?.lastName??''}, ${this.userService.currentUser?.firstName??''}"`;

  get viewType() {
    return getViewFromUrl(this.router.url);
  }

  get isFormView() {
    return this.viewType === PageView.Create;
  }

  get isReadOnlyView() {
    return this.viewType === PageView.Read;
  }

  get isEditView() {
    return this.viewType === PageView.Update;
  }

  get shouldShowErrors() {
    return this.notifications?.error;
  }

  get canSubmit() {
    return this.inquiry.validateForm ? this.inquiry?.form?.valid && !this.inquiry?.form?.pristine : true;
  }
}
