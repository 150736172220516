export const AUTH_HOST = 'ws.dev.justiceportal.jus.gov.on.ca';
export const AUTH_DOMAIN = `https://${AUTH_HOST}`;
export const AUTH_ENDPOINT_ROOT = `${AUTH_DOMAIN}/enf/auth`;
export const USER_ENDPOINT_ROOT = `${AUTH_DOMAIN}/enf/user`;

export const AUTH = {
  AUTHORITY_ISSUER: 'https://stage.login.security.gov.on.ca',
  BASIC_TOKEN: "RVNXUG9saWNlUG9ydGFsQ2xpZW50OkVzQGFyY2hQMGwxY0A=",
  IS_SERVICE_AUTHORIZED: `${AUTH_ENDPOINT_ROOT}/isAuthorizedService?email={email}&service={service}`,
  REQ_HEADER_DOMAIN_NAME: "ESearchWarrantPPortalDomain",
  CLIENT_ID: "ESWPolicePortalClient",
  SERVICE_NAME: "ESW"
};

export const API_PORT = 5201;
export const API_HOST = 'ws.dev.esw.officerportal.jus.gov.on.ca';
export const API_DOMAIN = `https://${API_HOST}`;
export const API_ENDPOINT_ROOT = `${API_DOMAIN}/esw-submission`;

export const API_ENDPOINT_AUTH = `${API_ENDPOINT_ROOT}/auth`;
export const API_ENDPOINT_REFERENCE_DATA = `${API_ENDPOINT_ROOT}/reference`;
const API_ENDPOINT_REFERENCE_DATA_TYPE = `${API_ENDPOINT_REFERENCE_DATA}/type`;

export const API_ENDPOINTS = {
  AUTH: API_ENDPOINT_AUTH,
  USERS: `${API_ENDPOINT_AUTH}/users`,

  USER: `${USER_ENDPOINT_ROOT}/findAll`,
  USER_GET: `${USER_ENDPOINT_ROOT}/findById?userId={userId}`,
  USER_GET_BY_EMAIL: `${USER_ENDPOINT_ROOT}/findByLoginEmail?email={email}`,
  USER_GET_BY_CERT_EMAIL: `${USER_ENDPOINT_ROOT}/findByCertEmail?email={email}`,
  USER_CREATE: `${USER_ENDPOINT_ROOT}/`,
  USER_SERVICE_ROLES: `${USER_ENDPOINT_ROOT}/serviceRoles`,

  REFERENCE_DATA: API_ENDPOINT_REFERENCE_DATA,
  REF_PACKAGE_TYPES: `${API_ENDPOINT_REFERENCE_DATA_TYPE}/REF_PACKAGE_TYPE`,
  REF_WARRANT_TYPES: `${API_ENDPOINT_REFERENCE_DATA_TYPE}/REF_ITO_TYPE`,
  REF_PROCESS_STATUSES: `${API_ENDPOINT_REFERENCE_DATA_TYPE}/REF_PROCESS_STATUS`,
  REF_COURT_REGIONS: `${API_ENDPOINT_REFERENCE_DATA_TYPE}/Onregion`,
  REF_COURTHOUSE: `${API_ENDPOINT_REFERENCE_DATA_TYPE}/CourtLocation`,
  REF_PRIORITY_LEVEL: `${API_ENDPOINT_REFERENCE_DATA_TYPE}/PriorityType`, 
  REF_ADULT_OR_YOUTH: `${API_ENDPOINT_REFERENCE_DATA_TYPE}/REF_ADULT_YOUTH`, 
  REF_ITO_PACKAGE_FORMAT: `${API_ENDPOINT_REFERENCE_DATA_TYPE}/REF_PACKAGE_FORMAT`, 
  REF_PROVINCES: `${API_ENDPOINT_REFERENCE_DATA_TYPE}/Province`,
  REF_DELEGATION: `${API_ENDPOINT_REFERENCE_DATA_TYPE}/REF_DELEGATION`,

  SUBMISSION_INQUIRY: `${API_ENDPOINT_ROOT}/home/list/packages`,
  SUBMISSION_INQUIRY_GET: `${API_ENDPOINT_ROOT}/inquiryform/get/{packageUUID}`,
  SUBMISSION_INQUIRY_CREATE: `${API_ENDPOINT_ROOT}/inquiryform/submit`,
  SUBMISSION_INQUIRY_ARCHIVE: `${API_ENDPOINT_ROOT}/home/package/archive/{packageUUID}`,
  SUBMISSION_INQUIRY_DELETE: `${API_ENDPOINT_ROOT}/home/package/delete/{packageUUID}`,
  SUBMISSION_INQUIRY_VIEW_PDF: `${API_ENDPOINT_ROOT}/home/download/package/{packageUUID}`,
  SUBMISSION_INQUIRY_DOWNLOAD_PDF: `${API_ENDPOINT_ROOT}/home/download/package/{packageUUID}`,

  SUBMISSION_PACKAGES: `${API_ENDPOINT_ROOT}/home/list/packages`,
  DELEGATED_PACKAGES: `${API_ENDPOINT_ROOT}/home/list/delegated/packages`,
  USER_DELEGATES: `${API_ENDPOINT_ROOT}/delegation/list/{package-uuid}`,
  SUBMISSION_PACKAGE_WARRANTS: `${API_ENDPOINT_ROOT}/home/list/warrants/{package-uuid}`,

  USER_DELEGATES_ASSIGN: `${API_ENDPOINT_ROOT}/delegation/assign`,
  USER_DELEGATES_UNASSIGN: `${API_ENDPOINT_ROOT}/delegation/unassign?packageUuid={packageUuid}&email={email}`,

  SUBMISSION_SEARCHWARRANT : `${API_ENDPOINT_ROOT}/searchwarrant/get`,
  SUBMISSION_SEARCHWARRANT_SAVE : `${API_ENDPOINT_ROOT}/searchwarrant/save`,
  SUBMISSION_SEARCHWARRANT_CREATE : `${API_ENDPOINT_ROOT}/searchwarrant/create`,
  SUBMISSION_SEARCHWARRANT_SUBMIT : `${API_ENDPOINT_ROOT}/searchwarrant/submit`,
  SUBMISSION_SEARCHWARRANT_DOWNLOAD_ITO : `${API_ENDPOINT_ROOT}/searchwarrant/download/ito`,
  SUBMISSION_SEARCHWARRANT_UPLOAD_APPC : `${API_ENDPOINT_ROOT}/searchwarrant/upload/appc`,
  SUBMISSION_SEARCHWARRANT_UPLOAD_ITO : `${API_ENDPOINT_ROOT}/searchwarrant/upload/ito`,
  SUBMISSION_SEARCHWARRANT_DELETE_APPC : `${API_ENDPOINT_ROOT}/searchwarrant/delete/appc`,
  SUBMISSION_SEARCHWARRANT_DELETE_ITO : `${API_ENDPOINT_ROOT}/searchwarrant/delete/ito`,

  SUBMISSION_PRODUCTIONORDER : `${API_ENDPOINT_ROOT}/prod-order/get`,
  SUBMISSION_PRODUCTIONORDER_SAVE : `${API_ENDPOINT_ROOT}/prod-order/save`,
  SUBMISSION_PRODUCTIONORDER_CREATE : `${API_ENDPOINT_ROOT}/prod-order/create`,

  MESSAGE: `${API_ENDPOINT_ROOT}/messages`,
  MESSAGE_GET: `${API_ENDPOINT_ROOT}/messages`,
  MESSAGE_CREATE: `${API_ENDPOINT_ROOT}/messages`,
};

/* IDT - Integrated Development */
export const environment = {
  authToken: '',
  
  production: false,

  // mock-server settings
  apiPort: API_PORT,
  httpDelayMs: 500,

  // i18n
  defaultLanguage: 'en',
  i18nDirectory: './assets/i18n/',

  // cache
  cacheLifespanInHours: 12,
  cacheMaxAge: 3600000, // 1 hour
  cacheableHttpMethods: ['GET'],
  cacheableUrls: [],

  // form
  validateForm: true,

  // auth
  defaultUserName: 'frontend',
  redirectUri: '',
  accessDeniedRedirectUri: '',
  clientId: '',
  scope: '',
  logoutUrl: '',
  postLogoutRedirectUri: '',

  // api
  apiDomain: API_DOMAIN,
  apiEndpoint: API_ENDPOINTS,
  docEndpoint: `${API_DOMAIN}/documents`
};
