import { Component, Input } from '@angular/core';

@Component({
  selector: 'ods-spinner',
  templateUrl: './ods-spinner.component.html',
  styleUrls: ['./ods-spinner.component.scss']
})
export class OdsSpinnerComponent {
  @Input() fullScreen = true;
}
