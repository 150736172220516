<div class="textareaContainer" [ngStyle]="getContainerStyle()">
  <textarea #theTextarea
    matInput [ngStyle]="getTextareaStyle()"
    [attr.required]="required"
    [formControlName]="formCtrlName"
    class="ontario-input ontario-textarea expandable-textarea"
    [class.ontario-input__error]="hasError"
    [id]="formCtrlId"
    [name]="formCtrlName"
    [attr.aria-describedby]="describedBy"
    [attr.aria-invalid]="hasError"></textarea>
  <div class="NgxEditor__Wrapper" [ngStyle]="getEditorStyle()">
    <ngx-editor-menu [editor]="editor" [toolbar]="toolbar" />
    <ngx-editor [editor]="editor" [spellcheck]="true" />
  </div>
  <button mat-icon-button type="button" class="float-right-top" (click)="expandTextarea()">
    <mat-icon role="img" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color"
      aria-hidden="true" data-mat-icon-type="font">{{ getIconText() }}</mat-icon>
  </button>
</div>
<ng-content></ng-content>
