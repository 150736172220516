
<div mat-dialog-title [innerHTML]="data.title"></div>
<hr>
<div mat-dialog-content>

    <table mat-table #usersListTable [dataSource]="dataSource" class="example-table" matSort #sort="matSort" matSortDisableClear matSortDirection="desc">
        <caption>{{ 'choose.login.emails.table.caption' | translate | async}}</caption>

        <ng-container matColumnDef="loginEmail">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "choose.login.emails.table.column.loginEmail.title" | translate | async }}</th>
            <td mat-cell *matCellDef="let row; let i = index">
                <div class="ontario-radios__item" >
                    <input class="ontario-radios__input" type="radio" name="choose-working-email" id="choose-working-email-{{i}}" [checked]="row?.loginEmail?.toLowerCase() === selectedEmail" value="{{ row?.loginEmail }}" (change)="onItemChange($event)">
                    <label class="ontario-radios__label" for="choose-working-email-{{i}}">{{ row?.loginEmail }}</label>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "choose.login.emails.table.column.name.title" | translate | async }}</th>
            <td mat-cell *matCellDef="let row">{{ row?.name }}</td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "choose.login.emails.table.column.title.title" | translate | async }}</th>
            <td mat-cell *matCellDef="let row">{{ row?.title }}</td>
        </ng-container>

        <ng-container matColumnDef="rank">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "choose.login.emails.table.column.rank.title" | translate | async }}</th>
            <td mat-cell *matCellDef="let row">{{ row?.rank }}</td>
        </ng-container>

        <ng-container matColumnDef="agency">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "choose.login.emails.table.column.angency.title" | translate | async }}</th>
            <td mat-cell *matCellDef="let row">{{ row?.agency }}</td>
        </ng-container>

        <ng-container matColumnDef="region">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "choose.login.emails.table.column.region.title" | translate | async }}</th>
            <td mat-cell *matCellDef="let row">{{ row?.region }}</td>
        </ng-container>

        <ng-container matColumnDef="empty-row">
            <mat-cell *matCellDef="let element">{{ "table.noData.text" | translate | async }}</mat-cell>
          </ng-container> 

        <tr mat-header-row *matHeaderRowDef="usersListColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: dataSource?.data?.length > 0 ? usersListColumns : ['empty-row'];" [class.ontario-table-row--highlight]="row.highlighted"></tr>
    </table>

</div>
<hr>
<div mat-dialog-actions class="dialog_buttons">
    <button class="ontario-button" (click)="dialogRef.close({selectedEmail : this.selectedEmail, usersList: this.usersList})">{{data.close}}</button>
</div>