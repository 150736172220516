import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'manage-delegates-dialog',
    templateUrl: './manage-delegates-dialog.component.html',
    styleUrls: ['./manage-delegates-dialog.component.scss'],
})
export class ManageDelegatesDialogComponent {

    constructor(public dialogRef: MatDialogRef<ManageDelegatesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            packageId: string,
            close?: string
        }) { }
}