import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppCoreModule } from '@app/core';
import { SharedModule } from '@shared/modules';
import { ProductionOrderFormRoutingModule } from './production-order-form-routing.module';
import { ProductionOrderFormComponent } from './production-order-form.component';

const components = [ProductionOrderFormComponent];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, ProductionOrderFormRoutingModule, AppCoreModule, SharedModule],
  exports: [...components]
})
export class ProductionOrderFormModule {}
