import { ChangeDetectionStrategy, Component, ElementRef, Injector, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { UserDispatcher, withUserTransformer } from "@app/core/auth";
import { NotificationType } from "@app/core/notifications";
import { BaseViewComponent } from "@app/view/base-view.component";
import { debounceTime, delay, distinctUntilChanged, forkJoin, fromEvent, merge, switchMap, takeWhile, tap } from "rxjs";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class UsersComponent extends BaseViewComponent implements OnInit {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true, read: ElementRef }) searchInput: ElementRef;

  users: any[] = [];
  notifications: any = {};

  isAccordionOpened = false;

  displayedColumns = [
    'name',
    'loginEmail',
    'role',
    'actions'
  ];
  loadingResults = true;
  resultsLength = 0;
  dataSource = new MatTableDataSource<any>();
  emptyData = new MatTableDataSource<any>([{ empty: "row" }]);

  searchForm = new FormGroup({
    searchInput: new FormControl('')
  });

  constructor(
    private injector: Injector,
    private route: ActivatedRoute,
    private dispatcher: UserDispatcher
  ) {
    super(injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.route.params
      .pipe(
        switchMap(() => this.loadResources()),
        takeWhile(() => this.alive),
        tap(() => this.register$([this.onNotificationChange, this.onUsersChange])),
        tap(([users]) => this.updateUserTable(users)),
        tap(() => this.updateView())
      )
      .subscribe();
  }

  ngAfterViewInit() {
    if (!this.sort || !this.paginator) return;
    merge(this.sort?.sortChange, this.paginator?.page)
      .pipe(
        switchMap((pagination: any) => {
          this.loadingResults = true;
          this.searchForm.get('searchInput').disable();
          return this.dispatcher.load(false, this.searchText);
        })
      )
      .subscribe(data => this.updateUserTable(data));

    if (!this.searchInput || !this.searchInput.nativeElement) return;
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        tap(() => {
          this.loadingResults = true;
          this.searchForm.get('searchInput').disable();
          this.changeDetectorRef.markForCheck();
        }),
        delay(1000),
        switchMap((searchInput: any) => this.dispatcher.load(false, searchInput?.target?.value))
      )
      .subscribe(data => {
        this.updateUserTable(data);
        this.updateView();
      });
  }

  private loadResources() {
    return forkJoin([this.dispatcher.load()]);
  }

  private get onNotificationChange() {
    return this.dispatcher.notifications$?.pipe(
      takeWhile(() => this.alive),
      distinctUntilChanged(),
      tap(notifications => {
        console.log('setting notifications here');
        console.log(notifications);
        this.notifications = {
          error: notifications?.find(n => n?.type === NotificationType.Error),
          success: notifications?.find(n => n?.type === NotificationType.Success)
        };
        this.updateUserTable();
      })
    );
  }

  private get onUsersChange() {
    return this.dispatcher.users$?.pipe(
      takeWhile(() => this.alive),
      distinctUntilChanged(),
      tap(users => {
        console.log('setting user here');
        this.users = users?.map(s => Object.assign({}, s, withUserTransformer({ state: s }).toTable()));
        console.log(this.users);
        this.updateUserTable(users);
      })
    );
  }

  private updateUserTable(data?) {
    this.loadingResults = false;
    this.searchForm.get('searchInput').enable();
    if (data && Object.prototype.hasOwnProperty.call(data, 'length')) {
      this.resultsLength = data.length;
      this.dataSource.data = [...data];
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  formCtrl(name) {
    return this.searchForm?.get(name) as FormControl;
  }

  get searchText() {
    return this.searchForm.get('searchInput').value;
  }
}