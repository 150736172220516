
import { Validators } from "@angular/forms";
import { FormFieldSchema, ValidatorType } from "../models";
import { SubmissionSchema } from "./submission.schema";

export const productionOrderSchema: any = Object.assign({}, SubmissionSchema, {
  courtRegion: {
    id: 'court-region',
    name: 'courtRegion',
    type: Number,
    step: 1,
    db: true,
    form: true,
    defaultValue: '',
    disabled: false,
    label: 'productionOrderForm.step1.field.courtRegion.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  courthouse: {
    id: 'courthouse',
    name: 'courthouse',
    type: Number,
    step: 1,
    db: true,
    form: true,
    defaultValue: '',
    disabled: true,
    label: 'productionOrderForm.step1.field.courthouse.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  associatedPreviousApp : {
    id: 'is-this-ito-associated',
    name: 'associatedPreviousApp',
    type: Boolean,
    step: 1,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step1.field.is.this.ito.associated',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  associatedPreviousNum : {
    id: 'control-reference-package-number',
    name: 'associatedPreviousNum',
    type: String,
    step: 1,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'productionOrderForm.step1.field.control.reference.package.number',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(15),
        value: 15,
        message: 'validation.message.maxLength'
      },
      {
        type: ValidatorType.Pattern,
        fn: Validators.pattern(/^[a-z0-9]+$/i),
        value: /^[a-z0-9]+$/i,
        message: 'validation.message.alphanumeric.only'
      },
      {
        type: ValidatorType.MinLength,
        fn: Validators.minLength(15),
        value:16,
        message: 'validation.message.less.than.thenumber'
      }
    ]
  },
  priorityCode: {
    id: 'priority-level',
    name: 'priorityCode',
    type: String,
    step: 1,
    db: true,
    form: true,
    defaultValue: '',
    disabled: false,
    label: 'productionOrderForm.step1.field.priority.level',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  enterOffenceWording: {
    id: 'enter-offence-wording',
    name: 'enterOffenceWording',
    type: String,
    step: 1,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step1.field.enter.offence.wording',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(5000),
        value: 5000,
        message: 'validation.message.maxLength'
      }
    ]
  },
  poTypeCode: {
    id: 'type-of-po',
    name: 'poTypeCode',
    type: Number,
    step: 2,
    db: true,
    form: true,
    defaultValue: '',
    disabled: false,
    label: 'productionOrderForm.step2.field.po.type',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  poUUID: {
    id: 'poUUID',
    name: 'poUUID',
    type: String,
    step: 2,
    db: false,
    form: true,
    defaultValue: '',
    disabled: false,
    label: '',
    description: '',
    validators: []
  },
  personGivenName: {
    id: 'person-given-name',
    name: 'personGivenName',
    type: String,
    step: 2,
    usedFor: ['014','016','017'],
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step2.field.person.givenname',
    description: '',
    validators: [
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(50),
        value: 50,
        message: 'validation.message.maxLength'
      }
    ]
  },
  personSurname: {
    id: 'person-surname',
    name: 'personSurname',
    type: String,
    step: 2,
    usedFor: ['014','016','017'],
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step2.field.person.surname',
    description: '',
    validators: [
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(50),
        value: 50,
        message: 'validation.message.maxLength'
      }
    ]
  },
  organizationName: {
    id: 'organizationName',
    name: 'organizationName',
    type: String,
    step: 2,
    usedFor: ['014','016','017'],
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step2.field.organization.name',
    description: '',
    validators: [
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(100),
        value: 100,
        message: 'validation.message.maxLength'
      }
    ]
  },
  personAndOrOrgName: {
    id: 'person-and-or-org-name',
    name: 'personAndOrOrgName',
    type: String,
    step: 2,
    usedFor: ['014','016','017'],
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step2.field.person.organization.hint',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      }
    ]
  },
  address2Searched: {
    id: 'address-be-searched',
    name: 'address2Searched',
    type: String,
    step: 2,
    usedFor: ['014','016','017','018'],
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step2.field.address.be.searched',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(250),
        value: 250,
        message: 'validation.message.maxLength'
      }
    ]
  },
  addressCity: {
    id: 'address-city',
    name: 'addressCity',
    type: String,
    step: 2,
    usedFor: ['014','016','017','018'],
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step2.field.address.city',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(40),
        value: 40,
        message: 'validation.message.maxLength'
      }
    ]
  },
  addressProvinceCode: {
    id: 'address-province',
    name: 'addressProvinceCode',
    type: Number,
    step: 2,
    usedFor: ['014','016','017','018'],
    db: true,
    form: true,
    defaultValue: '',
    disabled: false,
    label: 'productionOrderForm.step2.field.address.province',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  selectionOfDocOrData: {
    id: 'selection-of-doc-or-data',
    name: 'selectionOfDocOrData',
    type: String,
    step: 2,
    usedFor: ['014'],
    db: true,
    form: true,
    defaultValue: '',
    disabled: false,
    label: 'productionOrderForm.step2.field.what.be.produced',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      }
    ]
  },
  documentCopy: {
    id: 'document-copy',
    name: 'documentCopy',
    type: String,
    step: 2,
    usedFor: ['014'],
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'productionOrderForm.step2.field.document.copy',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(1024),
        value: 1024,
        message: 'validation.message.maxLength'
      }
    ]
  }, 
  containingData: {
    id: 'containing-data',
    name: 'containingData',
    type: String,
    step: 2,
    usedFor: ['All'], // Data / Transmission Data / Tracking Data
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'productionOrderForm.step2.field.document.containing',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(1024),
        value: 1024,
        message: 'validation.message.maxLength'
      }
    ]
  },
  deliveredToInformant : {
    id: 'delivered-to-informant',
    name: 'deliveredToInformant',
    type: Boolean,
    step: 2,
    usedFor: ['All'],
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step2.field.document.deliver.to.officer',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  deliveredToPersonName: {
    id: 'delivered-to-person-name',
    name: 'deliveredToPersonName',
    type: String,
    step: 2,
    usedFor: ['All'],
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'productionOrderForm.step2.field.document.deliver.to.person.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(50),
        value: 50,
        message: 'validation.message.maxLength'
      }
    ]
  },
  producedWithinDays: {
    id: 'produced-within-days',
    name: 'producedWithinDays',
    type: Number,
    step: 2,
    usedFor: ['014','016','017','018'],
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step2.field.produced.within.days',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      }
    ]
  },
  producedAtPlace: {
    id: 'produced-at-place',
    name: 'producedAtPlace',
    type: String,
    step: 2,
    usedFor: ['All'],
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step2.field.produced.at.place',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(200),
        value: 200,
        message: 'validation.message.maxLength'
      }
    ]
  },
  producedInFormSelection: {
    id: 'produced-in-form-selection',
    name: 'producedInFormSelection',
    type: String,
    step: 2,
    usedFor: ['All'],
    db: true,
    form: true,
    defaultValue: '',
    disabled: false,
    label: 'productionOrderForm.step2.field.specify.receive.document',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      }
    ]
  },
  producedInForm: {
    id: 'produced-in-form',
    name: 'producedInForm',
    type: String,
    step: 2,
    usedFor: ['All'],
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'productionOrderForm.step2.field.produced.in.form',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(200),
        value: 200,
        message: 'validation.message.maxLength'
      }
    ]
  },
  communication: {
    id: 'communication',
    name: 'communication',
    type: String,
    step: 2,
    usedFor: ['015'], // - device or person involved in
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step2.field.communication',
    description: '',
    validators: [
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(1000),
        value: 1000,
        message: 'validation.message.maxLength'
      }
    ]
  },
  orderConditions: {
    id: 'order-conditions',
    name: 'orderConditions',
    type: String,
    step: 2,
    usedFor: ['All'],
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step2.field.order.conditions',
    description: '',
    validators: [
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(1000),
        value: 1000,
        message: 'validation.message.maxLength'
      }
    ]
  },
  financialInstitution: {
    id: 'financial-institution',
    name: 'financialInstitution',
    type: String,
    step: 2,
    usedFor: ['018'],
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step2.field.financial.institution',
    description: '',
    validators: [
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(1000),
        value: 1000,
        message: 'validation.message.maxLength'
      }
    ]
  },

  
  assistanceOrderRequired: {
    id: 'is-an-assistance-order-required',
    name: 'assistanceOrderRequired',
    type: Boolean,
    step: 3,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step3.field.is.an.assistance.order.required',
    hint: 'productionOrderForm.step3.field.is.an.assistance.order.required.hint',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  ppaUUID: {
    id: 'ppaUUID',
    name: 'ppaUUID',
    type: String,
    step: 3,
    db: false,
    form: true,
    defaultValue: '',
    disabled: false,
    label: '',
    description: '',
    validators: []
  },
  givenName: {
    id: 'person-providing-assistance-given-name',
    name: 'givenName',
    type: String,
    step: 3,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step3.field.person.providing.assistance.givenname',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(100),
        value: 100,
        message: 'validation.message.maxLength'
      }
    ]
  },
  surname: {
    id: 'person-providing-assistance-surname',
    name: 'surname',
    type: String,
    step: 3,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step3.field.person.providing.assistance.surname',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(100),
        value: 100,
        message: 'validation.message.maxLength'
      }
    ]
  },
  title: {
    id: 'person-providing-assistance-title',
    name: 'title',
    type: String,
    step: 3,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step3.field.person.providing.assistance.title',
    description: '',
    validators: [
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(50),
        value: 50,
        message: 'validation.message.maxLength'
      }
    ]
  },
  qualifications: {
    id: 'person-providing-assistance-qualifications',
    name: 'qualifications',
    type: String,
    step: 3,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step3.field.person.providing.assistance.qualifications',
    description: '',
    validators: [
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(1024),
        value: 1024,
        message: 'validation.message.maxLength'
      }
    ]
  },    
  assistanceExplain: {
    id: 'person-providing-assistance-explain',
    name: 'assistanceExplain',
    type: String,
    step: 3,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step3.field.person.providing.assistance.explain',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(500),
        value: 500,
        message: 'validation.message.maxLength'
      }
    ]
  },
  personProvidingAssistanceChoose: {
    id: 'person-providing-assistance-choose',
    name: 'personProvidingAssistanceChoose',
    type: String,
    step: 3,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step3.field.person.providing.assistance.choose',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      }
    ]
  },
  providingAssistanceChooseSelectAll: {
    id: 'providing-assistance-choose-select-all',
    name: 'providingAssistanceChooseSelectAll',
    type: Boolean,
    step: 3,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'productionOrderForm.step3.field.person.providing.assistance.choose.select.all',
    description: '',
    validators: []
  }
});