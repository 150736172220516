<h1>{{ "users.title" | translate | async }}</h1>

<!-- notifications -->
<div class="ontario-row">
  <!-- success -->
  <div *ngIf="notifications?.success"
    class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
    <ods-page-alert role="success">
      <ng-container title>{{ notifications?.success?.title | translate | async }}</ng-container>
      <ng-container body>
        <p [innerHtml]="notifications?.success?.content | translate | async | safe : 'html'"></p>
      </ng-container>
    </ods-page-alert>
  </div>

  <!-- error -->
  <div *ngIf="notifications?.error"
    class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
    <ods-page-alert role="error">
      <ng-container title>{{ notifications?.error?.title | translate | async }}</ng-container>
      <ng-container body>
        <p>{{ notifications?.error?.content | translate | async }}</p>
      </ng-container>
    </ods-page-alert>
  </div>

  <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0"></div>
</div>

<!-- create button -->
<div class="ontario-row">
  <button [routerLink]="['/user']" class="ontario-button ontario-button--primary">
    {{ "users.button.create.text" | translate | async }}
  </button>
</div>

<!-- search bar -->
<div class="ontario-row">
  <form novalidate [formGroup]="searchForm" class="ontario-row">
    <div class="ontario-row">
      <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
        <div class="ontario-form-group">
          <odsf-label formCtrlId="search-input">
            <span name>{{ "users.input.filter.label.text" | translate | async }}</span>
          </odsf-label>

          <odsf-input #searchInput formCtrlId="search-input" formCtrlName="searchInput"
            [formCtrl]="formCtrl('searchInput')" [disabled]="loadingResults">
          </odsf-input>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- raw data -->
<div class="ontario-row" style="margin-bottom:20px!important">
  <div class='ontario-accordion' id='accordion-1'>
    <h3 class='ontario-accordion-heading' [class.ontario-expander--active]="isAccordionOpened">
      <button class='ontario-accordion__button' id='accordion-button-id-1' aria-controls='accordion-content-1' [attr.aria-expanded]='isAccordionOpened' data-toggle='ontario-collapse' (click)="isAccordionOpened = !isAccordionOpened">
        <span>Raw Data</span>
        <span class='ontario-accordion__button-icon--close'>
          <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
            <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-up"></use>
          </svg>
        </span>
        <span class='ontario-accordion__button-icon--open'>
          <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
            <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-down"></use>
          </svg>
        </span>
      </button>
    </h3>
    <section class='ontario-accordion__content' [class.ontario-expander__content--opened]="isAccordionOpened" id='accordion-content-1' aria-labelledby='accordion-button-id-1' [attr.aria-hidden]='!isAccordionOpened' data-toggle='ontario-expander-content'>
      <div *ngFor="let user of users">
        <span>
          <a class="ontario-__link mat-column-actions-btn" [routerLink]="['/user/',user.id,'edit']"
            aria-label="Edit User">
            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary"
              viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-edit"></use>
            </svg>
          </a>
        </span>
        <pre>{{ user | json}}</pre>
      </div>
      <br />
    </section>
  </div>
</div>

<div class="ontario-row">
  <div class="example-container" style="position: relative; width: 100%" [class.loading]="loadingResults">

    <ods-spinner *ngIf="loadingResults" [fullScreen]="false">
      {{ "app.spinner.text" | translate | async }}
    </ods-spinner>

    <div class="example-table-container" style="overflow: auto">
      <table mat-table [dataSource]="dataSource?.data.length > 0 ? dataSource : emptyData" class="example-table" matSort #sort="matSort" matSortActive="name" matSortDisableClear matSortDirection="desc">
        <!-- name column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "users.table.column.name.title" | translate | async }}</th>
          <!-- <td mat-cell *matCellDef="let row">{{ row?.name | namepipe }}</td> -->
          <td mat-cell *matCellDef="let row">{{ row?.firstName }}, {{ row?.lastName }}</td>
        </ng-container>

        <!-- email column -->
        <ng-container matColumnDef="loginEmail">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "users.table.column.email.title" | translate | async }}</th>
          <td mat-cell *matCellDef="let row">{{ row?.loginEmail }}</td>
        </ng-container>

        <!-- role column -->
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "users.table.column.role.title" | translate | async }}</th>
          <!-- <td mat-cell *matCellDef="let row">{{ row?.services | rolepipe }}</td> -->
          <td mat-cell *matCellDef="let row">{{ row?.loginEmail }}</td>
        </ng-container>

        <!-- actions column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ "users.table.column.actions.title" | translate | async }}</th>
          <td mat-cell *matCellDef="let row">
            <a class="ontario-__link mat-column-actions-btn" [routerLink]="['/user/',row.id]" aria-label="View User">
              <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-password-show"></use>
              </svg>
            </a>
            <a class="ontario-__link mat-column-actions-btn" [routerLink]="['/user/',row.id,'edit']" aria-label="Edit User">
              <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-edit"></use>
              </svg>
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="empty-row">
          <mat-cell *matCellDef="let element">{{ "table.noData.text" | translate | async }}</mat-cell>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: dataSource?.data?.length > 0 ? displayedColumns : ['empty-row'];" [class.ontario-table-row--highlight]="row?.highlighted"></tr>
      </table>
    </div>

    <mat-paginator #paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageSize]="5" showFirstLastButtons></mat-paginator>
  </div>
</div>