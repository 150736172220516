import { APP_REQUEST_CONTEXT } from '@/src/shared/loading';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ENDPOINTS, API_ENDPOINT_ROOT } from '@environments/environment';
import { Observable, map } from 'rxjs';

const transformer = {
  toSWPackage: s => {
    const { packageId, packageUUID, packageType, itoTypes, currentStatus, currentStatusDate, sentDate, receivedDate, file, delegationType } = s;
    return { ...s.originalRequest, id: packageId, packageId, packageUUID, packageType, typeOfInquiryCode: packageType, itoTypes, currentStatus, currentStatusDate, sentDate, receivedDate, file , delegationType};
  }
};

@Injectable({ providedIn: 'root' })
export class SubmissionSearchWarrantApiService {
  constructor(private httpClient: HttpClient) {}

  get(uuid: string, isAppRequest?: boolean): Observable<any> {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return (this.httpClient.get(`${API_ENDPOINTS.SUBMISSION_SEARCHWARRANT}/${uuid}`, options) as Observable<any>).pipe(
      map(transformer.toSWPackage)
    );
  }

  getAll(isAppRequest?: boolean): Observable<any> {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return (this.httpClient.get(API_ENDPOINTS.SUBMISSION_SEARCHWARRANT, options) as unknown as Observable<any[]>).pipe(
      map(ss => ss.map(transformer.toSWPackage))
    );
  }

  create(submission, isAppRequest?: boolean): Observable<any> {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return this.httpClient.post(API_ENDPOINTS.SUBMISSION_SEARCHWARRANT_CREATE, submission, options) as Observable<any>;
  }

  update(submission, packageUUID, isAppRequest?: boolean): Observable<any> {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return this.httpClient.post(`${API_ENDPOINTS.SUBMISSION_SEARCHWARRANT_SAVE}?packageUuid=${packageUUID}`, submission, options) as Observable<any>;
  }

  delete(id, isAppRequest?: boolean): Observable<any> {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return this.httpClient.delete(`${API_ENDPOINTS.SUBMISSION_SEARCHWARRANT}/${id}`, options) as Observable<any>;
  }

  save(submission, isAppRequest?: boolean): Observable<any> {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return this.httpClient.post(API_ENDPOINTS.SUBMISSION_SEARCHWARRANT_SAVE, submission, options) as Observable<any>;
  }


}
