
<h1>{{ "home.title" | translate : [userFirstName && userFirstName != '' ? userFirstName : 'No one'] | async }}</h1>

<!-- notifications -->
<div class="ontario-row">
  <!-- success -->
  <div *ngIf="notifications?.success" class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
    <ods-page-alert role="success">
      <ng-container title>{{ notifications?.success?.title | translate | async }}</ng-container>
      <ng-container body>
        <p [innerHtml]="notifications?.success?.content | translate | async | safe : 'html'"></p>
      </ng-container>
    </ods-page-alert>
  </div>

  <!-- error -->
  <div *ngIf="notifications?.error" class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
    <ods-page-alert role="error">
      <ng-container title>{{ notifications?.error?.title | translate | async }}</ng-container>
      <ng-container body>
        <p>{{ notifications?.error?.content | translate | async }}</p>
        <ul>
          <li *ngFor="let msg of notifications?.error?.data; let i = index; trackBy: trackByFn">
            <a><div [innerHTML]=" msg | translate | async"></div></a>
          </li>
        </ul>
      </ng-container>
    </ods-page-alert>
  </div>

  <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0"></div>
</div>



<div class="ontario-row">
  <div class='ontario-accordion' id='accordion-1'>
    <h3 class='ontario-accordion-heading' [class.ontario-expander--active]="isAccordionOpened">
      <button class='ontario-accordion__button' id='accordion-button-id-1' aria-controls='accordion-content-1' [attr.aria-expanded]='isAccordionOpened' data-toggle='ontario-collapse' (click)="isAccordionOpened = !isAccordionOpened">
        <span>{{ "home.table.title" | translate | async }}</span>
        <span class='ontario-accordion__button-icon--close'>
          <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
            <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-up"></use>
          </svg>
        </span>
        <span class='ontario-accordion__button-icon--open'>
          <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
            <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-down"></use>
          </svg>
        </span>
      </button>
    </h3>
    <section class='ontario-accordion__content' [class.ontario-expander__content--opened]="isAccordionOpened" id='accordion-content-1' aria-labelledby='accordion-button-id-1' [attr.aria-hidden]='!isAccordionOpened' data-toggle='ontario-expander-content'>
      
      <div class='ontario-accordion' id='accordion-1-1'>
        <h4 class='ontario-accordion-heading' [class.ontario-expander--active]="isAccordion11Opened">
          <button class='ontario-accordion__button ontario-sub-accordion__button' id='accordion-button-id-1-1' aria-controls='accordion-content-1-1' [attr.aria-expanded]='isAccordion11Opened' data-toggle='ontario-collapse' (click)="isAccordion11Opened = !isAccordion11Opened">
            <span class='ontario-accordion__button-icon--close'>
              <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-up"></use>
              </svg>
            </span>
            <span class='ontario-accordion__button-icon--open'>
              <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-down"></use>
              </svg>
            </span>
            <span>{{ "home.table.search.title" | translate | async }}</span>
          </button>
        </h4>
        <section class='ontario-accordion__content' [class.ontario-expander__content--opened]="isAccordion11Opened" id='accordion-content-1-1' aria-labelledby='accordion-button-id-1-1' [attr.aria-hidden]='!isAccordion11Opened' data-toggle='ontario-expander-content'>
          <!-- BEGIN SEARCH SUBMITTED PACKAGE -->
          <div class="ontario-row ontario-padding-left-64-! ontario-padding-right-64-!" >
            <form novalidate [formGroup]="searchSubmittedPackageForm" class="ontario-row" autocomplete="off">
              <div class="ontario-row">
                <!-- search submitted sent -->
                <div class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-32-!">
                  <div class="ontario-form-group ontario-fix-accordion__content">
                    <odsf-label formCtrlId="search-submitted-sent">
                      <span name>Sent</span>
                    </odsf-label>
                    <odsf-input type="date" #searchSubmittedSent formCtrlId="search-submitted-sent" formCtrlName="searchSubmittedSent"
                      [formCtrl]="submittedPackageFormCtrl('searchSubmittedSent')">
                    </odsf-input>
                  </div>
                </div>

                <!-- search submitted received -->
                <div class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-32-!">
                  <div class="ontario-form-group ontario-fix-accordion__content">
                    <odsf-label formCtrlId="search-submitted-received">
                      <span name>{{ "home.table.delegatedpackages.column.received.title" | translate | async }}</span>
                    </odsf-label>
                    <odsf-input type="date"  #searchSubmittedReceived formCtrlId="search-submitted-received" formCtrlName="searchSubmittedReceived"
                      [formCtrl]="submittedPackageFormCtrl('searchSubmittedReceived')">
                    </odsf-input>
                  </div>
                </div>

                <!-- search submitted package type -->
                <div class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-32-!">
                  <div class="ontario-form-group ontario-fix-accordion__content">
                    <odsf-label formCtrlId="search-submitted-package-type">
                      <span name>{{ "home.table.mysubmittedpackages.column.package.title" | translate | async }}</span>
                    </odsf-label>
                    <odsf-select #searchSubmittedPackageType formCtrlId="search-submitted-package-type" formCtrlName="searchSubmittedPackageType"
                        [formCtrl]="submittedPackageFormCtrl('searchSubmittedPackageType')">
                      <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                      <option *ngFor="let item of optionItems('packageType') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                        {{ item.id | submissiontype | translate | async }}
                      </option>
                    </odsf-select>
                  </div>
                </div>

                <!-- search submitted package number -->
                <div class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
                  <div class="ontario-form-group ontario-fix-accordion__content">
                    <odsf-label formCtrlId="search-submitted-package-id">
                      <span name>{{ "home.table.mysubmittedpackages.column.packageId.title" | translate | async }}</span>
                    </odsf-label>
                    <odsf-input #searchSubmittedPackageId formCtrlId="search-submitted-package-id" formCtrlName="searchSubmittedPackageId"
                      [formCtrl]="submittedPackageFormCtrl('searchSubmittedPackageId')" [disabled]="loadingResults" [maxLength]="17">
                    </odsf-input>
                  </div>
                </div>
              </div>

              <div class="ontario-row">
                <!-- search submitted submission type -->
                <div class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-32-!">
                  <div class="ontario-form-group ontario-fix-accordion__content">
                    <odsf-label formCtrlId="search-submitted-submission-type">
                      <span name>{{ "home.table.mysubmittedpackages.column.type.title" | translate | async }}</span>
                    </odsf-label>
                    <odsf-select #searchSubmittedSubmissionType formCtrlId="search-submitted-submission-type" formCtrlName="searchSubmittedSubmissionType"
                        [formCtrl]="submittedPackageFormCtrl('searchSubmittedSubmissionType')">
                      <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                      <option *ngFor="let item of submissionTypeOptionItems() | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                        {{ item.name[lang$ | async] }}
                      </option>
                    </odsf-select>
                  </div>
                </div>

                <!-- search submitted status type -->
                <div class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-top-0-! ontario-padding-left-0-!">
                  <div class="ontario-form-group ontario-fix-accordion__content">
                    <odsf-label formCtrlId="search-submitted-status">
                      <span name>{{ "home.table.delegatedpackages.column.status.title" | translate | async }}</span>
                    </odsf-label>
                    <odsf-select #searchSubmittedStatus formCtrlId="search-submitted-status" formCtrlName="searchSubmittedStatus"
                        [formCtrl]="submittedPackageFormCtrl('searchSubmittedStatus')">
                      <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                      <option *ngFor="let item of optionItems('processStatus') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                        {{ item.name[lang$ | async] }}
                      </option>
                    </odsf-select>
                  </div>
                </div>

                <div class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-top-0-! ontario-padding-left-0-!">
                  &nbsp;
                </div>

                <div class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-left-0-! button-container ">
                  <!-- submitted package search button -->
                  <button (click)="filterSubmittedPackages()" class="ontario-button ontario-button--primary">
                    {{ "package.search.btn" | translate | async }}
                  </button>

                  <button (click)="clearSubmittedPackageForm()" class="ontario-button ontario-button--secondary">{{ "inquiryForm.clearForm.btn" | translate | async }}</button>
                </div>                
              </div>
            </form>
          </div>


          <!-- END SEARCH SUBMITTED PACKAGE -->    
        </section>
      </div>
      
      <div class="example-container" style="position: relative; width: 100%" [class.loading]="loadingResults">

        <ods-spinner *ngIf="loadingResults" [fullScreen]="false">
          {{ "app.spinner.text" | translate | async }}
        </ods-spinner>
       
        <div class="example-table-container" style="overflow: auto">
          <table mat-table [dataSource]="mySubmittedPackagesDataSource?.data.length > 0 ? mySubmittedPackagesDataSource : emptyData" class="example-table" multiTemplateDataRows matSort #sort="matSort" matSortActive="sentDate" matSortDisableClear matSortDirection="desc">
            <!-- expand column -->
            <ng-container matColumnDef="expand">
              <th mat-header-cell *matHeaderCellDef>{{ "home.table.mysubmittedpackages.column.expand.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">
                <a *ngIf="row?.canExpand && ! expandedPackage[row.packageUUID]" class="ontario-__link mat-column-actions-btn" aria-label="Expand Package" (click)="expendPackage('loadingResults', row)" (keypress)="expendPackage('loadingResults', row)" tabindex="0">
                  <svg class="ontario-icon" alt="Expand Package" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-expand"></use>
                  </svg>
                </a>
                <a *ngIf="row?.canExpand && expandedPackage[row.packageUUID]" class="ontario-__link mat-column-actions-btn" aria-label="Expand Package" (click)="collapsePackage(row)" (keypress)="collapsePackage(row)" tabindex="0">
                  <svg class="ontario-icon" alt="Expand Package" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-collapse"></use>
                  </svg>
                </a>
              </td>
            </ng-container>

            <!-- sent column -->
            <ng-container matColumnDef="sentDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "home.table.mysubmittedpackages.column.sent.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">{{ row?.sentDate | date : "MM/dd/yyyy" }}</td>
            </ng-container>

            <!-- received column -->
            <ng-container matColumnDef="receivedDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "home.table.mysubmittedpackages.column.received.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">{{ row?.receivedDate | date : "MM/dd/yyyy" }}</td>
            </ng-container>

            <!-- package type column -->
            <ng-container matColumnDef="packageType">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "home.table.mysubmittedpackages.column.package.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">{{ row?.packageType | submissiontype | translate | async }}</td>
            </ng-container>
    
            <!-- package Id column -->
            <ng-container matColumnDef="packageId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "home.table.mysubmittedpackages.column.packageId.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">{{ row?.packageUUID }}
                <a class="ontario-__link mat-column-actions-btn ontario-padding-left-4-!" (click)="copyToClipboard(row?.packageUUID)" (keypress)="copyToClipboard(row?.packageUUID)" tabindex="0">
                  <span class="ontario-icon material-icons">content_copy</span>
                </a>
              </td>
            </ng-container>
    
            <!-- submission type column -->
            <ng-container matColumnDef="submissionType">
              <th mat-header-cell *matHeaderCellDef>{{ "home.table.mysubmittedpackages.column.type.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="row?.packageType === SubmissionType.SearchWarrant; else elsePackageType">
                  <ng-container *ngFor="let type of row?.itoTypes?.split(',')">
                    {{type| warranttype | async }}<br/>
                  </ng-container>
                </ng-container>
                <ng-template #elsePackageType>{{row?.itoTypes | inquirytype | async}}</ng-template>
              </td>
            </ng-container>
            
            <!-- status column -->
            <ng-container matColumnDef="currentStatus">
              <th mat-header-cell *matHeaderCellDef>{{ "home.table.mysubmittedpackages.column.status.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">
                <ods-status-label [color]="row?.currentStatus | submissionstatus : 'color'" classes="ontario-margin-top-12-! ontario-margin-bottom-0-!">
                  {{ row?.currentStatus | submissionstatus : "key" | translate | async }}
                </ods-status-label>
              </td>
            </ng-container>
    
            <!-- actions column -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>{{ "home.table.mysubmittedpackages.column.actions.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">
<!--
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item>
                    <mat-icon>dialpad</mat-icon>
                    <span>Redial</span>
                  </button>
                  <button mat-menu-item disabled>
                    <mat-icon>voicemail</mat-icon>
                    <span>Check voice mail</span>
                  </button>
                  <button mat-menu-item>
                    <mat-icon>notifications_off</mat-icon>
                    <span>Disable alerts</span>
                  </button>
                </mat-menu>
                -->
                <a class="ontario-__link mat-column-actions-btn" [class.mat-column-actions-btn-disabled]="!row?.canDownloadAsPDF" (click)="DownloadSubmission(row)" (keypress)="DownloadSubmission(row)" tabindex="0" role="button" aria-label="Download PDF File">
                  <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-download"></use>
                  </svg>
                </a>

                <a class="ontario-__link mat-column-actions-btn" [class.mat-column-actions-btn-disabled]="!row?.canViewAsPDF" (click)="ViewSubmission(row)" (keypress)="ViewSubmission(row)" tabindex="0" role="button" aria-label="View IF PDF">
                  <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-attach"></use>
                  </svg>
                </a>

                <a class="ontario-__link mat-column-actions-btn" [class.mat-column-actions-btn-disabled]="!row?.canArchive" (click)="archiveSubmission(row)" (keypress)="archiveSubmission(row)" tabindex="0" role="button" aria-label="Archive this submission">
                  <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-map"></use>
                  </svg>
                </a>

                <a class="ontario-__link mat-column-actions-btn" [class.mat-column-actions-btn-disabled]="!row?.canEditInputs" [routerLink]="[row?.packageType === SubmissionType.SearchWarrant? '/search-warrant-form/' : '/inquiry-form/', row.packageUUID, 'edit']" role="button" aria-label="Edit Inputs">
                  <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-edit"></use>
                  </svg>
                </a>

                <a class="ontario-__link mat-column-actions-btn" [class.mat-column-actions-btn-disabled]="!row?.canDelegate" [routerLink]="['/usersDelegates', row?.packageType === SubmissionType.SearchWarrant? 'search-warrant-form' : 'inquiry-form', row?.packageUUID ]" role="button" aria-label="View IF PDF">
                  <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-account"></use>
                  </svg>
                </a>

                <a class="ontario-__link mat-column-actions-btn" [class.mat-column-actions-btn-disabled]="!row?.canDelete" (click)="deleteSubmission(row)" (keypress)="deleteSubmission(row)" tabindex="0" role="button" aria-label="Delete this submission">
                  <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-delete"></use>
                  </svg>
                </a>

                
              </td>
            </ng-container>

            <ng-container matColumnDef="expandedPackage">
              <td mat-cell *matCellDef="let row" [attr.colspan]="mySubmittedPackagesDisplayedColumns.length" [class.empty_expand_detail_row]="expandedPackage && expandedPackage[row.packageUUID] && row.itoList ? false : true" >
                <div class="example-element-detail" *ngIf="expandedPackage && expandedPackage[row.packageUUID] && row.itoList">
                  <div class="inner-table mat-elevation-z8" >
                    <table #innerItosTables mat-table [dataSource]="row.itoList.length > 0 ? row.itoList : emptyData" matSort>

                      <!-- itoType column -->
                      <ng-container matColumnDef="itoType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "home.table.mysubmittedpackages.inner.column.itoType.title" | translate | async }}</th>
                        <td mat-cell *matCellDef="let ito"> {{ito["itoType"]}} </td>
                      </ng-container>

                      <!-- address column -->
                      <ng-container matColumnDef="address">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "home.table.mysubmittedpackages.inner.column.address.title" | translate | async }}</th>
                        <td mat-cell *matCellDef="let ito"> {{ito["address"]}} </td>
                      </ng-container>
                      
                      <!-- controlNumber column -->
                      <ng-container matColumnDef="controlNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "home.table.mysubmittedpackages.inner.column.controlNumber.title" | translate | async }}</th>
                        <td mat-cell *matCellDef="let ito"> {{ito["controlNumber"]}} </td>
                      </ng-container>
                      
                      <!-- status column -->
                      <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "home.table.mysubmittedpackages.inner.column.status.title" | translate | async }}</th>
                        <td mat-cell *matCellDef="let ito">
                          <ods-status-label [color]="ito.status | submissionstatus : 'color'" classes="ontario-margin-top-12-! ontario-margin-bottom-0-!">
                            {{ ito.status | submissionstatus : "key" | translate | async }}
                          </ods-status-label>
                        </td>
                      </ng-container>                      

                      <!-- actions column -->
                      <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "home.table.mysubmittedpackages.inner.column.actions.title" | translate | async }}</th>
                        <td mat-cell *matCellDef="let ito">
                           <a class="ontario-__link mat-column-actions-btn" [class.mat-column-actions-btn-disabled]="ito['actions'].toLowerCase()?.includes('[download]')" [href]="ito.downloadLink??'#'" target="_blank" role="button" aria-label="Download ITO PDF">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                              <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-download"></use>
                            </svg>
                          </a>
                          <a class="ontario-__link mat-column-actions-btn" [class.mat-column-actions-btn-disabled]="ito['actions'].toLowerCase()?.includes('[attach]')" [href]="row?.AttachLink??'#'" target="_blank" role="button" aria-label="Attach ITO PDF">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                              <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-attach"></use>
                            </svg>
                          </a>
                        </td>
                      </ng-container>  
                      
                      <ng-container matColumnDef="empty-row">
                        <mat-cell *matCellDef="let element">{{ "table.noData.text" | translate | async }}</mat-cell>
                      </ng-container> 

                      <tr mat-header-row *matHeaderRowDef="itoListColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: row.itoList?.length > 0 ? itoListColumns : ['empty-row'];"></tr>
                    </table>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="ViewPackagePDF">
              <td mat-cell *matCellDef="let row" [attr.colspan]="mySubmittedPackagesDisplayedColumns.length" [class.empty_expand_detail_row]="ViewPackagePDF && ViewPackagePDF[row.packageUUID] ? false : true" >
                <div class="example-element-detail" *ngIf="ViewPackagePDF && ViewPackagePDF[row.packageUUID]">
                  <ods-pdf-viewer [data]="getViewPackagePDFBase64(row)" (toClosePdfViewer)="closePdfViewer(row)"></ods-pdf-viewer>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="empty-row">
              <mat-cell *matCellDef="let element">{{ "table.noData.text" | translate | async }}</mat-cell>
            </ng-container> 
    
            <tr mat-header-row *matHeaderRowDef="mySubmittedPackagesDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: mySubmittedPackagesDataSource?.data?.length > 0 ? mySubmittedPackagesDisplayedColumns : ['empty-row'];" [class.ontario-table-row--highlight]="row.highlighted"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedPackage']" class="expand-detail-row"></tr>
            <tr mat-row *matRowDef="let row; columns: ['ViewPackagePDF']" class="expand-detail-row"></tr>
          </table>
        </div>
    
        <mat-paginator #paginator [length]="mySubmittedPackagesResultsLength" [pageSizeOptions]="[5, 10, 15, 20, 25]" [pageSize]="5" showFirstLastButtons></mat-paginator>
      </div>

    </section>
  </div>

</div>

<div class="ontario-padding-top-40-!">&nbsp;</div>

<div class="ontario-row">
  <div class='ontario-accordion' id='accordion-2'>
    <h3 class='ontario-accordion-heading' [class.ontario-expander--active]="isAccordion2Opened">
      <button class='ontario-accordion__button' id='accordion-button-id-2' aria-controls='accordion-content-2' [attr.aria-expanded]='isAccordion2Opened' data-toggle='ontario-collapse' (click)="isAccordion2Opened = !isAccordion2Opened">
        <span>{{ "home.table.delegate.title" | translate | async }}</span>
        <span class='ontario-accordion__button-icon--close'>
          <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
            <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-up"></use>
          </svg>
        </span>
        <span class='ontario-accordion__button-icon--open'>
          <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
            <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-down"></use>
          </svg>
        </span>
      </button>
    </h3>
    <section class='ontario-accordion__content' [class.ontario-expander__content--opened]="isAccordion2Opened" id='accordion-content-2' aria-labelledby='accordion-button-id-2' [attr.aria-hidden]='!isAccordion2Opened' data-toggle='ontario-expander-content'>

      <div class='ontario-accordion' id='accordion-2-1'>
        <h4 class='ontario-accordion-heading' [class.ontario-expander--active]="isAccordion21Opened">
          <button class='ontario-accordion__button ontario-sub-accordion__button' id='accordion-button-id-2-1' aria-controls='accordion-content-2-1' [attr.aria-expanded]='isAccordion21Opened' data-toggle='ontario-collapse' (click)="isAccordion21Opened = !isAccordion21Opened">
            <span class='ontario-accordion__button-icon--close'>
              <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-up"></use>
              </svg>
            </span>
            <span class='ontario-accordion__button-icon--open'>
              <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-down"></use>
              </svg>
            </span>
            <span>{{ "home.table.search.title" | translate | async }}</span>
          </button>
        </h4>
        <section class='ontario-accordion__content' [class.ontario-expander__content--opened]="isAccordion21Opened" id='accordion-content-2-1' aria-labelledby='accordion-button-id-2-1' [attr.aria-hidden]='!isAccordion21Opened' data-toggle='ontario-expander-content'>

          <!-- BEGIN SEARCH DELEGATED PACKAGE -->
          <div class="ontario-row ontario-padding-left-64-! ontario-padding-right-64-!">
            <form novalidate [formGroup]="searchDelegatedPackageForm" class="ontario-row" autocomplete="off">
              <div class="ontario-row">

                <!-- search delegated delegated by -->
                <div class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-32-!">
                  <div class="ontario-form-group ontario-fix-accordion__content">
                    <odsf-label formCtrlId="search-delegated-by">
                      <span name>{{ "home.table.delegatedpackages.column.delegatedBy.title" | translate | async }}</span>
                    </odsf-label>
                    <odsf-input #searchDelegatedBy formCtrlId="search-delegated-by" formCtrlName="searchDelegatedBy"
                      [formCtrl]="delegatedPackageFormCtrl('searchDelegatedBy')" [disabled]="loadingResults" [maxLength]="50">
                    </odsf-input>
                  </div>
                </div>

                <!-- search delegated received -->
                <div class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-32-!">
                  <div class="ontario-form-group ontario-fix-accordion__content">
                    <odsf-label formCtrlId="search-delegated-received">
                      <span name>{{ "home.table.delegatedpackages.column.received.title" | translate | async }}</span>
                    </odsf-label>
                    <odsf-input type="date" #searchDelegatedReceived formCtrlId="search-delegated-received" formCtrlName="searchDelegatedReceived"
                      [formCtrl]="delegatedPackageFormCtrl('searchDelegatedReceived')">
                    </odsf-input>
                  </div>
                </div>

                <!-- search delegated package type -->
                <div class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-32-!">
                  <div class="ontario-form-group ontario-fix-accordion__content">
                    <odsf-label formCtrlId="search-delegated-package-type">
                      <span name>{{ "home.table.mysubmittedpackages.column.package.title" | translate | async }}</span>
                    </odsf-label>
                    <odsf-select #searchDelegatedPackageType formCtrlId="search-delegated-package-type" formCtrlName="searchDelegatedPackageType"
                        [formCtrl]="delegatedPackageFormCtrl('searchDelegatedPackageType')">
                      <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                      <option *ngFor="let item of optionItems('packageType') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                        {{ item.id | submissiontype | translate | async }}
                      </option>
                    </odsf-select>
                  </div>
                </div>

                <!-- search delegated package number -->
                <div class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
                  <div class="ontario-form-group ontario-fix-accordion__content">
                    <odsf-label formCtrlId="search-delegated-package-id">
                      <span name>{{ "home.table.mysubmittedpackages.column.packageId.title" | translate | async }}</span>
                    </odsf-label>
                    <odsf-input #searchDelegatedPackageId formCtrlId="search-delegated-package-id" formCtrlName="searchDelegatedPackageId"
                      [formCtrl]="delegatedPackageFormCtrl('searchDelegatedPackageId')" [disabled]="loadingResults" [maxLength]="17">
                    </odsf-input>
                  </div>
                </div>
              </div>

              <div class="ontario-row">
                
                <!-- search delegated submission type -->
                <div class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
                  <div class="ontario-form-group ontario-fix-accordion__content">
                    <odsf-label formCtrlId="search-delegated-submission-type">
                      <span name>{{ "home.table.mysubmittedpackages.column.type.title" | translate | async }}</span>
                    </odsf-label>
                    <odsf-select #searchDelegatedSubmissionType formCtrlId="search-delegated-submission-type" formCtrlName="searchDelegatedSubmissionType"
                        [formCtrl]="delegatedPackageFormCtrl('searchDelegatedSubmissionType')">
                      <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                      <option *ngFor="let item of submissionTypeOptionItems() | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                        {{ item.name[lang$ | async] }}
                      </option>
                    </odsf-select>
                  </div>
                </div>

                <!-- search delegated status type -->
                <div class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
                  <div class="ontario-form-group ontario-fix-accordion__content">
                    <odsf-label formCtrlId="search-delegated-status">
                      <span name>{{ "home.table.delegatedpackages.column.status.title" | translate | async }}</span>
                    </odsf-label>
                    <odsf-select #searchDelegatedStatus formCtrlId="search-delegated-status" formCtrlName="searchDelegatedStatus"
                        [formCtrl]="delegatedPackageFormCtrl('searchDelegatedStatus')">
                      <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                      <option *ngFor="let item of optionItems('processStatus') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                        {{ item.name[lang$ | async] }}
                      </option>
                    </odsf-select>
                  </div>
                </div>

                <div class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-top-0-! ontario-padding-left-0-!">
                  &nbsp;
                </div>

                <div class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-left-0-! button-container ">
                  <!-- delegated package search button -->
                  <button (click)="filterDelegatedPackages()" class="ontario-button ontario-button--primary">
                    {{ "package.search.btn" | translate | async }}
                  </button>

                  <button (click)="clearDelegatedPackageForm()" class="ontario-button ontario-button--secondary">{{ "inquiryForm.clearForm.btn" | translate | async }}</button>      </div>                
              </div>
            </form>
          </div>

        </section>
      </div>
            

      <div class="example-container" style="position: relative; width: 100%" [class.loading]="loadingDelegateResults">

        <ods-spinner *ngIf="loadingDelegateResults" [fullScreen]="false">
          {{ "app.spinner.text" | translate | async }}
        </ods-spinner>
       
        <div class="example-table-container" style="overflow: auto">
          <table mat-table [dataSource]="delegatedPackagesDataSource?.data.length > 0 ? delegatedPackagesDataSource : emptyData" class="example-table" multiTemplateDataRows matSort #sort2="matSort" matSortActive="receivedDate" matSortDisableClear matSortDirection="desc">
            <!-- expand column -->
            <ng-container matColumnDef="expand">
              <th mat-header-cell *matHeaderCellDef>{{ "home.table.delegatedpackages.column.expand.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">
                <a *ngIf="row?.canExpand && ! expandedPackage[row.packageUUID]" class="ontario-__link mat-column-actions-btn" role="button" aria-label="Expand Package" (click)="expendPackage('loadingDelegateResults', row)" (keypress)="expendPackage('loadingDelegateResults', row)" tabindex="0">
                  <svg class="ontario-icon" alt="Expand Package" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-expand"></use>
                  </svg>
                </a>
                <a *ngIf="row?.canExpand && expandedPackage[row.packageUUID]" class="ontario-__link mat-column-actions-btn" role="button" aria-label="Expand Package" (click)="collapsePackage(row)" (keypress)="collapsePackage(row)" tabindex="0">
                  <svg class="ontario-icon" alt="Expand Package" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-collapse"></use>
                  </svg>
                </a>
              </td>
            </ng-container>

            <!-- delegated by -->
            <ng-container matColumnDef="delegatedBy">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "home.table.delegatedpackages.column.delegatedBy.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">{{ row?.delegatedBy }}</td>
            </ng-container>

            <!-- received column -->
            <ng-container matColumnDef="receivedDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "home.table.delegatedpackages.column.received.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">{{ row?.receivedDate | date : "MM/dd/yyyy" }}</td>
            </ng-container>

            <!-- package type column -->
            <ng-container matColumnDef="packageType">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "home.table.delegatedpackages.column.package.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">{{ row?.packageType | submissiontype | translate | async }}</td>
            </ng-container>
    
            <!-- package Id column -->
            <ng-container matColumnDef="packageId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "home.table.delegatedpackages.column.packageId.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">{{ row?.packageUUID }}
                <a class="ontario-__link mat-column-actions-btn ontario-padding-left-4-!" (click)="copyToClipboard(row?.packageUUID)" (keypress)="copyToClipboard(row?.packageUUID)" tabindex="0">
                  <span class="ontario-icon material-icons">content_copy</span>
                </a>
              </td>
            </ng-container>
    
            <!-- submission type column -->
            <ng-container matColumnDef="submissionType">
              <th mat-header-cell *matHeaderCellDef>{{ "home.table.delegatedpackages.column.type.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="row?.packageType === SubmissionType.SearchWarrant; else elsePackageType">
                  <ng-container *ngFor="let type of row?.itoTypes?.split(',')">
                    {{type| warranttype | async }}<br/>
                  </ng-container>
                </ng-container>
                <ng-template #elsePackageType>{{row?.itoTypes | inquirytype | async}}</ng-template>
              </td>
            </ng-container>
            
            <!-- status column -->
            <ng-container matColumnDef="currentStatus">
              <th mat-header-cell *matHeaderCellDef>{{ "home.table.delegatedpackages.column.status.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">
                <ods-status-label [color]="row?.currentStatus | submissionstatus : 'color'" classes="ontario-margin-top-12-! ontario-margin-bottom-0-!">
                  {{ row?.currentStatus | submissionstatus : "key" | translate | async }}
                </ods-status-label>
              </td>
            </ng-container>
    
            <!-- actions column -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>{{ "home.table.delegatedpackages.column.actions.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">
                <a class="ontario-__link mat-column-actions-btn" [class.mat-column-actions-btn-disabled]="!row?.canDownloadAsPDF" (click)="DownloadSubmission(row)" (keypress)="DownloadSubmission(row)" tabindex="0" role="button" aria-label="Download PDF File">
                  <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-download"></use>
                  </svg>
                </a>

                <a class="ontario-__link mat-column-actions-btn" [class.mat-column-actions-btn-disabled]="!row?.canViewAsPDF" (click)="ViewSubmission(row)" (keypress)="ViewSubmission(row)" tabindex="0" role="button" aria-label="View IF PDF">
                  <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-attach"></use>
                  </svg>
                </a>

                <a class="ontario-__link mat-column-actions-btn" [class.mat-column-actions-btn-disabled]="!row?.canArchive" (click)="archiveSubmission(row)" (keypress)="archiveSubmission(row)" tabindex="0" role="button" aria-label="Archive this submission">
                  <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-map"></use>
                  </svg>
                </a>

                <a class="ontario-__link mat-column-actions-btn" [class.mat-column-actions-btn-disabled]="!row?.canEditInputs" [routerLink]="[row?.packageType === SubmissionType.SearchWarrant? '/search-warrant-form/' : '/inquiry-form/', row.packageUUID, 'edit']" role="button" aria-label="Edit Inputs">
                  <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-edit"></use>
                  </svg>
                </a>

                <a class="ontario-__link mat-column-actions-btn" [class.mat-column-actions-btn-disabled]="!row?.canDelegate" [routerLink]="['/usersDelegates', row?.packageType === SubmissionType.SearchWarrant? 'search-warrant-form' : 'inquiry-form', row?.packageUUID ]" role="button" aria-label="Detegate Users">
                  <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-account"></use>
                  </svg>
                </a>

                <a class="ontario-__link mat-column-actions-btn" [class.mat-column-actions-btn-disabled]="!row?.canDelete" (click)="deleteSubmission(row)" (keypress)="deleteSubmission(row)" tabindex="0" role="button" aria-label="Delete this submission">
                  <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-delete"></use>
                  </svg>
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="expandedPackage">
              <td mat-cell *matCellDef="let row" [attr.colspan]="delegatedPackagesDisplayedColumns.length" [class.empty_expand_detail_row]="expandedPackage && expandedPackage[row.packageUUID] && row.itoList ? false : true" >
                <div class="example-element-detail" *ngIf="expandedPackage && expandedPackage[row.packageUUID] && row.itoList">
                  <div class="inner-table mat-elevation-z8" >
                    <table #innerItosTables mat-table [dataSource]="row.itoList.length > 0 ? row.itoList : emptyData" matSort>

                      <!-- itoType column -->
                      <ng-container matColumnDef="itoType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "home.table.delegatedPackages.inner.column.itoType.title" | translate | async }}</th>
                        <td mat-cell *matCellDef="let ito"> {{ito["itoType"]}} </td>
                      </ng-container>

                      <!-- address column -->
                      <ng-container matColumnDef="address">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "home.table.delegatedPackages.inner.column.address.title" | translate | async }}</th>
                        <td mat-cell *matCellDef="let ito"> {{ito["address"]}} </td>
                      </ng-container>
                      
                      <!-- controlNumber column -->
                      <ng-container matColumnDef="controlNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "home.table.delegatedPackages.inner.column.controlNumber.title" | translate | async }}</th>
                        <td mat-cell *matCellDef="let ito"> {{ito["controlNumber"]}} </td>
                      </ng-container>
                      
                      <!-- status column -->
                      <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>{{ "home.table.delegatedPackages.inner.column.status.title" | translate | async }}</th>
                        <td mat-cell *matCellDef="let ito">
                          <ods-status-label [color]="ito.status | submissionstatus : 'color'" classes="ontario-margin-top-12-! ontario-margin-bottom-0-!">
                            {{ ito.status | submissionstatus : "key" | translate | async }}
                          </ods-status-label>
                        </td>
                      </ng-container>                      

                      <!-- actions column -->
                      <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>{{ "home.table.delegatedPackages.inner.column.actions.title" | translate | async }}</th>
                        <td mat-cell *matCellDef="let ito">
                           <a class="ontario-__link mat-column-actions-btn" [class.mat-column-actions-btn-disabled]="ito['actions'].toLowerCase()?.includes('[download]')" [href]="ito.downloadLink??'#'" target="_blank" role="button" aria-label="Download ITO PDF">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                              <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-download"></use>
                            </svg>
                          </a>
                          <a class="ontario-__link mat-column-actions-btn" [class.mat-column-actions-btn-disabled]="ito['actions'].toLowerCase()?.includes('[attach]')" [href]="row?.AttachLink??'#'" target="_blank" role="button" aria-label="Attach ITO PDF">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                              <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-attach"></use>
                            </svg>
                          </a>
                        </td>
                      </ng-container>  
                      
                      <ng-container matColumnDef="empty-row">
                        <mat-cell *matCellDef="let element">{{ "table.noData.text" | translate | async }}</mat-cell>
                      </ng-container> 
                     
                      <tr mat-header-row *matHeaderRowDef="itoListColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: row.itoList?.length > 0 ? itoListColumns : ['empty-row'];"></tr>
                    </table>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="ViewPackagePDF">
              <td mat-cell *matCellDef="let row" [attr.colspan]="delegatedPackagesDisplayedColumns.length" [class.empty_expand_detail_row]="ViewPackagePDF && ViewPackagePDF[row.packageUUID] ? false : true" >
                <div class="example-element-detail" *ngIf="ViewPackagePDF && ViewPackagePDF[row.packageUUID]">
                  <ods-pdf-viewer [data]="getViewPackagePDFBase64(row)" (toClosePdfViewer)="closePdfViewer(row)"></ods-pdf-viewer>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="empty-row">
              <mat-cell *matCellDef="let element">{{ "table.noData.text" | translate | async }}</mat-cell>
            </ng-container> 

            <tr mat-header-row *matHeaderRowDef="delegatedPackagesDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: delegatedPackagesDataSource?.data.length > 0 ? delegatedPackagesDisplayedColumns : ['empty-row'];" [class.ontario-table-row--highlight]="row.highlighted"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedPackage']" class="expand-detail-row"></tr>
            <tr mat-row *matRowDef="let row; columns: ['ViewPackagePDF']" class="expand-detail-row"></tr>
          </table>
        </div>
    
        <mat-paginator #paginator2 [length]="delegatedPackagesResultsLength" [pageSizeOptions]="[5, 10, 15, 20, 25]" [pageSize]="5" showFirstLastButtons></mat-paginator>
      </div>

    </section>
  </div>

</div>

<div class="ontario-padding-top-8-!">&nbsp;</div>

<div class="ontario-row">
  <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
      <div class="help_appendix">
        <p><span class="ontario-label span_appendix" [innerHTML]='"home.need.help.title" | translate | async'></span></p>
        <p><span class="ontario-label__flag span_appendix" [innerHTML]='"home.need.help.text.1" | translate | async'></span></p>
        <p><span class="ontario-label__flag span_appendix" [innerHTML]='"home.need.help.text.2" | translate | async'></span></p>
      </div>
  </div>
</div>
