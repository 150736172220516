import { Component, Input } from '@angular/core';

@Component({
  selector: 'ods-status-label',
  templateUrl: './ods-status-label.component.html'
})
export class OdsStatusLabelComponent {
  @Input() color: string;
  @Input() classes: string;
}
