
import { NotificationType } from '@app/core/notifications';
import { SubmissionType, productionOrderForm } from '@app/core/submissions';
import { PageView } from '@app/view';
import { environment } from '@environments/environment';
import isEmpty from 'lodash/isEmpty';
import { concat, distinctUntilChanged, map, of, pairwise, startWith, tap } from 'rxjs';
import { onNotificationsChanged$, onPageViewChanged$ } from './app.subscriptions';
import { FormControl } from '@angular/forms';


export const onFormInputValueChanged$ = fieldName => (cond, trueResult, falseResult, complete?) => form => {
  // track prev and curr value using startWith and pairwise
  return concat(form.get(fieldName).valueChanges.pipe(
    startWith(null), 
    distinctUntilChanged(),
    pairwise()
  )).pipe(
    tap(([prev, curr]: any) =>  (cond({ form, prev, curr }) ? trueResult({ form, prev, curr }) : falseResult({ form, prev, curr }))),
    tap(() => complete ? complete({ form }) : null)
  );
};

export const onPOFormCourtRegionChanged$ = onFormInputValueChanged$('courtRegion')(
  ({ curr }) => !isEmpty(curr),
  ({ form, prev, curr }) => { if (prev!=curr) form.get('courthouse').reset(); form.get('courthouse').enable()},
  ({ form }) => form.get('courthouse').disable()
);



export const onPOFormIsThisItoAssociatedChanged$ = onFormInputValueChanged$('associatedPreviousApp')(
  ({ curr }) => curr === true || curr === 'true',
  ({ form }) => form.get('associatedPreviousNum').enable(),
  ({ form }) => form.get('associatedPreviousNum').disable()
);

export const onPOFormOrganizationNameChanged$ = onFormInputValueChanged$('organizationName')(
  ({ curr }) => !isEmpty(curr),
  ({ form, curr }) => (form.get('personAndOrOrgName') as FormControl)?.setValue(curr.replace(/(\r\n|\n|\r)/gm, "")),
  ({ form }) => {
    const tmp1 = (form.get('personGivenName') as FormControl)?.value??'';
    const tmp2 = (form.get('personSurname') as FormControl)?.value??'';
    if (tmp1 !== '' && tmp2 !== '')
      (form.get('personAndOrOrgName') as FormControl)?.setValue(`${tmp1}${tmp2}`);
    else 
      (form.get('personAndOrOrgName') as FormControl)?.setValue('');
  }
);

export const onPOFormPersonGivenNameChanged$ = onFormInputValueChanged$('personGivenName')(
  ({ curr }) => !isEmpty(curr),
  ({ form }) => { 
    const tmp1 = (form.get('personGivenName') as FormControl)?.value??'';
    const tmp2 = (form.get('personSurname') as FormControl)?.value??'';
    if (tmp1 !== '' && tmp2 !== '')
      (form.get('personAndOrOrgName') as FormControl)?.setValue(`${tmp1}${tmp2}`);
    else 
      (form.get('personAndOrOrgName') as FormControl)?.setValue('');
  },
  ({ form }) => {
    const tmp = (form.get('organizationName') as FormControl)?.value??'';
    (form.get('personAndOrOrgName') as FormControl)?.setValue(`${tmp}`);
  }
);

export const onPOFormPersonSurnameChanged$ = onFormInputValueChanged$('personSurname')(
  ({ curr }) => !isEmpty(curr),
  ({ form }) => { 
                  const tmp1 = (form.get('personGivenName') as FormControl)?.value??'';
                  const tmp2 = (form.get('personSurname') as FormControl)?.value??'';
                  if (tmp1 !== '' && tmp2 !== '')
                    (form.get('personAndOrOrgName') as FormControl)?.setValue(`${tmp1}${tmp2}`);
                  else 
                    (form.get('personAndOrOrgName') as FormControl)?.setValue('');
                },
  ({ form }) => {
    const tmp = (form.get('organizationName') as FormControl)?.value??'';
    (form.get('personAndOrOrgName') as FormControl)?.setValue(`${tmp}`);
  }
);

export const onPOFormPoTypeCodeChanged$ = onFormInputValueChanged$('poTypeCode')(
  ({ curr }) => !isEmpty(curr),
  ({ curr, form }) => {
    console.log('onPOFormItoTypeCodeChanged$', curr);
    form['startBtnClick']?.();
  },
  () => null
);

export const onPOFormDeliveredToInformantChanged$ = onFormInputValueChanged$('deliveredToInformant')(
  ({ curr }) => curr === true || curr === 'true',
  ({ form }) => form.get('deliveredToPersonName').disable(),
  ({ form }) => form.get('deliveredToPersonName').enable()
);

export const onPOFormSelectionOfDocOrDataChanged$ = onFormInputValueChanged$('selectionOfDocOrData')(
  ({ curr }) => !isEmpty(curr),
  ({ form, curr }) => {
    if (curr.includes('Doc')) form.get('documentCopy').enable(); else form.get('documentCopy').disable();
    if (curr.includes('Data')) form.get('containingData').enable(); else form.get('containingData').disable();
  },
  () => null
);

export const onPOFormProducedInFormSelectionChanged$ = onFormInputValueChanged$('producedInFormSelection')(
  ({ curr }) => curr === 'Other',
  ({ form }) => form.get('producedInForm').enable(),
  ({ form }) => form.get('producedInForm').disable()
);

export const onPOFormPersonProvidingAssistanceChooseChanged$ = onFormInputValueChanged$('providingAssistanceChooseSelectAll')(
  ({ curr }) => curr,
  ({ form }) => {
    Object.keys(form.controls).filter(x => x.startsWith('providingAssistanceChoose') && x != 'providingAssistanceChooseSelectAll')
      .forEach(x=>{
        if (form.get(x).value != true) form.get(x).setValue(true);
      })
  },
  null
);

export const onPOFormNotificationsChanged$ = onNotificationsChanged$(n => n?.id?.includes(SubmissionType.SearchWarrant.toString()));

export const onPOFormErrorsChanged$ = onPOFormNotificationsChanged$(NotificationType.Error);
export const onPOFormSuccessChanged$ = onPOFormNotificationsChanged$(NotificationType.Success);

export const onPOFormViewChanged$ = onPageViewChanged$(({ dispatcher, params, viewType }) => {
  const validateForm = environment.validateForm;
  if (viewType === PageView.Create) {
    return of(productionOrderForm({validateForm }));
  }

  return dispatcher.loadById(params.id, true).pipe(map(s => productionOrderForm({ data: s, validateForm, disableForm: viewType === PageView.Read })));
});
