import { Component, OnDestroy, OnInit } from '@angular/core';
import { Editor} from 'ngx-editor';

@Component({
    selector: 'ods-editor',
    templateUrl: './ods-editor.component.html',
    styleUrls: ['./ods-editor.component.scss']
})
export class OdsEditorComponent implements OnDestroy, OnInit {
  editor: Editor;
  html = '';

  // make sure to destory the editor
  ngOnDestroy(): void {
    this.editor.destroy();
  }

  ngOnInit(): void {
    this.editor = new Editor();
  }
}