import { Component, Input } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
} from '@angular/forms';

@Component({
  selector: 'odsf-input',
  templateUrl: './odsf-input.component.html',
  styleUrls: ['./odsf-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class OdsfInputComponent {
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() formCtrlId: string;
  @Input() formCtrlName: string;
  @Input() formCtrl: FormControl;
  @Input() value: string;
  @Input() hidden = false;
  @Input() maxLength: number;
  @Input() type: string;
  @Input() min: number;
  @Input() max: number;

  minNumber = Number.MIN_VALUE
  maxNumber = Number.MAX_VALUE;

  get hasError(): boolean {
    return this.formCtrl?.touched && this.formCtrl?.invalid;
  }

  get describedBy(): string {
    return this.hasError ? `${this.formCtrlName}-field-error` : null;
  }
}
