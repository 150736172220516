<div class="ontario-row">
  <h1>{{ "users.delegate.title" | translate | async }}</h1>
</div>

<!-- notifications -->
<div class="ontario-row">
  <!-- success -->
  <div *ngIf="notifications?.success"
    class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
    <ods-page-alert role="success">
      <ng-container title>{{ notifications?.success?.title | translate | async }}</ng-container>
      <ng-container body>
        <p [innerHtml]="notifications?.success?.content | translate | async | safe : 'html'"></p>
      </ng-container>
    </ods-page-alert>
  </div>

  <!-- error -->
  <div *ngIf="notifications?.error"
    class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
    <ods-page-alert role="error">
      <ng-container title>{{ notifications?.error?.title | translate | async }}</ng-container>
      <ng-container body>
        <p>{{ notifications?.error?.content | translate | async }}</p>
      </ng-container>
    </ods-page-alert>
  </div>

  <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0"></div>
</div>

<form novalidate [formGroup]="delegateForm" autocomplete="off">
  <!-- search bar -->
  <div class="ontario-row">
      <div style="position: relative; width: 100%">
        <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label formCtrlId="filter-input">
              <span name>{{ "users.input.filter.label.text" | translate | async }}</span>
            </odsf-label>

            <odsf-input #filterInput formCtrlId="filter-input" formCtrlName="filterInput"
              [formCtrl]="formCtrl('filterInput')" [disabled]="loadingResults">
            </odsf-input>

          </div>
        </div>
      </div>
  </div>

  <div class="ontario-row">
    <div class="ontario-columns ontario-large-12 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!" [class.loading]="loadingResults">

      <ods-spinner *ngIf="loadingResults" [fullScreen]="false">
        {{ "app.spinner.text" | translate | async }}
      </ods-spinner>

      <odsf-error *ngIf="showSelectErrormessage" >
        {{ "users.delegates.assigned.error.message" | translate | async }}
      </odsf-error>

      <table mat-table [dataSource]="dataSource?.data.length > 0 ? dataSource : emptyData" class="example-table" matSort #sort="matSort" matSortActive="name" matSortDisableClear matSortDirection="desc">
        
        <!-- select column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>{{ "users.table.column.name.select" | translate | async }}</th>
          <td mat-cell *matCellDef="let row">
            <input  class="ontario-checkboxes__input__1" type="checkbox" (click)="selected($event, row)">
          </td>
        </ng-container>

        <!-- name column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="firstName">{{ "users.table.column.name.title" | translate | async }}</th>
          <td mat-cell *matCellDef="let row">{{ row?.firstName }}, {{ row?.lastName }}</td>
        </ng-container>

        <!-- email column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "users.table.column.email.title" | translate | async }}</th>
          <td mat-cell *matCellDef="let row">{{ row?.email }}</td>
        </ng-container>

        <!-- badge column -->
        <ng-container matColumnDef="badge">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="badgeNo">{{ "users.table.column.badge.title" | translate | async }}</th>
          <td mat-cell *matCellDef="let row">{{ row?.badgeNo }}</td>
        </ng-container>

        <ng-container matColumnDef="empty-row">
          <mat-cell *matCellDef="let element">{{ "table.noData.text" | translate | async }}</mat-cell>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: dataSource?.data?.length > 0 ? displayedColumns : ['empty-row'];" [class.ontario-table-row--highlight]="row?.highlighted"></tr>
      </table>
      <mat-paginator #paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageSize]="5" showFirstLastButtons></mat-paginator>
    </div>
  </div>

  <div class="ontario-row ontario-padding-top-24-!">
    <!-- Choose Delegation Type -->
    <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
      <div class="ontario-form-group">
        <odsf-label [required]="true" formCtrlId="delegationTypeInput">
          <span name>{{ "users.delegate.choose.type.label" | translate | async }}</span>
          <span flag>({{ "form.field.required.text" | translate | async }})</span>
        </odsf-label>

        <odsf-error *ngIf="formCtrl('delegationTypeInput')?.hasError('required')" formCtrlId="delegationTypeInput">
          {{ "validation.message.select.a" | translate : [ "users.delegate.choose.type.label" | translate | async | lowercase] | async }}
        </odsf-error>

        <odsf-select formCtrlId="delegationTypeInput" formCtrlName="delegationTypeInput" [formCtrl]="formCtrl('delegationTypeInput')" [required]="true">
          <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
          <option *ngFor="let item of optionItems('delegationType') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
            {{ item.name[lang$ | async] }}
          </option>
        </odsf-select>
      </div>
    </div>

    <!-- save -->
    <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-32-! ontario-padding-top-4-!">
      <div class="ontario-padding-left-32-! ontario-padding-top-40-!">
        <button class="ontario-button ontario-button--primary" (click)="saveDelegates()">
          {{ "text.save" | translate | async }}
        </button>
      </div>
    </div>

    <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-0 ontario-padding-left-0-!"></div>
  </div>

  <div class="ontario-row">
    <div class="ontario-columns ontario-large-12 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!" [class.loading]="loadingDelegatesResults">

      <ods-spinner *ngIf="loadingDelegatesResults" [fullScreen]="false">
        {{ "app.spinner.text" | translate | async }}
      </ods-spinner>

      <table mat-table [dataSource]="delegatesDataSource?.data.length > 0 ? delegatesDataSource : emptyData" class="example-table" matSort #delegatesSort="matSort" matSortActive="name" matSortDisableClear matSortDirection="desc">
        <caption>{{'users.delegates.assigned.table.caption' | translate : [ packageId]| async}}</caption>

        <!-- name column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="firstName">{{ "users.table.column.name.title" | translate | async }}</th>
          <td mat-cell *matCellDef="let row">{{ row?.firstName }}, {{ row?.lastName }}</td>
        </ng-container>

        <!-- email column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "users.table.column.email.title" | translate | async }}</th>
          <td mat-cell *matCellDef="let row">{{ row?.email }}</td>
        </ng-container>

        <!-- badge column -->
        <ng-container matColumnDef="badge">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="badgeNo">{{ "users.table.column.badge.title" | translate | async }}</th>
          <td mat-cell *matCellDef="let row">{{ row?.badgeNo }}</td>
        </ng-container>

        <!-- type column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="delegationType">{{ "users.table.column.type.title" | translate | async }}</th>
          <td mat-cell *matCellDef="let row">{{ this.appCache.getValueFromCache('delegationType',row?.delegationType)}}</td>
        </ng-container>

        <!-- actions column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ "users.table.column.actions.title" | translate | async }}</th>
          <td mat-cell *matCellDef="let row">
            <a class="ontario-__link mat-column-actions-btn" >
              <mat-icon (click)="delegatesAssignedDelectRow(row)">delete</mat-icon>
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="empty-row">
          <mat-cell *matCellDef="let element">{{ "table.noData.text" | translate | async }}</mat-cell>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedDelegatesColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: delegatesDataSource?.data?.length > 0 ? displayedDelegatesColumns : ['empty-row'];" [class.ontario-table-row--highlight]="row?.highlighted"></tr>
      </table>

    </div>
  </div>


</form>