export enum NotificationType {
  Info = <any>'info',
  Warning = <any>'warning',
  Error = <any>'error',
  Success = <any>'success'
}

export interface NotificationModel {
  id?: string;
  from?: string;
  type?: NotificationType;
  model: string;
  title?: string;
  content?: string;
  data?: any;
}

export const NotificationUtils = {
  nid: function ({ model, type, from }: NotificationModel): string {
    const frags = [type, from].filter(f => !!f).map(f => (f as string).replaceAll('/', ''));
    if (frags.length) return `${model}/${frags.join('/')}`;
    return model;
  }
};

export const Notification = function (args: NotificationModel) {
  const state: NotificationModel = {
    id: NotificationUtils.nid(args),
    from: null,
    type: NotificationType.Info,
    model: null,
    title: null,
    content: null,
    data: null
  };

  return Object.assign({}, state, args);
};
