import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppCoreModule } from '@app/core';
import { SharedModule } from '@shared/modules';
import { SearchWarrantFormRoutingModule } from './search-warrant-form-routing.module';
import { SearchWarrantFormComponent } from './search-warrant-form.component';

const components = [SearchWarrantFormComponent];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, SearchWarrantFormRoutingModule, AppCoreModule, SharedModule],
  exports: [...components]
})
export class SearchWarrantFormModule {}
