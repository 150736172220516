import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, Component, ContentChildren, ElementRef, Injector, OnInit, QueryList, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppService, Utils } from '@app/core';
import { cloneDeep as clone } from 'lodash';

import {
  onSWFormCourtRegionChanged$,
  onSWFormCourtRegionJurisdictionChanged$,
  onSWFormAreThereOccupantsChanged$,
  onSWFormBusinessNameChanged$,
  onSWFormOwnerOccupantGivenNameChanged$,
  onSWFormOwnerOccupantSurnameChanged$,
  onSWFormAdultOrYouthChanged$,
  onSWFormWasAnInquiryFormSubmittedChanged$,
  onSWFormIsThisWarrantApplicationAssociatedChanged$,
  onSWFormPriorityLevelChanged$,
  onSWFormIsASealingOrderChanged$,
  onSWFormRequestTermsCondChanged$,
  onSWFormItoTypeCodeChanged$,
  onSWFormIsAVehicleBeingSearchedChanged$,
  onSWFormTheInformantSaysChanged$,
  onSWFormAreNightGroundsBeingRequestedChanged$,
  onSWFormIsAPublicOfficerRequiredChanged$,
  onSWFormPersonProvidingAssistanceChooseChanged$,
  onSWFormVehiclePlateNumChanged$,
  onSWFormVehicleIdentificationNumChanged$,
  onSWFormErrorsChanged$,
  onSWFormSuccessChanged$,
  onSWFormViewChanged$
} from '@app/core/app.subscriptions.searchwarrant';
import { SubmissionSearchWarrantDispatcher, searchWarrantForm, SW_DATA_TYPE, SubmissionType } from '@app/core/submissions';
import { BaseViewComponent, PageView, getViewFromUrl } from '@app/view';
import { environment } from '@environments/environment';
import isEmpty from 'lodash/isEmpty';
import { Observable, delay, takeWhile, tap, of, concatMap, timer } from 'rxjs';
import { OdsDialogComponent, OdsTableComponent, OdsfSelectComponent } from '@/src/shared/components/ods';
import { MatDialog } from '@angular/material/dialog';

/*
const testData3: any[] = [ {
  'ito' : '1.CC 487 - 100 Queen St W., Toronto',
  'template' : '[download]',
  'uploadFile' : '[upload]',
  'actions': '',
},
{
  'ito' : '2.CC 487 - 222 Jarvis St., Toronto',
  'template' : '[download]',
  'uploadFile' : '<a href="8A620_ITO2_CC487_222JarvisSt.pdf">8A620_ITO2_CC487_222JarvisSt.pdf</a>',
  'actions': '[delete]',
},
{
  'ito' : '3.Cannabis - 222 Jarvis St., Toronto',
  'template' : '[download]',
  'uploadFile' : '<a href="8A620_ITO3_Cannabis_222JarvisSt.pdf">8A620_ITO3_Cannabis_222JarvisSt.pdf</a>',
  'actions': '[delete]',
},{
  'ito' : 'Appendix C-plus',
  'template' : '[download]',
  'uploadFile' : '<a href="8A620_AppCplus.pdf">8A620_AppCplus.pdf</a>',
  'actions': '[delete]',
},
]
*/


@Component({
  selector: 'app-search-warrant-form',
  templateUrl: './search-warrant-form.component.html',
  styleUrls: ['./search-warrant-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchWarrantFormComponent extends BaseViewComponent implements OnInit {

  parseInt = parseInt;

  searchWarrant: any = {};
  submissions: any[] = [];
  notifications: any = {};

  currentStep = 1;
  totalStep = 4;

  itoSummarycolumns: string[] = ['ito','courthouse','ownerOccupant','address','type','actions'];

  personsProvidingAssistanceSummaryColumns: string[] = ['name','title','explanation','ito','actions'];

  manageAndUploadDocumentsColumns: string[] = ['ito','template','uploadFile','actions'];
  manageAndUploadDocumentsDatasource : any[] =  [] ;

  @ViewChild('manageAndUploadDocumentsTable') manageAndUploadDocumentsTable: OdsTableComponent<any>;
  @ViewChild('itoSummaryTable') itoSummaryTable: OdsTableComponent<any>;
  @ViewChild('personsProvidingAssistanceSummaryTable') personsProvidingAssistanceSummaryTable: OdsTableComponent<any>;
  @ViewChild('selectItoType') selectItoType: OdsfSelectComponent;

  startedANewITO = false;
  OccupantsList: {ownerOccupantGivenName:string, ownerOccupantSurname:string, adultOrYouth:string}[] = [];

  constructor(
    private injector: Injector,
    private route: ActivatedRoute,
    private dispatcher: SubmissionSearchWarrantDispatcher,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private appService: AppService
  ) {
    super(injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    const route = this.route,
      router = this.router,
      dispatcher = this.dispatcher;

    onSWFormViewChanged$({ route, router, dispatcher })
      .pipe(takeWhile(() => this.alive))
      .subscribe((searchWarrant: any) => {
          this.searchWarrant = searchWarrant;
          this.searchWarrant.form['startBtnClick'] = () => this.startBtnClick();
          dispatcher.clearNotifications();

          if (this.isFormView || this.isEditView) {
            this.register$([
              onSWFormCourtRegionChanged$(searchWarrant.form),
              onSWFormCourtRegionJurisdictionChanged$(searchWarrant.form),
              onSWFormAreThereOccupantsChanged$(searchWarrant.form),
              onSWFormBusinessNameChanged$(searchWarrant.form),
              onSWFormOwnerOccupantGivenNameChanged$(searchWarrant.form),
              onSWFormOwnerOccupantSurnameChanged$(searchWarrant.form),
              onSWFormAdultOrYouthChanged$(searchWarrant.form),
              onSWFormWasAnInquiryFormSubmittedChanged$(searchWarrant.form),
              onSWFormIsThisWarrantApplicationAssociatedChanged$(searchWarrant.form),
              onSWFormPriorityLevelChanged$(searchWarrant.form),
              onSWFormIsASealingOrderChanged$(searchWarrant.form),
              onSWFormRequestTermsCondChanged$(searchWarrant.form),
              onSWFormItoTypeCodeChanged$(searchWarrant.form),
              onSWFormIsAVehicleBeingSearchedChanged$(searchWarrant.form),
              onSWFormTheInformantSaysChanged$(searchWarrant.form),
              onSWFormAreNightGroundsBeingRequestedChanged$(searchWarrant.form),
              onSWFormIsAPublicOfficerRequiredChanged$(searchWarrant.form),
              onSWFormPersonProvidingAssistanceChooseChanged$(searchWarrant.form),
              onSWFormVehiclePlateNumChanged$(searchWarrant.form),
              onSWFormVehicleIdentificationNumChanged$(searchWarrant.form),
              onSWFormErrorsChanged$(dispatcher.notifications$, ns => {
                if (ns?.length>0) {
                  this.notifications.error = ns[0];
                  setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }), 300) ;
                } 
              }),
              onSWFormSuccessChanged$(dispatcher.notifications$, ns => {
                //if (!isEmpty(ns)) setTimeout(() => this.router.navigate(['/']), 2000);
              })
            ]);
          }
          if (this.isEditView || this.isReadOnlyView) this.searchWarrant.toRetriveDataToForm(form => { if (this.isReadOnlyView) Object.keys(form.controls).forEach(x=> (form.get(x) as FormControl).disable())});
          this.updateView();
        });
  }

  onButtonClick() {
    // Handle button click event here
    console.log('Button clicked');
  }


  sendForm() {
    return this.dispatcher.save(this.searchWarrant.toPayload(this.OccupantsList), { from: this.router.url }, true)
      .pipe(takeWhile(() => this.alive))
      .subscribe();
  }

  clearFormForStep2() {
    this.cleanErrorMessage();
    this.searchWarrant.toClearForm(2, ()=> {this.startedANewITO = false; this.formCtrl('itoTypeCode').enable();});
  }

  resetForm() {
    /*
    this.dispatcher.currentSubmission$.pipe(
      tap(s => this.searchWarrant = searchWarrantForm({ data: s, validateForm: environment.validateForm }))
    ).subscribe(() => this.updateView());
    */
  }

  printForm() {
    setTimeout(() => {
      window.print();
    }, 100);
  }

  optionItems(name) {
    return this.appCache[`${name}Options`];
  }

  formCtrl(name): FormControl {
    if (name.startsWith('providingAssistanceChoose')){
      name = Utils.toPascalCase(name);
    }
    return this.searchWarrant?.form?.get(name) as FormControl;
  }

  field(name): any {
    return this.searchWarrant?.schema && this.searchWarrant?.schema[name];
  }

  get courtHouseOptions() {
    if (!this.formCtrl('courtRegion')?.value) return [];
    return this.appCache?.courtHouseOptions.filter(o => Number(o?.parentId) === Number(this.formCtrl('courtRegion')?.value));
  }

  get courthouseJurisdiction() {
    if (!this.formCtrl('courtRegionJurisdiction')?.value) return [];
    return this.appCache?.courtHouseOptions.filter(o => Number(o?.parentId) === Number(this.formCtrl('courtRegionJurisdiction')?.value));
  }

  private cleanErrorMessage() : void {
    this.dispatcher?.clearNotifications();
    this.notifications = {};
  }

  get officerName() {
    return this.appCache.user?.name;
  }

  get viewType() {
    return getViewFromUrl(this.router.url);
  }

  get isFormView() {
    return this.viewType === PageView.Create;
  }

  get isReadOnlyView() {
    return this.viewType === PageView.Read || this.delegationType === 'NOTIFIC';
  }

  get isEditView() {
    return this.viewType === PageView.Update && (this.delegationType === "OWNER" || this.delegationType === 'PREPARE'  || this.delegationType === 'PRANDNO');
  }

  get isEditAsDelegate() {
    return this.viewType === PageView.Update && (this.delegationType === 'PREPARE'  || this.delegationType === 'PRANDNO');
  }

  get delegationType(): string {
    return isEmpty(this.searchWarrant.model.data.delegationType)?"OWNER":this.searchWarrant.model.data.delegationType;
  }

  get shouldShowErrors() {
    return this.notifications?.error;
  }

  get canSubmit() {
    return this.searchWarrant.validateForm ? this.searchWarrant?.form?.valid && !this.searchWarrant?.form?.pristine : true;
  }

  gotoNext(): void {
    if (this.isReadOnlyView) {
      if (this.currentStep < this.totalStep) 
        this.currentStep = this.currentStep + 1;
      return;
    }

    this.cleanErrorMessage();
    if( this.currentStep == 3 && this.searchWarrant.toValidate(33) && (this.formCtrl('assistanceOrderRequired').value == 'false' || this.searchWarrant.model.data?.step3AssistanceList?.length>0)) {
      this.currentStep = 4;
    } else if (this.searchWarrant.toValidate(this.currentStep)) {
      if (this.currentStep < this.totalStep) 
        this.currentStep = this.currentStep + 1;
      if (this.currentStep===2)
        timer(300).subscribe(()=>
          this.selectItoType.selectComponent.nativeElement.focus()
        );
    } else if( this.currentStep == 2 && !this.startedANewITO && this.searchWarrant.model.data?.step2ItoList?.length>0) {
      this.formCtrl('itoTypeCode').clearValidators();
      this.currentStep = 3;
    } else {
      this.dispatcher?.addErrorNotification({data: ["notification.error.content.completeFormFields"]});
      this.dialog.open(OdsDialogComponent,{
        width: '500px',
        data: {title: this.appCache.getPropertyItem('notification.dialog.warning.title.html'), 
          content: `<label class="ontario-label">${this.appCache.getPropertyItem('notification.error.content.invalidFormFields')}</label><br/><span>${this.appCache.getPropertyItem('notification.error.content.goto.next')}</span>`,
          yes: this.appCache.getPropertyItem('radio.value.yes'),
          no: this.appCache.getPropertyItem('radio.value.no')
        }
        }).afterClosed().subscribe(result => {
          if (result==='Yes') {
            if (this.currentStep < this.totalStep) {
              this.cleanErrorMessage();
              this.currentStep = this.currentStep + 1;
              if (this.currentStep===2)
                timer(300).subscribe(()=>
                  this.selectItoType.selectComponent.nativeElement.focus()
                );
              this.changeDetectorRef.markForCheck();
            }
          } 
      });
    }
    if (this.startedANewITO) 
      this.formCtrl('itoTypeCode').disable();
    else 
      this.formCtrl('itoTypeCode').enable();
  }

  gotoBack(): void {
    if (this.currentStep > 1 ) {
      this.cleanErrorMessage();
      this.currentStep = this.currentStep - 1;
    }
    if( this.currentStep == 3){
      this.formCtrl('itoTypeCode').clearValidators();
    }
    if (this.currentStep===2)
      timer(300).subscribe(()=>
        this.selectItoType.selectComponent.nativeElement.focus()
      );
  }

  saveDraft(): void {
    this.cleanErrorMessage();
    if (this.searchWarrant.toValidate(0)) {
      this.dispatcher.saveDraft(this.searchWarrant.toPayload(this.OccupantsList), this.searchWarrant.model.packageUUID, key => this.appCache.getPropertyItem(this.field(key)?.label??key) ,{ from: this.router.url }, true)
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: value => {
          console.log(value);
          if (value && value.packageId) {
            this.searchWarrant.model.data['id'] = value.packageId;
            this.searchWarrant.model.data['packageId'] = value.packageId;
          }
          if (value && value.packageUUID) this.searchWarrant.model.data['packageUUID'] = value.packageUUID;
          this.dialog.open(OdsDialogComponent,{
          width: '500px',
          data: {title: this.appCache.getPropertyItem('notification.dialog.info.access.successful.title.html'), 
            content: `<label class="ontario-label">${this.appCache.getPropertyItem('notification.dialog.info.save.draft')}</label><br/><span>${this.appCache.getPropertyItem('notification.dialog.info.would.you.exit')}</span>`,
            yes: this.appCache.getPropertyItem('radio.value.yes'),
            no: this.appCache.getPropertyItem('radio.value.no')
          }
          }).afterClosed().subscribe(result => {
            if (result==='Yes') {
              this.appService.goToHomePage();
            } 
         });
        },
        error: error => {
          console.log(error);
        }
      });
    } else {
      this.dispatcher?.addErrorNotification({data: ["notification.error.content.saveDraft.FormFields"]});
      this.currentStep = 1;
    }
  }  

  startBtnClick(): void {
    this.cleanErrorMessage();
    if (this.searchWarrant.toValidate(6)){
      this.startedANewITO = true;
      this.formCtrl('itoTypeCode').disable();
    } else {
      this.dispatcher?.addErrorNotification({data: ["notification.error.content.ito.type.start"]});
    }
  }

  addAnotherOccupantBtnClick(): void {
    this.cleanErrorMessage();
    if (this.searchWarrant.toValidate(5)) {
      this.OccupantsList.push({
        ownerOccupantGivenName: this.formCtrl('ownerOccupantGivenName').value,
        ownerOccupantSurname: this.formCtrl('ownerOccupantSurname').value, 
        adultOrYouth: this.formCtrl('adultOrYouth').value
      });
      this.formCtrl('ownerOccupantGivenName').clearValidators();
      this.formCtrl('ownerOccupantSurname').clearValidators();
      this.formCtrl('adultOrYouth').clearValidators();
      this.formCtrl('ownerOccupantGivenName').setValue(null);
      this.formCtrl('ownerOccupantSurname').setValue(null);
      this.formCtrl('adultOrYouth').setValue(null);
    } else {
      this.dispatcher?.addErrorNotification({data: ["notification.error.content.add.another.occupant"]});
    }
  }


  deleteAnotherOccupantBtnClick(row: any): void {
    const index = this.OccupantsList.indexOf(row);
    if (index >= 0) {
      this.OccupantsList.splice(index, 1);
    }
  }

  addToItoSummary(): void {
    this.cleanErrorMessage();
    if (this.isReadOnlyView) return;
    if (this.searchWarrant.toValidate(22)) {
      this.searchWarrant.toAddToItoSummary(true, this.OccupantsList, (ito)=>{
        this.OccupantsList = [];
        this.startedANewITO = false;
        this.formCtrl('itoTypeCode').enable();
        this.addToItoSummaryTable(ito);
      });
    } else {
      this.dialog.open(OdsDialogComponent,{
        width: '500px',
        data: {title: this.appCache.getPropertyItem('notification.dialog.warning.title.html'), 
          content: `<label class="ontario-label">${this.appCache.getPropertyItem('notification.dialog.warning.content.add.to.ito.summary')}</label><br/><span>${this.appCache.getPropertyItem('notification.dialog.warning.content.hint')}</span>`,
          yes: this.appCache.getPropertyItem('radio.value.yes'),
          no: this.appCache.getPropertyItem('radio.value.no')
        }
      }).afterClosed().subscribe(result => {
          if (result==='Yes') {
            this.searchWarrant.toAddToItoSummary(false, this.OccupantsList, (ito)=>{
              this.OccupantsList = [];
              this.startedANewITO = false;
              this.formCtrl('itoTypeCode').enable();
              this.addToItoSummaryTable(ito);
            });
          } else {
            this.dispatcher?.addErrorNotification({data: ["notification.error.content.add.to.ito.summary"]});
          }
       });
    }
  }


  handleAddRow(row : any): void{
    console.log("Handle Add A Row:", row);
  }

  handleDelectRow(row : any): void{
    console.log("Handle Delete A Row:", row);
  }

  handleEditRow(row : any): void{
    console.log("Handle Edit A Row", row);
  }

  createPackageBtnClick(): void {
    this.currentStep = 4;
  }

  handleUploadFile(p : any) {
    console.log("Handle Edit A Row", p);
    this.onFileSelected(p);
  }

  handleDownloadFile(p : any) {
    console.log("Not completed yet", p);
  }

  onFileSelected(p: any) {
    const file: File = p.event.target.files[0];
    const reader: FileReader = new FileReader();
    
    reader.onloadend = (e) => {
      const base64String: string = btoa(reader.result as string);
      console.log(base64String);
      this.manageAndUploadDocumentsTable.dataSource.data[p.row.rowid].uploadFile
        = '<a href="' + file.name +  '">' + file.name + '</a>';
      this.manageAndUploadDocumentsTable.dataSource.data[p.row.rowid].actions = '[delete]';
      this.changeDetectorRef.markForCheck();


      Utils.saveBase64ToFile(base64String, file.name);


    };
    
    reader.readAsBinaryString(file);
  }


  itoSummaryAddRow(row : any): void{
    console.log("Handle Add A Row:", row);
  }

  itoSummaryDelectRow(row: any): void{
    if (!this.isReadOnlyView){
      if (this.itoSummaryTable.deleteAnRowFromTable(row)){
        this.searchWarrant.toDeleteFromItoSummary('itoUUID', row['itoUUID']);
      }
    }
  }

  itoSummaryEditRow(row : any): void{
    this.clearFormForStep2();
    const ito = this.searchWarrant.toEditItoInSummary('itoUUID', row['itoUUID']);
    if (ito) {
      this.OccupantsList = clone(ito.occupantList)??[] ;
      if (!this.isReadOnlyView) 
        this.itoSummaryDelectRow(row);
      else
        this.startedANewITO = true;
    }
  }
  

  private addToItoSummaryTable(newIto: any): void {
    if (newIto){
      this.itoSummaryTable.addAnRowToTable(this.itoToObj(newIto));
    }
  }

  get itoSummaryDatasource(): any[] {
    const data = this.searchWarrant.model.data as SW_DATA_TYPE;
    return data.step2ItoList?.reduce((x, y) => {
      x.push(this.itoToObj(y));
      return x;
      } , []);
  }

  private itoToObj(ito: any){
    return {
      itoUUID: ito.itoUUID,
      ito : ito.itoTypeCode? this.appCache.getValueFromCache('typeOfIto', ito.itoTypeCode):"",
      courthouse: ito.courtHouseCode? this.appCache.getValueFromCache('courtHouse', ito.courtHouseCode):"",
      ownerOccupant: ito.areThereOccupants != 'true' ? '' : `${ito.businessName??""} ${ito.currentOccupant.ownerOccupantGivenName??""} ${ito.currentOccupant.ownerOccupantSurname??""}` + (isEmpty(ito.occupantList)? "" : "," + ito.occupantList.reduce((m,n) => { m = m + (m==""?"":",") + `${n.ownerOccupantGivenName??""} ${n.ownerOccupantSurname??""}` ; return m;} , "")),
      address: `${ito.address2Searched??''} ${ito.addressCity??''} ${ito.addressProvinceCode??''}`,
      type: ito.what2Searched??'',
      actions: `${ito.completed?'[green_check_mark]':'[gray_check_mark]'}${this.isReadOnlyView?'[edit]':'[edit]/[delete]'}`
      }
  }

  clearFormForStep3() {
    this.cleanErrorMessage();
    this.searchWarrant.toClearForm(3);
  }

  addToPpaSummary() {
    this.cleanErrorMessage();
    if (this.isReadOnlyView) return;
    if (this.searchWarrant.toValidate(7)) {
      this.searchWarrant.toAddToPpaSummary(true, (ppa)=>{
        this.addToPpaSummaryTable(ppa);
      });
    } else {
      this.dialog.open(OdsDialogComponent,{
        width: '500px',
        data: {title: this.appCache.getPropertyItem('notification.dialog.warning.title.html'), 
          content: `<label class="ontario-label">${this.appCache.getPropertyItem('notification.dialog.warning.content.add.to.ppa.summary')}</label><br/><span>${this.appCache.getPropertyItem('notification.dialog.warning.content.hint')}</span>`,
          yes: this.appCache.getPropertyItem('radio.value.yes'),
          no: this.appCache.getPropertyItem('radio.value.no')
        }
      }).afterClosed().subscribe(result => {
          if (result==='Yes') {
            this.searchWarrant.toAddToPpaSummary(false, (ppa)=>{
              this.addToPpaSummaryTable(ppa);
            })
          } else {
            this.dispatcher?.addErrorNotification({data: ["notification.error.content.add.to.ppa.summary"]});
          }
       });
    }
  }

  private addToPpaSummaryTable(newPPA: any): void {
    if (newPPA){
      this.personsProvidingAssistanceSummaryTable.addAnRowToTable(this.ppaToObj(newPPA));
    }
  }

  private ppaToObj(ppa: any){
    return {
      ppaUUID: ppa.ppaUUID,
      name: `${ppa.givenName} ${ppa.surname}`,
      title : ppa.title,
      explanation: ppa.assistanceExplain,
      ito: ppa.itoUUIDList?.reduce((x,y)=>{ 
        const data = this.searchWarrant.model.data as SW_DATA_TYPE;
        const ito = data.step2ItoList.find(w => w.itoUUID == y);
        if (ito) {
          const obj = this.itoToObj(ito);
          x = x + (x===""?"":"<br>") + `${obj.ito} - ${obj.address}`;
        }
        return x;
      },""),
      actions: `${ppa.completed?'[green_check_mark]':'[gray_check_mark]'}${this.isReadOnlyView?'[edit]':'[edit]/[delete]'}`
      }
  }

  get personsProvidingAssistanceSummaryDatasource() : any[]{
    const data = this.searchWarrant.model.data as SW_DATA_TYPE;
    return data.step3AssistanceList?.reduce((x, y) => {
      x.push(this.ppaToObj(y));
      return x;
      } , []);
  }

  ppaSummaryAddRow(row : any): void{
    console.log("Handle Add A Row:", row);
  }

  ppaSummaryDelectRow(row: any): void{
    if (!this.isReadOnlyView){
      if (this.personsProvidingAssistanceSummaryTable.deleteAnRowFromTable(row)){
        this.searchWarrant.toDeleteFromPpaSummary('ppaUUID', row['ppaUUID']);
      }
    }
  }

  ppaSummaryEditRow(row : any): void{
    const ppa = this.searchWarrant.toEditPpaInSummary('ppaUUID', row['ppaUUID']);
    if (ppa) {
      if (!this.isReadOnlyView) 
        this.ppaSummaryDelectRow(row);
    }
  }
}
