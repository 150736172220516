import { API_ENDPOINTS } from '@/src/environments/environment';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ValidatorType } from '@app/core/models';
import { InquiryType, SubmissionSchema } from '@app/core/submissions';
import { Observable, from, map, of } from 'rxjs';
import { environment } from '@environments/environment';

function packageNumberRequiredIf(control: AbstractControl): ValidationErrors | null {
  const form = control.parent as FormGroup;
  if (form && form.get('typeOfInquiryCode') && form.get('typeOfInquiryCode').value && form.get('typeOfInquiryCode').value !== InquiryType.Urgent) {
    return !control.value ? { required: true } : null;
  }
  return null;
}


function packageNumberExistance(control: AbstractControl): Observable<ValidationErrors | null> {
  const result = {};
  if (control && control['_rawValidators']) {
    control['_rawValidators'].filter(x => x?.name !== 'packageNumberExistance').forEach(x => Object.assign(result, x(control)));
  }
  if (control?.value && !(result['pattern'] || result['required'] || result['maxlength'])) {

    const ifByPackageUuidEndpoint = API_ENDPOINTS.SUBMISSION_INQUIRY_GET.replace(`{packageUUID}`, control?.value);
    const authToken = environment.authToken;
    return from(fetch(ifByPackageUuidEndpoint,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + authToken,
        },    
      })).pipe(
        map((response) => ((!response || response?.status !== 200) ? { existance: true } : null))
      );
  } else {
    return null;
  }
}

export const InquirySchema: any = Object.assign({}, SubmissionSchema, {
  courtRegionCode: {
    id: 'court-region-code',
    name: 'courtRegionCode',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'inquiryForm.field.courtRegionCode.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  courtHouseCode: {
    id: 'courtHouseCode',
    name: 'courtHouseCode',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'inquiryForm.field.courtHouseCode.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  typeOfInquiryCode: {
    id: 'type-of-inquiry-code',
    name: 'typeOfInquiryCode',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'inquiryForm.field.typeOfInquiryCode.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  packageNumber: {
    id: 'package-number',
    name: 'packageNumber',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'inquiryForm.field.packageNumber.name',
    description: '',
    validators: [
      {
        type: ValidatorType.RequiredIf,
        fn: packageNumberRequiredIf,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(36),
        value: 36,
        message: 'validation.message.maxLength'
      },
      {
        type: ValidatorType.Pattern,
        fn: Validators.pattern(/^[0-9]{5}-[0-9]{5}-[0-9]{5}$/),
        value: /^[0-9]{5}-[0-9]{5}-[0-9]{5}$/,
        message: 'validation.message.pattern'
      },
      {
        type: 'existance',
        async: true,
        fn: packageNumberExistance,
        message: 'validation.message.not.found'
      },
    ]
  },
  subject: {
    id: 'subject',
    name: 'subject',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'inquiryForm.field.subject.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(50),
        value: 50,
        message: 'validation.message.maxLength'
      }
    ]
  },
  message: {
    id: 'message',
    name: 'message',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'inquiryForm.field.message.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(1000),
        value: 1000,
        message: 'validation.message.maxLength'
      }
    ]
  },
  declare: {
    id: 'declare',
    name: 'declare',
    type: Boolean,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'inquiryForm.field.declare.name',
    description: '',
    validators: [
      {
        type: ValidatorType.RequiredTrue,
        fn: Validators.requiredTrue,
        message: 'validation.message.must.select.true'
      }
    ]
  }
});
