<!-- eslint-disable @angular-eslint/template/valid-aria -->
<mat-form-field>
    <input
        #selectDate
        matInput
        [matDatepicker]="picker"
        [attr.required]="required"
        [readonly]="disabled"
        formControlName="{{ formCtrlName }}"
        class="{{hidden?'hidden-input':'ontario-input'}}"
        type="text"
        [class.ontario-input__error]="hasError"
        id="{{ formCtrlId }}"
        name="{{ formCtrlName }}"
        value="{{value}}"
        placeholder="Choose a date"
        aria-describedby="describedBy"
        aria-invalid="hasError"/>
    <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
    </mat-datepicker-toggle>
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
