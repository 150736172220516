import { Injectable } from '@angular/core';
import { NotificationService, NotificationType } from '@app/core/notifications';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { SubmissionModel, SubmissionType } from '.';
import { SubmissionService } from './submission.service';
import { SubmissionApiService } from './submission-api.service';
import { API_ENDPOINTS } from '@/src/environments/environment';
import { DatePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class SubmissionDispatcher {
  constructor(
    private submissionApiService: SubmissionApiService, 
    private submissionService: SubmissionService, 
    private notificationService: NotificationService,
    private datePipe: DatePipe
  ) {}

  get submissions$() {
    return this.submissionService?.list$;
  }

  get selected$() {
    return this.submissionService?.selected$;
  }

  get notifications$() {
    return this.notificationService?.list$;
  }

  get delegatedPackages$() {
    return this.submissionService?.listDelegated$;
  }

  clearNotifications() {
    return this.notificationService?.clear();
  }

  addSubmissionSuccessNotification(details) {
    return this.notificationService?.addSuccess({
      ...details,
      title: 'submission.notification.create.success.title.text',
      content: 'submission.notification.create.success.content.text'
    });
  }

  addDeleteSuccessNotification(details) {
    return this.notificationService?.addSuccess({
      ...details,
      title: 'submission.notification.delete.success.title.text',
      content: 'submission.notification.delete.success.content.text'
    });
  }

  addSubmissionErrorNotification(details) {
    return this.notificationService?.addError({
      ...details,
      title: 'submission.notification.create.error.title.text',
      content: 'submission.notification.create.error.content.invalidFormFields.text'
    });
  }

  addDeleteErrorNotification(details) {
    return this.notificationService?.addError({
      ...details,
      title: 'submission.notification.delete.error.title.text',
      content: 'submission.notification.delete.error.content.text'
    });
  }

  load(isAppRequest = false): Observable<any[]> {
    return this.submissionApiService?.getAll(isAppRequest).pipe(
      map((submissions: any[]) => {
        if (!this.submissionService) return [];
        this.submissionService.list = cloneDeep(submissions.filter(s => !s.isArchived && !s.isDeleted));
        return this.submissionService?.list;
      })
    );
  }

  loadAllPackages(isAppRequest = false, searchSentDate = '', searchReceiveDate = '', searchPackageType = '', searchPackageId = '', searchSubmissionType = '', searchStatus = ''): Observable<any[]> {
    return this.submissionApiService?.getAllPackages(isAppRequest).pipe(
      map((submissions: any[]) => {
        if (!this.submissionService) return [];
        submissions = submissions.filter((u: any) => {
          const matchesSentDate = !searchSentDate.trim() || (u.sentDate != null && searchSentDate === this.datePipe.transform(u.sentDate, 'yyyy-MM-dd'));
          const matchesReceivedDate = !searchReceiveDate.trim() || (u.receivedDate != null && searchReceiveDate === this.datePipe.transform(u.receivedDate, 'yyyy-MM-dd'));
          const matchesPackageType = !searchPackageType.trim() || (u.packageType && u.packageType === searchPackageType);
          const matchesPackageId = !searchPackageId.trim() || (u.packageUUID && u.packageUUID.includes(searchPackageId.trim()));
          const matchesInquiryType = !searchSubmissionType.trim() || (u.itoTypes && u.itoTypes === searchSubmissionType);
          const matchesStatus = !searchStatus.trim() || (u.currentStatus && u.currentStatus === searchStatus);

          return matchesSentDate && matchesReceivedDate && matchesPackageType && matchesPackageId && matchesInquiryType && matchesStatus;
        });
        this.submissionService.list = cloneDeep(submissions);
        return this.submissionService?.list;
      }),
      catchError(err => {
        this.notificationService?.add({
          type: NotificationType.Error,
          model: null,
          title: err?.error?.cause,
          content: err?.error?.message});
          return [];
        })
    );
  }

  loadAllDelegatedPackages(isAppRequest = false, searchDelegatedBy = '', searchReceiveDate = '', searchPackageType = '', searchPackageId = '', searchSubmissionType = '', searchStatus = ''): Observable<any[]> {
    return this.submissionApiService?.getAllDelegatedPackages(isAppRequest).pipe(
      map((submissions: any[]) => {
        if (!this.submissionService) return [];
        submissions = submissions.filter((u: any) => {
          const matchesDelegatedBy = !searchDelegatedBy.trim() || (u.delegatedBy && u.delegatedBy === searchDelegatedBy.trim());
          const matchesReceivedDate = !searchReceiveDate.trim() || (u.receivedDate && searchReceiveDate === this.datePipe.transform(u.receivedDate, 'yyyy-MM-dd'));
          const matchesPackageType = !searchPackageType.trim() || (u.packageType && u.packageType === searchPackageType);
          const matchesPackageId = !searchPackageId.trim() || (u.packageUUID && u.packageUUID.includes(searchPackageId.trim()));
          const matchesSubmissionType = !searchSubmissionType.trim() || (u.itoTypes && u.itoTypes === searchSubmissionType);
          const matchesStatus = !searchStatus.trim() || (u.currentStatus && u.currentStatus === searchStatus);

          return matchesDelegatedBy && matchesReceivedDate && matchesPackageType && matchesPackageId && matchesSubmissionType && matchesStatus;
        });
        this.submissionService.listDelegated = cloneDeep(submissions);
        return this.submissionService?.listDelegated;
      }),
      catchError(err => {
        this.notificationService?.add({
          type: NotificationType.Error,
          model: null,
          title: err?.error?.cause,
          content: err?.error?.message});
        return [];
      })
    );
  }

  loadById(id, isAppRequest = false): Observable<SubmissionModel> {
    const toBeLoaded = this.submissionService.list.find(s => s?.packageUUID === id);
    const requestId = API_ENDPOINTS.SUBMISSION_INQUIRY_GET.indexOf(`{packageUUID}`) === -1 ? toBeLoaded?.id : toBeLoaded?.packageUUID;
    return this.submissionApiService?.get(requestId || id, isAppRequest).pipe(
      map((submission: any) => {
        if (!this.submissionService) return null;
        this.submissionService.selected = { ...submission };
        return this.submissionService?.selected;
      })
    );
  }

  getAllPackageWarrants(packageUUID : string, isAppRequest = false): Observable<any[]> {
    return this.submissionApiService?.getAllPackageWarrants(packageUUID, isAppRequest).pipe(
      catchError(e => {
        this.notificationService?.clear();
        const errors = Array.isArray(e.error) ? e.error : [e.error?.message??"ERROR"];
        this.addSubmissionErrorNotification({ model: SubmissionType.SearchWarrant.toString(), data: errors });
        return of([]) ;
      })
    );
  }
  
  save(payload, getFieldLabel?, opts?, isAppRequest?) {
    const submissionSaveRequest = () =>
      payload?.id ? this.submissionApiService.update(payload, isAppRequest) : this.submissionApiService.create(payload, isAppRequest);

    return submissionSaveRequest().pipe(
      tap(submission => {
        this.submissionService?.add(submission);
        this.notificationService?.clear();
        this.addSubmissionSuccessNotification({ model: SubmissionType.Inquiry.toString(), from: opts.from });
      }),
      catchError(e => {
        this.notificationService?.clear();
        const errors = Array.isArray(e.error) ? e.error : 
            e.error?.errors? Object.keys(e.error?.errors).map(errKey => `${getFieldLabel? getFieldLabel(errKey) : errKey} : ${e.error.errors[errKey]}`) : 
            [e.error?.message??"ERROR"];
            this.addSubmissionErrorNotification({ model: SubmissionType.Inquiry.toString(), from: opts.from, data: errors });
        return of(e);
      })
    );
  }

  archive(payload, opts?, isAppRequest?) {
    return this.submissionApiService?.archive(payload?.packageUUID, isAppRequest).pipe(
      tap(submission => {
        this.submissionService?.remove(payload?.packageUUID);
        this.notificationService?.clear();
        this.addDeleteSuccessNotification({ model: SubmissionType.Inquiry.toString(), from: opts.from });
      }),
      catchError(e => {
        this.notificationService?.clear();
        this.addDeleteErrorNotification({ model: SubmissionType.Inquiry.toString(), from: opts.from, data: e.error });
        return of(e);
      })
    );
  }

  delete(payload, opts?, isAppRequest?) {
    return this.submissionApiService?.delete(payload?.packageUUID, isAppRequest).pipe(
      tap(submission => {
        this.submissionService?.remove(payload?.packageUUID);
        this.notificationService?.clear();
        this.addDeleteSuccessNotification({ model: SubmissionType.Inquiry.toString(), from: opts.from });
      }),
      catchError(e => {
        this.notificationService?.clear();
        this.addDeleteErrorNotification({ model: SubmissionType.Inquiry.toString(), from: opts.from, data: e.error });
        return of(e);
      })
    );
  }

  download(payload, opts?, isAppRequest?) {
    return this.submissionApiService?.download(payload?.packageUUID, isAppRequest).pipe(
      catchError(e => {
        this.notificationService?.clear();
        this.notificationService?.addError({
          model: SubmissionType.Inquiry.toString(),
          from: opts.from, 
          data: e.error,
          title: 'submission.notification.generic.error.title.text',
          content: 'submission.notification.download.error.content.text'
        });
        throw e;
      })
    );
  }

}
