import { Component } from '@angular/core';
import { AppService } from '@app/core';
import { UserService } from '@app/core/auth';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent {

  constructor(private userService: UserService, private appService: AppService) { }

  ngOnInit() {
    this.userService.loggedIn$.pipe(
      switchMap(loggedIn => loggedIn ?
        this.userService.logOffAndRevokeAccess() :
        this.appService.goToUnauthorizdPage())
    ).subscribe();
  }

  get loggedIn$() {
    return this.userService.loggedIn$;
  }
}
