import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import { Editor, Toolbar, toHTML  } from 'ngx-editor';

@Component({
  selector: 'odsf-textarea-plus',
  templateUrl: './odsf-textarea-plus.component.html',
  styleUrls: ['./odsf-textarea-plus.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class OdsfTextareaPlusComponent implements OnDestroy, OnInit {
  @Input() required: boolean;
  @Input() formCtrlId: string;
  @Input() formCtrlName: string;
  @Input() formCtrl: FormControl;

  @ViewChild('theTextarea') theTextarea: ElementRef;
  
  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  editorContent: unknown;

  // make sure to destory the editor
  ngOnDestroy(): void {
    this.editor.destroy();
  }

  ngOnInit(): void {
    this.editor = new Editor();
    this.editor.valueChanges
    .subscribe((value) => this.editorContent = value);
  }

  get hasError(): boolean {
    return this.formCtrl?.touched && this.formCtrl?.invalid;
  }

  get describedBy(): string {
    return this.hasError ? `${this.formCtrlName}-field-error` : null;
  }

  expanded: boolean = false;

  expandedContainerStyle: Partial<CSSStyleDeclaration> = {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    zIndex: '65525',
    backgroundColor: 'white',
    resize: 'none'
  };

  normalContainerStyle: Partial<CSSStyleDeclaration> = {
    position: 'relative',
    backgroundColor: 'transparent',
    resize: 'both'
  }

  // editorConfig: Partial<CSSStyleDeclaration> = {
  //   editable: true,
  //   spellcheck: true,
  //   height: '100%',
  //   minHeight: '100%',
  //   translate: 'no'
  // };

  isDisabled(): boolean {
    return this.theTextarea?.nativeElement.disabled;
  }

  getContainerStyle(): Partial<CSSStyleDeclaration> {
    return !this.isDisabled() && this.expanded ? this.expandedContainerStyle : this.normalContainerStyle;
  }

  getTextareaStyle(): Partial<CSSStyleDeclaration> {
    return !this.isDisabled() && this.expanded ? {'display':'none'} : {'display' : 'block'};
  }

  getEditorStyle(): Partial<CSSStyleDeclaration> {
    return !this.isDisabled() && this.expanded ? {'display' : 'block'} :  {'display':'none'};
  }

  expandTextarea(): void {
    if (!this.isDisabled()) {
      this.expanded = ! this.expanded;
      if (this.expanded){
        this.editor.setContent(this.theTextarea.nativeElement.value);
      } else {
        this.theTextarea.nativeElement.value = this.editorContent?toHTML(this.editorContent):'';
      }
    }
  }

  getIconText(): string {
    return !this.isDisabled() && this.expanded ? 'transit_enterexit' : 'open_in_new'; //'exit_to_app'
  }
}
