<div class="ontario-row">
  <h1>{{ "messages.title" | translate | async }}</h1>
</div>

<!-- notifications -->
<div class="ontario-row">
  <!-- success -->
  <div *ngIf="notifications?.success" class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
    <ods-page-alert role="success">
      <ng-container title>{{ notifications?.success?.title | translate | async }}</ng-container>
      <ng-container body>
        <p [innerHtml]="notifications?.success?.content | translate | async | safe : 'html'"></p>
      </ng-container>
    </ods-page-alert>
  </div>

  <!-- error -->
  <!-- <div *ngIf="notifications?.error" class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
    <ods-page-alert role="error">
      <ng-container title>{{ notifications?.error?.title | translate | async }}</ng-container>
      <ng-container body>
        <p>{{ notifications?.error?.content | translate | async }}</p>
      </ng-container>
    </ods-page-alert>
  </div> -->

  <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0"></div>
</div>

<!-- create button -->
<div class="ontario-row">
  <button [routerLink]="['/message']" class="ontario-button ontario-button--primary">
    {{ "messages.button.create.text" | translate | async }}
  </button>
</div>

<div class="ontario-row">
  <div class='ontario-accordion' id='accordion-1'>
    <h3 class='ontario-accordion-heading' [class.ontario-expander--active]="isAccordionOpened">
        <button class='ontario-accordion__button' id='accordion-button-id-1' aria-controls='accordion-content-1' [attr.aria-expanded]='isAccordionOpened' data-toggle='ontario-collapse' (click)="isAccordionOpened = !isAccordionOpened">
            <span>{{ "messages.table.title" | translate | async }}</span>
            <span class='ontario-accordion__button-icon--close'>
                <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-up"></use>
                </svg>
            </span>
            <span class='ontario-accordion__button-icon--open'>
                <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-down"></use>
                </svg>
            </span>
        </button>
    </h3>
    <section class='ontario-accordion__content' [class.ontario-expander__content--opened]="isAccordionOpened" id='accordion-content-1' aria-labelledby='accordion-button-id-1' [attr.aria-hidden]='!isAccordionOpened' data-toggle='ontario-expander-content'>
      <div class="example-container" style="position: relative; width: 100%" [class.loading]="loadingResults">
    
        <ods-spinner *ngIf="loadingResults" [fullScreen]="false">
          {{ "app.spinner.text" | translate | async }}
        </ods-spinner>
    
        <div class="example-table-container" style="overflow: auto">
          <table mat-table [dataSource]="dataSource?.data.length > 0 ? dataSource : emptyData" class="example-table" multiTemplateDataRows matSort #sort="matSort" matSortActive="sentDate" matSortDisableClear matSortDirection="desc">
            <!-- sent column -->
            <ng-container matColumnDef="sentDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "messages.table.column.sent.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">{{ row?.sentDate | date : "MM/dd/yyyy" : "UTC" }}</td>
            </ng-container>
    
            <!-- sent column -->
            <ng-container matColumnDef="courtHouse">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="courtHouseCode">{{ "messages.table.column.courtHouse.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">{{ row?.courtHouseCode? this.appCache.getValueFromCache('courtHouse', row.courtHouseCode):"" }}</td>
            </ng-container>

            <!-- sent column -->
            <ng-container matColumnDef="package">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="packageNumber">{{ "messages.table.column.package.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">{{ row?.packageNumber }}</td>
            </ng-container>

            <!-- subject column -->
            <ng-container matColumnDef="subject">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "messages.table.column.subject.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">
                <a class="ontario-__link mat-column-actions-btn" [routerLink]="['/message/',row.messageId]" aria-label="View Message">
                  {{ row?.subject }}
                </a>
              </td>
            </ng-container>

            <!-- expand column -->
            <ng-container matColumnDef="expand">
              <th mat-header-cell *matHeaderCellDef>{{ "messages.table.column.message.title" | translate | async }}</th>
              <td mat-cell *matCellDef="let row">
                <a *ngIf="!expandedPackage[row.messageId]" class="ontario-__link mat-column-actions-btn" aria-label="Expand Message" (click)="expendPackage(row)" (keypress)="expendPackage(row)" tabindex="0">
                  <svg class="ontario-icon" alt="Expand Package" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-down"></use>
                  </svg>
                </a>
                <a *ngIf="expandedPackage[row.messageId]" class="ontario-__link mat-column-actions-btn" aria-label="Expand Message" (click)="collapsePackage(row)" (keypress)="collapsePackage(row)" tabindex="0">
                  <svg class="ontario-icon" alt="Expand Package" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-up"></use>
                  </svg>
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="expandedMessage">
              <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length" [class.empty_expand_detail_row]="expandedPackage && expandedPackage[row.messageId] ? false : true" >
                <div class="example-element-detail" *ngIf="expandedPackage && expandedPackage[row.messageId]">
                  <div class="inner-message-container mat-elevation-z8">
                    <span><strong>{{ "messages.table.column.subject.title" | translate | async }} :</strong> {{row.subject}}</span>
                    <hr class="inner-message-hr">
                    <span [innerHTML]="row.message" ></span>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="empty-row">
              <mat-cell *matCellDef="let element">{{ "table.noData.text" | translate | async }}</mat-cell>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: dataSource?.data?.length > 0 ? displayedColumns : ['empty-row'];"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedMessage']" class="expand-detail-row"></tr>
          </table>
        </div>
    
        <mat-paginator #paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageSize]="5" showFirstLastButtons></mat-paginator>
      </div>
    </section>
  </div>
</div>