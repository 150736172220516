import { Component, Input } from '@angular/core';

@Component({
    selector: 'odsf-accordion',
    templateUrl: './ods-accordion.component.html',
    styleUrls: ['./ods-accordion.component.scss']
})
export class OdsAccordionComponent {
  @Input() opened: boolean = false;
  
  clickButton(): void {
    this.opened = !this.opened;
  }
}