import { Validators } from '@angular/forms';
import { ValidatorType } from '@app/core/models';
import { DEFAULT_STATUS } from './submission.constants';

export const SubmissionSchema = {
  id: {
    id: 'id',
    name: 'id',
    type: Number,
    db: true,
    form: false,
    defaultValue: null,
    disabled: false,
    label: 'inquiryForm.field.id.name',
    description: '',
    validators: []
  },
  packageId: {
    id: 'package-id',
    name: 'packageId',
    type: String,
    db: true,
    form: false,
    defaultValue: null,
    disabled: false,
    label: 'inquiryForm.field.packageId.name',
    description: '',
    validators: []
  },
  packageUUID: {
    id: 'package-uuid',
    name: 'packageUUID',
    type: String,
    db: false,
    form: false,
    defaultValue: null,
    disabled: false,
    label: 'inquiryForm.field.packageUUID.name',
    description: '',
    validators: []
  },
  packageType: {
    id: 'package-type',
    name: 'packageType',
    type: String,
    db: false,
    form: false,
    defaultValue: null,
    disabled: false,
    label: 'inquiryForm.field.packageType.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  currentStatus: {
    id: 'current-status',
    name: 'currentStatus',
    type: String,
    db: false,
    form: false,
    defaultValue: DEFAULT_STATUS,
    disabled: false,
    label: 'inquiryForm.field.currentStatus.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  sentDate: {
    id: 'sent-date',
    name: 'sentDate',
    type: Date,
    db: false,
    form: false,
    defaultValue: null,
    disabled: false,
    label: 'inquiryForm.field.sentDate.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  receivedDate: {
    id: 'received-date',
    name: 'receivedDate',
    type: Date,
    db: false,
    form: false,
    defaultValue: null,
    disabled: false,
    label: 'inquiryForm.field.receivedDate.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  delegatedPackage: {
    id: '',
    name: 'delegatedPackage',
    type: Boolean,
    db: true,
    form: false,
    defaultValue: null,
    disabled: false,
    label: null,
    description: '',
    validators: []
  },
  isArchived: {
    id: '',
    name: 'isArchived',
    type: Boolean,
    db: true,
    form: false,
    defaultValue: null,
    disabled: false,
    label: null,
    description: '',
    validators: []
  },
  isDeleted: {
    id: '',
    name: 'isDeleted',
    type: Boolean,
    db: true,
    form: false,
    defaultValue: null,
    disabled: false,
    label: null,
    description: '',
    validators: []
  }
};
