import { APP_REQUEST_CONTEXT } from '@/src/shared/loading';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgHttpOptions } from '@app/core/http';
import { API_ENDPOINTS } from '@environments/environment';
import { Observable, map, of } from 'rxjs';
import { UserModel } from '.';

const parseBool = v => v === "Y" ? true : false;
const boolToStr = v => v ? "Y" : "N";

const transformer = {
  rawToModel: u => {
    return {
      ...u,
      isActive: parseBool(u?.isActive),
      isSeconded: parseBool(u?.isSeconded)
    };
  },
  modelToRaw: u => {
    // return {
    //   ...u,
    //   isActive: boolToStr(u?.isActive),
    //   isSeconded: boolToStr(u?.isSeconded),
    //   services: []
    // };
    return {
      id: u?.id,
      firstName: u?.firstName,
      lastName: u?.lastName,
      loginEmail: u?.loginEmail,
      agency: u?.agency,
      isActive: u?.isActive,
      receiveNotification: false,
      isSeconded: false,
      dataAccess: false,
      services: []
    };
  }
};

@Injectable({ providedIn: 'root' })
export class UserApiService {
  constructor(private httpClient: HttpClient) { }

  get(id: string, isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    const endpoint = API_ENDPOINTS.USER_GET.indexOf(`{userId}`) === -1 ?
      `${API_ENDPOINTS.USER_GET}/${id}` :
      API_ENDPOINTS.USER_GET.replace(`{userId}`, id);
    return (this.httpClient.get(endpoint, options) as Observable<any>);
  }

  getByEmail(email?: string, isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    const endpoint = API_ENDPOINTS.USER_GET_BY_EMAIL.replace(`{email}`, email);
    return (this.httpClient.get(endpoint, options) as unknown as Observable<any[]>);
  }

  getAll(isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return (this.httpClient.get(API_ENDPOINTS.USER, options) as unknown as Observable<any[]>);
  }

  getDelegateUsersAssignedToPackage(packageId: string, isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return (this.httpClient.get(API_ENDPOINTS.USER_DELEGATES.replace(`{package-uuid}`, packageId), options) as unknown as Observable<any[]>);
  }

  getServiceRoles(isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return (this.httpClient.get(API_ENDPOINTS.USER_SERVICE_ROLES, options) as unknown as Observable<any[]>);
  }

  create(user, isAppRequest?: boolean) {
    const userPayload = transformer.modelToRaw(user);
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return this.httpClient.post(API_ENDPOINTS.USER_CREATE, userPayload, options) as Observable<any>;
  }

  update(user, isAppRequest?: boolean) {
    const userPayload = transformer.modelToRaw(user);
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return this.httpClient.post(API_ENDPOINTS.USER_CREATE, userPayload, options) as Observable<any>;
  }

  assignDelegateUsersToPackage(payload, isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return this.httpClient.post(API_ENDPOINTS.USER_DELEGATES_ASSIGN, payload, options) as Observable<any>;
  }

  deleteDelegateUserToPackage(packageId: string, email: string, isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return this.httpClient.delete(API_ENDPOINTS.USER_DELEGATES_UNASSIGN.replace(`{packageUuid}`, packageId).replace(`{email}`, email) , options) as Observable<any>;
  }

  getAllAccountByCertEmail(email?: string, isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    const endpoint = API_ENDPOINTS.USER_GET_BY_CERT_EMAIL.replace(
      `{email}`,
      email
    );
    return this.httpClient.get(endpoint, options) as unknown as Observable<
      any[]
    >;
  }


}
