export enum SubmissionStatus {
  Delivered = <any>0,
  Response = <any>1,
  Withdraw = <any>2,
  NotWithdraw = <any>3,
  Error = <any>4,
  Failure = <any>5,
  Transmitting = <any>6,
  Draft = <any>7,
  Certify = <any>8,

  DELIVERED = <any>0,
  RESPONSE = <any>1,
  WITHDRAW = <any>2,
  NOTWITHDRAW = <any>3,
  ERROR = <any>4,
  FAILURE = <any>5,
  TRANSMITTING = <any>6,
  DRAFT = <any>7,
  CERTIFY = <any>8,

  ISSUED = <any>101,
  NOTISSUED = <any>102,
  Issued = <any>101,
  NotIssued = <any>102,

}

export const DEFAULT_STATUS = SubmissionStatus.Delivered;

export enum SubmissionType {
  Inquiry = <any>'IF',
  SearchWarrant = <any>'ESW',
  ProductionOrder = <any>'PO'
}

export enum InquiryType {
  Urgent = <any>'REQUEST',
  FollowUp = <any>'FOLLOWUP',
  Withdraw = <any>'IFWITHDRAW'
}

export class SubmissionConstants {
  public static readonly SUBMISSION_TYPE = {
    [SubmissionType[SubmissionType.Inquiry]]: 'submission.type.inquiry.text',
    [SubmissionType[SubmissionType.SearchWarrant]]: 'submission.type.searchWarrant.text',
    [SubmissionType.Inquiry]: 'submission.type.inquiry.text',
    [SubmissionType.SearchWarrant]: 'submission.type.searchWarrant.text'
  };

  public static readonly SUBMISSION_STATUS = {
    [SubmissionStatus[SubmissionStatus.Delivered]]: {
      key: 'submission.status.delivered.text',
      color: '#666666'
    },
    [SubmissionStatus[SubmissionStatus.Response]]: {
      key: 'submission.status.response.text',
      color: '#666666'
    },
    [SubmissionStatus[SubmissionStatus.Withdraw]]: {
      key: 'submission.status.withdrawn.text',
      color: '#118847'
    },
    [SubmissionStatus[SubmissionStatus.NotWithdraw]]: {
      key: 'submission.status.notWithdrawn.text',
      color: '#FFD440'
    },
    [SubmissionStatus[SubmissionStatus.Error]]: {
      key: 'submission.status.error.text',
      color: '#CD0000'
    },
    [SubmissionStatus[SubmissionStatus.Failure]]: {
      key: 'submission.status.failure.text',
      color: '#FFD440'
    },
    [SubmissionStatus[SubmissionStatus.Transmitting]]: {
      key: 'submission.status.transmitting.text',
      color: '#666666'
    },
    [SubmissionStatus[SubmissionStatus.Draft]]: {
      key: 'submission.status.draft.text',
      color: '#B975B7'
    },
    [SubmissionStatus[SubmissionStatus.Certify]]: {
      key: 'submission.status.certify.text',
      color: '#666666'
    },

    [SubmissionStatus[SubmissionStatus.DELIVERED]]: {
      key: 'submission.status.delivered.text',
      color: '#666666'
    },
    [SubmissionStatus[SubmissionStatus.RESPONSE]]: {
      key: 'submission.status.response.text',
      color: '#666666'
    },
    [SubmissionStatus[SubmissionStatus.WITHDRAW]]: {
      key: 'submission.status.withdrawn.text',
      color: '#118847'
    },
    [SubmissionStatus[SubmissionStatus.NOTWITHDRAW]]: {
      key: 'submission.status.notWithdrawn.text',
      color: '#FFD440'
    },
    [SubmissionStatus[SubmissionStatus.ERROR]]: {
      key: 'submission.status.error.text',
      color: '#CD0000'
    },
    [SubmissionStatus[SubmissionStatus.FAILURE]]: {
      key: 'submission.status.failure.text',
      color: '#FFD440'
    },
    [SubmissionStatus[SubmissionStatus.TRANSMITTING]]: {
      key: 'submission.status.transmitting.text',
      color: '#666666'
    },
    [SubmissionStatus[SubmissionStatus.DRAFT]]: {
      key: 'submission.status.draft.text',
      color: '#B975B7'
    },
    [SubmissionStatus[SubmissionStatus.CERTIFY]]: {
      key: 'submission.status.certify.text',
      color: '#666666'
    },

    [SubmissionStatus.Delivered]: {
      key: 'submission.status.delivered.text',
      color: '#666666'
    },
    [SubmissionStatus.Response]: {
      key: 'submission.status.response.text',
      color: '#666666'
    },
    [SubmissionStatus.Withdraw]: {
      key: 'submission.status.withdrawn.text',
      color: '#118847'
    },
    [SubmissionStatus.NotWithdraw]: {
      key: 'submission.status.notWithdrawn.text',
      color: '#FFD440'
    },
    [SubmissionStatus.Error]: {
      key: 'submission.status.error.text',
      color: '#CD0000'
    },
    [SubmissionStatus.Failure]: {
      key: 'submission.status.failure.text',
      color: '#FFD440'
    },
    [SubmissionStatus.Transmitting]: {
      key: 'submission.status.transmitting.text',
      color: '#666666'
    },
    [SubmissionStatus.Draft]: {
      key: 'submission.status.draft.text',
      color: '#B975B7'
    },
    [SubmissionStatus.Certify]: {
      key: 'submission.status.certify.text',
      color: '#666666'
    },
    [SubmissionStatus.ISSUED]: {
      key: 'submission.status.issued.text',
      color: '#118847'
    },
    [SubmissionStatus.Issued]: {
      key: 'submission.status.issued.text',
      color: '#118847'
    },
    [SubmissionStatus.NOTISSUED]: {
      key: 'submission.status.notissued.text',
      color: '#CD0000'
    },
    [SubmissionStatus.NotIssued]: {
      key: 'submission.status.notissued.text',
      color: '#CD0000'
    },
    [SubmissionStatus[SubmissionStatus.ISSUED]]: {
      key: 'submission.status.issued.text',
      color: '#118847'
    },
    [SubmissionStatus[SubmissionStatus.NOTISSUED]]: {
      key: 'submission.status.notissued.text',
      color: '#CD0000'
    },
  };
}
