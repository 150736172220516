import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InquiryFormRefactorComponent } from './inquiry-form-refactor.component';

const routes: Routes = [{ path: '', component: InquiryFormRefactorComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)]
})
export class InquiryFormRefactorRoutingModule {}
