<div class="pdf-viewer-toolbar">
  <a class="ontario-__link mat-column-actions-btn" (click)="clickClosePdfViewer()" (keypress)="clickClosePdfViewer()" tabindex="0" role="button" aria-label="Close PDF Viewer">
    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
      <use xlink:href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-close"></use>
    </svg>
  </a>
</div>
<div class="pdf-viewer-div">
  <iframe #iframeItem *ngFor="let x of dataBase64 ; let i = index"  id="embedded_x_{{i}}"  
    title="pdf reviewer of document {{ (filesName && filesName[i]) ? filesName[i] : '' }}" 
    [src]="'/assets/pdfjs/viewer.html'  |  safe : 'resourceUrl'"  
    [ngClass]="dataBase64.length === 1 ? 'pdf-viewer-single-iframe' : 'pdf-viewer-multi-iframes'" 
    (load)="iframeLoaded(i, x)" >
  </iframe>
</div>