import { NgModule } from "@angular/core";
import { MessageApiService } from "./message-api.service";
import { ServerMessageModel, MessageModel, MessageArguments, Message, MessageFormArguments, messageForm, withMessageTransformer } from "./message.model";
import { MessageSchema } from "./message.schema";
import { MessageService } from "./message.service";
import { MessageDispatcher } from "./message.dispatcher";

const mods = [];

@NgModule({
  declarations: [...mods],
  exports: [...mods]
})
class MessageModule { }

export {
  MessageApiService,
  MessageService,
  MessageDispatcher,
  MessageModule,
  MessageSchema,
  ServerMessageModel,
  MessageModel,
  MessageArguments,
  Message,
  MessageFormArguments,
  messageForm,
  withMessageTransformer
};