import { Component, Input } from '@angular/core';

@Component({
  selector: 'odsf-label',
  styleUrls: ['./odsf-label.component.scss'],
  templateUrl: './odsf-label.component.html'
})
export class OdsfLabelComponent {
  @Input() required: boolean;
  @Input() formCtrlId: string;
  @Input() optional: boolean;
}
