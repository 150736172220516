import { Injectable } from '@angular/core';
import { Language } from '@shared/language';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private _language: Language = undefined;
  private subject = new BehaviorSubject<Language>(this._language);
  private _language$: Observable<string> = this.subject.asObservable();

  get language(): Language {
    return this._language;
  }

  set language(language: Language) {
    this._language = language;
    this.subject.next(this._language);
  }

  get language$(): Observable<string> {
    return this._language$;
  }
}
