import { API_ENDPOINTS } from "@/src/environments/environment";
import { Injectable } from "@angular/core";
import { NotificationService } from "@app/core/notifications";
import cloneDeep from 'lodash/cloneDeep';
import { BehaviorSubject, Observable, catchError, map, of, tap } from "rxjs";
import { UserApiService, UserService } from ".";

@Injectable({ providedIn: 'root' })
export class UserDispatcher {
  constructor(
    private userApiService: UserApiService,
    private userService: UserService,
    private notificationService: NotificationService
  ) { }

  get users$() {
    return this.userService?.list$;
  }

  get selected$() {
    return this.userService?.selected$;
  }

  get notifications$() {
    return this.notificationService?.list$;
  }

  get delegatesList$() {
    return this.userService?.delegatesList$;
  }


  clearNotifications() {
    return this.notificationService?.clear();
  }

  addUserSuccessNotification(details) {
    return this.notificationService?.addSuccess({
      ...details,
      title: 'submission.notification.create.success.title.text',
      content: 'submission.notification.create.success.content.text'
    });
  }

  addDeleteSuccessNotification(details) {
    return this.notificationService?.addSuccess({
      ...details,
      title: 'submission.notification.delete.success.title.text',
      content: 'submission.notification.delete.success.content.text'
    });
  }

  addUserErrorNotification(details) {
    return this.notificationService?.addError({
      ...details,
      title: 'submission.notification.create.error.title.text',
      content: 'submission.notification.create.error.content.invalidFormFields.text'
    });
  }

  addDeleteErrorNotification(details) {
    return this.notificationService?.addError({
      ...details,
      title: 'submission.notification.delete.error.title.text',
      content: 'submission.notification.delete.error.content.text'
    });
  }

  load(isAppRequest = false, searchText = ''): Observable<any[]> {
    return this.userApiService?.getAll(isAppRequest).pipe(
      map((users: any[]) => {
        if (!this.userService) return [];
        let resultUsers = cloneDeep(users);
        if (searchText) {
          resultUsers = resultUsers.filter((u: any) => 
            u?.firstName?.includes(searchText) || 
            u?.lastName?.includes(searchText) || 
            u?.loginEmail?.includes(searchText))
        }
        this.userService.list = cloneDeep(resultUsers);
        return this.userService?.list;
      })
    );
  }

  loadDelegateUsersAssignedToPackage(packageId = '', isAppRequest = false): Observable<any[]> {
    return this.userApiService?.getDelegateUsersAssignedToPackage(packageId, isAppRequest).pipe(
      map((users: any[]) => {
        if (!this.userService) return [];
        const resultUsers = cloneDeep(users);
        this.userService.delegatesList = cloneDeep(resultUsers);
        return this.userService?.delegatesList;
      })
    );
  }

  loadById(id, isAppRequest = false): Observable<any> {
    const toBeLoaded = this.userService.list.find(s => s?.id === parseInt(id));
    const requestId = API_ENDPOINTS.USER_GET.indexOf(`{userId}`) === -1 ? toBeLoaded?.id : toBeLoaded?.userId;
    // return this.userApiService?.get(requestId || id, isAppRequest).pipe(
    return this.userApiService.getByEmail(toBeLoaded?.loginEmail, isAppRequest).pipe(
      map((user: any) => {
        if (!this.userService) return null;
        this.userService.selected = { ...user };
        return this.userService?.selected;
      })
    );
    // return this.load(isAppRequest).pipe(
    //   map((users) => {
    //     if (!this.userService) return null;
    //     const userId = requestId || id;
    //     const user = users.find(u => u.id === parseInt(userId));
    //     if (!user) return null;
    //     this.userService.selected = { ...user };
    //     return this.userService?.selected;
    //   })
    // );
  }

  save(payload, opts?, isAppRequest?) {
    const userSaveRequest = () =>
      payload?.id ? this.userApiService.update(payload, isAppRequest) : this.userApiService.create(payload, isAppRequest);

    return userSaveRequest().pipe(
      tap(user => {
        this.userService?.add(user);
        this.notificationService?.clear();
        this.addUserSuccessNotification({ model: 'User', from: opts.from });
      }),
      catchError(e => {
        this.notificationService?.clear();
        const errors = Array.isArray(e.error) ? e.error : Object.keys(e.error?.errors).map(errKey => e.error.errors[errKey]);
        this.addUserErrorNotification({ model: 'User', from: opts.from, data: errors });
        return of(e);
      })
    );
  }

  saveDelegateUsersAssignedToPackage(payload, isAppRequest = false): Observable<any[]> {
    return this.userApiService?.assignDelegateUsersToPackage(payload, isAppRequest).pipe(
      map((users: any[]) => {
        if (!this.userService) return [];
        const resultUsers = cloneDeep(users);
        this.userService.delegatesList = cloneDeep(resultUsers);
        return this.userService?.delegatesList;
      })
    );
  }

  deleteDelegateUserAssignedToPackage(packageId: string, email: string, isAppRequest = false): Observable<any[]> {
    return this.userApiService?.deleteDelegateUserToPackage(packageId, email, isAppRequest).pipe(
      map((users: any[]) => {
        if (!this.userService) return [];
        const resultUsers = cloneDeep(users);
        this.userService.delegatesList = cloneDeep(resultUsers);
        return this.userService?.delegatesList;
      })
    );
  }


}