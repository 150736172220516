<ng-container *ngIf="view.isReady">
  <ng-container>
    <!-- title -->
    <div class="ontario-row">
      <h1>{{ (isFormView?"searchWarrantForm.create.title":
              isEditView? "searchWarrantForm.update.title": "searchWarrantForm.readonly.title") | translate | async }}</h1>
    </div>
    <!-- form input errors from server -->
    <ng-container *ngIf="shouldShowErrors" [ngTemplateOutlet]="errorNotificationTemplate"></ng-container>

    <div *ngIf="searchWarrant?.form" class="ontario-row">
      <ng-container *ngTemplateOutlet="formTemplateStepNavigater"></ng-container>
      <container-element [ngSwitch]="currentStep">
          <ng-container *ngSwitchCase=1>
            <ng-container *ngTemplateOutlet="formTemplateStep1;context:{readonly:isReadOnlyView}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase=2>
            <ng-container *ngTemplateOutlet="formTemplateStep2;context:{readonly:isReadOnlyView}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase=3>
            <ng-container *ngTemplateOutlet="formTemplateStep3;context:{readonly:isReadOnlyView}"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase=4>
            <ng-container *ngTemplateOutlet="formTemplateStep4;context:{readonly: isReadOnlyView || isEditAsDelegate}"></ng-container>
          </ng-container>
      </container-element>
      <ng-container *ngTemplateOutlet="buttonsDiv"></ng-container>
    </div>        
  </ng-container>
   
  <ng-template #formTemplateStep1 let-readonly="readonly">
    <div class="ontario-row">
      <form novalidate [formGroup]="searchWarrant.form" class="ontario-row" autocomplete="off">
        <div class="ontario-row">
          <!-- information -->
          <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-label">
              {{"searchWarrantForm.step1.field.courthouse.instruction.1" | translate | async }}<br/>
              <span class="ontario-label__flag">{{"searchWarrantForm.step1.field.courthouse.instruction.2" | translate | async }}</span>
            </div>
          </div>
        </div>
        
        <div class="ontario-row">
          <!-- court region -->
          <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('courtRegion').required" formCtrlId="{{ field('courtRegion').id }}">
                <span name>{{ field('courtRegion').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
        
              <odsf-error *ngIf="!readonly && field('courtRegion').required && formCtrl('courtRegion')?.hasError('required')" formCtrlId="{{ field('courtRegion').id }}">
                {{ field('courtRegion').getValidator("required").message | translate : [field('courtRegion').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-select formCtrlId="{{ field('courtRegion').id }}" formCtrlName="{{ field('courtRegion').name }}" [formCtrl]="formCtrl('courtRegion')" [required]="field('courtRegion').required">
                <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                <option *ngFor="let item of optionItems('courtRegion') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                  {{ item.name[lang$ | async] }}
                </option>
              </odsf-select>
            </div>
          </div>
        
          <!-- courthouse -->
          <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('courthouse').required  && !formCtrl('courthouse')?.disabled" formCtrlId="{{ field('courthouse').id }}">
                <span name>{{ field('courthouse').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
  
              <odsf-error *ngIf="!readonly && field('courthouse').required && formCtrl('courthouse')?.hasError('required')" formCtrlId="{{ field('courthouse').id }}">
                {{ field('courthouse').getValidator("required").message | translate : [field('courthouse').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-select formCtrlId="{{ field('courthouse').id }}" formCtrlName="{{ field('courthouse').name }}" [formCtrl]="formCtrl('courthouse')" [required]="field('courthouse').required">
                <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                <option *ngFor="let item of (readonly ? optionItems('courtHouse') : courtHouseOptions) | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                  {{ item.name[lang$ | async] }} ({{ item.id }})
                </option>
              </odsf-select>
            </div>
          </div>

          <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>
  
        <div class="ontario-row">
          <!-- Has an Inquiry Form been submitted in relation to this ITO -->
          <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">

              <odsf-label [required]="field('wasIFSubmitted').required" formCtrlId="{{ field('wasIFSubmitted').id }}">
                <span name>{{ field('wasIFSubmitted').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
              <odsf-error *ngIf="!readonly && field('wasIFSubmitted').required && formCtrl('wasIFSubmitted')?.hasError('required')" formCtrlId="{{ field('wasIFSubmitted').id }}">
                {{ field('wasIFSubmitted').getValidator("required").message | translate : [field('wasIFSubmitted').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-radio formCtrlId="{{ field('wasIFSubmitted').id }}" formCtrlName="{{ field('wasIFSubmitted').name }}" [formCtrl]="formCtrl('wasIFSubmitted')" [required]="field('wasIFSubmitted').requiredTrue"
                [optionItems]="['radio.value.yes'| translate | async, 'radio.value.no'| translate | async ]" [values]="[true,false]" ></odsf-radio>
            </div>
          </div>
        </div>

        <div class="ontario-row" *ngIf="!formCtrl('ifReferenceNum')?.disabled || (readonly && formCtrl('wasIFSubmitted')?.value)">
          <!-- Inquiry Form Reference Number -->
          <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('ifReferenceNum').required && !formCtrl('ifReferenceNum')?.disabled" formCtrlId="{{ field('ifReferenceNum').id }}">
                <span name>{{ field('ifReferenceNum').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
  
              <odsf-error *ngIf="!readonly && field('ifReferenceNum').required && formCtrl('ifReferenceNum')?.hasError('required')" formCtrlId="{{ field('ifReferenceNum').id }}">
                {{ field('ifReferenceNum').getValidator("required").message | translate : [field('ifReferenceNum').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-error *ngIf="!readonly && field('ifReferenceNum').maxLength && formCtrl('ifReferenceNum')?.hasError('maxlength')" formCtrlId="{{ field('ifReferenceNum').id }}">
                {{ field('ifReferenceNum').getValidator("maxLength").message | translate : [field('ifReferenceNum').label | translate | async, formCtrl('ifReferenceNum')?.errors["maxlength"].requiredLength] | async }}
              </odsf-error>
  
              <odsf-input formCtrlId="{{ field('ifReferenceNum').id }}" formCtrlName="{{ field('ifReferenceNum').name }}" [formCtrl]="formCtrl('ifReferenceNum')" [required]="field('ifReferenceNum').required">
                <span *ngIf="!readonly && field('ifReferenceNum').maxLength && !formCtrl('ifReferenceNum')?.disabled && (formCtrl('ifReferenceNum')?.value?.length <= field('ifReferenceNum')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('ifReferenceNum')?.maxLength - formCtrl('ifReferenceNum')?.value?.length] | async }}</span>
              </odsf-input>
            </div>
          </div>
        </div>

        <div class="ontario-row">
          <!-- Is this ITO associated to previously submitted ITO or on-going project -->
          <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('associatedPreviousApp').required" formCtrlId="{{ field('associatedPreviousApp').id }}">
                <span name>{{ field('associatedPreviousApp').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
  
              <odsf-error *ngIf="!readonly && field('associatedPreviousApp').required && formCtrl('associatedPreviousApp')?.hasError('required')" formCtrlId="{{ field('associatedPreviousApp').id }}">
                {{ field('associatedPreviousApp').getValidator("required").message | translate : [field('associatedPreviousApp').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-radio formCtrlId="{{ field('associatedPreviousApp').id }}" formCtrlName="{{ field('associatedPreviousApp').name }}" [formCtrl]="formCtrl('associatedPreviousApp')" [required]="field('associatedPreviousApp').requiredTrue"
                [optionItems]="['radio.value.yes'| translate | async, 'radio.value.no'| translate | async ]" [values]="[true,false]" ></odsf-radio>
            </div>
          </div>
        </div>

        <div class="ontario-row" *ngIf="!formCtrl('associatedPreviousNum')?.disabled || (readonly && formCtrl('associatedPreviousApp')?.value)">
          <!-- Provide the associated Control, Reference or Package number -->
          <div class="ontario-columns ontario-large-8 ontario-medium-8 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('associatedPreviousNum').required && !formCtrl('associatedPreviousNum')?.disabled" formCtrlId="{{ field('associatedPreviousNum').id }}">
                <span name>{{ field('associatedPreviousNum').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
  
              <odsf-error *ngIf="!readonly && field('associatedPreviousNum').required && formCtrl('associatedPreviousNum')?.hasError('required')" formCtrlId="{{ field('associatedPreviousNum').id }}">
                {{ field('associatedPreviousNum').getValidator("required").message | translate : [field('associatedPreviousNum').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-error *ngIf="!readonly && field('associatedPreviousNum').maxLength && formCtrl('associatedPreviousNum')?.hasError('maxlength')" formCtrlId="{{ field('associatedPreviousNum').id }}">
                {{ field('associatedPreviousNum').getValidator("maxLength").message | translate : [field('associatedPreviousNum').label | translate | async, formCtrl('associatedPreviousNum')?.errors["maxlength"].requiredLength] | async }}
              </odsf-error>

              <odsf-error *ngIf="!readonly && field('associatedPreviousNum').minLength && formCtrl('associatedPreviousNum')?.hasError('minlength')" formCtrlId="{{ field('associatedPreviousNum').id }}">
                {{ field('associatedPreviousNum').getValidator("minLength").message | translate : [field('associatedPreviousNum').label | translate | async, formCtrl('associatedPreviousNum')?.errors["minlength"].requiredLength] | async }}
              </odsf-error>              
  
              <odsf-error *ngIf="!readonly && field('associatedPreviousNum').pattern && formCtrl('associatedPreviousNum')?.hasError('pattern')" formCtrlId="{{ field('associatedPreviousNum').id }}">
                {{ field('associatedPreviousNum').getValidator("pattern").message | translate : [field('associatedPreviousNum').label | translate | async | lowercase ] | async }}
              </odsf-error>

              <odsf-input formCtrlId="{{ field('associatedPreviousNum').id }}" formCtrlName="{{ field('associatedPreviousNum').name }}" [formCtrl]="formCtrl('associatedPreviousNum')" [required]="field('associatedPreviousNum').required">
                <span *ngIf="!readonly && field('associatedPreviousNum').maxLength && !formCtrl('associatedPreviousNum')?.disabled && (formCtrl('associatedPreviousNum')?.value?.length <= field('associatedPreviousNum')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('associatedPreviousNum')?.maxLength - formCtrl('associatedPreviousNum')?.value?.length] | async }}</span>
              </odsf-input>
            </div>
          </div>
        </div>

        <div class="ontario-row">
          <!-- Priority Level -->
          <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('priorityCode').required" formCtrlId="{{ field('priorityCode').id }}">
                <span name>{{ field('priorityCode').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
  
              <odsf-error *ngIf="!readonly && field('priorityCode').required && formCtrl('priorityCode')?.hasError('required')" formCtrlId="{{ field('priorityCode').id }}">
                {{ field('priorityCode').getValidator("required").message | translate : [field('priorityCode').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-select formCtrlId="{{ field('priorityCode').id }}" formCtrlName="{{ field('priorityCode').name }}" [formCtrl]="formCtrl('priorityCode')" [required]="field('priorityCode').required">
                <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                <option *ngFor="let item of optionItems('priorityLevel') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                  {{ item.name[lang$ | async] }}
                </option>
              </odsf-select>
            </div>
          </div>
        </div>


        <div class="ontario-row" *ngIf="!formCtrl('synopsis4Priority')?.disabled || (readonly && (['ASAP1','ASAP2','ASAP3','ASAP4'].includes(formCtrl('priorityCode')?.value) || parseInt(formCtrl('priorityCode')?.value) <= 6))">
          <!-- Synopsis to support why the package is a priority -->
          <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('synopsis4Priority').required && !formCtrl('synopsis4Priority')?.disabled" formCtrlId="{{ field('synopsis4Priority').id }}">
                <span name>{{ field('synopsis4Priority').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
              <span [innerHTML]='"searchWarrantForm.step1.field.synopsis.to.support.why.hint" | translate | async'></span><br/><br/>

              <odsf-error *ngIf="!readonly && field('synopsis4Priority').required && formCtrl('synopsis4Priority')?.hasError('required')" formCtrlId="{{ field('synopsis4Priority').id }}">
                {{ field('synopsis4Priority').getValidator("required").message | translate : [field('synopsis4Priority').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-error *ngIf="!readonly && field('synopsis4Priority').maxLength && formCtrl('synopsis4Priority')?.hasError('maxlength')" formCtrlId="{{ field('synopsis4Priority').id }}">
                {{ field('synopsis4Priority').getValidator("maxLength").message | translate : [field('synopsis4Priority').label | translate | async, formCtrl('synopsis4Priority')?.errors["maxlength"].requiredLength] | async }}
              </odsf-error>
  
              <odsf-textarea-pro formCtrlId="{{ field('synopsis4Priority').id }}" formCtrlName="{{ field('synopsis4Priority').name }}" [formCtrl]="formCtrl('synopsis4Priority')" [required]="field('synopsis4Priority').required">
                <span *ngIf="!readonly && field('synopsis4Priority').maxLength && !formCtrl('synopsis4Priority')?.disabled && (formCtrl('synopsis4Priority')?.value?.length <= field('synopsis4Priority')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('synopsis4Priority')?.maxLength - formCtrl('synopsis4Priority')?.value?.length] | async }}</span>
              </odsf-textarea-pro>
            </div>
          </div>
        </div>

        <div class="ontario-row">
          <!-- How do you want the ITO to be created? -->
          <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('isFreeform').required" formCtrlId="{{ field('isFreeform').id }}">
                <span name>{{ field('isFreeform').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
  
              <odsf-error *ngIf="!readonly && field('isFreeform').required && formCtrl('isFreeform')?.hasError('required')" formCtrlId="{{ field('isFreeform').id }}">
                {{ field('isFreeform').getValidator("required").message | translate : [field('isFreeform').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-select formCtrlId="{{ field('isFreeform').id }}" formCtrlName="{{ field('isFreeform').name }}" [formCtrl]="formCtrl('isFreeform')" [required]="field('isFreeform').required">
                <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                <option *ngFor="let item of optionItems('isFreeform') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                  {{ item.name[lang$ | async] }}
                </option>
              </odsf-select>
            </div>
          </div>
          <div class="ontario-columns ontario-large-2 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
          <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-accordion [opened]="true">
                <span title> {{ "searchWarrantForm.step1.field.free.form.ito.hint" | translate | async }}</span>
                <span content>
                  <div class="step1_appendix">
                    <span class="ontario-label__flag span_appendix" [innerHTML]='"searchWarrantForm.step1.field.free.form.ito.callout.indent" | translate | async'></span>
                  </div>
                </span>
              </odsf-accordion> 
            </div>
          </div>
          
        </div>
  
        <div class="ontario-row" *ngIf="formCtrl('isFreeform')?.value === 'false'">
          <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
              <div class="step1_appendix">
                <p><span class="ontario-label span_appendix" [innerHTML]='"searchWarrantForm.step1.field.appendix.c.plus" | translate | async'></span></p>
                <p><span class="ontario-label__flag span_appendix" [innerHTML]='"searchWarrantForm.step1.field.appendix.c.plus.description.1" | translate | async'></span></p>
                <p><span class="ontario-label__flag span_appendix" [innerHTML]='"searchWarrantForm.step1.field.appendix.c.plus.description.2" | translate | async'></span></p>
                <p><span class="ontario-label__flag span_appendix" [innerHTML]='"searchWarrantForm.step1.field.appendix.c.plus.description.3" | translate | async'></span></p>
              </div>
          </div>
        </div>

      </form>
    </div>
  </ng-template>
  
  <ng-template #formTemplateStep2 let-readonly="readonly">
    <div class="ontario-row">
      <form novalidate [formGroup]="searchWarrant.form" class="ontario-row" autocomplete="off">
        <div class="ontario-row">
          <!-- Select ITO Type -->
          <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('itoTypeCode').required" formCtrlId="{{ field('itoTypeCode').id }}">
                <span name>{{ field('itoTypeCode').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
  
              <odsf-error *ngIf="!readonly && field('itoTypeCode').required && formCtrl('itoTypeCode')?.hasError('required')" formCtrlId="{{ field('itoTypeCode').id }}">
                {{ field('itoTypeCode').getValidator("required").message | translate : [field('itoTypeCode').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-select #selectItoType formCtrlId="{{ field('itoTypeCode').id }}" formCtrlName="{{ field('itoTypeCode').name }}" [formCtrl]="formCtrl('itoTypeCode')" [required]="field('itoTypeCode').required" [disabled]="startedANewITO">
                <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                <option *ngFor="let item of optionItems('typeOfIto') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                  {{ item.name[lang$ | async] }}
                </option>
              </odsf-select>
            </div>
          </div>
        
          <!-- button Start -->
          <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <!--
              <button [disabled]="startedANewITO || isReadOnlyView" [ngClass]="{'disabled': startedANewITO || isReadOnlyView}" (click)="startBtnClick()" class="ontario-button step2_button_v">{{ "searchWarrantForm.step2.button.ito.type.start" | translate | async }}</button>
              -->
              <button (click)="clearFormForStep2()" class="ontario-button ontario-button--secondary step2_button_v">{{ "searchWarrantForm.step2.button.clear.form" | translate | async }}</button>
            </div>
          </div>
          <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>

        <div class="ontario-row">
          <hr>
        </div>

        <ng-container *ngIf="startedANewITO">

          <div class="ontario-row">
            <div class="ontario-label">
              {{"searchWarrantForm.step2.field.courthouse.jurisdiction" | translate | async }}
            </div>
          </div>

          <div class="ontario-row">
            <!-- court region jurisdiction-->
            <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('courtRegionJurisdiction').required" formCtrlId="{{ field('courtRegionJurisdiction').id }}">
                  <span name>{{ field('courtRegionJurisdiction').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('courtRegionJurisdiction').required && formCtrl('courtRegionJurisdiction')?.hasError('required')" formCtrlId="{{ field('courtRegionJurisdiction').id }}">
                  {{ field('courtRegionJurisdiction').getValidator("required").message | translate : [field('courtRegionJurisdiction').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-select formCtrlId="{{ field('courtRegionJurisdiction').id }}" formCtrlName="{{ field('courtRegionJurisdiction').name }}" [formCtrl]="formCtrl('courtRegionJurisdiction')" [required]="field('courtRegionJurisdiction').required">
                  <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                  <option *ngFor="let item of optionItems('courtRegion') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                    {{ item.name[lang$ | async] }}
                  </option>
                </odsf-select>
              </div>
            </div>
          
            <!-- courthouse jurisdiction-->
            <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('courthouseJurisdiction').required && !formCtrl('courthouseJurisdiction')?.disabled" formCtrlId="{{ field('courthouseJurisdiction').id }}">
                  <span name>{{ field('courthouseJurisdiction').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('courthouseJurisdiction').required && formCtrl('courthouseJurisdiction')?.hasError('required')" formCtrlId="{{ field('courthouseJurisdiction').id }}">
                  {{ field('courthouseJurisdiction').getValidator("required").message | translate : [field('courthouseJurisdiction').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-select formCtrlId="{{ field('courthouseJurisdiction').id }}" formCtrlName="{{ field('courthouseJurisdiction').name }}" [formCtrl]="formCtrl('courthouseJurisdiction')" [required]="field('courthouseJurisdiction').required">
                  <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                  <option *ngFor="let item of (readonly ? optionItems('courtHouse') : courthouseJurisdiction) | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                    {{ item.name[lang$ | async] }} ({{ item.id }})
                  </option>
                </odsf-select>
              </div>
            </div>

            <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-0 ontario-padding-left-0-!"></div>
          </div>
    
          <div class="ontario-row">
            <!-- Is the owner(s) or occupants(s) of the place to be searched known? -->
            <div class="ontario-columns ontario-large-12 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('areThereOccupants').required" formCtrlId="{{ field('areThereOccupants').id }}">
                  <span name>{{ field('areThereOccupants').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('areThereOccupants').required && formCtrl('areThereOccupants')?.hasError('required')" formCtrlId="{{ field('areThereOccupants').id }}">
                  {{ field('areThereOccupants').getValidator("required").message | translate : [field('areThereOccupants').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-radio formCtrlId="{{ field('areThereOccupants').id }}" formCtrlName="{{ field('areThereOccupants').name }}" [formCtrl]="formCtrl('areThereOccupants')" [required]="field('areThereOccupants').requiredTrue"
                  [optionItems]="['radio.value.yes'| translate | async, 'radio.value.no'| translate | async ]" [values]="[true,false]" ></odsf-radio>
              </div>
            </div>
          </div>
   
          <ng-container *ngIf="formCtrl('areThereOccupants')?.value === 'true'"> 
            <div class="ontario-row">
              <!-- Owner/occupant(s) of dwelling, building, etc. -->
              <div class="ontario-columns ontario-large-3 ontario-medium-3 ontario-small-6 ontario-padding-left-0-!">
                <div class="ontario-form-group">
                  <odsf-label [required]="field('ownerOccupantGivenName').required && !formCtrl('ownerOccupantGivenName')?.disabled" formCtrlId="{{ field('ownerOccupantGivenName').id }}">
                    <span name>{{ field('ownerOccupantGivenName').label | translate | async }}</span>
                    <span flag>({{ "form.field.required.text" | translate | async }})</span>
                  </odsf-label>
      
                  <odsf-error *ngIf="!readonly && field('ownerOccupantGivenName').required && formCtrl('ownerOccupantGivenName')?.hasError('required')" formCtrlId="{{ field('ownerOccupantGivenName').id }}">
                    {{ field('ownerOccupantGivenName').getValidator("required").message | translate : [field('ownerOccupantGivenName').label | translate | async | lowercase] | async }}
                  </odsf-error>
      
                  <odsf-error *ngIf="!readonly && field('ownerOccupantGivenName').maxLength && formCtrl('ownerOccupantGivenName')?.hasError('maxlength')" formCtrlId="{{ field('ownerOccupantGivenName').id }}">
                    {{ field('ownerOccupantGivenName').getValidator("maxLength").message | translate : [field('ownerOccupantGivenName').label | translate | async, formCtrl('ownerOccupantGivenName')?.errors["maxlength"].requiredLength] | async }}
                  </odsf-error>
      
                  <odsf-input formCtrlId="{{ field('ownerOccupantGivenName').id }}" formCtrlName="{{ field('ownerOccupantGivenName').name }}" [formCtrl]="formCtrl('ownerOccupantGivenName')" [required]="field('ownerOccupantGivenName').required">
                    <span *ngIf="!readonly && field('ownerOccupantGivenName').maxLength && !formCtrl('ownerOccupantGivenName')?.disabled && (formCtrl('ownerOccupantGivenName')?.value?.length <= field('ownerOccupantGivenName')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('ownerOccupantGivenName')?.maxLength - formCtrl('ownerOccupantGivenName')?.value?.length] | async }}</span>
                  </odsf-input>
                </div>
              </div>
            
              <div class="ontario-columns ontario-large-3 ontario-medium-3 ontario-small-6 ontario-padding-left-0-!">
                <div class="ontario-form-group">
                  <odsf-label [required]="field('ownerOccupantSurname').required && !formCtrl('ownerOccupantSurname')?.disabled" formCtrlId="{{ field('ownerOccupantSurname').id }}">
                    <span name>{{ field('ownerOccupantSurname').label | translate | async }}</span>
                    <span flag>({{ "form.field.required.text" | translate | async }})</span>
                  </odsf-label>
      
                  <odsf-error *ngIf="!readonly && field('ownerOccupantSurname').required && formCtrl('ownerOccupantSurname')?.hasError('required')" formCtrlId="{{ field('ownerOccupantSurname').id }}">
                    {{ field('ownerOccupantSurname').getValidator("required").message | translate : [field('ownerOccupantSurname').label | translate | async | lowercase] | async }}
                  </odsf-error>
      
                  <odsf-error *ngIf="!readonly && field('ownerOccupantSurname').maxLength && formCtrl('ownerOccupantSurname')?.hasError('maxlength')" formCtrlId="{{ field('ownerOccupantSurname').id }}">
                    {{ field('ownerOccupantSurname').getValidator("maxLength").message | translate : [field('ownerOccupantSurname').label | translate | async, formCtrl('ownerOccupantSurname')?.errors["maxlength"].requiredLength] | async }}
                  </odsf-error>
      
                  <odsf-input formCtrlId="{{ field('ownerOccupantSurname').id }}" formCtrlName="{{ field('ownerOccupantSurname').name }}" [formCtrl]="formCtrl('ownerOccupantSurname')" [required]="field('ownerOccupantSurname').required">
                    <span *ngIf="!readonly && field('ownerOccupantSurname').maxLength && !formCtrl('ownerOccupantSurname')?.disabled && (formCtrl('ownerOccupantSurname')?.value?.length <= field('ownerOccupantSurname')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('ownerOccupantSurname')?.maxLength - formCtrl('ownerOccupantSurname')?.value?.length] | async }}</span>
                  </odsf-input>
                </div>
              </div>

              <!-- Adult/Youth -->
              <div class="ontario-columns ontario-large-2 ontario-medium-2 ontario-small-12 ontario-padding-left-0-!">
                <div class="ontario-form-group">
                  <odsf-label [required]="field('adultOrYouth').required && !formCtrl('adultOrYouth')?.disabled" formCtrlId="{{ field('adultOrYouth').id }}">
                    <span name>{{ field('adultOrYouth').label | translate | async }}</span>
                    <span flag>({{ "form.field.required.text" | translate | async }})</span>
                  </odsf-label>
      
                  <odsf-error *ngIf="!readonly && field('adultOrYouth').required && formCtrl('adultOrYouth')?.hasError('required')" formCtrlId="{{ field('adultOrYouth').id }}">
                    {{ field('adultOrYouth').getValidator("required").message | translate : [field('adultOrYouth').label | translate | async | lowercase] | async }}
                  </odsf-error>
      
                  <odsf-select formCtrlId="{{ field('adultOrYouth').id }}" formCtrlName="{{ field('adultOrYouth').name }}" [formCtrl]="formCtrl('adultOrYouth')" [required]="field('adultOrYouth').required">
                    <option value=""></option>
                    <option *ngFor="let item of optionItems('adultOrYouth') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                      {{ item.name[lang$ | async] }}
                    </option>
                  </odsf-select>
                </div>
              </div>

              <!-- button Add Another Occupant -->
              <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
                <div class="ontario-form-group">
                  <button name="addAnotherOccupantBtn" id="addAnotherOccupantBtn"  [disabled]="formCtrl('adultOrYouth')?.disabled || isReadOnlyView" [ngClass]="{'disabled': formCtrl('adultOrYouth')?.disabled || isReadOnlyView}"  (click)="addAnotherOccupantBtnClick()" class="ontario-button step2_button_v">{{ "searchWarrantForm.step2.button.add.another.occupant" | translate | async }}</button>
                </div>
              </div>

              <div class="ontario-columns ontario-large-2 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
            </div>


            <div class="ontario-row" *ngFor=" let occupant of OccupantsList">
              <!-- Owner/occupant(s) of dwelling, building, etc. -->
              <div class="ontario-columns ontario-large-3 ontario-medium-3 ontario-small-6 ontario-padding-left-0-!">
                <span class="step2_span_v">{{occupant.ownerOccupantGivenName}}</span>
              </div>

              <div class="ontario-columns ontario-large-3 ontario-medium-3 ontario-small-6 ontario-padding-left-0-!">
                <span class="step2_span_v">{{occupant.ownerOccupantSurname}}</span>
              </div>

              <!-- Adult/Youth -->
              <div class="ontario-columns ontario-large-2 ontario-medium-2 ontario-small-12 ontario-padding-left-0-!">
                  <ng-container *ngFor="let item of optionItems('adultOrYouth') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn">
                    <ng-container *ngIf="item.id === occupant.adultOrYouth"><span class="step2_span_v">{{ item.name[lang$ | async] }}</span></ng-container>
                  </ng-container>
              </div>

              <!-- button delete an Occupant -->
              <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
                  <button [disabled]="isReadOnlyView" (click)="deleteAnotherOccupantBtnClick(occupant)" class="ontario-button" [ngClass]="{'disabled': isReadOnlyView}">{{ "searchWarrantForm.step2.button.delete.another.occupant" | translate | async }}</button>
              </div>

              <div class="ontario-columns ontario-large-2 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
            </div>

            <div class="ontario-row">
              <!-- Use if entering business/company name as the owner/occupant -->
              <div class="ontario-columns ontario-large-8 ontario-medium-8 ontario-small-12 ontario-padding-left-0-!">
                <div class="ontario-form-group">
                  <odsf-label [required]="field('businessName').required && !formCtrl('businessName')?.disabled" formCtrlId="{{ field('businessName').id }}">
                    <span name>{{ field('businessName').label | translate | async }}</span>
                    <span flag>({{ "form.field.required.text" | translate | async }})</span>
                  </odsf-label>
                  <span>{{ field('businessName').hint | translate | async}}</span><br/>

                  <odsf-error *ngIf="!readonly && field('businessName').required && formCtrl('businessName')?.hasError('required')" formCtrlId="{{ field('businessName').id }}">
                    {{ field('businessName').getValidator("required").message | translate : [field('businessName').label | translate | async | lowercase] | async }}
                  </odsf-error>
      
                  <odsf-error *ngIf="!readonly && field('businessName').maxLength && formCtrl('businessName')?.hasError('maxlength')" formCtrlId="{{ field('businessName').id }}">
                    {{ field('businessName').getValidator("maxLength").message | translate : [field('businessName').label | translate | async, formCtrl('businessName')?.errors["maxlength"].requiredLength] | async }}
                  </odsf-error>
      
                  <odsf-input formCtrlId="{{ field('businessName').id }}" formCtrlName="{{ field('businessName').name }}" [formCtrl]="formCtrl('businessName')" [required]="field('businessName').required">
                    <span *ngIf="!readonly && field('businessName').maxLength && !formCtrl('businessName')?.disabled && (formCtrl('businessName')?.value?.length <= field('businessName')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('businessName')?.maxLength - formCtrl('businessName')?.value?.length] | async }}</span>
                  </odsf-input>
                </div>
              </div>
            </div>
          </ng-container> 

          <div class="ontario-row">
            <!-- What will be searched? -->
            <div class="ontario-columns ontario-large-8 ontario-medium-8 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('what2Searched').required" formCtrlId="{{ field('what2Searched').id }}">
                  <span name>{{ field('what2Searched').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
                <span>{{ field('what2Searched').hint | translate | async}}</span><br/>

                <odsf-error *ngIf="!readonly && field('what2Searched').required && formCtrl('what2Searched')?.hasError('required')" formCtrlId="{{ field('what2Searched').id }}">
                  {{ field('what2Searched').getValidator("required").message | translate : [field('what2Searched').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('what2Searched').maxLength && formCtrl('what2Searched')?.hasError('maxlength')" formCtrlId="{{ field('what2Searched').id }}">
                  {{ field('what2Searched').getValidator("maxLength").message | translate : [field('what2Searched').label | translate | async, formCtrl('what2Searched')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-input formCtrlId="{{ field('what2Searched').id }}" formCtrlName="{{ field('what2Searched').name }}" [formCtrl]="formCtrl('what2Searched')" [required]="field('what2Searched').required">
                  <span *ngIf="!readonly && field('what2Searched').maxLength && !formCtrl('what2Searched')?.disabled && (formCtrl('what2Searched')?.value?.length <= field('what2Searched')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('what2Searched')?.maxLength - formCtrl('what2Searched')?.value?.length] | async }}</span>
                </odsf-input>
              </div>
            </div>
          </div>

          <div class="ontario-row">
            <!-- Is a vehicle being searched? -->
            <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('isVehicle2Searched').required" formCtrlId="{{ field('isVehicle2Searched').id }}">
                  <span name>{{ field('isVehicle2Searched').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('isVehicle2Searched').required && formCtrl('isVehicle2Searched')?.hasError('required')" formCtrlId="{{ field('isVehicle2Searched').id }}">
                  {{ field('isVehicle2Searched').getValidator("required").message | translate : [field('isVehicle2Searched').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-radio formCtrlId="{{ field('isVehicle2Searched').id }}" formCtrlName="{{ field('isVehicle2Searched').name }}" [formCtrl]="formCtrl('isVehicle2Searched')" [required]="field('isVehicle2Searched').requiredTrue"
                  [optionItems]="['radio.value.yes'| translate | async, 'radio.value.no'| translate | async ]" [values]="[true,false]" ></odsf-radio>
              </div>
            </div>

            <!-- Vehicle Plate Number -->
            <div class="ontario-columns ontario-large-3 ontario-medium-3 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('vehiclePlateNum').required && !formCtrl('vehiclePlateNum')?.disabled" formCtrlId="{{ field('vehiclePlateNum').id }}">
                  <span name>{{ field('vehiclePlateNum').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('vehiclePlateNum').required && formCtrl('vehiclePlateNum')?.hasError('required')" formCtrlId="{{ field('vehiclePlateNum').id }}">
                  {{ field('vehiclePlateNum').getValidator("required").message | translate : [field('vehiclePlateNum').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('vehiclePlateNum').maxLength && formCtrl('vehiclePlateNum')?.hasError('maxlength')" formCtrlId="{{ field('vehiclePlateNum').id }}">
                  {{ field('vehiclePlateNum').getValidator("maxLength").message | translate : [field('vehiclePlateNum').label | translate | async, formCtrl('vehiclePlateNum')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-input formCtrlId="{{ field('vehiclePlateNum').id }}" formCtrlName="{{ field('vehiclePlateNum').name }}" [formCtrl]="formCtrl('vehiclePlateNum')" [required]="field('vehiclePlateNum').required">
                  <span *ngIf="!readonly && field('vehiclePlateNum').maxLength && !formCtrl('vehiclePlateNum')?.disabled && (formCtrl('vehiclePlateNum')?.value?.length <= field('vehiclePlateNum')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('vehiclePlateNum')?.maxLength - formCtrl('vehiclePlateNum')?.value?.length] | async }}</span>
                </odsf-input>
              </div>
            </div>

            <div class="ontario-columns ontario-large-1 ontario-medium-1 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('vehicleInfoForSearch').required && !formCtrl('vehicleInfoForSearch')?.disabled" formCtrlId="{{ field('vehicleInfoForSearch').id }}">
                  <span name>{{ field('vehicleInfoForSearch').label | translate | async }}</span>
                  <span flag><br/>{{"searchWarrantForm.step2.field.vehicle.info.for.Search.2" | translate | async }}<br/>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('vehicleInfoForSearch').required && formCtrl('vehicleInfoForSearch')?.hasError('required')" formCtrlId="{{ field('vehicleInfoForSearch').id }}">
                  {{ field('vehicleInfoForSearch').getValidator("required").message | translate : [field('vehiclePlateNum').label | translate | async | lowercase, field('vehicleIdentificationNum').label | translate | async | lowercase] | async }}
                </odsf-error>

                <odsf-input formCtrlId="{{ field('vehicleInfoForSearch').id }}" formCtrlName="{{ field('vehicleInfoForSearch').name }}" [formCtrl]="formCtrl('vehicleInfoForSearch')" [required]="field('vehicleInfoForSearch').required" [hidden]="true">
                </odsf-input>
              </div>
            </div>


            <!-- Vehicle Identification Number -->
            <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('vehicleIdentificationNum').required && !formCtrl('vehicleIdentificationNum')?.disabled" formCtrlId="{{ field('vehicleIdentificationNum').id }}">
                  <span name>{{ field('vehicleIdentificationNum').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('vehicleIdentificationNum').required && formCtrl('vehicleIdentificationNum')?.hasError('required')" formCtrlId="{{ field('vehicleIdentificationNum').id }}">
                  {{ field('vehicleIdentificationNum').getValidator("required").message | translate : [field('vehicleIdentificationNum').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('vehicleIdentificationNum').maxLength && formCtrl('vehicleIdentificationNum')?.hasError('maxlength')" formCtrlId="{{ field('vehicleIdentificationNum').id }}">
                  {{ field('vehicleIdentificationNum').getValidator("maxLength").message | translate : [field('vehicleIdentificationNum').label | translate | async, formCtrl('vehicleIdentificationNum')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-input formCtrlId="{{ field('vehicleIdentificationNum').id }}" formCtrlName="{{ field('vehicleIdentificationNum').name }}" [formCtrl]="formCtrl('vehicleIdentificationNum')" [required]="field('vehicleIdentificationNum').required">
                  <span *ngIf="!readonly && field('vehicleIdentificationNum').maxLength && !formCtrl('vehicleIdentificationNum')?.disabled && (formCtrl('vehicleIdentificationNum')?.value?.length <= field('vehicleIdentificationNum')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('vehicleIdentificationNum')?.maxLength - formCtrl('vehicleIdentificationNum')?.value?.length] | async }}</span>
                </odsf-input>
              </div>
            </div>         
          </div>

          <div class="ontario-row">
            <!-- Address or location to be searched -->
            <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('address2Searched').required" formCtrlId="{{ field('address2Searched').id }}">
                  <span name>{{ field('address2Searched').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('address2Searched').required && formCtrl('address2Searched')?.hasError('required')" formCtrlId="{{ field('address2Searched').id }}">
                  {{ field('address2Searched').getValidator("required").message | translate : [field('address2Searched').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('address2Searched').maxLength && formCtrl('address2Searched')?.hasError('maxlength')" formCtrlId="{{ field('address2Searched').id }}">
                  {{ field('address2Searched').getValidator("maxLength").message | translate : [field('address2Searched').label | translate | async, formCtrl('address2Searched')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-input formCtrlId="{{ field('address2Searched').id }}" formCtrlName="{{ field('address2Searched').name }}" [formCtrl]="formCtrl('address2Searched')" [required]="field('address2Searched').required">
                  <span *ngIf="!readonly && field('address2Searched').maxLength && !formCtrl('address2Searched')?.disabled && (formCtrl('address2Searched')?.value?.length <= field('address2Searched')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('address2Searched')?.maxLength - formCtrl('address2Searched')?.value?.length] | async }}</span>
                </odsf-input>
              </div>
            </div>

            <!-- City -->
            <div class="ontario-columns ontario-large-3 ontario-medium-3 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('addressCity').required" formCtrlId="{{ field('addressCity').id }}">
                  <span name>{{ field('addressCity').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('addressCity').required && formCtrl('addressCity')?.hasError('required')" formCtrlId="{{ field('addressCity').id }}">
                  {{ field('addressCity').getValidator("required").message | translate : [field('addressCity').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('addressCity').maxLength && formCtrl('addressCity')?.hasError('maxlength')" formCtrlId="{{ field('addressCity').id }}">
                  {{ field('addressCity').getValidator("maxLength").message | translate : [field('addressCity').label | translate | async, formCtrl('addressCity')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-input formCtrlId="{{ field('addressCity').id }}" formCtrlName="{{ field('addressCity').name }}" [formCtrl]="formCtrl('addressCity')" [required]="field('addressCity').required">
                  <span *ngIf="!readonly && field('addressCity').maxLength && !formCtrl('addressCity')?.disabled && (formCtrl('addressCity')?.value?.length <= field('addressCity')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('addressCity')?.maxLength - formCtrl('addressCity')?.value?.length] | async }}</span>
                </odsf-input>
              </div>
            </div>
            
            <!-- Province -->
            <div class="ontario-columns ontario-large-3 ontario-medium-3 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('addressProvinceCode').required" formCtrlId="{{ field('addressProvinceCode').id }}">
                  <span name>{{ field('addressProvinceCode').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('addressProvinceCode').required && formCtrl('addressProvinceCode')?.hasError('required')" formCtrlId="{{ field('addressProvinceCode').id }}">
                  {{ field('addressProvinceCode').getValidator("required").message | translate : [field('addressProvinceCode').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-select formCtrlId="{{ field('addressProvinceCode').id }}" formCtrlName="{{ field('addressProvinceCode').name }}" [formCtrl]="formCtrl('addressProvinceCode')" [required]="field('addressProvinceCode').required">
                  <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
                  <option *ngFor="let item of optionItems('province') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                    {{ item.name[lang$ | async] }}
                  </option>
                </odsf-select>
              </div>
            </div>
          
          </div>

          <div class="ontario-row">
            <!-- Appendix A: Describe things to be searched for -->
            <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('appendixA').required" formCtrlId="{{ field('appendixA').id }}">
                  <span name>{{ field('appendixA').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('appendixA').required && formCtrl('appendixA')?.hasError('required')" formCtrlId="{{ field('appendixA').id }}">
                  {{ field('appendixA').getValidator("required").message | translate : [field('appendixA').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('appendixA').maxLength && formCtrl('appendixA')?.hasError('maxlength')" formCtrlId="{{ field('appendixA').id }}">
                  {{ field('appendixA').getValidator("maxLength").message | translate : [field('appendixA').label | translate | async, formCtrl('appendixA')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-textarea-pro formCtrlId="{{ field('appendixA').id }}" formCtrlName="{{ field('appendixA').name }}" [formCtrl]="formCtrl('appendixA')" [required]="field('appendixA').required">
                  <span *ngIf="!readonly && field('appendixA').maxLength && !formCtrl('appendixA')?.disabled && (formCtrl('appendixA')?.value?.length <= field('appendixA')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('appendixA')?.maxLength - formCtrl('appendixA')?.value?.length] | async }}</span>
                </odsf-textarea-pro>
              </div>
            </div>
          </div>

          <div class="ontario-row" *ngIf="formCtrl('itoTypeCode')?.value ==='CC'">
            <!-- Select the reasonable grounds to believe: -->
            <div class="ontario-columns ontario-large-12 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('reasonableGroundsToBelieve').required" formCtrlId="{{ field('reasonableGroundsToBelieve').id }}">
                  <span name>{{ field('reasonableGroundsToBelieve').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('reasonableGroundsToBelieve').required && formCtrl('reasonableGroundsToBelieve')?.hasError('required')" formCtrlId="{{ field('reasonableGroundsToBelieve').id }}">
                  {{ field('reasonableGroundsToBelieve').getValidator("required").message | translate : [field('reasonableGroundsToBelieve').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-radio formCtrlId="{{ field('reasonableGroundsToBelieve').id }}" formCtrlName="{{ field('reasonableGroundsToBelieve').name }}" [formCtrl]="formCtrl('reasonableGroundsToBelieve')" [required]="field('reasonableGroundsToBelieve').requiredTrue"
                  [inOneLine]="false"
                  [optionItems]="['searchWarrantForm.step2.field.reasonable.grounds.option.1'| translate | async, 
                    'searchWarrantForm.step2.field.reasonable.grounds.option.2'| translate | async, 
                    'searchWarrantForm.step2.field.reasonable.grounds.option.3'| translate | async, 
                    'searchWarrantForm.step2.field.reasonable.grounds.option.4'| translate | async, 
                    'searchWarrantForm.step2.field.reasonable.grounds.option.5'| translate | async, 
                    'searchWarrantForm.step2.field.reasonable.grounds.option.6'| translate | async ]"  ></odsf-radio>
              </div>
            </div>
          </div>

          <div class="ontario-row">
            <!-- Appendix B: List the offences pertaining to the warrant being sought -->
            <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('appendixB').required" formCtrlId="{{ field('appendixB').id }}">
                  <span name>{{ field('appendixB').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('appendixB').required && formCtrl('appendixB')?.hasError('required')" formCtrlId="{{ field('appendixB').id }}">
                  {{ field('appendixB').getValidator("required").message | translate : [field('appendixB').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('appendixB').maxLength && formCtrl('appendixB')?.hasError('maxlength')" formCtrlId="{{ field('appendixB').id }}">
                  {{ field('appendixB').getValidator("maxLength").message | translate : [field('appendixB').label | translate | async, formCtrl('appendixB')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-textarea-pro formCtrlId="{{ field('appendixB').id }}" formCtrlName="{{ field('appendixB').name }}" [formCtrl]="formCtrl('appendixB')" [required]="field('appendixB').required">
                  <span *ngIf="!readonly && field('appendixB').maxLength && !formCtrl('appendixB')?.disabled && (formCtrl('appendixB')?.value?.length <= field('appendixB')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('appendixB')?.maxLength - formCtrl('appendixB')?.value?.length] | async }}</span>
                </odsf-textarea-pro>
              </div>
            </div>
          </div>

          <div class="ontario-row">
            <!-- The informant says they have knowledge of a prior application for a warrant in respect of the same matter -->
            <div class="ontario-columns ontario-large-12 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('informantHaveKnowledge').required" formCtrlId="{{ field('informantHaveKnowledge').id }}">
                  <span name>{{ field('informantHaveKnowledge').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('informantHaveKnowledge').required && formCtrl('informantHaveKnowledge')?.hasError('required')" formCtrlId="{{ field('informantHaveKnowledge').id }}">
                  {{ field('informantHaveKnowledge').getValidator("required").message | translate : [field('informantHaveKnowledge').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-radio formCtrlId="{{ field('informantHaveKnowledge').id }}" formCtrlName="{{ field('informantHaveKnowledge').name }}" [formCtrl]="formCtrl('informantHaveKnowledge')" [required]="field('informantHaveKnowledge').requiredTrue"
                  [optionItems]="['radio.value.yes'| translate | async, 'radio.value.no'| translate | async ]" [values]="[true,false]" ></odsf-radio>
              </div>
            </div>
          </div>

          <div class="ontario-row" *ngIf="!formCtrl('circumstancesOfPriorApplication')?.disabled || (readonly && formCtrl('informantHaveKnowledge')?.value)">
            <!-- Provide circumstances of prior application -->
            <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('circumstancesOfPriorApplication').required && !formCtrl('circumstancesOfPriorApplication')?.disabled" formCtrlId="{{ field('circumstancesOfPriorApplication').id }}">
                  <span name>{{ field('circumstancesOfPriorApplication').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('circumstancesOfPriorApplication').required && formCtrl('circumstancesOfPriorApplication')?.hasError('required')" formCtrlId="{{ field('circumstancesOfPriorApplication').id }}">
                  {{ field('circumstancesOfPriorApplication').getValidator("required").message | translate : [field('circumstancesOfPriorApplication').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('circumstancesOfPriorApplication').maxLength && formCtrl('circumstancesOfPriorApplication')?.hasError('maxlength')" formCtrlId="{{ field('circumstancesOfPriorApplication').id }}">
                  {{ field('circumstancesOfPriorApplication').getValidator("maxLength").message | translate : [field('circumstancesOfPriorApplication').label | translate | async, formCtrl('circumstancesOfPriorApplication')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-textarea-pro formCtrlId="{{ field('circumstancesOfPriorApplication').id }}" formCtrlName="{{ field('circumstancesOfPriorApplication').name }}" [formCtrl]="formCtrl('circumstancesOfPriorApplication')" [required]="field('circumstancesOfPriorApplication').required">
                  <span *ngIf="!readonly && field('circumstancesOfPriorApplication').maxLength && !formCtrl('circumstancesOfPriorApplication')?.disabled && (formCtrl('circumstancesOfPriorApplication')?.value?.length <= field('circumstancesOfPriorApplication')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('circumstancesOfPriorApplication')?.maxLength - formCtrl('circumstancesOfPriorApplication')?.value?.length] | async }}</span>
                </odsf-textarea-pro>
              </div>
            </div>
          </div>

          <div class="ontario-row">
            <!-- Are night grounds being requested? -->
            <div class="ontario-columns ontario-large-12 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('nightGroundsRequested').required" formCtrlId="{{ field('nightGroundsRequested').id }}">
                  <span name>{{ field('nightGroundsRequested').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('nightGroundsRequested').required && formCtrl('nightGroundsRequested')?.hasError('required')" formCtrlId="{{ field('nightGroundsRequested').id }}">
                  {{ field('nightGroundsRequested').getValidator("required").message | translate : [field('nightGroundsRequested').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-radio formCtrlId="{{ field('nightGroundsRequested').id }}" formCtrlName="{{ field('nightGroundsRequested').name }}" [formCtrl]="formCtrl('nightGroundsRequested')" [required]="field('nightGroundsRequested').requiredTrue"
                  [optionItems]="['radio.value.yes'| translate | async, 'radio.value.no'| translate | async ]" [values]="[true,false]" ></odsf-radio>
              </div>
            </div>
          </div>

          <div class="ontario-row" *ngIf="!formCtrl('groundsToExecuteAtNight')?.disabled || (readonly && formCtrl('nightGroundsRequested')?.value)">
            <!-- The reasonable grounds to execute the warrant at night are -->
            <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('groundsToExecuteAtNight').required && !formCtrl('groundsToExecuteAtNight')?.disabled" formCtrlId="{{ field('groundsToExecuteAtNight').id }}">
                  <span name>{{ field('groundsToExecuteAtNight').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('groundsToExecuteAtNight').required && formCtrl('groundsToExecuteAtNight')?.hasError('required')" formCtrlId="{{ field('groundsToExecuteAtNight').id }}">
                  {{ field('groundsToExecuteAtNight').getValidator("required").message | translate : [field('groundsToExecuteAtNight').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('groundsToExecuteAtNight').maxLength && formCtrl('groundsToExecuteAtNight')?.hasError('maxlength')" formCtrlId="{{ field('groundsToExecuteAtNight').id }}">
                  {{ field('groundsToExecuteAtNight').getValidator("maxLength").message | translate : [field('groundsToExecuteAtNight').label | translate | async, formCtrl('groundsToExecuteAtNight')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-textarea formCtrlId="{{ field('groundsToExecuteAtNight').id }}" formCtrlName="{{ field('groundsToExecuteAtNight').name }}" [formCtrl]="formCtrl('groundsToExecuteAtNight')" [required]="field('groundsToExecuteAtNight').required">
                  <span *ngIf="!readonly && field('groundsToExecuteAtNight').maxLength && !formCtrl('groundsToExecuteAtNight')?.disabled && (formCtrl('groundsToExecuteAtNight')?.value?.length <= field('groundsToExecuteAtNight')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('groundsToExecuteAtNight')?.maxLength - formCtrl('groundsToExecuteAtNight')?.value?.length] | async }}</span>
                </odsf-textarea>
              </div>
            </div>
          </div>

          <div class="ontario-row">
            <!-- Is a Public Officer (who is not a peace officer) required to execute this warrant or order? -->
            <div class="ontario-columns ontario-large-12 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('publicOfficerRequired').required" formCtrlId="{{ field('publicOfficerRequired').id }}">
                  <span name>{{ field('publicOfficerRequired').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('publicOfficerRequired').required && formCtrl('publicOfficerRequired')?.hasError('required')" formCtrlId="{{ field('publicOfficerRequired').id }}">
                  {{ field('publicOfficerRequired').getValidator("required").message | translate : [field('publicOfficerRequired').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-radio formCtrlId="{{ field('publicOfficerRequired').id }}" formCtrlName="{{ field('publicOfficerRequired').name }}" [formCtrl]="formCtrl('publicOfficerRequired')" [required]="field('publicOfficerRequired').requiredTrue"
                  [optionItems]="['radio.value.yes'| translate | async, 'radio.value.no'| translate | async ]" [values]="[true,false]" ></odsf-radio>
              </div>
            </div>
          </div>

          <div class="ontario-row" *ngIf="!formCtrl('namesOfPublicOfficers')?.disabled || (readonly && formCtrl('publicOfficerRequired')?.value)">
            <!-- Names of Public Officers -->
            <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('namesOfPublicOfficers').required && !formCtrl('namesOfPublicOfficers')?.disabled" formCtrlId="{{ field('namesOfPublicOfficers').id }}">
                  <span name>{{ field('namesOfPublicOfficers').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
                <span>{{"searchWarrantForm.step2.field.names.of.public.officers.hint" | translate | async}}</span>

                <odsf-error *ngIf="!readonly && field('namesOfPublicOfficers').required && formCtrl('namesOfPublicOfficers')?.hasError('required')" formCtrlId="{{ field('namesOfPublicOfficers').id }}">
                  {{ field('namesOfPublicOfficers').getValidator("required").message | translate : [field('namesOfPublicOfficers').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('namesOfPublicOfficers').maxLength && formCtrl('namesOfPublicOfficers')?.hasError('maxlength')" formCtrlId="{{ field('namesOfPublicOfficers').id }}">
                  {{ field('namesOfPublicOfficers').getValidator("maxLength").message | translate : [field('namesOfPublicOfficers').label | translate | async, formCtrl('namesOfPublicOfficers')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-textarea formCtrlId="{{ field('namesOfPublicOfficers').id }}" formCtrlName="{{ field('namesOfPublicOfficers').name }}" [formCtrl]="formCtrl('namesOfPublicOfficers')" [required]="field('namesOfPublicOfficers').required">
                  <span *ngIf="!readonly && field('namesOfPublicOfficers').maxLength && !formCtrl('namesOfPublicOfficers')?.disabled && (formCtrl('namesOfPublicOfficers')?.value?.length <= field('namesOfPublicOfficers')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('namesOfPublicOfficers')?.maxLength - formCtrl('namesOfPublicOfficers')?.value?.length] | async }}</span>
                </odsf-textarea>
              </div>
            </div>
          </div>

          <div class="ontario-row"  *ngIf="!isReadOnlyView">
            <!-- button Add to ITO Summary -->
            <button (click)="addToItoSummary()" class="ontario-button ontario-button--primary">
              {{ "searchWarrantForm.step2.button.add.to.ito.summary" | translate | async }}
            </button>
            <!-- button clear Form -->
            <button (click)="clearFormForStep2()" class="ontario-button ontario-button--secondary">{{ "searchWarrantForm.step2.button.clear.form" | translate | async }}</button>
          </div>
        </ng-container>

        <div class="ontario-row">
          <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <ods-table #itoSummaryTable [columns]="itoSummarycolumns"  [dataSourceByArray]="itoSummaryDatasource"
                [tableCaption]="'searchWarrantForm.step2.table.itosummary.caption' | translate | async"
                [columnsTitle]="['searchWarrantForm.step2.table.itosummary.column.title.1'| translate | async, 
                'searchWarrantForm.step2.table.itosummary.column.title.2'| translate | async, 
                'searchWarrantForm.step2.table.itosummary.column.title.3'| translate | async, 
                'searchWarrantForm.step2.table.itosummary.column.title.4'| translate | async, 
                'searchWarrantForm.step2.table.itosummary.column.title.5'| translate | async, 
                'searchWarrantForm.step2.table.itosummary.column.title.6'| translate | async ]"
                [noDataText]="'table.noData.text' | translate | async"
                (actionDeleteClick)="itoSummaryDelectRow($event)"
                (actionEditClick)="itoSummaryEditRow($event)"
                (actionAddClick)="itoSummaryAddRow($event)"
              ></ods-table>
            </div>
          </div>
        </div>

        <div class="ontario-row">
          <!-- Is a sealing order being requested for this package? -->
          <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('isSORequested').required" formCtrlId="{{ field('isSORequested').id }}">
                <span name>{{ field('isSORequested').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
  
              <odsf-error *ngIf="!readonly && field('isSORequested').required && formCtrl('isSORequested')?.hasError('required')" formCtrlId="{{ field('isSORequested').id }}">
                {{ field('isSORequested').getValidator("required").message | translate : [field('isSORequested').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-radio formCtrlId="{{ field('isSORequested').id }}" formCtrlName="{{ field('isSORequested').name }}" [formCtrl]="formCtrl('isSORequested')" [required]="field('isSORequested').requiredTrue"
                [optionItems]="['radio.value.yes'| translate | async, 'radio.value.no'| translate | async ]" [values]="[true,false]" ></odsf-radio>
            </div>
          </div>
        </div>

        <div class="ontario-row" *ngIf="!formCtrl('synopsis4SO')?.disabled || (readonly && formCtrl('isSORequested')?.value)">
          <!-- Synopsis to support the sealing order request -->
          <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('synopsis4SO').required && !formCtrl('synopsis4SO')?.disabled" formCtrlId="{{ field('synopsis4SO').id }}">
                <span name>{{ field('synopsis4SO').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
              <span>{{"searchWarrantForm.step2.field.synopsis.to.support.the.sealing.order.hint.1" | translate | async}}</span><br/>
              <span>{{"searchWarrantForm.step2.field.synopsis.to.support.the.sealing.order.hint.2" | translate | async}}</span>
  
              <odsf-error *ngIf="!readonly && field('synopsis4SO').required && formCtrl('synopsis4SO')?.hasError('required')" formCtrlId="{{ field('synopsis4SO').id }}">
                {{ field('synopsis4SO').getValidator("required").message | translate : [field('synopsis4SO').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-error *ngIf="!readonly && field('synopsis4SO').maxLength && formCtrl('synopsis4SO')?.hasError('maxlength')" formCtrlId="{{ field('synopsis4SO').id }}">
                {{ field('synopsis4SO').getValidator("maxLength").message | translate : [field('synopsis4SO').label | translate | async, formCtrl('synopsis4SO')?.errors["maxlength"].requiredLength] | async }}
              </odsf-error>
  
              <odsf-textarea-pro formCtrlId="{{ field('synopsis4SO').id }}" formCtrlName="{{ field('synopsis4SO').name }}" [formCtrl]="formCtrl('synopsis4SO')" [required]="field('synopsis4SO').required">
                <span *ngIf="!readonly && field('synopsis4SO')?.maxLength && !formCtrl('synopsis4SO')?.disabled && (formCtrl('synopsis4SO')?.value?.length <= field('synopsis4SO')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('synopsis4SO')?.maxLength - formCtrl('synopsis4SO')?.value?.length] | async }}</span>
              </odsf-textarea-pro>
            </div>
          </div>
        </div>

        <div class="ontario-row">
          <!-- Are you requesting terms and conditions in the sealing order? -->
          <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('requestTermsCond').required" formCtrlId="{{ field('requestTermsCond').id }}">
                <span name>{{ field('requestTermsCond').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
  
              <odsf-error *ngIf="!readonly && field('requestTermsCond').required && formCtrl('requestTermsCond')?.hasError('required')" formCtrlId="{{ field('requestTermsCond').id }}">
                {{ field('requestTermsCond').getValidator("required").message | translate : [field('requestTermsCond').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-radio formCtrlId="{{ field('requestTermsCond').id }}" formCtrlName="{{ field('requestTermsCond').name }}" [formCtrl]="formCtrl('requestTermsCond')" [required]="field('requestTermsCond').requiredTrue"
                [optionItems]="['radio.value.yes'| translate | async, 'radio.value.no'| translate | async ]" [values]="[true,false]" ></odsf-radio>
            </div>
          </div>
        </div>

        <div class="ontario-row" *ngIf="!formCtrl('synopsisTermsCond')?.disabled || (readonly && formCtrl('requestTermsCond')?.value)">
          <!-- Provide the requested terms for sealing order -->
          <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('synopsisTermsCond').required && !formCtrl('synopsisTermsCond')?.disabled" formCtrlId="{{ field('synopsisTermsCond').id }}">
                <span name>{{ field('synopsisTermsCond').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
  
              <odsf-error *ngIf="!readonly && field('synopsisTermsCond').required && formCtrl('synopsisTermsCond')?.hasError('required')" formCtrlId="{{ field('synopsisTermsCond').id }}">
                {{ field('synopsisTermsCond').getValidator("required").message | translate : [field('synopsisTermsCond').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-error *ngIf="!readonly && field('synopsisTermsCond').maxLength && formCtrl('synopsisTermsCond')?.hasError('maxlength')" formCtrlId="{{ field('synopsisTermsCond').id }}">
                {{ field('synopsisTermsCond').getValidator("maxLength").message | translate : [field('synopsisTermsCond').label | translate | async, formCtrl('synopsisTermsCond')?.errors["maxlength"].requiredLength] | async }}
              </odsf-error>
  
              <odsf-textarea-pro formCtrlId="{{ field('synopsisTermsCond').id }}" formCtrlName="{{ field('synopsisTermsCond').name }}" [formCtrl]="formCtrl('synopsisTermsCond')" [required]="field('synopsisTermsCond').required">
                <span *ngIf="!readonly && field('synopsisTermsCond')?.maxLength && !formCtrl('synopsisTermsCond')?.disabled && (formCtrl('synopsisTermsCond')?.value?.length <= field('synopsisTermsCond')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('synopsisTermsCond')?.maxLength - formCtrl('synopsisTermsCond')?.value?.length] | async }}</span>
              </odsf-textarea-pro>
            </div>
          </div>
        </div>

      </form>
    </div>
  </ng-template>

  <ng-template #formTemplateStep3 let-readonly="readonly">
    <div class="ontario-row">
      <form novalidate [formGroup]="searchWarrant.form" class="ontario-row" autocomplete="off">
        <div class="ontario-row">
          <!-- Is an assistance order required for this warrant? -->
          <div class="ontario-columns ontario-large-8 ontario-medium-8 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('assistanceOrderRequired').required" formCtrlId="{{ field('assistanceOrderRequired').id }}">
                <span name>{{ field('assistanceOrderRequired').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
              <span>{{ field('assistanceOrderRequired').hint | translate | async}}</span><br/>

              <odsf-error *ngIf="!readonly && field('assistanceOrderRequired').required && formCtrl('assistanceOrderRequired')?.hasError('required')" formCtrlId="{{ field('assistanceOrderRequired').id }}">
                {{ field('assistanceOrderRequired').getValidator("required").message | translate : [field('assistanceOrderRequired').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-radio formCtrlId="{{ field('assistanceOrderRequired').id }}" formCtrlName="{{ field('assistanceOrderRequired').name }}" [formCtrl]="formCtrl('assistanceOrderRequired')" [required]="field('assistanceOrderRequired').requiredTrue"
                [optionItems]="['radio.value.yes'| translate | async, 'radio.value.no'| translate | async ]" [values]="[true,false]" ></odsf-radio>

            </div>
          </div>
        </div>

        <div *ngIf="formCtrl('assistanceOrderRequired')?.value === 'true'" style="background-color: #E2F0F4; max-width: calc(83.33% - 16px)">
          <div class="ontario-row">
            <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
              <odsf-label>
                <span name>{{ 'searchWarrantForm.step3.field.person.providing.assistance' | translate | async }}</span>
                <span flag>:</span>
              </odsf-label>
            </div>
          </div>

          <div class="ontario-row">
            <!-- GivenName of Person providing assistance -->
            <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('givenName').required" formCtrlId="{{ field('givenName').id }}">
                  <span name>{{ field('givenName').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('givenName').required && formCtrl('givenName')?.hasError('required')" formCtrlId="{{ field('givenName').id }}">
                  {{ field('givenName').getValidator("required").message | translate : [field('givenName').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('givenName').maxLength && formCtrl('givenName')?.hasError('maxlength')" formCtrlId="{{ field('givenName').id }}">
                  {{ field('givenName').getValidator("maxLength").message | translate : [field('givenName').label | translate | async, formCtrl('givenName')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-input formCtrlId="{{ field('givenName').id }}" formCtrlName="{{ field('givenName').name }}" [formCtrl]="formCtrl('givenName')" [required]="field('givenName').required">
                  <span *ngIf="!readonly && field('givenName').maxLength && !formCtrl('givenName')?.disabled && (formCtrl('givenName')?.value?.length <= field('givenName')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('givenName')?.maxLength - formCtrl('givenName')?.value?.length] | async }}</span>
                </odsf-input>
              </div>
            </div>
          
            <!-- SurName of Person providing assistance -->
            <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('surname').required" formCtrlId="{{ field('surname').id }}">
                  <span name>{{ field('surname').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('surname').required && formCtrl('surname')?.hasError('required')" formCtrlId="{{ field('surname').id }}">
                  {{ field('surname').getValidator("required").message | translate : [field('surname').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('surname').maxLength && formCtrl('surname')?.hasError('maxlength')" formCtrlId="{{ field('surname').id }}">
                  {{ field('surname').getValidator("maxLength").message | translate : [field('surname').label | translate | async, formCtrl('surname')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-input formCtrlId="{{ field('surname').id }}" formCtrlName="{{ field('surname').name }}" [formCtrl]="formCtrl('surname')" [required]="field('surname').required">
                  <span *ngIf="!readonly && field('surname').maxLength && !formCtrl('surname')?.disabled && (formCtrl('surname')?.value?.length <= field('surname')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('surname')?.maxLength - formCtrl('surname')?.value?.length] | async }}</span>
                </odsf-input>
              </div>
            </div>

            <!-- Title -->
            <div class="ontario-columns ontario-large-3 ontario-medium-3 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('title').required" formCtrlId="{{ field('title').id }}">
                  <span name>{{ field('title').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('title').required && formCtrl('title')?.hasError('required')" formCtrlId="{{ field('title').id }}">
                  {{ field('title').getValidator("required").message | translate : [field('title').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('title').maxLength && formCtrl('title')?.hasError('maxlength')" formCtrlId="{{ field('title').id }}">
                  {{ field('title').getValidator("maxLength").message | translate : [field('title').label | translate | async, formCtrl('title')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-input formCtrlId="{{ field('title').id }}" formCtrlName="{{ field('title').name }}" [formCtrl]="formCtrl('title')" [required]="field('title').required">
                  <span *ngIf="!readonly && field('title').maxLength && !formCtrl('title')?.disabled && (formCtrl('title')?.value?.length <= field('title')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('title')?.maxLength - formCtrl('title')?.value?.length] | async }}</span>
                </odsf-input>
              </div>
            </div>

            <div class="ontario-columns ontario-large-1 ontario-medium-1 ontario-small-0 ontario-padding-left-0-!"></div>
          </div>

          <div class="ontario-row">
            <!-- Qualifications of person -->
            <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('qualifications').required" formCtrlId="{{ field('qualifications').id }}">
                  <span name>{{ field('qualifications').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>

                <odsf-error *ngIf="!readonly && field('qualifications').required && formCtrl('qualifications')?.hasError('required')" formCtrlId="{{ field('qualifications').id }}">
                  {{ field('qualifications').getValidator("required").message | translate : [field('qualifications').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('qualifications').maxLength && formCtrl('qualifications')?.hasError('maxlength')" formCtrlId="{{ field('qualifications').id }}">
                  {{ field('qualifications').getValidator("maxLength").message | translate : [field('qualifications').label | translate | async, formCtrl('qualifications')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-input formCtrlId="{{ field('qualifications').id }}" formCtrlName="{{ field('qualifications').name }}" [formCtrl]="formCtrl('qualifications')" [required]="field('qualifications').required">
                  <span *ngIf="!readonly && field('qualifications').maxLength && !formCtrl('qualifications')?.disabled && (formCtrl('qualifications')?.value?.length <= field('qualifications')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('qualifications')?.maxLength - formCtrl('qualifications')?.value?.length] | async }}</span>
                </odsf-input>
              </div>
            </div>
          </div>

          <div class="ontario-row">
            <!-- Explain how the person may assist -->
            <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <odsf-label [required]="field('assistanceExplain').required" formCtrlId="{{ field('assistanceExplain').id }}">
                  <span name>{{ field('assistanceExplain').label | translate | async }}</span>
                  <span flag>({{ "form.field.required.text" | translate | async }})</span>
                </odsf-label>
    
                <odsf-error *ngIf="!readonly && field('assistanceExplain').required && formCtrl('assistanceExplain')?.hasError('required')" formCtrlId="{{ field('assistanceExplain').id }}">
                  {{ field('assistanceExplain').getValidator("required").message | translate : [field('assistanceExplain').label | translate | async | lowercase] | async }}
                </odsf-error>
    
                <odsf-error *ngIf="!readonly && field('assistanceExplain').maxLength && formCtrl('assistanceExplain')?.hasError('maxlength')" formCtrlId="{{ field('assistanceExplain').id }}">
                  {{ field('assistanceExplain').getValidator("maxLength").message | translate : [field('assistanceExplain').label | translate | async, formCtrl('assistanceExplain')?.errors["maxlength"].requiredLength] | async }}
                </odsf-error>
    
                <odsf-textarea-pro formCtrlId="{{ field('assistanceExplain').id }}" formCtrlName="{{ field('assistanceExplain').name }}" [formCtrl]="formCtrl('assistanceExplain')" [required]="field('assistanceExplain').required">
                  <span *ngIf="!readonly && field('assistanceExplain').maxLength && !formCtrl('assistanceExplain')?.disabled && (formCtrl('assistanceExplain')?.value?.length <= field('assistanceExplain')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('assistanceExplain')?.maxLength - formCtrl('assistanceExplain')?.value?.length] | async }}</span>
                </odsf-textarea-pro>
              </div>
            </div>
          </div>


          <div class="ontario-row">
            <!-- Choose which ITO to assign this person -->
            <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
              <odsf-label [required]="field('personProvidingAssistanceChoose').required" formCtrlId="{{ field('personProvidingAssistanceChoose').id }}">
                <span name>{{ field('personProvidingAssistanceChoose').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
              <odsf-error *ngIf="!readonly && field('personProvidingAssistanceChoose').required && formCtrl('personProvidingAssistanceChoose')?.hasError('required')" formCtrlId="{{ field('personProvidingAssistanceChoose').id }}">
                {{ field('personProvidingAssistanceChoose').getValidator("required").message | translate : [field('personProvidingAssistanceChoose').label | translate | async | lowercase] | async }}
              </odsf-error>


              <div class="ontario-form-group">
                <div class="ontario-row">
                  <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-! span_appendix">
                    <odsf-checkbox formCtrlId="{{ field('providingAssistanceChooseSelectAll').id }}" formCtrlName="{{ field('providingAssistanceChooseSelectAll').name }}" [formCtrl]="formCtrl('providingAssistanceChooseSelectAll')" >
                      {{ field('providingAssistanceChooseSelectAll').label | translate | async }}
                    </odsf-checkbox> 
                  </div>
                  <div *ngFor="let itoItem of itoSummaryDatasource" class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-! span_appendix">
                    <odsf-checkbox formCtrlId="providing-assistance-choose-{{ itoItem.itoUUID }}" formCtrlName="providingAssistanceChoose{{itoItem.itoUUID | ToPascalCase}}" [formCtrl]="formCtrl('providingAssistanceChoose' + itoItem.itoUUID)" >
                      {{ itoItem.ito }} - {{ itoItem.address }}
                    </odsf-checkbox>
                  </div>
                  <div class="ontario-columns ontario-large-2 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="ontario-row" *ngIf="!isReadOnlyView">
            <button (click)="addToPpaSummary()" class="ontario-button ontario-button--primary">
              {{ "searchWarrantForm.step3.button.add.to.summary" | translate | async }}
            </button>
            <button (click)="clearFormForStep3()" class="ontario-button ontario-button--secondary">{{ "searchWarrantForm.step2.button.clear.form" | translate | async }}</button>
          </div>
        </div>

        <div class="ontario-row">
          <div class="ontario-columns ontario-large-2 ontario-medium-2 ontario-small-0 ontario-padding-left-0-!">&nbsp;</div>
        </div>

        <div class="ontario-row">
          <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <ods-table #personsProvidingAssistanceSummaryTable [columns]="personsProvidingAssistanceSummaryColumns" [dataSourceByArray]="personsProvidingAssistanceSummaryDatasource" 
                [tableCaption]="'searchWarrantForm.step3.table.summary.caption' | translate | async"
                [columnsTitle]="['searchWarrantForm.step3.table.summary.column.title.1'| translate | async, 
                'searchWarrantForm.step3.table.summary.column.title.2'| translate | async, 
                'searchWarrantForm.step3.table.summary.column.title.3'| translate | async, 
                'searchWarrantForm.step3.table.summary.column.title.4'| translate | async, 
                'searchWarrantForm.step3.table.summary.column.title.5'| translate | async]"
                [noDataText]="'table.noData.text' | translate | async"
                (actionDeleteClick)="ppaSummaryDelectRow($event)"
                (actionEditClick)="ppaSummaryEditRow($event)"
                (actionAddClick)="ppaSummaryAddRow($event)"
              ></ods-table>
            </div>
          </div>
        </div>

      </form>
    </div>
  </ng-template>
 
  <ng-template #formTemplateStep4 let-readonly="readonly">
    <div class="ontario-row">
      <form novalidate [formGroup]="searchWarrant.form" class="ontario-row" autocomplete="off">
        <div class="ontario-row">
       
          <!-- button Upload Appendix C-Plus -->
          <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label>
                <span name>{{ 'searchWarrantForm.step4.label.upload.appendix.c' | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>

              <button (click)="createPackageBtnClick()" class="ontario-button ontario-button--primary">{{ "searchWarrantForm.step4.button.upload" | translate | async }}</button>
            </div>
          </div>
          <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>
        <div class="ontario-row">
          <hr>
        </div>
        <div class="ontario-row">
          <div class="ontario-columns ontario-large-12 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
            <span>{{"searchWarrantForm.step4.list.of.ito.information" | translate | async}}</span></div>
        </div>

        <div class="ontario-row">
          <div class="ontario-columns ontario-large-12 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <ods-table #manageAndUploadDocumentsTable [dataSourceByArray]="manageAndUploadDocumentsDatasource" [columns]="manageAndUploadDocumentsColumns" 
                [tableCaption]="'searchWarrantForm.step4.table.list.of.ito.caption' | translate | async"
                [columnsTitle]="['searchWarrantForm.step4.table.list.of.ito.column.title.1'| translate | async, 
                'searchWarrantForm.step4.table.list.of.ito.column.title.2'| translate | async, 
                'searchWarrantForm.step4.table.list.of.ito.column.title.3'| translate | async, 
                'searchWarrantForm.step4.table.list.of.ito.column.title.4'| translate | async]"
                [noDataText]="'table.noData.text' | translate | async"
                (actionDeleteClick)="handleDelectRow($event)"
                (actionEditClick)="handleEditRow($event)"
                (actionAddClick)="handleAddRow($event)"
                (actionUploadClick)="handleUploadFile($event)"
                (actionDownloadClick)="handleDownloadFile($event)"
              ></ods-table>
            </div>
          </div>
        </div>

      </form>
    </div>
  </ng-template>

  <ng-template #formTemplateStepNavigater>
    <div class="ontario-row">
      <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
        <div class="ontario-footer__links-container--inline">
          <span *ngIf="currentStep !==1" (click)="gotoBack()" (keypress)="gotoBack()" tabindex="0" style="cursor: pointer;"><a><h3>{{"searchWarrantForm.button.back" | translate | async}}</h3></a></span>
          <span *ngIf="currentStep !==1" style="width: 4rem;">&nbsp;</span>
          <span *ngIf="currentStep ===1 || currentStep ===2" (click)="gotoNext()" (keypress)="gotoNext()" tabindex="0" style="cursor: pointer;"><a><h3>{{"searchWarrantForm.button.next" | translate | async}}</h3></a></span>
          <span *ngIf="currentStep ===3 && !(isReadOnlyView || isEditAsDelegate)" (click)="createPackageBtnClick()" (keypress)="createPackageBtnClick()" tabindex="0" style="cursor: pointer;"><a><h3>{{"searchWarrantForm.step4.button.create.ackage" | translate | async}}</h3></a></span>
        </div>
      </div>
      <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
        <span style="cursor: pointer; float: right;"><h3>{{"searchWarrantForm.step.of.steps" | translate: [currentStep, totalStep] | async}}</h3></span>
      </div>
    </div>
    <hr>
  </ng-template>

  <ng-template #buttonsDiv>
      <!-- buttons -->
      <div class="ontario-row">
          <button *ngIf="currentStep ===1 || currentStep ===2" (click)="gotoNext()" class="ontario-button ontario-button--primary">
            {{ "searchWarrantForm.button.continue" | translate | async }}
          </button>
          <button *ngIf="currentStep ===3 && !(isReadOnlyView || isEditAsDelegate)" (click)="createPackageBtnClick()" class="ontario-button ontario-button--primary">
            {{ "searchWarrantForm.step4.button.create.ackage" | translate | async }}
          </button>
          <button *ngIf="currentStep ===4" (click)="sendForm()" class="ontario-button ontario-button--primary" [disabled]="!canSubmit">
            {{ "searchWarrantForm.button.submit.package" | translate | async }} <span *ngIf="!canSubmit">({{ "text.disabled" | translate | async }})</span>
          </button>
          <button *ngIf="!isReadOnlyView" (click)="saveDraft()" class="ontario-button ontario-button--secondary">{{ "searchWarrantForm.button.save.draft" | translate | async }}</button>
      </div>
  </ng-template>

  <ng-template #errorNotificationTemplate>
    <div class="ontario-row">
      <div class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
        <ods-page-alert role="error">
          <ng-container title>{{ notifications?.error?.title??"notification.error.title" | translate | async }}</ng-container>
          <ng-container body>
            <p>{{ notifications?.error?.content??"notification.error.content.invalidFormFields" | translate | async }}</p>
            <ul>
              <li *ngFor="let msg of notifications?.error?.data; let i = index; trackBy: trackByFn">
                <a><div [innerHTML]=" msg | translate | async"></div></a>
              </li>
            </ul>
          </ng-container>
        </ods-page-alert>
      </div>
      
      <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0"></div>
    </div>
  </ng-template>
  
