<ng-container *ngIf="view.isReady">
  <ng-container *ngIf="isFormView">
    <!-- title -->
    <div class="ontario-row">
      <h1>{{ "inquiryForm.create.title" | translate | async }}</h1>
    </div>
    <!-- form input errors from server -->
    <ng-container *ngIf="shouldShowErrors" [ngTemplateOutlet]="errorNotificationTemplate"></ng-container>

    <div *ngIf="inquiry?.form" class="ontario-row">
      <ng-container *ngTemplateOutlet="formTemplate;context:{readonly:false}"></ng-container>
    </div>

    <!-- buttons -->
    <div class="ontario-row">
      <button (click)="sendForm($event)" [disabled]="isSubmitting" class="ontario-button ontario-button--primary" >
        {{ "inquiryForm.submit.btn" | translate | async }}
        <ods-spinner [fullScreen]="false" *ngIf="this.isSubmitting"></ods-spinner>
      </button>

      <button (click)="clearForm()" class="ontario-button ontario-button--secondary">{{ "inquiryForm.clearForm.btn" | translate | async }}</button>
    </div>
  </ng-container>

  <ng-container *ngIf="isReadOnlyView">
    <!-- title -->
    <div class="ontario-row">
      <h1>{{ "inquiryForm.readonly.title" | translate | async }}</h1>
    </div>
    <div *ngIf="inquiry?.form" class="ontario-row">
      <ng-container *ngTemplateOutlet="formTemplate;context:{readonly:true}"></ng-container>
    </div>

    <!-- buttons -->
    <div class="ontario-row">
      <button (click)="printForm()" class="ontario-button ontario-button--secondary">{{ "inquiryForm.printForm.btn" | translate | async }}</button>
    </div>
  </ng-container>

  <!-- WIP -->
  <ng-container *ngIf="isEditView">
    <!-- title -->
    <div class="ontario-row">
      <h1>{{ "inquiryForm.update.title" | translate | async }}</h1>
    </div>
    <!-- form input errors from server -->
    <ng-container *ngIf="shouldShowErrors" [ngTemplateOutlet]="errorNotificationTemplate"></ng-container>

    <div *ngIf="inquiry?.form" class="ontario-row">
      <ng-container *ngTemplateOutlet="formTemplate;context:{readonly:false}"></ng-container>
    </div>

    <!-- buttons -->
    <div class="ontario-row">
      <button (click)="sendForm($event)" [disabled]="isSubmitting" class="ontario-button ontario-button--primary" >
        {{ "inquiryForm.update.btn" | translate | async }}
        <ods-spinner [fullScreen]="false" *ngIf="this.isSubmitting"></ods-spinner>
      </button>
      <button (click)="resetForm()" class="ontario-button ontario-button--secondary">{{ "inquiryForm.resetForm.btn" | translate | async }}</button>
    </div>
  </ng-container>
</ng-container>

<ng-template #formTemplate let-readonly="readonly">
  <div class="ontario-row">
    <form novalidate [formGroup]="inquiry.form" class="ontario-row" autocomplete="off">
      <div class="ontario-row">
        <div class="ontario-columns ontario-large-12 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
          <p class="ontario-paragraph" [innerHTML]="'inquiryForm.description' | translate | async | safe : 'html'"></p>
        </div>
      </div>

      <div class="ontario-row">
        <!-- court region code -->
        <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('courtRegionCode').required" formCtrlId="{{ field('courtRegionCode').id }}">
              <span name>{{ field('courtRegionCode').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="!readonly && field('courtRegionCode').required && formCtrl('courtRegionCode')?.touched && formCtrl('courtRegionCode')?.hasError('required')" formCtrlId="{{ field('courtRegionCode').id }}">
              {{ field('courtRegionCode').getValidator("required").message | translate : [field('courtRegionCode').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-select formCtrlId="{{ field('courtRegionCode').id }}" formCtrlName="{{ field('courtRegionCode').name }}" [formCtrl]="formCtrl('courtRegionCode')" [required]="field('courtRegionCode').required">
              <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
              <option *ngFor="let item of optionItems('courtRegion') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                {{ item.name[lang$ | async] }}
              </option>
            </odsf-select>
          </div>
        </div>

        <!-- court house code -->
        <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('courtHouseCode').required && !formCtrl('courtHouseCode')?.disabled" formCtrlId="{{ field('courtHouseCode').id }}">
              <span name>{{ field('courtHouseCode').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="!readonly && field('courtHouseCode').required && formCtrl('courtHouseCode')?.touched && formCtrl('courtHouseCode')?.hasError('required')" formCtrlId="{{ field('courtHouseCode').id }}">
              {{ field('courtHouseCode').getValidator("required").message | translate : [field('courtHouseCode').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-select
              formCtrlId="{{ field('courtHouseCode').id }}"
              formCtrlName="{{ field('courtHouseCode').name }}"
              [formCtrl]="formCtrl('courtHouseCode')"
              [required]="field('courtHouseCode').required">
              <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
              <option *ngFor="let item of (readonly ? optionItems('courtHouse') : courtHouseOptions) | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                {{ item.name[lang$ | async] }} ({{ item.id }})
              </option>
            </odsf-select>
          </div>
        </div>

        <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-0 ontario-padding-left-0-!"></div>
      </div>

      <div class="ontario-row">
        <!-- type of inquiry code -->
        <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('typeOfInquiryCode').required" formCtrlId="{{ field('typeOfInquiryCode').id }}">
              <span name>{{ field('typeOfInquiryCode').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="!readonly && field('typeOfInquiryCode').required && formCtrl('typeOfInquiryCode')?.touched && formCtrl('typeOfInquiryCode')?.hasError('required')" formCtrlId="{{ field('typeOfInquiryCode').id }}">
              {{ field('typeOfInquiryCode').getValidator("required").message | translate : [field('typeOfInquiryCode').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-select formCtrlId="{{ field('typeOfInquiryCode').id }}" formCtrlName="{{ field('typeOfInquiryCode').name }}" [formCtrl]="formCtrl('typeOfInquiryCode')" [required]="field('typeOfInquiryCode').required">
              <option value="">{{ "form.field.dropdown.list.select" | translate | async }}</option>
              <option *ngFor="let item of optionItems('inquiryType') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                {{ item.name[lang$ | async] }}
              </option>
            </odsf-select>
          </div>
        </div>

        <!-- package number -->
        <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('packageNumber').requiredIf && !formCtrl('packageNumber')?.disabled" formCtrlId="{{ field('packageNumber').id }}">
              <span name>{{ field('packageNumber').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="!readonly && field('packageNumber').requiredIf && formCtrl('packageNumber')?.touched && formCtrl('packageNumber')?.hasError('required')" formCtrlId="{{ field('packageNumber').id }}">
              {{ field('packageNumber').getValidator("requiredIf").message | translate : [field('packageNumber').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('packageNumber').existance && formCtrl('packageNumber')?.touched && formCtrl('packageNumber')?.hasError('existance')" formCtrlId="{{ field('packageNumber').id }}">
              {{ field('packageNumber').getValidator("existance").message | translate : [field('packageNumber').label | translate | async | lowercase, formCtrl('packageNumber')?.value] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('packageNumber').pattern && formCtrl('packageNumber')?.touched && formCtrl('packageNumber')?.hasError('pattern')" formCtrlId="{{ field('packageNumber').id }}">
              {{ field('packageNumber').getValidator("pattern").message | translate : [field('packageNumber').label | translate | async | lowercase, '(e.g. 12345-12345-12345)'] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('packageNumber').maxLength && formCtrl('packageNumber')?.touched && formCtrl('packageNumber')?.hasError('maxlength')" formCtrlId="{{ field('packageNumber').id }}">
              {{ field('packageNumber').getValidator("maxLength").message | translate : [field('packageNumber').label | translate | async, formCtrl('packageNumber')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-input
              formCtrlId="{{ field('packageNumber').id }}"
              formCtrlName="{{ field('packageNumber').name }}"
              [formCtrl]="formCtrl('packageNumber')"
              [required]="field('packageNumber').required"
              [disabled]="!formCtrl('typeOfInquiryCode')?.value">
            </odsf-input>
          </div>
        </div>

        <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
      </div>

      <div class="ontario-row">
        <!-- subject -->
        <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('subject').required" formCtrlId="{{ field('subject').id }}">
              <span name>{{ field('subject').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="!readonly && field('subject').required && formCtrl('subject')?.touched && formCtrl('subject')?.hasError('required')" formCtrlId="{{ field('subject').id }}">
              {{ field('subject').getValidator("required").message | translate : [field('subject').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('subject').maxLength && formCtrl('subject')?.touched && formCtrl('subject')?.hasError('maxlength')" formCtrlId="{{ field('subject').id }}">
              {{ field('subject').getValidator("maxLength").message | translate : [field('subject').label | translate | async, formCtrl('subject')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-input formCtrlId="{{ field('subject').id }}" formCtrlName="{{ field('subject').name }}" [formCtrl]="formCtrl('subject')" [required]="field('subject').required"> </odsf-input>
          </div>
        </div>
      </div>

      <div class="ontario-row">
        <!-- message -->
        <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('message').required" formCtrlId="{{ field('message').id }}">
              <span name>{{ field('message').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="!readonly && field('message').required && formCtrl('message')?.touched && formCtrl('message')?.hasError('required')" formCtrlId="{{ field('message').id }}">
              {{ field('message').getValidator("required").message | translate : [field('message').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('message').maxLength && formCtrl('message')?.touched && formCtrl('message')?.hasError('maxlength')" formCtrlId="{{ field('message').id }}">
              {{ field('message').getValidator("maxLength").message | translate : [field('message').label | translate | async, formCtrl('message')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-textarea-pro formCtrlId="{{ field('message').id }}" formCtrlName="{{ field('message').name }}" [formCtrl]="formCtrl('message')" [required]="field('message').required">
              <span *ngIf="!readonly && (formCtrl('message')?.value?.length <= field('message')?.maxLength)">{{ 'validation.message.characters.remaining' | translate:[field('message')?.maxLength - formCtrl('message')?.value?.length] | async }}</span>
            </odsf-textarea-pro>
          </div>
        </div>
      </div>

      <div class="ontario-row">
        <!-- declare checkbox -->
        <div class="ontario-columns ontario-large-10 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-error *ngIf="!readonly && field('declare').requiredTrue && formCtrl('declare')?.touched && formCtrl('declare')?.hasError('required')" formCtrlId="{{ field('declare').id }}">
              {{ field('declare').getValidator("requiredTrue").message | translate | async }}
            </odsf-error>

            <odsf-checkbox formCtrlId="{{ field('declare').id }}" formCtrlName="{{ field('declare').name }}" [formCtrl]="formCtrl('declare')" [required]="field('declare').requiredTrue">
              {{ field('declare').label | translate: [officerName] | async }}
            </odsf-checkbox>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #errorNotificationTemplate>
  <div class="ontario-row">
    <div class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
      <ods-page-alert role="error">
        <ng-container title>{{ "notification.error.title" | translate | async }}</ng-container>
        <ng-container body>
          <p>{{ "notification.error.content.invalidFormFields" | translate | async }}</p>
          <ul>
            <li *ngFor="let msg of notifications?.error?.data; let i = index; trackBy: trackByFn">
              <a href="#">{{ msg }}</a>
            </li>
          </ul>
        </ng-container>
      </ods-page-alert>
    </div>
    
    <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0"></div>
  </div>
</ng-template>
