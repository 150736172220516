import { FormFieldSchema, withForm, withSchema, withModel } from "@app/core/models";
import { MessageSchema } from "./message.schema";
import cloneDeep from 'lodash/cloneDeep';

export interface ServerMessageModel {
  id: number;
  messageId: number;
  sentDate: string;
  originalRequest: {
    courtHouseCode: string;
    packageNumber: string;
    subject: string;
    message: string;
  };
}

export interface MessageModel {
  id?: number;
  messageId?: string;
  sentDate?: Date;
  courtHouseCode: number;
  packageNumber?: string;
  subject: string;
  message: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  toForm?: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  toSchema?: Function;
  [x: string | number | symbol]: any;
}

export interface MessageArguments {
  data?: unknown;
  schema: FormFieldSchema;
  options: { validateForm: boolean };
}

export const Message = function ({ data, schema, options: { validateForm } }: MessageArguments) {
  // set default value using schema
  const fields = Object.keys(schema);
  let state: Partial<MessageModel> = fields.reduce((f, name) => {
    f[name] = schema[name].defaultValue;
    return f;
  }, {});

  state = Object.assign(state, data);
  return Object.assign(
    state,
    withForm({ schema, validateForm: validateForm }),
    withSchema({ schema, validateForm: validateForm }),
    withModel({ schema, state })
  );
};

export interface MessageFormArguments {
  data?: unknown;
  validateForm?: boolean;
  disableForm?: boolean;
}

export const messageForm = function ({ data, validateForm, disableForm }: MessageFormArguments) {
  const s = Message({
    data: data ?? {},
    schema: MessageSchema,
    options: { validateForm }
  });
  const form = s.toForm({ disableAll: disableForm });
  const schema = s.toSchema();
  return {
    model: s,
    form,
    schema,
    validateForm,
    disableForm,
    toModel: s.toModel,
    toPayload: () => s.toPayload({ formGroup: form }),
    toForm: s.toForm,
    toSchema: s.toSchema
  };
};

export const withMessageTransformer = function ({ state }) {
  return {
    toTable: function () {
      return cloneDeep(state);
    }
  }
}