import { Injectable } from '@angular/core';
import { NotificationModel, NotificationService } from '@app/core/notifications';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { SubmissionModel, SubmissionType } from '.';
import { SubmissionService } from './submission.service';
import { SubmissionSearchWarrantApiService } from './submission.searchwarrant.api.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class SubmissionSearchWarrantDispatcher {
  constructor(
    private submissionApiService: SubmissionSearchWarrantApiService, 
    private submissionService: SubmissionService, 
    private router: Router,
    private notificationService: NotificationService) {}

  get submissions$() {
    return this.submissionService?.list$;
  }

  get selected$() {
    return this.submissionService?.selected$;
  }

  get notifications$() {
    return this.notificationService?.list$;
  }

  clearNotifications() {
    return this.notificationService?.clear();
  }

  addErrorNotification(details){
    return this.notificationService?.addError({
      ...details,
      model: SubmissionType.SearchWarrant.toString()
    });
  }
  
  addSuccessNotification(details){
    return this.notificationService?.addSuccess({
      ...details,
      model: SubmissionType.SearchWarrant.toString()
    });
  }
  
  
  addSubmissionSuccessNotification(details: NotificationModel) {
    return this.notificationService?.addSuccess({
      ...details,
      title: 'submission.notification.create.success.title.text',
      content: 'submission.notification.create.success.content.text'
    });
  }

  addDeleteSuccessNotification(details: NotificationModel) {
    return this.notificationService?.addSuccess({
      ...details,
      title: 'submission.notification.delete.success.title.text',
      content: 'submission.notification.delete.success.content.text'
    });
  }

  addSubmissionErrorNotification(details: NotificationModel) {
    return this.notificationService?.addError({
      ...details,
      title: 'submission.notification.create.error.title.text',
      content: 'submission.notification.create.error.content.invalidFormFields.text'
    });
  }

  addDeleteErrorNotification(details: NotificationModel) {
    return this.notificationService?.addError({
      ...details,
      title: 'submission.notification.delete.error.title.text',
      content: 'submission.notification.delete.error.content.text'
    });
  }

  load(isAppRequest = false): Observable<any[]> {
    return this.submissionApiService?.getAll(isAppRequest).pipe(
      map((submissions: any[]) => {
        if (!this.submissionService) return [];
        this.submissionService.list = cloneDeep(submissions);
        return this.submissionService?.list;
      })
    );
  }

  loadById(id, isAppRequest = false): Observable<SubmissionModel> {
    return this.submissionApiService?.get(id, isAppRequest).pipe(
      map((submission: any) => {
        if (!this.submissionService) return null;
        this.submissionService.selected = { ...submission };
        return this.submissionService?.selected;
      }),
      catchError(e => {
        console.log(e);
        this.router.navigate(['error']);
        return of(null);
      })
    );
  }

  save(payload, opts?, isAppRequest?) {
    const submissionSaveRequest = () =>
      payload?.id ? this.submissionApiService.update(payload, isAppRequest) : this.submissionApiService.create(payload, isAppRequest);

    return submissionSaveRequest().pipe(
      tap(submission => {
        this.submissionService?.add(submission);
        this.notificationService?.clear();
        this.addSubmissionSuccessNotification({ model: SubmissionType.SearchWarrant.toString(), from: opts.from });
      }),
      catchError(e => {
        this.notificationService?.clear();
        const errors = Array.isArray(e.error) ? e.error : Object.keys(e.error?.errors).map(errKey => e.error.errors[errKey]);
        this.addSubmissionErrorNotification({ model: SubmissionType.SearchWarrant.toString(), from: opts.from, data: errors });
        return of(e);
      })
    );
  }

  saveDraft(payload, packageUUID?, getFieldLabel?, opts?, isAppRequest?): Observable<any> {
    const submissionSaveRequest = () =>
      packageUUID ? this.submissionApiService.update(payload, packageUUID, isAppRequest) : this.submissionApiService.save(payload, isAppRequest);  

    return submissionSaveRequest().pipe(
      tap(submission => {
        this.submissionService?.add(submission);
        this.notificationService?.clear();
        this.addSubmissionSuccessNotification({ model: SubmissionType.SearchWarrant.toString(), from: opts.from });
      }),
      catchError(e => {
        console.log(e);
        this.notificationService?.clear();
        const errors = Array.isArray(e.error) ? e.error : 
            e.error?.errors? Object.keys(e.error?.errors).map(errKey => `${getFieldLabel? getFieldLabel(errKey) : errKey} : ${e.error.errors[errKey]}`) : 
            [e.error?.message??"ERROR"];
        this.addSubmissionErrorNotification({ model: SubmissionType.SearchWarrant.toString(), from: opts.from, data: errors });
        throw e;
      })
    );
  }

  delete(payload, opts?, isAppRequest?) {
    return this.submissionApiService?.delete(payload?.id, isAppRequest).pipe(
      tap(submission => {
        this.submissionService?.remove(payload?.id);
        this.notificationService?.clear();
        this.addDeleteSuccessNotification({ model: SubmissionType.SearchWarrant.toString(), from: opts.from });
      }),
      catchError(e => {
        this.notificationService?.clear();
        this.addDeleteErrorNotification({ model: SubmissionType.SearchWarrant.toString(), from: opts.from, data: e.error });
        return of(e);
      })
    );
  }
}
