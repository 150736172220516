import { Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'odsf-textarea-pro',
  templateUrl: './odsf-textarea-pro.component.html',
  styleUrls: ['./odsf-textarea-pro.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class OdsfTextareaProComponent {
  @Input() required: boolean;
  @Input() formCtrlId: string;
  @Input() formCtrlName: string;
  @Input() formCtrl: FormControl;

  @ViewChild('theTextarea') theTextarea: ElementRef;
  
  @ViewChild('editor', { static: true }) editorElement: ElementRef;

  get hasError(): boolean {
    return this.formCtrl?.touched && this.formCtrl?.invalid;
  }

  get describedBy(): string {
    return this.hasError ? `${this.formCtrlName}-field-error` : null;
  }

  expanded: boolean = false;

  expandedContainerStyle: Partial<CSSStyleDeclaration> = {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top:  '0',
    left: '0',
    zIndex: '10000',
    backgroundColor: 'white',
    resize: 'none'
  };

  normalContainerStyle: Partial<CSSStyleDeclaration> = {
    position: 'relative',
    backgroundColor: 'transparent',
    resize: 'both'
  }

  expandedContentStyle: Partial<CSSStyleDeclaration> = {
    position: 'fixed',
    left: '20px',
    bottom: '10px',
    zIndex: '20000',
    backgroundColor: 'transparent'
  };

  normalContentStyle: Partial<CSSStyleDeclaration> = {
  };

  isDisabled(): boolean {
    return this.theTextarea?.nativeElement.disabled;
  }

  getContainerStyle(): Partial<CSSStyleDeclaration> {
    return !this.isDisabled() && this.expanded ? this.expandedContainerStyle : this.normalContainerStyle;
  }

  expandTextarea(): void {
    if (!this.isDisabled())
      this.expanded = ! this.expanded;
  }

  getIconText(): string {
    return !this.isDisabled() && this.expanded ? 'transit_enterexit' : 'open_in_new'; //'exit_to_app'
  }
  
  getContentStyle(): Partial<CSSStyleDeclaration> {
    return !this.isDisabled() && this.expanded ? this.expandedContentStyle : this.normalContentStyle;
  }
}
