<div class='ontario-accordion'>
    <div class='ontario-accordion-heading'>
        <button class='ontario-accordion__button_2' (click)="clickButton()" >
            <span  *ngIf="opened">
                <svg class="ontario-icon" alt="" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-up"></use>
                </svg>
            </span>
            <span *ngIf="!opened">
                <svg class="ontario-icon" alt="" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-down"></use>
                </svg>
            </span>
            <ng-content select="[title]"></ng-content>
        </button>
    </div>
    <section class='ontario-accordion__content_2' *ngIf="opened">
        <ng-content select="[content]"></ng-content>
    </section>
</div>