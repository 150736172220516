import { Pipe, PipeTransform } from '@angular/core';
import isUndefined from 'lodash/isUndefined';
import { SubmissionConstants, SubmissionStatus } from './submission.constants';

@Pipe({ name: 'submissionstatus' })
export class SubmissionStatusPipe implements PipeTransform {
  transform(value: any, prop: string): string {
    const statusType = SubmissionStatus[value];
    const statusTypeConst = SubmissionConstants.SUBMISSION_STATUS[statusType];
    return (!isUndefined(statusType) && !isUndefined(statusTypeConst) && statusTypeConst[prop]) || '';
  }
}
