<ng-container *ngIf="view.isReady">
  <ng-container *ngIf="isFormView">
    <!-- title -->
    <div class="ontario-row">
      <h1>{{ "userForm.create.title" | translate | async }}</h1>
    </div>
    
    <!-- back to users button -->
    <ng-container [ngTemplateOutlet]="formTemplateUsersNavigater"></ng-container>

    <!-- form input errors from server -->
    <ng-container *ngIf="shouldShowErrors" [ngTemplateOutlet]="errorNotificationTemplate"></ng-container>

    <div *ngIf="user?.form" class="ontario-row">
      <ng-container *ngTemplateOutlet="formTemplate;context:{readonly:false}"></ng-container>
    </div>

    <!-- buttons -->
    <div class="ontario-row">
      <button (click)="sendForm()" class="ontario-button ontario-button--primary" [disabled]="!canSubmit">
        {{ "userForm.submit.btn" | translate | async }} <span *ngIf="!canSubmit">({{ "text.disabled" | translate | async }})</span>
      </button>

      <button (click)="clearForm()" class="ontario-button ontario-button--secondary">{{ "userForm.clearForm.btn" | translate | async }}</button>
    </div>
  </ng-container>

  <ng-container *ngIf="isReadOnlyView">
    <div class="ontario-row">
      <h1>{{ "userForm.readonly.title" | translate | async }}</h1>      
    </div>

    <!-- back to users button -->
    <ng-container [ngTemplateOutlet]="formTemplateUsersNavigater"></ng-container>

    <div *ngIf="user?.form" class="ontario-row">
      <ng-container *ngTemplateOutlet="formTemplate;context:{readonly:true}"></ng-container>
    </div>

    <!-- buttons -->
    <div class="ontario-row">
      <button (click)="printForm()" class="ontario-button ontario-button--secondary">{{ "userForm.printForm.btn" | translate | async }}</button>
      <button [routerLink]="['/user/',user?.model?.id,'edit']" class="ontario-button ontario-button--secondary">{{ "userForm.edit.btn" | translate | async }}</button>
    </div>
  </ng-container>

  <ng-container *ngIf="isEditView">
    <div class="ontario-row">
      <h1>{{ "userForm.update.title" | translate | async }}</h1>
    </div>

    <!-- back to users button -->
    <ng-container [ngTemplateOutlet]="formTemplateUsersNavigater"></ng-container>

    <!-- form input errors from server -->
    <ng-container *ngIf="shouldShowErrors" [ngTemplateOutlet]="errorNotificationTemplate"></ng-container>

    <div *ngIf="user?.form" class="ontario-row">
      <ng-container *ngTemplateOutlet="formTemplate;context:{editing:true}"></ng-container>
    </div>

    <!-- buttons -->
    <div class="ontario-row">
      <button (click)="sendForm()" class="ontario-button ontario-button--primary" [disabled]="!canSubmit">
        {{ "userForm.update.btn" | translate | async }} <span *ngIf="!canSubmit">({{ "text.disabled" | translate |
          async }})</span>
      </button>
      <!-- <button (click)="resetForm()" class="ontario-button ontario-button--secondary">{{ "userForm.resetForm.btn" |
        translate | async }}</button> -->
      <button [routerLink]="['/user/',user?.model?.id]" class="ontario-button ontario-button--secondary">{{ "userForm.cancel.btn" | translate | async }}</button>
    </div>
  </ng-container>
</ng-container>

<ng-template #formTemplate let-readonly="readonly" let-editing="editing">
  <div class="ontario-row">
    <form novalidate [formGroup]="user.form" class="ontario-row">
      <div class="ontario-row">
        <!-- first name -->
        <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('firstName').required" formCtrlId="{{ field('firstName').id }}">
              <span name>{{ field('firstName').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error
              *ngIf="!readonly && field('firstName').required && formCtrl('firstName')?.hasError('required')"
              formCtrlId="{{ field('firstName').id }}">
              {{ field('firstName').getValidator("required").message | translate : [field('firstName').label |
              translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error
              *ngIf="!readonly && field('firstName').maxLength && formCtrl('firstName')?.hasError('maxlength')"
              formCtrlId="{{ field('firstName').id }}">
              {{ field('firstName').getValidator("maxLength").message | translate : [field('firstName').label |
              translate | async, formCtrl('firstName')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-input formCtrlId="{{ field('firstName').id }}" formCtrlName="{{ field('firstName').name }}"
              [formCtrl]="formCtrl('firstName')" [required]="field('firstName').required">
            </odsf-input>
          </div>
        </div>

        <!-- last name -->
        <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('lastName').required" formCtrlId="{{ field('lastName').id }}">
              <span name>{{ field('lastName').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error
              *ngIf="!readonly && field('lastName').required && formCtrl('lastName')?.hasError('required')"
              formCtrlId="{{ field('lastName').id }}">
              {{ field('lastName').getValidator("required").message | translate : [field('lastName').label |
              translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error
              *ngIf="!readonly && field('lastName').maxLength && formCtrl('lastName')?.hasError('maxlength')"
              formCtrlId="{{ field('lastName').id }}">
              {{ field('lastName').getValidator("maxLength").message | translate : [field('lastName').label |
              translate | async, formCtrl('lastName')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-input formCtrlId="{{ field('lastName').id }}" formCtrlName="{{ field('lastName').name }}"
              [formCtrl]="formCtrl('lastName')" [required]="field('lastName').required">
            </odsf-input>
          </div>
        </div>

        <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
      </div>

      <div class="ontario-row">
        <!-- email -->
        <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('loginEmail').required" formCtrlId="{{ field('loginEmail').id }}">
              <span name>{{ field('loginEmail').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error
              *ngIf="!readonly && field('loginEmail').required && formCtrl('loginEmail')?.hasError('required')"
              formCtrlId="{{ field('loginEmail').id }}">
              {{ field('loginEmail').getValidator("required").message | translate : [field('loginEmail').label |
              translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error
              *ngIf="!readonly && field('loginEmail').maxLength && formCtrl('loginEmail')?.hasError('maxlength')"
              formCtrlId="{{ field('loginEmail').id }}">
              {{ field('loginEmail').getValidator("maxLength").message | translate : [field('loginEmail').label |
              translate | async, formCtrl('loginEmail')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-input formCtrlId="{{ field('loginEmail').id }}" formCtrlName="{{ field('loginEmail').name }}"
              [formCtrl]="formCtrl('loginEmail')" [required]="field('loginEmail').required">
            </odsf-input>
          </div>
        </div>

        <!-- rank -->
        <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('rank').required" formCtrlId="{{ field('rank').id }}">
              <span name>{{ field('rank').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error
              *ngIf="!readonly && field('rank').required && formCtrl('rank')?.hasError('required')"
              formCtrlId="{{ field('rank').id }}">
              {{ field('rank').getValidator("required").message | translate : [field('rank').label |
              translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error
              *ngIf="!readonly && field('rank').maxLength && formCtrl('rank')?.hasError('maxlength')"
              formCtrlId="{{ field('rank').id }}">
              {{ field('rank').getValidator("maxLength").message | translate : [field('rank').label |
              translate | async, formCtrl('rank')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-input formCtrlId="{{ field('rank').id }}" formCtrlName="{{ field('rank').name }}"
              [formCtrl]="formCtrl('rank')" [required]="field('rank').required">
            </odsf-input>
          </div>
        </div>

        <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
      </div>

      <div class="ontario-row">
        <!-- title -->
        <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('titleCode').required" formCtrlId="{{ field('titleCode').id }}">
              <span name>{{ field('titleCode').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error
              *ngIf="!readonly && field('titleCode').required && formCtrl('titleCode')?.hasError('required')"
              formCtrlId="{{ field('titleCode').id }}">
              {{ field('titleCode').getValidator("required").message | translate : [field('titleCode').label |
              translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error
              *ngIf="!readonly && field('titleCode').maxLength && formCtrl('titleCode')?.hasError('maxlength')"
              formCtrlId="{{ field('titleCode').id }}">
              {{ field('titleCode').getValidator("maxLength").message | translate : [field('titleCode').label |
              translate | async, formCtrl('titleCode')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-input formCtrlId="{{ field('titleCode').id }}" formCtrlName="{{ field('titleCode').name }}"
              [formCtrl]="formCtrl('titleCode')" [required]="field('titleCode').required">
            </odsf-input>
          </div>
        </div>

        <!-- role -->
        <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('serviceRole').required" formCtrlId="{{ field('serviceRole').id }}">
              <span name>{{ field('serviceRole').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="!readonly && field('serviceRole').required && formCtrl('serviceRole')?.hasError('required')" formCtrlId="{{ field('serviceRole').id }}">
              {{ field('serviceRole').getValidator("required").message | translate : [field('serviceRole').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-select formCtrlId="{{ field('serviceRole').id }}" formCtrlName="{{ field('serviceRole').name }}" [formCtrl]="formCtrl('serviceRole')" [required]="field('serviceRole').required">
              <option value=""></option>
              <option *ngFor="let item of optionItems('userServiceRole') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                {{ item.serviceCode }} - {{ item.roleCode }}
              </option>
            </odsf-select>
          </div>
        </div>

        <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
      </div>

      <div class="ontario-row">
        <!-- has badge checkbox -->
        <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-error *ngIf="!readonly && field('hasBadge').required && formCtrl('hasBadge')?.hasError('required')" formCtrlId="{{ field('hasBadge').id }}">
              {{ field('hasBadge').getValidator("required").message | translate : [field('hasBadge').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-checkbox formCtrlId="{{ field('hasBadge').id }}" formCtrlName="{{ field('hasBadge').name }}" [formCtrl]="formCtrl('hasBadge')" [required]="field('hasBadge').required">
              {{ field('hasBadge').label | translate | async }}
            </odsf-checkbox>
          </div>
        </div>

        <!-- badge number -->
        <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('badgeNumber').required" formCtrlId="{{ field('badgeNumber').id }}">
              <span name>{{ field('badgeNumber').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="!readonly && field('badgeNumber').requiredIf && formCtrl('badgeNumber')?.hasError('required')" formCtrlId="{{ field('badgeNumber').id }}">
              {{ field('badgeNumber').getValidator("requiredIf").message | translate : [field('badgeNumber').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('badgeNumber').existance && formCtrl('badgeNumber')?.hasError('existance')" formCtrlId="{{ field('badgeNumber').id }}">
              {{ field('badgeNumber').getValidator("existance").message | translate : [field('badgeNumber').label | translate | async | lowercase, formCtrl('badgeNumber')?.value] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('badgeNumber').pattern && formCtrl('badgeNumber')?.hasError('pattern')" formCtrlId="{{ field('badgeNumber').id }}">
              {{ field('badgeNumber').getValidator("pattern").message | translate : [field('badgeNumber').label | translate | async | lowercase, '(e.g. bb799073-1ddf-4552-9cb4-6a4fffc8d062)'] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('badgeNumber').maxLength && formCtrl('badgeNumber')?.hasError('maxlength')" formCtrlId="{{ field('badgeNumber').id }}">
              {{ field('badgeNumber').getValidator("maxLength").message | translate : [field('badgeNumber').label | translate | async, formCtrl('badgeNumber')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-input
              formCtrlId="{{ field('badgeNumber').id }}"
              formCtrlName="{{ field('badgeNumber').name }}"
              [formCtrl]="formCtrl('badgeNumber')"
              [required]="field('badgeNumber').required"
              [disabled]="!formCtrl('hasBadge')?.value">
            </odsf-input>
          </div>
        </div>

        <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
      </div>

      <div class="ontario-row">
        <!-- agency -->
        <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('agency').required" formCtrlId="{{ field('agency').id }}">
              <span name>{{ field('agency').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error
              *ngIf="!readonly && field('agency').required && formCtrl('agency')?.hasError('required')"
              formCtrlId="{{ field('agency').id }}">
              {{ field('agency').getValidator("required").message | translate : [field('agency').label |
              translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error
              *ngIf="!readonly && field('agency').maxLength && formCtrl('agency')?.hasError('maxlength')"
              formCtrlId="{{ field('agency').id }}">
              {{ field('agency').getValidator("maxLength").message | translate : [field('agency').label |
              translate | async, formCtrl('agency')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-input formCtrlId="{{ field('agency').id }}" formCtrlName="{{ field('agency').name }}"
              [formCtrl]="formCtrl('agency')" [required]="field('agency').required">
            </odsf-input>
          </div>
        </div>

        <!-- court region code -->
        <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('courtRegionCode').required" formCtrlId="{{ field('courtRegionCode').id }}">
              <span name>{{ field('courtRegionCode').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="!readonly && field('courtRegionCode').required && formCtrl('courtRegionCode')?.hasError('required')" formCtrlId="{{ field('courtRegionCode').id }}">
              {{ field('courtRegionCode').getValidator("required").message | translate : [field('courtRegionCode').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-select formCtrlId="{{ field('courtRegionCode').id }}" formCtrlName="{{ field('courtRegionCode').name }}" [formCtrl]="formCtrl('courtRegionCode')" [required]="field('courtRegionCode').required">
              <option value=""></option>
              <option *ngFor="let item of optionItems('courtRegion') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                {{ item.name[lang$ | async] }}
              </option>
            </odsf-select>
          </div>
        </div>

        <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
      </div>

      <div class="ontario-row">
        <!-- is seconded checkbox -->
        <div class="ontario-columns ontario-large-10 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-error *ngIf="!readonly && field('isSeconded').required && formCtrl('isSeconded')?.hasError('required')" formCtrlId="{{ field('isSeconded').id }}">
              {{ field('isSeconded').getValidator("required").message | translate : [field('isSeconded').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-checkbox formCtrlId="{{ field('isSeconded').id }}" formCtrlName="{{ field('isSeconded').name }}" [formCtrl]="formCtrl('isSeconded')" [required]="field('isSeconded').required">
              {{ field('isSeconded').label | translate | async }}
            </odsf-checkbox>
          </div>
        </div>
      </div>

      <div class="ontario-row">
        <!-- seconded agency code -->
        <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('secondedAgencyCode').required" formCtrlId="{{ field('secondedAgencyCode').id }}">
              <span name>{{ field('secondedAgencyCode').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="!readonly && field('secondedAgencyCode').requiredIf && formCtrl('secondedAgencyCode')?.hasError('required')" formCtrlId="{{ field('secondedAgencyCode').id }}">
              {{ field('secondedAgencyCode').getValidator("requiredIf").message | translate : [field('secondedAgencyCode').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('secondedAgencyCode').existance && formCtrl('secondedAgencyCode')?.hasError('existance')" formCtrlId="{{ field('secondedAgencyCode').id }}">
              {{ field('secondedAgencyCode').getValidator("existance").message | translate : [field('secondedAgencyCode').label | translate | async | lowercase, formCtrl('secondedAgencyCode')?.value] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('secondedAgencyCode').pattern && formCtrl('secondedAgencyCode')?.hasError('pattern')" formCtrlId="{{ field('secondedAgencyCode').id }}">
              {{ field('secondedAgencyCode').getValidator("pattern").message | translate : [field('secondedAgencyCode').label | translate | async | lowercase, '(e.g. bb799073-1ddf-4552-9cb4-6a4fffc8d062)'] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('secondedAgencyCode').maxLength && formCtrl('secondedAgencyCode')?.hasError('maxlength')" formCtrlId="{{ field('secondedAgencyCode').id }}">
              {{ field('secondedAgencyCode').getValidator("maxLength").message | translate : [field('secondedAgencyCode').label | translate | async, formCtrl('secondedAgencyCode')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-input
              formCtrlId="{{ field('secondedAgencyCode').id }}"
              formCtrlName="{{ field('secondedAgencyCode').name }}"
              [formCtrl]="formCtrl('secondedAgencyCode')"
              [required]="field('secondedAgencyCode').required"
              [disabled]="!formCtrl('isSeconded')?.value">
            </odsf-input>
          </div>
        </div>

        <!-- seconded email -->
        <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('secondedEmail').required" formCtrlId="{{ field('secondedEmail').id }}">
              <span name>{{ field('secondedEmail').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="!readonly && field('secondedEmail').requiredIf && formCtrl('secondedEmail')?.hasError('required')" formCtrlId="{{ field('secondedEmail').id }}">
              {{ field('secondedEmail').getValidator("requiredIf").message | translate : [field('secondedEmail').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('secondedEmail').existance && formCtrl('secondedEmail')?.hasError('existance')" formCtrlId="{{ field('secondedEmail').id }}">
              {{ field('secondedEmail').getValidator("existance").message | translate : [field('secondedEmail').label | translate | async | lowercase, formCtrl('secondedEmail')?.value] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('secondedEmail').pattern && formCtrl('secondedEmail')?.hasError('pattern')" formCtrlId="{{ field('secondedEmail').id }}">
              {{ field('secondedEmail').getValidator("pattern").message | translate : [field('secondedEmail').label | translate | async | lowercase, '(e.g. bb799073-1ddf-4552-9cb4-6a4fffc8d062)'] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('secondedEmail').maxLength && formCtrl('secondedEmail')?.hasError('maxlength')" formCtrlId="{{ field('secondedEmail').id }}">
              {{ field('secondedEmail').getValidator("maxLength").message | translate : [field('secondedEmail').label | translate | async, formCtrl('secondedEmail')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-input
              formCtrlId="{{ field('secondedEmail').id }}"
              formCtrlName="{{ field('secondedEmail').name }}"
              [formCtrl]="formCtrl('secondedEmail')"
              [required]="field('secondedEmail').required"
              [disabled]="!formCtrl('isSeconded')?.value">
            </odsf-input>
          </div>
        </div>

        <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
      </div>

      <div *ngIf="editing" class="ontario-row">
        <!-- is active checkbox -->
        <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-error *ngIf="!readonly && field('isActive').required && formCtrl('isActive')?.hasError('required')" formCtrlId="{{ field('isActive').id }}">
              {{ field('isActive').getValidator("required").message | translate : [field('isActive').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-checkbox formCtrlId="{{ field('isActive').id }}" formCtrlName="{{ field('isActive').name }}" [formCtrl]="formCtrl('isActive')" [required]="field('isActive').required">
              {{ field('isActive').label | translate | async }}
            </odsf-checkbox>
          </div>
        </div>

        <!-- deactivation reason -->
        <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('deactivationReason').required" formCtrlId="{{ field('deactivationReason').id }}">
              <span name>{{ field('deactivationReason').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="!readonly && field('deactivationReason').requiredIf && formCtrl('deactivationReason')?.hasError('required')" formCtrlId="{{ field('deactivationReason').id }}">
              {{ field('deactivationReason').getValidator("requiredIf").message | translate : [field('deactivationReason').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('deactivationReason').existance && formCtrl('deactivationReason')?.hasError('existance')" formCtrlId="{{ field('deactivationReason').id }}">
              {{ field('deactivationReason').getValidator("existance").message | translate : [field('deactivationReason').label | translate | async | lowercase, formCtrl('deactivationReason')?.value] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('deactivationReason').pattern && formCtrl('deactivationReason')?.hasError('pattern')" formCtrlId="{{ field('deactivationReason').id }}">
              {{ field('deactivationReason').getValidator("pattern").message | translate : [field('deactivationReason').label | translate | async | lowercase, '(e.g. bb799073-1ddf-4552-9cb4-6a4fffc8d062)'] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('deactivationReason').maxLength && formCtrl('deactivationReason')?.hasError('maxlength')" formCtrlId="{{ field('deactivationReason').id }}">
              {{ field('deactivationReason').getValidator("maxLength").message | translate : [field('deactivationReason').label | translate | async, formCtrl('deactivationReason')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-input
              formCtrlId="{{ field('deactivationReason').id }}"
              formCtrlName="{{ field('deactivationReason').name }}"
              [formCtrl]="formCtrl('deactivationReason')"
              [required]="field('deactivationReason').required"
              [disabled]="formCtrl('isActive')?.value">
            </odsf-input>
          </div>
        </div>

        <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
      </div>

      <div *ngIf="editing" class="ontario-row">
        <!-- read only checkbox -->
        <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-error *ngIf="!readonly && field('readOnly').required && formCtrl('readOnly')?.hasError('required')" formCtrlId="{{ field('readOnly').id }}">
              {{ field('readOnly').getValidator("required").message | translate : [field('readOnly').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-checkbox formCtrlId="{{ field('readOnly').id }}" formCtrlName="{{ field('readOnly').name }}" [formCtrl]="formCtrl('readOnly')" [required]="field('readOnly').required">
              {{ field('readOnly').label | translate | async }}
            </odsf-checkbox>
          </div>
        </div>

        <!-- deactivation reason -->
        <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('readOnlyReason').required" formCtrlId="{{ field('readOnlyReason').id }}">
              <span name>{{ field('readOnlyReason').label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="!readonly && field('readOnlyReason').requiredIf && formCtrl('readOnlyReason')?.hasError('required')" formCtrlId="{{ field('readOnlyReason').id }}">
              {{ field('readOnlyReason').getValidator("requiredIf").message | translate : [field('readOnlyReason').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('readOnlyReason').existance && formCtrl('readOnlyReason')?.hasError('existance')" formCtrlId="{{ field('readOnlyReason').id }}">
              {{ field('readOnlyReason').getValidator("existance").message | translate : [field('readOnlyReason').label | translate | async | lowercase, formCtrl('readOnlyReason')?.value] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('readOnlyReason').pattern && formCtrl('readOnlyReason')?.hasError('pattern')" formCtrlId="{{ field('readOnlyReason').id }}">
              {{ field('readOnlyReason').getValidator("pattern").message | translate : [field('readOnlyReason').label | translate | async | lowercase, '(e.g. bb799073-1ddf-4552-9cb4-6a4fffc8d062)'] | async }}
            </odsf-error>

            <odsf-error *ngIf="!readonly && field('readOnlyReason').maxLength && formCtrl('readOnlyReason')?.hasError('maxlength')" formCtrlId="{{ field('readOnlyReason').id }}">
              {{ field('readOnlyReason').getValidator("maxLength").message | translate : [field('readOnlyReason').label | translate | async, formCtrl('readOnlyReason')?.errors["maxlength"].requiredLength] | async }}
            </odsf-error>

            <odsf-input
              formCtrlId="{{ field('readOnlyReason').id }}"
              formCtrlName="{{ field('readOnlyReason').name }}"
              [formCtrl]="formCtrl('readOnlyReason')"
              [required]="field('readOnlyReason').required"
              [disabled]="!formCtrl('readOnly')?.value">
            </odsf-input>
          </div>
        </div>

        <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
      </div>

      <div class="ontario-row">
        <!-- receive notification checkbox -->
        <div class="ontario-columns ontario-large-10 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-error *ngIf="!readonly && field('receiveNotification').required && formCtrl('receiveNotification')?.hasError('required')" formCtrlId="{{ field('receiveNotification').id }}">
              {{ field('receiveNotification').getValidator("required").message | translate : [field('receiveNotification').label | translate | async | lowercase] | async }}
            </odsf-error>

            <odsf-checkbox formCtrlId="{{ field('receiveNotification').id }}" formCtrlName="{{ field('receiveNotification').name }}" [formCtrl]="formCtrl('receiveNotification')" [required]="field('receiveNotification').required">
              {{ field('receiveNotification').label | translate | async }}
            </odsf-checkbox>
          </div>
        </div>
      </div>

      <div class="ontario-row">
        <!-- declare checkbox -->
        <div class="ontario-columns ontario-large-10 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-error *ngIf="!readonly && field('declare').requiredTrue && formCtrl('declare')?.hasError('required')" formCtrlId="{{ field('declare').id }}">
              {{ field('declare').getValidator("requiredTrue").message | translate | async }}
            </odsf-error>

            <odsf-checkbox formCtrlId="{{ field('declare').id }}" formCtrlName="{{ field('declare').name }}" [formCtrl]="formCtrl('declare')" [required]="field('declare').required">
              {{ field('declare').label | translate | async }}
            </odsf-checkbox>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #errorNotificationTemplate>
  <div class="ontario-row">
    <div
      class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
      <ods-page-alert role="error">
        <ng-container title>{{ "notification.error.title" | translate | async }}</ng-container>
        <ng-container body>
          <p>{{ "notification.error.content.invalidFormFields" | translate | async }}</p>
          <ul>
            <li *ngFor="let msg of notifications?.error?.data; let i = index; trackBy: trackByFn">
              <a href="#">{{ msg }}</a>
            </li>
          </ul>
        </ng-container>
      </ods-page-alert>
    </div>

    <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0"></div>
  </div>
</ng-template>

<ng-template #formTemplateUsersNavigater>
  <div class="ontario-row navigator">
    <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
      <div class="ontario-footer__links-container--inline">
        <span (click)="goToUsersPage()" (keypress)="goToUsersPage()" tabindex="0"><a><h3>{{"userForm.backToUsers.btn" | translate | async}}</h3></a></span>
      </div>
    </div>
    <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
    </div>
  </div>

  <div class="ontario-row raw-data">
    <div class='ontario-accordion' id='accordion-1'>
      <h3 class='ontario-accordion-heading' [class.ontario-expander--active]="isAccordionOpened">
        <button class='ontario-accordion__button' id='accordion-button-id-1' aria-controls='accordion-content-1'
          [attr.aria-expanded]='isAccordionOpened' data-toggle='ontario-collapse'
          (click)="isAccordionOpened = !isAccordionOpened">
          <span>Raw Data</span>
          <span class='ontario-accordion__button-icon--close'>
            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary"
              viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-up"></use>
            </svg>
          </span>
          <span class='ontario-accordion__button-icon--open'>
            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary"
              viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-down"></use>
            </svg>
          </span>
        </button>
      </h3>
      <section class='ontario-accordion__content' [class.ontario-expander__content--opened]="isAccordionOpened"
        id='accordion-content-1' aria-labelledby='accordion-button-id-1' [attr.aria-hidden]='!isAccordionOpened'
        data-toggle='ontario-expander-content'>
        <pre *ngIf="user && user?.model">{{ user?.model | json }}</pre>
        <br />
      </section>
    </div>
  </div>
  <hr>
</ng-template>