export class AppConstants {
  public static readonly NAV_ACTION = {
    CLOSE: 'close',
    OPEN: 'open'
  };
}

export interface ServerReferenceData {
  code: string;
  comment?: string | null;
  descEn?: string;
  descFr?: string;
  order: number;
  parentCode: string;
}

export interface I18nString {
  en: string;
  fr: string;
}

export interface ReferenceData {
  id: string;
  name: I18nString;
  displayOrder: number;
  parentId: string;
}
