import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchWarrantFormComponent } from './search-warrant-form.component';

const routes: Routes = [{ path: '', component: SearchWarrantFormComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)]
})
export class SearchWarrantFormRoutingModule {}
