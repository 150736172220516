import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatCellDef, MatColumnDef, MatHeaderRowDef, MatRowDef, MatTable, MatTableDataSource,  } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ods-table',
  templateUrl: './ods-table.component.html',
  styleUrls: ['./ods-table.component.scss']
})
export class OdsTableComponent<T> implements OnInit {
  @ContentChildren(MatHeaderRowDef) headerRowDefs: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<T>>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;
  @ContentChildren(MatCellDef) cellDefs: QueryList<MatCellDef>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<T>;

  @Input() columns: string[];
  @Input() dataSourceByArray: T[];
  @Input() columnsTitle: string[];
  @Input() tableCaption: string;
  @Input() showFilter: false;
  @Input() showPaginator: false;
  @Input() noDataText: string;
  
  dataSource: MatTableDataSource<T> ;
  displayedColumns: string[] = [];

  @Output() loadData = new EventEmitter();
  @Output() updateView = new EventEmitter();

  @Output() actionAddClick = new EventEmitter();
  @Output() actionEditClick = new EventEmitter();
  @Output() actionDeleteClick = new EventEmitter();
  @Output() actionDownloadClick = new EventEmitter();
  @Output() actionUploadClick = new EventEmitter();

  loading = true;
  emptyData = new MatTableDataSource<any>([{ empty: "row" }]);

  constructor(protected sanitizer: DomSanitizer){}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.dataSourceByArray);
    this.displayedColumns = this.columns;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  setDataSourceByArray(dbArray: T[]): void {
    this.dataSourceByArray = dbArray;
    this.dataSource = new MatTableDataSource(this.dataSourceByArray);
  }

  addAnRowToTable(row: T): void {
    this.dataSource.data.push(row);
    this.dataSource._updateChangeSubscription();
  }

  deleteAnRowFromTable(row: T): boolean {
    const rid = this.dataSource.data.indexOf(row);
    if (rid >= 0) {
      this.dataSource.data.splice(rid, 1);
      this.dataSource._updateChangeSubscription();
      return true;
    } else {
      return false;
    }
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  performActionAdd(row: T): void {
    if (this.actionAddClick) this.actionAddClick.emit(row);
  }

  performActionEdit(row: T): void {
    if (this.actionEditClick) this.actionEditClick.emit(row);
  }

  performActionDelete(row: T): void {
    if (this.actionDeleteClick) this.actionDeleteClick.emit(row);
  }

  performActionDownload(row: T): void {
    if (this.actionDownloadClick) this.actionDownloadClick.emit(row);
  }

  performActionUpload(row: T): void {
    if (this.actionUploadClick) this.actionUploadClick.emit(row);
  }

  includeButtonsOrNot(str: string): boolean {
    return  /^\[.*\]$/.test(str);
  }
}
