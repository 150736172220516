import { APP_REQUEST_CONTEXT } from '@/src/shared/loading';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ENDPOINTS, API_ENDPOINT_ROOT } from '@environments/environment';
import { Observable, map, of } from 'rxjs';

const transformer = {
  rawToModel: m => {
    const { messageId, sentDate } = m;
    return {
      messageId,
      sentDate,
      ...m.originalRequest
    };
  }
};

const mockMessages = [
  {
    "id": 1,
    "messageId": 1,
    "sentDate": "2023-06-10T19:31:30.579Z",
    "originalRequest": {
      "courtHouseCode": "1313",
      "packageNumber": "d8a911b0-0b4f-4184-bf81-6c6925c75cda",
      "subject": "1. Letters to search and document holders",
      "message": "We are unable to meet the legal requirements to access your files on police databases. Please let us know what information you hold, and we will provide the information to you, at our cost. Your names will remain confidential. Police databases include information of people who are suspected of committing crimes. Your names, information about you and others are passed to relevant officers, including by a team leader if the crimes involved a firearm or an explosive..."
    }
  },
  {
    "id": 2,
    "messageId": 2,
    "sentDate": "2023-07-10T19:31:30.579Z",
    "originalRequest": {
      "courtHouseCode": "1313",
      "packageNumber": "b0293d9f-ffcf-4d7c-b6c7-c7e6336cd501",
      "subject": "2. Letters to search and document holders",
      "message": "We are unable to meet the legal requirements to access your files on police databases. Please let us know what information you hold, and we will provide the information to you, at our cost. Your names will remain confidential. Police databases include information of people who are suspected of committing crimes. Your names, information about you and others are passed to relevant officers, including by a team leader if the crimes involved a firearm or an explosive..."
    }
  },
  {
    "id": 3,
    "messageId": 3,
    "sentDate": "2023-08-10T19:31:30.579Z",
    "originalRequest": {
      "courtHouseCode": "1313",
      "packageNumber": "",
      "subject": "3. Letters to search and document holders",
      "message": "We are unable to meet the legal requirements to access your files on police databases. Please let us know what information you hold, and we will provide the information to you, at our cost. Your names will remain confidential. Police databases include information of people who are suspected of committing crimes. Your names, information about you and others are passed to relevant officers, including by a team leader if the crimes involved a firearm or an explosive..."
    }
  },
  {
    "id": 4,
    "messageId": 4,
    "sentDate": "2023-09-10T19:31:30.579Z",
    "originalRequest": {
      "courtHouseCode": "1313",
      "subject": "4. Letters to search and document holders",
      "message": "We are unable to meet the legal requirements to access your files on police databases. Please let us know what information you hold, and we will provide the information to you, at our cost. Your names will remain confidential. Police databases include information of people who are suspected of committing crimes. Your names, information about you and others are passed to relevant officers, including by a team leader if the crimes involved a firearm or an explosive..."
    }
  },
  {
    "id": 5,
    "messageId": 5,
    "sentDate": "2023-10-06T16:29:28.765Z",
    "originalRequest": {
      "courtHouseCode": "1313",
      "packageNumber": "c014cd92-5b28-43f7-bd1e-0f90e7b1fa8a",
      "subject": "123123",
      "message": "Message 1"
    }
  },
  {
    "id": 6,
    "messageId": 6,
    "sentDate": "2023-10-06T16:31:46.074Z",
    "originalRequest": {
      "courtHouseCode": "1313",
      "packageNumber": "c014cd92-5b28-43f7-bd1e-0f90e7b1fa8a",
      "subject": "123123",
      "message": "Message 2"
    }
  },
  {
    "id": 7,
    "messageId": 7,
    "sentDate": "2023-10-06T16:35:37.035Z",
    "originalRequest": {
      "courtHouseCode": "1911",
      "packageNumber": "c014cd92-5b28-43f7-bd1e-0f90e7b1fa8a",
      "subject": "123123",
      "message": "Message 3"
    }
  },
  {
    "id": 8,
    "messageId": 8,
    "sentDate": "2023-10-06T16:37:52.788Z",
    "originalRequest": {
      "courtHouseCode": "3911",
      "packageNumber": "c014cd92-5b28-43f7-bd1e-0f90e7b1fa8a",
      "subject": "123123",
      "message": "Message 4"
    }
  },
  {
    "id": 9,
    "messageId": 9,
    "sentDate": "2023-10-06T16:45:05.243Z",
    "originalRequest": {
      "courtHouseCode": "3921",
      "packageNumber": "c014cd92-5b28-43f7-bd1e-0f90e7b1fa8a",
      "subject": "123123",
      "message": "Message 5"
    }
  },
  {
    "id": 10,
    "messageId": 10,
    "sentDate": "2023-10-06T16:48:06.872Z",
    "originalRequest": {
      "courtHouseCode": "3711",
      "packageNumber": "c014cd92-5b28-43f7-bd1e-0f90e7b1fa8a",
      "subject": "123123",
      "message": "Message 6"
    }
  },
  {
    "id": 11,
    "messageId": 11,
    "sentDate": "2023-10-06T16:56:45.444Z",
    "originalRequest": {
      "courtHouseCode": "1313",
      "packageNumber": "c014cd92-5b28-43f7-bd1e-0f90e7b1fa8a",
      "subject": "123123",
      "message": "Message 7"
    }
  },
  {
    "id": 12,
    "messageId": 12,
    "sentDate": "2023-10-06T16:58:26.900Z",
    "originalRequest": {
      "courtHouseCode": "1313",
      "packageNumber": "c014cd92-5b28-43f7-bd1e-0f90e7b1fa8a",
      "subject": "123123",
      "message": "Message 7"
    }
  },
  {
    "id": 13,
    "messageId": 13,
    "sentDate": "2023-10-06T17:14:50.432Z",
    "originalRequest": {
      "courtHouseCode": "1913",
      "packageNumber": "c014cd92-5b28-43f7-bd1e-0f90e7b1fa8a",
      "subject": "123123",
      "message": "Message 8"
    }
  },
  {
    "id": 14,
    "messageId": 14,
    "sentDate": "2023-10-06T17:16:49.320Z",
    "originalRequest": {
      "courtHouseCode": "1311",
      "packageNumber": "c014cd92-5b28-43f7-bd1e-0f90e7b1fa8a",
      "subject": "123123",
      "message": "Message 9"
    }
  },
  {
    "id": 15,
    "messageId": 15,
    "sentDate": "2023-10-06T17:18:30.381Z",
    "originalRequest": {
      "courtHouseCode": "1311",
      "packageNumber": "c014cd92-5b28-43f7-bd1e-0f90e7b1fa8a",
      "subject": "123123",
      "message": "Message 10"
    }
  },
  {
    "id": 16,
    "messageId": 16,
    "sentDate": "2023-10-06T18:39:16.623Z",
    "originalRequest": {
      "courtHouseCode": "2011",
      "packageNumber": "c014cd92-5b28-43f7-bd1e-0f90e7b1fa8a",
      "subject": "123213",
      "message": "Message 11"
    }
  },
  {
    "id": 17,
    "messageId": 17,
    "sentDate": "2023-10-06T18:43:13.511Z",
    "originalRequest": {
      "courtHouseCode": "1811",
      "packageNumber": "c014cd92-5b28-43f7-bd1e-0f90e7b1fa8a",
      "subject": "123123",
      "message": "Message 12"
    }
  },
  {
    "id": 18,
    "messageId": 18,
    "sentDate": "2023-10-06T18:45:44.573Z",
    "originalRequest": {
      "courtHouseCode": "1911",
      "packageNumber": "c014cd92-5b28-43f7-bd1e-0f90e7b1fa8a",
      "subject": "123",
      "message": "Message 13"
    }
  }
];

@Injectable({ providedIn: 'root' })
export class MessageApiService {
  constructor(private httpClient: HttpClient) { }

  get(id: string, isAppRequest?: boolean) {
    // const options: any = {};
    // if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    // const endpoint = API_ENDPOINTS.MESSAGE_GET.indexOf(`{messageId}`) === -1 ?
    //   `${API_ENDPOINTS.MESSAGE_GET}/${id}` :
    //   API_ENDPOINTS.MESSAGE_GET.replace(`{messageId}`, id);
    // return (this.httpClient.get(endpoint, options) as Observable<any>).pipe(
    //   map(transformer.rawToModel)
    // );
    const message = mockMessages.find((m: any) => m.messageId === parseInt(id));

    return of(transformer.rawToModel(message));
  }

  getAll(isAppRequest?: boolean) {
    // const options: any = {};
    // if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    // return (this.httpClient.get(API_ENDPOINTS.MESSAGE, options) as unknown as Observable<any[]>).pipe(
    //   map(ss => ss.map(transformer.rawToModel))
    // );

    return of(mockMessages.map(transformer.rawToModel));
  }

  create(message, isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return (this.httpClient.post(API_ENDPOINTS.MESSAGE_CREATE, message, options) as Observable<any>).pipe(
      map(transformer.rawToModel)
    );
  }
}
