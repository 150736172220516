
import { Validators } from "@angular/forms";
import { FormFieldSchema, ValidatorType } from "../models";
import { SubmissionSchema } from "./submission.schema";

export const searchWarrantSchema: any = Object.assign({}, SubmissionSchema, {
  courtRegion: {
    id: 'court-region',
    name: 'courtRegion',
    type: Number,
    step: 1,
    db: true,
    form: true,
    defaultValue: '',
    disabled: false,
    label: 'searchWarrantForm.step1.field.courtRegion.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  courthouse: {
    id: 'courthouse',
    name: 'courthouse',
    type: Number,
    step: 1,
    db: true,
    form: true,
    defaultValue: '',
    disabled: true,
    label: 'searchWarrantForm.step1.field.courthouse.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  wasIFSubmitted : {
    id: 'was-an-inquiry-form-submitted',
    name: 'wasIFSubmitted',
    type: Boolean,
    step: 1,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step1.field.was.an.inquiry.form.submitted',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  ifReferenceNum: {
    id: 'inquiry-form-reference-number',
    name: 'ifReferenceNum',
    type: String,
    step: 1,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'searchWarrantForm.step1.field.inquiry.form.reference.number',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(16),
        value: 16,
        message: 'validation.message.maxLength'
      }
    ]
  },
  associatedPreviousApp : {
    id: 'is-this-warrant-application-associated',
    name: 'associatedPreviousApp',
    type: Boolean,
    step: 1,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step1.field.is.this.warrant.application.associated',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  associatedPreviousNum : {
    id: 'control-reference-package-number',
    name: 'associatedPreviousNum',
    type: String,
    step: 1,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'searchWarrantForm.step1.field.control.reference.package.number',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(16),
        value: 16,
        message: 'validation.message.maxLength'
      },
      {
        type: ValidatorType.Pattern,
        fn: Validators.pattern(/^[a-z0-9]+$/i),
        value: /^[a-z0-9]+$/i,
        message: 'validation.message.alphanumeric.only'
      },
      {
        type: ValidatorType.MinLength,
        fn: Validators.minLength(16),
        value:16,
        message: 'validation.message.less.than.thenumber'
      }
    ]
  },
  priorityCode: {
    id: 'priority-level',
    name: 'priorityCode',
    type: String,
    step: 1,
    db: true,
    form: true,
    defaultValue: '',
    disabled: false,
    label: 'searchWarrantForm.step1.field.priority.level',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  synopsis4Priority: {
    id: 'synopsis-to-support-why',
    name: 'synopsis4Priority',
    type: String,
    step: 1,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'searchWarrantForm.step1.field.synopsis.to.support.why',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(200),
        value: 200,
        message: 'validation.message.maxLength'
      }
    ]
  },
  isFreeform: {
    id: 'is_free_form',
    name: 'isFreeform',
    type: Boolean,
    step: 1,
    db: true,
    form: true,
    defaultValue: '',
    disabled: false,    
    label: 'searchWarrantForm.step1.field.free.form.ito',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      }
    ]
  },
  itoTypeCode: {
    id: 'type-of-ito',
    name: 'itoTypeCode',
    type: Number,
    step: 2,
    db: true,
    form: true,
    defaultValue: '',
    disabled: false,
    label: 'searchWarrantForm.step2.field.ito.type',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  itoUUID: {
    id: 'itoUUID',
    name: 'itoUUID',
    type: String,
    step: 2,
    db: false,
    form: true,
    defaultValue: '',
    disabled: false,
    label: '',
    description: '',
    validators: []
  },
  courtRegionJurisdiction: {
    id: 'court-region-jurisdiction',
    name: 'courtRegionJurisdiction',
    type: Number,
    step: 2,
    db: true,
    form: true,
    defaultValue: '',
    disabled: false,
    label: 'searchWarrantForm.step1.field.courtRegion.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  courthouseJurisdiction: {
    id: 'courthouse-jurisdiction',
    name: 'courthouseJurisdiction',
    type: Number,
    step: 2,
    db: true,
    form: true,
    defaultValue: '',
    disabled: true,
    label: 'searchWarrantForm.step1.field.courthouse.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  areThereOccupants: {
    id: 'are-there-occupants',
    name: 'areThereOccupants',
    type: Boolean,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step2.field.owner.occupant',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      }
    ]
  },
  ownerOccupantGivenName: {
    id: 'owner-occupant-given-name',
    name: 'ownerOccupantGivenName',
    type: String,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step2.field.owner.occupant.given.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(50),
        value: 100,
        message: 'validation.message.maxLength'
      }
    ]
  },
  ownerOccupantSurname: {
    id: 'owner-occupant-surname',
    name: 'ownerOccupantSurname',
    type: String,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step2.field.owner.occupant.surname',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(50),
        value: 100,
        message: 'validation.message.maxLength'
      }
    ]
  },
  adultOrYouth: {
    id: 'adult-or-youth',
    name: 'adultOrYouth',
    type: Number,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step2.field.adult.or.youth',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  businessName: {
    id: 'business-company-name',
    name: 'businessName',
    type: String,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step2.field.business.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(50),
        value: 100,
        message: 'validation.message.maxLength'
      }
    ]
  },
  what2Searched: {
    id: 'what-will-be-searched',
    name: 'what2Searched',
    type: String,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step2.field.what.will.be.searched',
    hint: 'searchWarrantForm.step2.field.what.will.be.searched.hint',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(24),
        value: 24,
        message: 'validation.message.maxLength'
      }
    ]
  },
  isVehicle2Searched : {
    id: 'is-a-vehicle-being-searched',
    name: 'isVehicle2Searched',
    type: Boolean,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step2.field.is.a.vehicle.being.searched',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  vehiclePlateNum: {
    id: 'vehicle-plate-number',
    name: 'vehiclePlateNum',
    type: String,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'searchWarrantForm.step2.field.vehicle.plate.number',
    description: '',
    validators: [
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(10),
        value: 10,
        message: 'validation.message.maxLength'
      }
    ]
  },
  vehicleIdentificationNum: {
    id: 'vehicle-identification-number',
    name: 'vehicleIdentificationNum',
    type: String,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'searchWarrantForm.step2.field.vehicle.identification.number',
    description: '',
    validators: [
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(20),
        value: 20,
        message: 'validation.message.maxLength'
      }
    ]
  },
  vehicleInfoForSearch: {
    id: 'vehicle-info-for-Search',
    name: 'vehicleInfoForSearch',
    type: String,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'searchWarrantForm.step2.field.vehicle.info.for.Search.1',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.b'
      }
    ]
  },
  address2Searched: {
    id: 'address-be-searched',
    name: 'address2Searched',
    type: String,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step2.field.address.be.searched',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(250),
        value: 250,
        message: 'validation.message.maxLength'
      }
    ]
  },
  addressCity: {
    id: 'address-city',
    name: 'addressCity',
    type: String,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step2.field.address.city',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(40),
        value: 40,
        message: 'validation.message.maxLength'
      }
    ]
  },
  addressProvinceCode: {
    id: 'address-province',
    name: 'addressProvinceCode',
    type: Number,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step2.field.address.province',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  appendixA: {
    id: 'appendix-a',
    name: 'appendixA',
    type: String,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step2.field.appendix.a',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(1024),
        value: 1024,
        message: 'validation.message.maxLength'
      }
    ]
  }, 
  reasonableGroundsToBelieve : {
    id: 'reasonable-grounds-to-believe',
    name: 'reasonableGroundsToBelieve',
    type: Number,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step2.field.reasonable.grounds.to.believe',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  appendixB: {
    id: 'appendix-b',
    name: 'appendixB',
    type: String,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step2.field.appendix.b',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(1024),
        value: 1024,
        message: 'validation.message.maxLength'
      }
    ]
  },
  informantHaveKnowledge : {
    id: 'the-informant-says',
    name: 'informantHaveKnowledge',
    type: Boolean,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step2.field.the.informant.says',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  circumstancesOfPriorApplication: {
    id: 'circumstances-of-prior-application',
    name: 'circumstancesOfPriorApplication',
    type: String,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'searchWarrantForm.step2.field.circumstances.of.prior.application',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(1024),
        value: 1024,
        message: 'validation.message.maxLength'
      }
    ]
  }, 
  nightGroundsRequested : {
    id: 'are-night-grounds-being-requested',
    name: 'nightGroundsRequested',
    type: Boolean,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step2.field.are.night.grounds.being.requested',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  groundsToExecuteAtNight: {
    id: 'reasonable-grounds-to-execute-the-warrant-at-night',
    name: 'groundsToExecuteAtNight',
    type: String,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'searchWarrantForm.step2.field.reasonable.grounds.to.execute.the.warrant.at.night',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(500),
        value: 500,
        message: 'validation.message.maxLength'
      }
    ]
  },
  publicOfficerRequired : {
    id: 'is-a-public-officer-required',
    name: 'publicOfficerRequired',
    type: Boolean,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step2.field.is.a.public.officer.required',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  namesOfPublicOfficers: {
    id: 'names-of-public-officers',
    name: 'namesOfPublicOfficers',
    type: String,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'searchWarrantForm.step2.field.names.of.public.officers',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(250),
        value: 250,
        message: 'validation.message.maxLength'
      }
    ]
  },
  isSORequested : {
    id: 'is-a-sealing-order',
    name: 'isSORequested',
    type: Boolean,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step2.field.is.a.sealing.order',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  synopsis4SO: {
    id: 'synopsis-to-support-the-sealing-order',
    name: 'synopsis4SO',
    type: String,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'searchWarrantForm.step2.field.synopsis.to.support.the.sealing.order',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(200),
        value: 200,
        message: 'validation.message.maxLength'
      }
    ]
  },
requestTermsCond: {
    id: 'request_terms_Cond',
    name: 'requestTermsCond',
    type: Boolean,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step2.field.requesting.terms.and.conditions',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  synopsisTermsCond: {
    id: 'synopsis_terms_cond',
    name: 'synopsisTermsCond',
    type: String,
    step: 2,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'searchWarrantForm.step2.field.synopsis.of.terms.and.conditions',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(200),
        value: 200,
        message: 'validation.message.maxLength'
      }
    ]
  },  
  assistanceOrderRequired: {
    id: 'is-an-assistance-order-required',
    name: 'assistanceOrderRequired',
    type: Boolean,
    step: 3,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step3.field.is.an.assistance.order.required',
    hint: 'searchWarrantForm.step3.field.is.an.assistance.order.required.hint',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.select.a'
      }
    ]
  },
  ppaUUID: {
    id: 'ppaUUID',
    name: 'ppaUUID',
    type: String,
    step: 3,
    db: false,
    form: true,
    defaultValue: '',
    disabled: false,
    label: '',
    description: '',
    validators: []
  },
  givenName: {
    id: 'person-providing-assistance-given-name',
    name: 'givenName',
    type: String,
    step: 3,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step3.field.person.providing.assistance.givenname',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(100),
        value: 100,
        message: 'validation.message.maxLength'
      }
    ]
  },
  surname: {
    id: 'person-providing-assistance-surname',
    name: 'surname',
    type: String,
    step: 3,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step3.field.person.providing.assistance.surname',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(100),
        value: 100,
        message: 'validation.message.maxLength'
      }
    ]
  },
  title: {
    id: 'person-providing-assistance-title',
    name: 'title',
    type: String,
    step: 3,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step3.field.person.providing.assistance.title',
    description: '',
    validators: [
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(50),
        value: 50,
        message: 'validation.message.maxLength'
      }
    ]
  },
  qualifications: {
    id: 'person-providing-assistance-qualifications',
    name: 'qualifications',
    type: String,
    step: 3,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step3.field.person.providing.assistance.qualifications',
    description: '',
    validators: [
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(1024),
        value: 1024,
        message: 'validation.message.maxLength'
      }
    ]
  },    
  assistanceExplain: {
    id: 'person-providing-assistance-explain',
    name: 'assistanceExplain',
    type: String,
    step: 3,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step3.field.person.providing.assistance.explain',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(500),
        value: 500,
        message: 'validation.message.maxLength'
      }
    ]
  },
  personProvidingAssistanceChoose: {
    id: 'person-providing-assistance-choose',
    name: 'personProvidingAssistanceChoose',
    type: String,
    step: 3,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step3.field.person.providing.assistance.choose',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      }
    ]
  },
  providingAssistanceChooseSelectAll: {
    id: 'providing-assistance-choose-select-all',
    name: 'providingAssistanceChooseSelectAll',
    type: Boolean,
    step: 3,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'searchWarrantForm.step3.field.person.providing.assistance.choose.select.all',
    description: '',
    validators: []
  }
});