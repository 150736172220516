import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { BehaviorSubject, Observable } from 'rxjs';
import { SubmissionModel } from '.';

@Injectable({ providedIn: 'root' })
export class SubmissionService {
  private _list: any[] = [];
  private listSubject = new BehaviorSubject(this._list);
  private _list$: Observable<any[]> = this.listSubject.asObservable();

  private _listDelegated: any[] = [];
  private listDelegatedSubject = new BehaviorSubject(this._listDelegated);
  private _listDelegated$: Observable<any[]> = this.listDelegatedSubject.asObservable();

  private _selected: SubmissionModel = null;
  private selectedSubject = new BehaviorSubject<SubmissionModel>(this._selected);
  private _selected$: Observable<SubmissionModel> = this.selectedSubject.asObservable();

  constructor(private httpClient: HttpClient) {}

  add(item) {
    this.list = cloneDeep(this._list).concat(item);
    return this._list$;
  }

  remove(id) {
    this.list = cloneDeep(this._list).filter(s => s?.packageUUID !== id);
    return this._list$;
  }

  get list() {
    return this._list;
  }

  set list(list: any[]) {
    this._list = list;
    this.listSubject.next(this._list);
  }

  get list$() {
    return this._list$;
  }

  get selected(): SubmissionModel {
    return this._selected;
  }

  set selected(item: SubmissionModel) {
    this._selected = item;
    this.selectedSubject.next(this._selected);
  }

  get selected$(): Observable<SubmissionModel> {
    return this._selected$;
  }

  set listDelegated(list: any[]) {
    this._listDelegated = list;
    this.listDelegatedSubject.next(this._listDelegated);
  }

  get listDelegated$() {
    return this._listDelegated$;
  }

}
