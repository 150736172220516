import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OdsPageAlertType } from './ods-page-alert-type.enum';

@Component({
  selector: 'ods-page-alert',
  templateUrl: './ods-page-alert.component.html',
  styleUrls: ['./ods-page-alert.component.scss']
})
export class OdsPageAlertComponent {
  @Input() role = OdsPageAlertType.Info as unknown as string;
  @Output() closeClick = new EventEmitter();

  get isInfoAlert(): boolean {
    return this.role === (OdsPageAlertType.Info as unknown as string);
  }

  get isWarningAlert(): boolean {
    return this.role === (OdsPageAlertType.Warning as unknown as string);
  }

  get isSuccessAlert(): boolean {
    return this.role === (OdsPageAlertType.Success as unknown as string);
  }

  get isErrorAlert(): boolean {
    return this.role === (OdsPageAlertType.Error as unknown as string);
  }

  willClose(): void {
    this.closeClick.emit();
  }
}
