<textarea
  matInput
  [attr.required]="required"
  [formControlName]="formCtrlName"
  class="ontario-input ontario-textarea"
  [class.ontario-input__error]="hasError"
  [id]="formCtrlId"
  [name]="formCtrlName"
  [attr.aria-describedby]="describedBy"
  [attr.aria-invalid]="hasError"
  [maxLength]="maxLength"
  [readonly]="disabled"
></textarea>
<ng-content></ng-content>
