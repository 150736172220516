<input
  matInput
  [type]="type??'text'"
  [attr.required]="required"
  [readonly]="disabled"
  [formControlName]="formCtrlName"
  [class]="hidden ? 'hidden-input' : 'ontario-input'"
  [class.ontario-input__error]="hasError"
  [id]="formCtrlId"
  [name]="formCtrlName"
  [value]="value"
  [attr.aria-describedby]="describedBy"
  [attr.aria-invalid]="hasError"
  [maxLength]="maxLength > 0 ? maxLength : 5000"
  [min]="min"
  [max]="max"
/>
