import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppConstants } from '@app/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserService } from '@app/core/auth';

@Component({
  selector: 'app-top-nav',
  templateUrl: './app-top-nav.component.html',
  styleUrls: ['./app-top-nav.component.scss']
})
export class AppTopNavComponent {
  @ViewChild('mainNav') mainNav: ElementRef;
  @ViewChild('openMenuBtn') openMenuBtn: ElementRef;
  @ViewChild('closeMenuBtn') closeMenuBtn: ElementRef;

  @Input() title: string;
  @Input() langLabel: string;
  @Input() langLabelAbbr: string;
  @Output() languageToggled = new EventEmitter();

  searchEnabled = false;
  menuEnabled = true;
  i18nEnabled = true;
  isNavOpened = false;

  constructor(private authService: OidcSecurityService, private userService: UserService) { }

  get loggedIn() {
    return this.userService.loggedIn;
  }

  get userEmail() {
    return this.userService.userEmail;
  }

  get userId() {
    return this.userService.userId;
  }

  get isAuthorized() {
    return this.userService.isAuthorized;
  }

  get userAccList() {
    return this.userService.userAccList;
  }
  
  toggleLanguage() {
    this.languageToggled.emit();
  }

  toggleNav(action?) {
    if (action) {
      this.isNavOpened = action === AppConstants.NAV_ACTION.OPEN;
    } else {
      this.isNavOpened = !this.isNavOpened;
    }
    return;
  }

  isSelectDialogPopup = false;
  
  switchUser(){
    this.isSelectDialogPopup = true;
    const emails = this.userAccList.reduce((x,y)=>{
      x.push(y.loginEmail);
      return x;
    }, []);
    this.userService.selectUser(emails, this.userService.currentUser?.certEmail?.toLowerCase(), this.userEmail, ()=>this.isSelectDialogPopup = false);
  }

  logout() {
    this.authService.logoff().subscribe();
  }

  @HostListener('window: keyup.esc') onKeyUp() {
    if (!this.isNavOpened) return;
    this.toggleNav(AppConstants.NAV_ACTION.CLOSE);
  }

  @HostListener('document:click', ['$event.target']) onClick(target) {
    if (!this.isNavOpened) return;

    const menuElems = [this.mainNav, this.openMenuBtn, this.closeMenuBtn];
    const clickedOutside = menuElems.every(elem => !elem.nativeElement.contains(target));
    if (clickedOutside) {
      this.toggleNav(AppConstants.NAV_ACTION.CLOSE);
    }
  }

  onSelectChange(event) {
    if(event?.srcElement?.value)
      this.userService.userEmail = event.srcElement.value;
  }
}
