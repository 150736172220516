import { API_DOMAIN, API_ENDPOINTS, environment } from '@/src/environments/environment';
import { FormFieldSchema, withForm, withModel, withSchema } from '@app/core/models';
import { InquirySchema, SubmissionStatus, SubmissionType } from '@app/core/submissions';

export interface ServerSubmissionModel {
  id: number;
  packageId: number;
  packageUUID: string;
  packageType: string;
  subPackageType: string | null;
  currentStatus: string;
  sentDate: string;
  receivedDate: string | null;
  file: {
    name: string;
    url: string;
  };
  originalRequest: {
    courtRegionCode: string;
    courtHouseCode: string;
    typeOfInquiryCode: string;
    packageNumber: string;
    subject: string;
    message: string;
    declare: boolean;
  };
}
export interface SubmissionModel {
  id?: number;
  packageId?: string;
  packageUUID?: string;
  packageType?: string;
  currentStatus: number;
  sentDate?: Date;
  receivedDate?: Date;
  typeOfInquiryCode: number;
  courtRegionCode: number;
  courtHouseCode: number;
  packageNumber?: string;
  subject: string;
  message: string;
  declare: boolean;
  delegatedPackage: boolean;
  isArchived: boolean;
  isDeleted: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  toForm?: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  toSchema?: Function;
  [x: string | number | symbol]: any;
}

export interface SubmissionArguments {
  data?: unknown;
  schema: FormFieldSchema;
  options: { validateForm: boolean };
}

export const Submission = function ({ data, schema, options: { validateForm } }: SubmissionArguments) {
  // set default value using schema
  const fields = Object.keys(schema);
  let state: Partial<SubmissionModel> = fields.reduce((f, name) => {
    f[name] = schema[name].defaultValue;
    return f;
  }, {});

  state = Object.assign(state, data);
  return Object.assign(
    state,
    withForm({ schema, validateForm: validateForm }),
    withSchema({ schema, validateForm: validateForm }),
    withModel({ schema, state })
  );
};

export interface InquiryFormArguments {
  data?: unknown;
  validateForm?: boolean;
  disableForm?: boolean;
}

export const inquiryForm = function ({ data, validateForm, disableForm }: InquiryFormArguments) {
  const s = Submission({
    data: data ?? { type: SubmissionType.Inquiry },
    schema: InquirySchema,
    options: { validateForm }
  });
  const form = s.toForm({ disableAll: disableForm });
  const schema = s.toSchema();
  return {
    model: s,
    form,
    schema,
    validateForm,
    disableForm,
    toModel: s.toModel,
    toPayload: () => s.toPayload({ formGroup: form }),
    toForm: s.toForm,
    toSchema: s.toSchema
  };
};

export const withSubmittedInquiryPackageTable = function ({ state }) {
  const VIEW_AS_PDF_STATUSES = [SubmissionStatus[SubmissionStatus.RESPONSE]];
  state.canViewAsPDF = VIEW_AS_PDF_STATUSES.includes(state.currentStatus);

  const DOWNLOAD_AS_PDF_STATUSES = [SubmissionStatus[SubmissionStatus.RESPONSE]];
  state.canDownloadAsPDF = DOWNLOAD_AS_PDF_STATUSES.includes(state.currentStatus);

  state.canViewInputs = [
    SubmissionStatus[SubmissionStatus.DELIVERED],
    SubmissionStatus[SubmissionStatus.RESPONSE],
    SubmissionStatus[SubmissionStatus.WITHDRAW],
    SubmissionStatus[SubmissionStatus.NOTWITHDRAW],
    SubmissionStatus[SubmissionStatus.FAILURE],
    SubmissionStatus[SubmissionStatus.ERROR]
  ].includes(state.currentStatus);

  state.canDelegate = [SubmissionStatus[SubmissionStatus.RESPONSE]].includes(state.currentStatus);

  state.canArchive = [
    SubmissionStatus[SubmissionStatus.RESPONSE],
    SubmissionStatus[SubmissionStatus.WITHDRAW],
    SubmissionStatus[SubmissionStatus.NOTWITHDRAW],
    SubmissionStatus[SubmissionStatus.FAILURE],
    SubmissionStatus[SubmissionStatus.ERROR]
  ].includes(state.currentStatus);

  state.canDelete = [
    SubmissionStatus[SubmissionStatus.DELIVERED],
    SubmissionStatus[SubmissionStatus.FAILURE],
    SubmissionStatus[SubmissionStatus.ERROR]
  ].includes(state.currentStatus);

  state.highlighted = [SubmissionStatus[SubmissionStatus.NOTWITHDRAW], SubmissionStatus[SubmissionStatus.FAILURE]].includes(state.currentStatus);

  return state;
};