import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseValidators as baseConfig } from '@app/core/models';
import { cloneDeep as clone } from 'lodash';

export enum ValidatorType {
  Required = <any>'required',
  RequiredIf = <any>'requiredIf',
  RequiredTrue = <any>'requiredTrue',
  MaxLength = <any>'maxLength',
  Pattern = <any>'pattern',
  Existance = <any>'existance',
  MinLength = <any>'minLength'
}

export const BaseValidators = Object.values(ValidatorType).reduce((base, vt) => {
  base[vt] = false;
  return base;
}, {});

export interface FormFieldSchemaValidator {
  type: ValidatorType;
  fn: Validators;
  error: string;
}

export interface FormFieldSchema {
  id: string;
  name: string;
  type: unknown;
  db: boolean;
  defaultValue: unknown;
  label: string;
  description: string;
  require?: boolean;
  requireTrue?: boolean;
  maxlength?: number;
  minlength?: number;
  pattern?: RegExp;
  validators: FormFieldSchemaValidator[];
}

export const withSchema = function ({ schema, validateForm = true }) {
  // set to TRUE if the validator is specified in schema
  const setValidator = (config, v) => {
    config[v.type] = v?.value ?? true;
    return config;
  };

  // schema initialization
  const toSchema = function () {
    const fields = Object.keys(schema);

    return fields.reduce(function (schema, name) {
      // group validators by type
      schema[name].getValidator = function (vt) {
        return this.validators?.find(v => v.type === vt);
      };

      const vConfig = !validateForm ? clone(baseConfig) : schema[name]?.validators?.reduce(setValidator, clone(baseConfig));
      schema[name] = Object.assign({}, schema[name], vConfig);

      return schema;
    }, clone(schema));
  };

  const requiredFields = function () {
    const schema = toSchema();
    const fields = Object.keys(schema);
    return fields.filter(name => schema[name]?.required);
  };

  return { toSchema, requiredFields };
};

export const withForm = function ({ schema, validateForm }) {
  return {
    toForm: function (opts?) {
      const fields = Object.keys(schema);

      return new FormGroup(
        fields.reduce((formGroup, name) => {
          const field = schema[name];

          // skip if not a form field
          if (!field.form) return formGroup;

          // use default if no value is set
          const ctrlValue = this[name] ?? field.defaultValue;
          const validatorOrOpts = { validators: [], asyncValidators: [] };

          if (validateForm) {
            validatorOrOpts.validators = field.validators.filter(v => !v.async).map(v => v.fn);
            validatorOrOpts.asyncValidators = field.validators.filter(v => v.async).map(v => v.fn);
          }

          formGroup[field.name] = new FormControl(
            {
              value: ctrlValue,
              disabled: field.disabled || opts?.disableAll
            },
            validatorOrOpts
          );

          return formGroup;
        }, {})
      );
    }
  };
};
