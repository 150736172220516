import {
  HttpContext,
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { LoadingService } from '.';

export const APP_REQUEST_TOKEN = new HttpContextToken<boolean>(() => false);
export const APP_REQUEST_CONTEXT = new HttpContext().set(
  APP_REQUEST_TOKEN,
  true
);

@Injectable({ providedIn: 'root' })
export class LoadingInterceptor implements HttpInterceptor {
  activeRequests = 0;

  constructor(private _loadingService: LoadingService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.isAppRequest(req)) {
      if (this.activeRequests === 0) {
        this._loadingService.startLoading();
      }

      this.activeRequests++;

      return next.handle(req).pipe(
        finalize(() => {
          this.activeRequests--;
          if (this.activeRequests === 0) {
            this._loadingService.stopLoading();
          }
        })
      );
    }

    return next.handle(req);
  }

  private isAppRequest(req: HttpRequest<unknown>): boolean {
    return req.context.get(APP_REQUEST_TOKEN) === true;
  }
}
