import { Language } from "@/src/shared/language";
import { FormFieldSchema, withForm, withModel, withSchema } from "@app/core/models";
import cloneDeep from 'lodash/cloneDeep';
import { UserSchema } from "@app/core/auth";

export interface ServerAuthorizedServiceModel {
  id: number;
  serviceCode: string;
  roleCode: string;
}

export interface ServerUserModel {
  id: number;
  userId: number;
  loginEmail: string;
  firstName: string;
  lastName: string;
  agency: string;
  isActive: string;
  services: ServerAuthorizedServiceModel[];
}

export interface UserModel {
  id: number;
  userId: number;
  loginEmail?: string;
  firstName: string;
  lastName?: string;
  name?: string;
  username?: string;
  email?: string;
  badge?: string;
  agency?: string;
  isActive: string;
  services?: ServerAuthorizedServiceModel[];
  langPreference?: Language;
  // eslint-disable-next-line @typescript-eslint/ban-types
  toForm?: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  toSchema?: Function;
  [x: string | number | symbol]: any;
}

export interface UserArguments {
  data?: unknown;
  schema: FormFieldSchema;
  options: { validateForm: boolean };
}

export const User = function ({ data, schema, options: { validateForm } }: UserArguments) {
  // set default value using schema
  const fields = Object.keys(schema);
  let state: Partial<UserModel> = fields.reduce((f, name) => {
    f[name] = schema[name].defaultValue;
    return f;
  }, {});

  state = Object.assign(state, data);
  return Object.assign(
    state,
    withForm({ schema, validateForm: validateForm }),
    withSchema({ schema, validateForm: validateForm }),
    withModel({ schema, state })
  );
};

export interface UserFormArguments {
  data?: unknown;
  validateForm?: boolean;
  disableForm?: boolean;
}

export const userForm = function ({ data, validateForm, disableForm }: UserFormArguments) {
  const s = User({
    data: data ?? {},
    schema: UserSchema,
    options: { validateForm }
  });
  const form = s.toForm({ disableAll: disableForm });
  const schema = s.toSchema();
  return {
    model: s,
    form,
    schema,
    validateForm,
    disableForm,
    toModel: s.toModel,
    toPayload: () => s.toPayload({ formGroup: form }),
    toForm: s.toForm,
    toSchema: s.toSchema
  };
};

export const withUserTransformer = function ({ state }) {
  return {
    toTable: function () {
      return cloneDeep(state);
    }
  }
}