<select #selectComponent
  matNativeControl
  [attr.required]="required"
  [formControlName]="formCtrlName"
  class="ontario-input ontario-dropdown"
  [class.ontario-input__error]="hasError"
  [id]="formCtrlId"
  [name]="formCtrlName"
  [value]="value"
  [attr.aria-describedby]="describedBy"
  [attr.aria-invalid]="hasError"
  [attr.disabled]="disabled">
  <ng-content></ng-content>
</select>
