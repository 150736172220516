import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, Component, ContentChildren, ElementRef, Injector, OnInit, QueryList, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppService, Utils } from '@app/core';
import { cloneDeep as clone } from 'lodash';

import {
  onPOFormCourtRegionChanged$,
  onPOFormPersonGivenNameChanged$,
  onPOFormPersonSurnameChanged$,
  onPOFormOrganizationNameChanged$,
  onPOFormIsThisItoAssociatedChanged$,
  onPOFormPoTypeCodeChanged$,
  onPOFormSelectionOfDocOrDataChanged$,
  onPOFormDeliveredToInformantChanged$,
  onPOFormProducedInFormSelectionChanged$,
  onPOFormPersonProvidingAssistanceChooseChanged$,
  onPOFormErrorsChanged$,
  onPOFormSuccessChanged$,
  onPOFormViewChanged$
} from '@app/core/app.subscriptions.productionorder';
import { SubmissionProductionOrderDispatcher, productionOrderForm, PO_DATA_TYPE, SubmissionType } from '@app/core/submissions';
import { BaseViewComponent, PageView, getViewFromUrl } from '@app/view';
import { environment } from '@environments/environment';
import isEmpty from 'lodash/isEmpty';
import { Observable, delay, takeWhile, tap, of, concatMap, timer } from 'rxjs';
import { OdsDialogComponent, OdsTableComponent, OdsfSelectComponent } from '@/src/shared/components/ods';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-production-order-form',
  templateUrl: './production-order-form.component.html',
  styleUrls: ['./production-order-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductionOrderFormComponent extends BaseViewComponent implements OnInit {

  parseInt = parseInt;

  productionOrder: any = {};
  submissions: any[] = [];
  notifications: any = {};

  currentStep = 1;
  totalStep = 4;

  itoSummarycolumns: string[] = ['po','person_org','actions'];

  personsProvidingAssistanceSummaryColumns: string[] = ['name','title','explanation','ito','actions'];

  manageAndUploadDocumentsColumns: string[] = ['ito','template','uploadFile','actions'];
  manageAndUploadDocumentsDatasource : any[] =  [] ;

  @ViewChild('manageAndUploadDocumentsTable') manageAndUploadDocumentsTable: OdsTableComponent<any>;
  @ViewChild('itoSummaryTable') itoSummaryTable: OdsTableComponent<any>;
  @ViewChild('personsProvidingAssistanceSummaryTable') personsProvidingAssistanceSummaryTable: OdsTableComponent<any>;
  @ViewChild('selectPoType') selectPoType: OdsfSelectComponent;

  startedANewPO = false;

  constructor(
    private injector: Injector,
    private route: ActivatedRoute,
    private dispatcher: SubmissionProductionOrderDispatcher,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private appService: AppService
  ) {
    super(injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    const route = this.route,
      router = this.router,
      dispatcher = this.dispatcher;

    onPOFormViewChanged$({ route, router, dispatcher })
      .pipe(takeWhile(() => this.alive))
      .subscribe((productionOrder: any) => {
          this.productionOrder = productionOrder;
          this.productionOrder.form['startBtnClick'] = () => this.startBtnClick();
          dispatcher.clearNotifications();

          if (this.isFormView || this.isEditView) {
            this.register$([
              onPOFormCourtRegionChanged$(productionOrder.form),
              onPOFormPoTypeCodeChanged$(productionOrder.form),
              onPOFormPersonGivenNameChanged$(productionOrder.form),
              onPOFormPersonSurnameChanged$(productionOrder.form),
              onPOFormOrganizationNameChanged$(productionOrder.form),
              onPOFormIsThisItoAssociatedChanged$(productionOrder.form),
              onPOFormSelectionOfDocOrDataChanged$(productionOrder.form),
              onPOFormDeliveredToInformantChanged$(productionOrder.form),
              onPOFormProducedInFormSelectionChanged$(productionOrder.form),
              onPOFormPersonProvidingAssistanceChooseChanged$(productionOrder.form),
              onPOFormErrorsChanged$(dispatcher.notifications$, ns => {
                if (ns?.length>0) {
                  this.notifications.error = ns[0];
                  setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }), 300) ;
                } 
              }),
              onPOFormSuccessChanged$(dispatcher.notifications$, ns => {
                //if (!isEmpty(ns)) setTimeout(() => this.router.navigate(['/']), 2000);
              })
            ]);
          }
          if (this.isEditView || this.isReadOnlyView) this.productionOrder.toRetriveDataToForm(form => { if (this.isReadOnlyView) Object.keys(form.controls).forEach(x=> (form.get(x) as FormControl).disable())});
          this.updateView();
        });
  }


  sendForm() {
    return this.dispatcher.save(this.productionOrder.toPayload(), { from: this.router.url }, true)
      .pipe(takeWhile(() => this.alive))
      .subscribe();
  }

  clearFormForStep2() {
    this.cleanErrorMessage();
    this.productionOrder.toClearForm(2, ()=> {this.startedANewPO = false; this.formCtrl('poTypeCode').enable();});
  }


  optionItems(name) {
    return this.appCache[`${name}Options`];
  }

  formCtrl(name): FormControl {
    if (name.startsWith('providingAssistanceChoose')){
      name = Utils.toPascalCase(name);
    }
    return this.productionOrder?.form?.get(name) as FormControl;
  }

  field(name): any {
    return this.productionOrder?.schema && this.productionOrder?.schema[name];
  }

  get courtHouseOptions() {
    if (!this.formCtrl('courtRegion')?.value) return [];
    return this.appCache?.courtHouseOptions.filter(o => Number(o?.parentId) === Number(this.formCtrl('courtRegion')?.value));
  }

  private cleanErrorMessage() : void {
    this.dispatcher?.clearNotifications();
    this.notifications = {};
  }

  get officerName() {
    return this.appCache.user?.name;
  }

  get viewType() {
    return getViewFromUrl(this.router.url);
  }

  get isFormView() {
    return this.viewType === PageView.Create;
  }

  get isReadOnlyView() {
    return this.viewType === PageView.Read || this.delegationType === 'NOTIFIC';
  }

  get isEditView() {
    return this.viewType === PageView.Update && (this.delegationType === "OWNER" || this.delegationType === 'PREPARE'  || this.delegationType === 'PRANDNO');
  }

  get isEditAsDelegate() {
    return this.viewType === PageView.Update && (this.delegationType === 'PREPARE'  || this.delegationType === 'PRANDNO');
  }

  get delegationType(): string {
    return isEmpty(this.productionOrder.model.data.delegationType)?"OWNER":this.productionOrder.model.data.delegationType;
  }

  get shouldShowErrors() {
    return this.notifications?.error;
  }

  get canSubmit() {
    return this.productionOrder.validateForm ? this.productionOrder?.form?.valid && !this.productionOrder?.form?.pristine : true;
  }

  gotoNext(): void {
    if (this.isReadOnlyView) {
      if (this.currentStep < this.totalStep) 
        this.currentStep = this.currentStep + 1;
      return;
    }

    this.cleanErrorMessage();
    if( this.currentStep == 3 && this.productionOrder.toValidate(33) && (this.formCtrl('assistanceOrderRequired').value == 'false' || this.productionOrder.model.data?.step3AssistanceList?.length>0)) {
      this.currentStep = 4;
    } else if (this.productionOrder.toValidate(this.currentStep)) {
      if (this.currentStep < this.totalStep) 
        this.currentStep = this.currentStep + 1;
      if (this.currentStep===2)
        timer(300).subscribe(()=>
          this.selectPoType.selectComponent.nativeElement.focus()
        );
    } else if( this.currentStep == 2 && !this.startedANewPO && this.productionOrder.model.data?.step2ItoList?.length>0) {
      this.formCtrl('poTypeCode').clearValidators();
      this.currentStep = 3;
    } else {
      this.dispatcher?.addErrorNotification({data: ["notification.error.content.completeFormFields"]});
      this.dialog.open(OdsDialogComponent,{
        width: '500px',
        data: {title: this.appCache.getPropertyItem('notification.dialog.warning.title.html'), 
          content: `<label class="ontario-label">${this.appCache.getPropertyItem('notification.error.content.invalidFormFields')}</label><br/><span>${this.appCache.getPropertyItem('notification.error.content.goto.next')}</span>`,
          yes: this.appCache.getPropertyItem('radio.value.yes'),
          no: this.appCache.getPropertyItem('radio.value.no')
        }
        }).afterClosed().subscribe(result => {
          if (result==='Yes') {
            if (this.currentStep < this.totalStep) {
              this.cleanErrorMessage();
              this.currentStep = this.currentStep + 1;
              if (this.currentStep===2)
                timer(300).subscribe(()=>
                  this.selectPoType.selectComponent.nativeElement.focus()
                );
              this.changeDetectorRef.markForCheck();
            }
          } 
      });
    }
    if (this.startedANewPO) 
      this.formCtrl('poTypeCode').disable();
    else 
      this.formCtrl('poTypeCode').enable();
  }

  gotoBack(): void {
    if (this.currentStep > 1 ) {
      this.cleanErrorMessage();
      this.currentStep = this.currentStep - 1;
    }
    if( this.currentStep == 3){
      this.formCtrl('poTypeCode').clearValidators();
    }
    if (this.currentStep===2)
      timer(300).subscribe(()=>
        this.selectPoType.selectComponent.nativeElement.focus()
      );
  }

  saveDraft(): void {
    this.cleanErrorMessage();
    if (this.productionOrder.toValidate(0)) {
      this.dispatcher.saveDraft(this.productionOrder.toPayload(), this.productionOrder.model.packageUUID, key => this.appCache.getPropertyItem(this.field(key)?.label??key) ,{ from: this.router.url }, true)
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: value => {
          console.log(value);
          if (value && value.packageId) {
            this.productionOrder.model.data['id'] = value.packageId;
            this.productionOrder.model.data['packageId'] = value.packageId;
          }
          if (value && value.packageUUID) this.productionOrder.model.data['packageUUID'] = value.packageUUID;
          this.dialog.open(OdsDialogComponent,{
          width: '500px',
          data: {title: this.appCache.getPropertyItem('notification.dialog.info.access.successful.title.html'), 
            content: `<label class="ontario-label">${this.appCache.getPropertyItem('notification.dialog.info.save.draft')}</label><br/><span>${this.appCache.getPropertyItem('notification.dialog.info.would.you.exit')}</span>`,
            yes: this.appCache.getPropertyItem('radio.value.yes'),
            no: this.appCache.getPropertyItem('radio.value.no')
          }
          }).afterClosed().subscribe(result => {
            if (result==='Yes') {
              this.appService.goToHomePage();
            } 
         });
        },
        error: error => {
          console.log(error);
        }
      });
    } else {
      this.dispatcher?.addErrorNotification({data: ["notification.error.content.saveDraft.FormFields"]});
      this.currentStep = 1;
    }
  }  

  startBtnClick(): void {
    this.cleanErrorMessage();
    if (this.productionOrder.toValidate(6)){
      this.startedANewPO = true;
      this.formCtrl('poTypeCode').disable();
    } else {
      this.dispatcher?.addErrorNotification({data: ["notification.error.content.ito.type.start"]});
    }
  }


  addToItoSummary(): void {
    this.cleanErrorMessage();
    if (this.isReadOnlyView) return;
    if (this.productionOrder.toValidate(214)) {
      this.productionOrder.toAddToItoSummary(true, (ito)=>{
        this.startedANewPO = false;
        this.formCtrl('poTypeCode').enable();
        this.addToItoSummaryTable(ito);
      });
    } else {
      this.dialog.open(OdsDialogComponent,{
        width: '500px',
        data: {title: this.appCache.getPropertyItem('notification.dialog.warning.title.html'), 
          content: `<label class="ontario-label">${this.appCache.getPropertyItem('notification.dialog.warning.content.add.to.ito.summary')}</label><br/><span>${this.appCache.getPropertyItem('notification.dialog.warning.content.hint')}</span>`,
          yes: this.appCache.getPropertyItem('radio.value.yes'),
          no: this.appCache.getPropertyItem('radio.value.no')
        }
      }).afterClosed().subscribe(result => {
          if (result==='Yes') {
            this.productionOrder.toAddToItoSummary(false, (ito)=>{
              this.startedANewPO = false;
              this.formCtrl('poTypeCode').enable();
              this.addToItoSummaryTable(ito);
            });
          } else {
            this.dispatcher?.addErrorNotification({data: ["notification.error.content.add.to.ito.summary"]});
          }
       });
    }
  }


  handleAddRow(row : any): void{
    console.log("Handle Add A Row:", row);
  }

  handleDelectRow(row : any): void{
    console.log("Handle Delete A Row:", row);
  }

  handleEditRow(row : any): void{
    console.log("Handle Edit A Row", row);
  }

  createPackageBtnClick(): void {
    this.currentStep = 4;
  }

  handleUploadFile(p : any) {
    console.log("Handle Edit A Row", p);
    this.onFileSelected(p);
  }

  handleDownloadFile(p : any) {
    console.log("Not completed yet", p);
  }

  onFileSelected(p: any) {
    const file: File = p.event.target.files[0];
    const reader: FileReader = new FileReader();
    
    reader.onloadend = (e) => {
      const base64String: string = btoa(reader.result as string);
      console.log(base64String);
      this.manageAndUploadDocumentsTable.dataSource.data[p.row.rowid].uploadFile
        = '<a href="' + file.name +  '">' + file.name + '</a>';
      this.manageAndUploadDocumentsTable.dataSource.data[p.row.rowid].actions = '[delete]';
      this.changeDetectorRef.markForCheck();


      Utils.saveBase64ToFile(base64String, file.name);


    };
    
    reader.readAsBinaryString(file);
  }

  addMorePO() {
    timer(300).subscribe(()=>
      this.selectPoType.selectComponent.nativeElement.focus()
    );
  }

  itoSummaryAddRow(row : any): void{
    console.log("Handle Add A Row:", row);
  }

  itoSummaryDelectRow(row: any): void{
    if (!this.isReadOnlyView){
      if (this.itoSummaryTable.deleteAnRowFromTable(row)){
        this.productionOrder.toDeleteFromItoSummary('poUUID', row['poUUID']);
      }
    }
  }

  itoSummaryEditRow(row : any): void{
    this.clearFormForStep2();
    const ito = this.productionOrder.toEditItoInSummary('poUUID', row['poUUID']);
    if (ito) {
      if (!this.isReadOnlyView) 
        this.itoSummaryDelectRow(row);
      else
        this.startedANewPO = true;
    }
  }
  

  private addToItoSummaryTable(newIto: any): void {
    if (newIto){
      this.itoSummaryTable.addAnRowToTable(this.itoToObj(newIto));
    }
  }

  get itoSummaryDatasource(): any[] {
    const data = this.productionOrder.model.data as PO_DATA_TYPE;
    return data.poList?.reduce((x, y) => {
      x.push(this.itoToObj(y));
      return x;
      } , []);
  }

  private itoToObj(ito: any){
    return {
      poUUID: ito.poUUID,
      po : ito.poTypeCode? this.appCache.getValueFromCache('typeOfPo', ito.poTypeCode):"",
      person_org: `${ito.personGivenName??''} ${ito.personSurname??''} <br/> ${ito.organizationName??''}`,
      actions: `${ito.completed?'[green_check_mark]':'[gray_check_mark]'}${this.isReadOnlyView?'[edit]':'[edit]/[delete]'}`
      }
  }

  clearFormForStep3() {
    this.cleanErrorMessage();
    this.productionOrder.toClearForm(3);
  }

  addToPpaSummary() {
    this.cleanErrorMessage();
    if (this.isReadOnlyView) return;
    if (this.productionOrder.toValidate(7)) {
      this.productionOrder.toAddToPpaSummary(true, (ppa)=>{
        this.addToPpaSummaryTable(ppa);
      });
    } else {
      this.dialog.open(OdsDialogComponent,{
        width: '500px',
        data: {title: this.appCache.getPropertyItem('notification.dialog.warning.title.html'), 
          content: `<label class="ontario-label">${this.appCache.getPropertyItem('notification.dialog.warning.content.add.to.ppa.summary')}</label><br/><span>${this.appCache.getPropertyItem('notification.dialog.warning.content.hint')}</span>`,
          yes: this.appCache.getPropertyItem('radio.value.yes'),
          no: this.appCache.getPropertyItem('radio.value.no')
        }
      }).afterClosed().subscribe(result => {
          if (result==='Yes') {
            this.productionOrder.toAddToPpaSummary(false, (ppa)=>{
              this.addToPpaSummaryTable(ppa);
            })
          } else {
            this.dispatcher?.addErrorNotification({data: ["notification.error.content.add.to.ppa.summary"]});
          }
       });
    }
  }

  private addToPpaSummaryTable(newPPA: any): void {
    if (newPPA){
      this.personsProvidingAssistanceSummaryTable.addAnRowToTable(this.ppaToObj(newPPA));
    }
  }

  private ppaToObj(ppa: any){
    return {
      ppaUUID: ppa.ppaUUID,
      name: `${ppa.givenName} ${ppa.surname}`,
      title : ppa.title,
      explanation: ppa.assistanceExplain,
      ito: ppa.poUUIDList?.reduce((x,y)=>{ 
        const data = this.productionOrder.model.data as PO_DATA_TYPE;
        const ito = data.poList.find(w => w.poUUID == y);
        if (ito) {
          const obj = this.itoToObj(ito);
          x = x + (x===""?"":"<br>") + `${obj.po} - ${obj.person_org}`;
        }
        return x;
      },""),
      actions: `${ppa.completed?'[green_check_mark]':'[gray_check_mark]'}${this.isReadOnlyView?'[edit]':'[edit]/[delete]'}`
      }
  }

  get personsProvidingAssistanceSummaryDatasource() : any[]{
    const data = this.productionOrder.model.data as PO_DATA_TYPE;
    return data.step3AssistanceList?.reduce((x, y) => {
      x.push(this.ppaToObj(y));
      return x;
      } , []);
  }

  ppaSummaryAddRow(row : any): void{
    console.log("Handle Add A Row:", row);
  }

  ppaSummaryDelectRow(row: any): void{
    if (!this.isReadOnlyView){
      if (this.personsProvidingAssistanceSummaryTable.deleteAnRowFromTable(row)){
        this.productionOrder.toDeleteFromPpaSummary('ppaUUID', row['ppaUUID']);
      }
    }
  }

  ppaSummaryEditRow(row : any): void{
    const ppa = this.productionOrder.toEditPpaInSummary('ppaUUID', row['ppaUUID']);
    if (ppa) {
      if (!this.isReadOnlyView) 
        this.ppaSummaryDelectRow(row);
    }
  }
}
