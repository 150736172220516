import { APP_REQUEST_CONTEXT } from '@/src/shared/loading';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ENDPOINTS, API_ENDPOINT_ROOT } from '@environments/environment';
import { Observable, catchError, map, of } from 'rxjs';

const transformer = {
  rawIfToModel: s => {
    const { packageId, packageUUID, currentStatus, isArchived, isDeleted } = s;
    return {
      packageId, 
      packageUUID,
      currentStatus, 
      ...s.originalRequest
    };
  },
  rawPackageToModel: s => {
    const { packageId, packageUUID, packageType, packageStatus, currentStatus, itoTypes, sentDate, receivedDate, delegatedPackage, isArchived, isDeleted, file, originalRequest } = s;
    return {
      id: packageId, 
      packageId, 
      packageUUID, 
      packageType, 
      typeOfInquiryCode: originalRequest?.typeOfInquiryCode || itoTypes, 
      currentStatus: currentStatus || packageStatus, 
      sentDate, 
      receivedDate,
      delegatedPackage, 
      isArchived, 
      isDeleted, 
      file
    };
  },
  toInOrSwPackage: s => {
      const { packageId, packageUUID, packageType, itoTypes, packageStatus, sentDate, receivedDate, file } = s;
      return { ...s.originalRequest, id: packageId, packageId, packageUUID, packageType, itoTypes, currentStatus: packageStatus, sentDate, receivedDate, file };
  },
  toDelegatedPackage: s => {
    const { packageId, packageUUID, packageType, itoTypes, packageStatus, sentDate, receivedDate, file, delegatedBy } = s;
    return { ...s.originalRequest, id: packageId, packageId, packageUUID, packageType, itoTypes, currentStatus: packageStatus, sentDate, receivedDate, file, delegatedBy };
  }
};

@Injectable({ providedIn: 'root' })
export class SubmissionApiService {
  constructor(private httpClient: HttpClient) {}

  get(id: string, isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    const endpoint = API_ENDPOINTS.SUBMISSION_INQUIRY_GET.indexOf(`{packageUUID}`) === -1 ? 
      `${API_ENDPOINTS.SUBMISSION_INQUIRY_GET}/${id}` : 
      API_ENDPOINTS.SUBMISSION_INQUIRY_GET.replace(`{packageUUID}`, id);
    return (this.httpClient.get(endpoint, options) as Observable<any>).pipe(
      map(transformer.rawIfToModel),
      catchError(err => {
        console.log(err);
        if (err.status >= 500)
          return of();
        else throw(err);
      })
    );
  }

  getAll(isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return (this.httpClient.get(API_ENDPOINTS.SUBMISSION_INQUIRY, options) as unknown as Observable<any[]>).pipe(
      map(ss => ss.map(transformer.rawPackageToModel)),
      catchError(err => {
        console.log(err);
        if (err.status >= 500)
          return of([]);
        else throw(err);
      })
    );
  }

  getAllPackages(isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return (this.httpClient.get(API_ENDPOINTS.SUBMISSION_PACKAGES, options) as unknown as Observable<any[]>).pipe(
      map(ss => ss?.filter(s => !s?.['isDeleted']).map(transformer.toInOrSwPackage)),
      catchError(err => {
        console.log(err);
        if (err.status >= 500)
          return of([]);
        else throw(err);
      })
    );
  }

  getAllDelegatedPackages(isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return (this.httpClient.get(API_ENDPOINTS.DELEGATED_PACKAGES, options) as unknown as Observable<any[]>).pipe(
      map(ss => ss?.filter(s => !s?.['isDeleted']).map(transformer.toDelegatedPackage)),
      catchError(err => {
        console.log(err);
        if (err.status >= 500)
          return of([]);
        else throw(err);
      })
    );
  }

  getAllPackageWarrants(packageUUID : string, isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return (this.httpClient.get(API_ENDPOINTS.SUBMISSION_PACKAGE_WARRANTS.replace(`{package-uuid}`, packageUUID), options) as unknown as Observable<any[]>).pipe(
      catchError(err => {
        console.log(err);
        if (err.status >= 500)
          return of([]);
        else throw(err);
      })
    );
  }

  create(submission, isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return this.httpClient.post(API_ENDPOINTS.SUBMISSION_INQUIRY_CREATE, submission, options) as Observable<any>;
  }

  update(submission, isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return this.httpClient.put(API_ENDPOINTS.SUBMISSION_INQUIRY, submission, options) as Observable<any>;
  }

  archive(packageUUID, isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return this.httpClient.put(API_ENDPOINTS.SUBMISSION_INQUIRY_ARCHIVE.replace(`{packageUUID}`, packageUUID), options) as Observable<any>;
  }

  delete(packageUUID, isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return this.httpClient.delete(API_ENDPOINTS.SUBMISSION_INQUIRY_DELETE.replace(`{packageUUID}`, packageUUID), options) as Observable<any>;
  }

  download(packageUUID, isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return this.httpClient.get(API_ENDPOINTS.SUBMISSION_INQUIRY_DOWNLOAD_PDF.replace(`{packageUUID}`, packageUUID), options) as Observable<any>;
  }
}
