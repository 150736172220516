import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { inquiryForm } from '@app/core/submissions';
import { BaseViewComponent } from '@app/view';
import { environment } from '@environments/environment';

const withView = function ({ state, providers }) {
  const { changeDetectorRef } = providers;

  const isReady = () => state.isReady;

  const print = function () {
    console.log(state);
  };

  const update = function (data?) {
    state.isReady = true;
    changeDetectorRef.markForCheck();
  };

  return { isReady, update, print };
};

enum FormView {
  Create = <any>'create',
  Update = <any>'update',
  Read = <any>'read'
}

const withViewUtils = function () {
  const getViewFromUrl = function (url) {
    const [root, formName, formId, viewName] = url.split('/');
    if (!formId) return FormView.Create;
    if (!viewName) return FormView.Read;
    return FormView.Update;
  };

  return { getViewFromUrl };
};

const viewUtils = withViewUtils();

const withFormView = function ({ state, providers }) {
  const { router } = providers;

  const isNewForm = function () {
    return viewUtils.getViewFromUrl(router.url) === FormView.Create;
  };

  const isReadOnlyForm = function () {
    return viewUtils.getViewFromUrl(router.url) === FormView.Read;
  };

  const isEditForm = function () {
    return viewUtils.getViewFromUrl(router.url) === FormView.Update;
  };

  return { isNewForm, isReadOnlyForm, isEditForm };
};

const inquiryFormView = function ({ injector }) {
  const changeDetectorRef = injector.get(ChangeDetectorRef);
  const router = injector.get(Router);
  const providers = { router, changeDetectorRef };

  const state = {
    isReady: false
  };

  return Object.assign({}, withView({ state, providers }), withFormView({ state, providers }));
};

@Component({
  selector: 'app-inquiry-form-refactor',
  templateUrl: './inquiry-form-refactor.component.html',
  styleUrls: ['./inquiry-form-refactor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InquiryFormRefactorComponent extends BaseViewComponent implements OnInit {
  formView;
  inquiry;

  constructor(private injector: Injector, private route: ActivatedRoute) {
    super(injector);
    this.formView = inquiryFormView({ injector });
  }

  override ngOnInit(): void {
    super.ngOnInit();

    const validateForm = environment.validateForm;
    this.inquiry = inquiryForm({ validateForm });

    this.formView.update();
    this.formView.print();
  }

  optionItems(name) {
    return this.appCache[`${name}Options`];
  }

  formCtrl(name) {
    return this.inquiry?.form?.get(name) as FormControl;
  }

  field(name) {
    return this.inquiry?.schema && this.inquiry?.schema[name];
  }

  get courtHouseOptions() {
    if (!this.formCtrl('courtRegion')?.value) return [];
    return this.appCache?.courtHouseOptions.filter(o => Number(o?.courtRegionCode) === Number(this.formCtrl('courtRegion')?.value));
  }

  get officerName() {
    return this.appCache.user?.name;
  }
}
