import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@app/core';
import { UserService } from '@app/core/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  constructor(private router: Router, private userService: UserService, private appService: AppService) { }

  ngOnInit(): void {
    this.userService.loggedIn$.subscribe(loggedIn => loggedIn && this.appService.goToCallbackPage())
  }

  get loggedIn$() {
    return this.userService.loggedIn$;
  }
}
