import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';

@Component({
    selector: 'ods-datepicker',
    templateUrl: './ods-datepicker.component.html',
    styleUrls: ['./ods-datepicker.component.scss'],
    viewProviders: [
        {
          provide: ControlContainer,
          useExisting: FormGroupDirective
        }
      ]
})
export class OdsDatepickerComponent {
    @Input() required: boolean;
    @Input() disabled: boolean;
    @Input() formCtrlId: string;
    @Input() formCtrlName: string;
    @Input() formCtrl: FormControl;
    @Input() value: string;
    @Input() hidden = false;

    @ViewChild('selectDate', { static: true, read: ElementRef }) inputSelectDate: ElementRef;

    get hasError() {
        return this.formCtrl?.touched && this.formCtrl?.invalid;
    }

    get describedBy() {
        return this.hasError ? `${this.formCtrlName}-field-error` : null;
    }

    get selectDate() {
        return this.inputSelectDate.nativeElement.value;
    }

}