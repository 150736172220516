<div
  class="ontario-alert ontario-alert--success"
  [class.ontario-alert--informational]="isInfoAlert"
  [class.ontario-alert--warning]="isWarningAlert"
  [class.ontario-alert--success]="isSuccessAlert"
  [class.ontario-alert--error]="isErrorAlert">
  <div class="ontario-alert__header">
    <div class="ontario-alert__header-icon">
      <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
        <use *ngIf="isInfoAlert" href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-alert-information"></use>
        <use *ngIf="isWarningAlert" href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-alert-warning"></use>
        <use *ngIf="isSuccessAlert" href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-alert-success"></use>
        <use *ngIf="isErrorAlert" href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-alert-error"></use>
      </svg>
    </div>
    <h2 class="ontario-alert__header-title ontario-h4">
      <ng-content select="[title]"></ng-content>
    </h2>
    <div class="ontario-alert__header-icon ontario-alert__header-close-icon">
      <div class="ontario-alert__header-svg-icon" (click)="willClose()">
        <svg>
          <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-close"></use>
        </svg> 
      </div>   
    </div>
  </div>
  <div class="ontario-alert__body">
    <ng-content select="[body]"></ng-content>
  </div>
</div>
