import { Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService, NotificationType } from '@app/core/notifications';
import { Submission, SubmissionService, SubmissionType, inquiryForm, searchWarrantForm } from '@app/core/submissions';
import { PageView, getViewFromUrl } from '@app/view';
import { environment } from '@environments/environment';
import isEmpty from 'lodash/isEmpty';
import { Observable, combineLatest, concat, distinctUntilChanged, map, of, pairwise, startWith, switchMap, tap } from 'rxjs';
import { onFormInputChanged$, onNotificationsChanged$, onPageViewChanged$ } from './app.subscriptions';
import { FormControl } from '@angular/forms';


export const onFormInputValueChanged$ = fieldName => (cond, trueResult, falseResult, complete?) => form => {
  // track prev and curr value using startWith and pairwise
  return concat(form.get(fieldName).valueChanges.pipe(
    startWith(null), 
    distinctUntilChanged(),
    pairwise()
  )).pipe(
    tap(([prev, curr]: any) =>  (cond({ form, prev, curr }) ? trueResult({ form, prev, curr }) : falseResult({ form, prev, curr }))),
    tap(() => complete ? complete({ form }) : null)
  );
};

export const onSWFormCourtRegionChanged$ = onFormInputValueChanged$('courtRegion')(
  ({ curr }) => !isEmpty(curr),
  ({ form, prev, curr }) => { if (prev!=curr) form.get('courthouse').reset(); form.get('courthouse').enable()},
  ({ form }) => form.get('courthouse').disable()
);

export const onSWFormCourtRegionJurisdictionChanged$ = onFormInputValueChanged$('courtRegionJurisdiction')(
  ({ curr }) => !isEmpty(curr),
  ({ form, prev, curr }) => { if (prev!=curr) form.get('courthouseJurisdiction').reset(); form.get('courthouseJurisdiction').enable()},
  ({ form }) => form.get('courthouseJurisdiction').disable()
);

export const onSWFormAreThereOccupantsChanged$ = onFormInputValueChanged$('areThereOccupants')(
  ({ curr }) => curr === true || curr === 'true',
  ({ form }) => { if (isEmpty(form.get('businessName').value)) form.get('ownerOccupantGivenName').enable();
                  if (isEmpty(form.get('businessName').value)) form.get('ownerOccupantSurname').enable();
                  if (isEmpty(form.get('businessName').value)) form.get('adultOrYouth').enable();
                  if (isEmpty(form.get('ownerOccupantGivenName').value) && isEmpty(form.get('ownerOccupantSurname').value) 
                    && isEmpty(form.get('adultOrYouth').value)) form.get('businessName').enable();
                },
  ({ form }) => { form.get('ownerOccupantGivenName').disable();
                  form.get('ownerOccupantSurname').disable();
                  form.get('adultOrYouth').disable();
                  form.get('businessName').disable();
                }
);

export const onSWFormBusinessNameChanged$ = onFormInputValueChanged$('businessName')(
  ({ curr }) => !isEmpty(curr),
  ({ form }) => { if (!form.get('ownerOccupantGivenName').disabled) form.get('ownerOccupantGivenName').disable();
                  if (!form.get('ownerOccupantSurname').disabled) form.get('ownerOccupantSurname').disable();
                  if (!form.get('adultOrYouth').disabled) form.get('adultOrYouth').disable();
                },
  ({ form }) => { if (form.get('ownerOccupantGivenName').disabled) form.get('ownerOccupantGivenName').enable();
                  if (form.get('ownerOccupantSurname').disabled) form.get('ownerOccupantSurname').enable();
                  if (form.get('adultOrYouth').disabled) form.get('adultOrYouth').enable();
              }
);

export const onSWFormOwnerOccupantGivenNameChanged$ = onFormInputValueChanged$('ownerOccupantGivenName')(
  ({ curr }) => !isEmpty(curr),
  ({ form }) => { if (!form.get('businessName').disabled) form.get('businessName').disable();},
  ({ form }) => { if (form.get('businessName').disabled && isEmpty(form.get('ownerOccupantGivenName').value) 
                      && isEmpty(form.get('ownerOccupantSurname').value) && isEmpty(form.get('adultOrYouth').value))
                    form.get('businessName').enable();
                }
);

export const onSWFormOwnerOccupantSurnameChanged$ = onFormInputValueChanged$('ownerOccupantSurname')(
  ({ curr }) => !isEmpty(curr),
  ({ form }) => { if (!form.get('businessName').disabled) form.get('businessName').disable();},
  ({ form }) => { if (form.get('businessName').disabled && isEmpty(form.get('ownerOccupantGivenName').value) 
                      && isEmpty(form.get('ownerOccupantSurname').value) && isEmpty(form.get('adultOrYouth').value))
                    form.get('businessName').enable();
                }
);

export const onSWFormAdultOrYouthChanged$ = onFormInputValueChanged$('adultOrYouth')(
  ({ curr }) => !isEmpty(curr),
  ({ form }) => { if (!form.get('businessName').disabled) form.get('businessName').disable();},
  ({ form }) => { if (form.get('businessName').disabled && isEmpty(form.get('ownerOccupantGivenName').value) 
                      && isEmpty(form.get('ownerOccupantSurname').value) && isEmpty(form.get('adultOrYouth').value))
                    form.get('businessName').enable();
                }
);

export const onSWFormWasAnInquiryFormSubmittedChanged$ = onFormInputValueChanged$('wasIFSubmitted')(
  ({ curr }) => curr === true || curr === 'true',
  ({ form }) => form.get('ifReferenceNum').enable(),
  ({ form }) => form.get('ifReferenceNum').disable()
);

export const onSWFormIsThisWarrantApplicationAssociatedChanged$ = onFormInputValueChanged$('associatedPreviousApp')(
  ({ curr }) => curr === true || curr === 'true',
  ({ form }) => form.get('associatedPreviousNum').enable(),
  ({ form }) => form.get('associatedPreviousNum').disable()
);

export const onSWFormPriorityLevelChanged$ = onFormInputValueChanged$('priorityCode')(
  ({ curr }) => !isEmpty(curr) && (['ASAP1','ASAP2','ASAP3','ASAP4'].includes(curr) || parseInt(curr) <= 6),
  ({ form }) => form.get('synopsis4Priority').enable(),
  ({ form }) => form.get('synopsis4Priority').disable()
);

export const onSWFormIsASealingOrderChanged$ = onFormInputValueChanged$('isSORequested')(
  ({ curr }) => curr === true || curr === 'true',
  ({ form }) => form.get('synopsis4SO').enable(),
  ({ form }) => form.get('synopsis4SO').disable()
);

export const onSWFormRequestTermsCondChanged$ = onFormInputValueChanged$('requestTermsCond')(
  ({ curr }) => curr === true || curr === 'true',
  ({ form }) => form.get('synopsisTermsCond').enable(),
  ({ form }) => form.get('synopsisTermsCond').disable()
);

export const onSWFormItoTypeCodeChanged$ = onFormInputValueChanged$('itoTypeCode')(
  ({ curr }) => !isEmpty(curr),
  ({ curr, form }) => {
    if (curr == 'CC')
      form.get('reasonableGroundsToBelieve').enable();
    else
      form.get('reasonableGroundsToBelieve').disable();
    form['startBtnClick']?.();
  },
  ({ form }) => form.get('reasonableGroundsToBelieve').disable()
);

export const onSWFormIsAVehicleBeingSearchedChanged$ = onFormInputValueChanged$('isVehicle2Searched')(
  ({ curr }) => curr === true || curr === 'true',
  ({ form }) => {form.get('vehiclePlateNum').enable(); form.get('vehicleInfoForSearch').enable(); form.get('vehicleIdentificationNum').enable()},
  ({ form }) => {form.get('vehiclePlateNum').disable(); form.get('vehicleInfoForSearch').disable(); form.get('vehicleIdentificationNum').disable()}
);

export const onSWFormVehiclePlateNumChanged$ = onFormInputValueChanged$('vehiclePlateNum')(
  ({ curr }) => true,
  ({ form, curr }) => { 
                  const tmp = (form.get('vehicleIdentificationNum') as FormControl)?.value??'';
                  (form.get('vehicleInfoForSearch') as FormControl)?.setValue(`${curr??''}${tmp}`);
                },
  () => null
);

export const onSWFormVehicleIdentificationNumChanged$ = onFormInputValueChanged$('vehicleIdentificationNum')(
  ({ curr }) => true,
  ({ form, curr }) => { 
                  const tmp = (form.get('vehiclePlateNum') as FormControl)?.value??'';
                  (form.get('vehicleInfoForSearch') as FormControl)?.setValue(`${curr??''}${tmp}`);
                },
  () => null
);

export const onSWFormTheInformantSaysChanged$ = onFormInputValueChanged$('informantHaveKnowledge')(
  ({ curr }) => curr === true || curr === 'true',
  ({ form }) => form.get('circumstancesOfPriorApplication').enable(),
  ({ form }) => form.get('circumstancesOfPriorApplication').disable()
);

export const onSWFormAreNightGroundsBeingRequestedChanged$ = onFormInputValueChanged$('nightGroundsRequested')(
  ({ curr }) => curr === true || curr === 'true',
  ({ form }) => form.get('groundsToExecuteAtNight').enable(),
  ({ form }) => form.get('groundsToExecuteAtNight').disable()
);

export const onSWFormIsAPublicOfficerRequiredChanged$ = onFormInputValueChanged$('publicOfficerRequired')(
  ({ curr }) => curr === true || curr === 'true',
  ({ form }) => form.get('namesOfPublicOfficers').enable(),
  ({ form }) => form.get('namesOfPublicOfficers').disable()
);

export const onSWFormPersonProvidingAssistanceChooseChanged$ = onFormInputValueChanged$('providingAssistanceChooseSelectAll')(
  ({ curr }) => curr,
  ({ form }) => {
    Object.keys(form.controls).filter(x => x.startsWith('providingAssistanceChoose') && x != 'providingAssistanceChooseSelectAll')
      .forEach(x=>{
        if (form.get(x).value != true) form.get(x).setValue(true);
      })
  },
  () => null
);



export const onSWFormNotificationsChanged$ = onNotificationsChanged$(n => n?.id?.includes(SubmissionType.SearchWarrant.toString()));

export const onSWFormErrorsChanged$ = onSWFormNotificationsChanged$(NotificationType.Error);
export const onSWFormSuccessChanged$ = onSWFormNotificationsChanged$(NotificationType.Success);

export const onSWFormViewChanged$ = onPageViewChanged$(({ dispatcher, params, viewType }) => {
  const validateForm = environment.validateForm;
  if (viewType === PageView.Create) {
    return of(searchWarrantForm({validateForm }));
  }

  return dispatcher.loadById(params.id, true).pipe(map(s => searchWarrantForm({ data: s, validateForm, disableForm: viewType === PageView.Read })));
});
