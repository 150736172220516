<div class="app-top-nav-container">
  <div class="ontario-header__container" [class.ontario-navigation--open]="isNavOpened">
    <header class="ontario-header" id="ontario-header">
      <div class="ontario-row ontario-row-without-max">
        <div class="ontario-header__logo-container ontario-columns ontario-small-2 ontario-medium-4 ontario-large-9">
          <a href="https://www.ontario.ca/page/government-ontario">
            <img role="img" class="ontario-show-for-medium" src="assets/ontario-design-system/logos/ontario-logo--desktop.svg" alt="Government of Ontario" />
            <img role="img" class="ontario-show-for-small-only" src="assets/ontario-design-system/logos/ontario-logo--mobile.svg" alt="Government of Ontario" />
          </a>
        </div>

        <form
          *ngIf="searchEnabled"
          name="searchForm"
          id="ontario-search-form-container"
          onsubmit="return false"
          class="ontario-header__search-container ontario-columns ontario-small-10 ontario-medium-offset-3 ontario-medium-6 ontario-large-offset-0 ontario-large-6"
          novalidate>
          <label for="ontario-search-input-field" class="ontario-show-for-sr">Search</label>
          <input type="text" name="search" id="ontario-search-input-field" autocomplete="off" aria-autocomplete="none" class="ontario-input ontario-header__search-input" required />
          <input class="ontario-header__search-reset" id="ontario-search-reset" type="reset" value="" aria-label="Clear field" />
          <button class="ontario-header__search-submit" id="ontario-search-submit" type="submit" onclick="doSearch()">
            <span class="ontario-show-for-sr">Submit</span>
            <svg class="ontario-icon" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <use xlink:href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-search"></use>
            </svg>
          </button>
        </form>

        <div class="ontario-header__nav-right-container ontario-columns ontario-small-10 ontario-medium-8 ontario-large-6">
          <ng-container *ngIf="loggedIn && userAccList.length <= 1">
            <button class="ontario-header__language-toggler ontario-header-button ontario-header-button--without-outline">
              <abbr id="user-email" title="{{ userEmail }}" class="ontario-show-for-small-only"> {{ userEmail }} </abbr>
              <span id="user-email" class="ontario-show-for-medium"> {{ userEmail }} </span>
            </button>
          </ng-container>

          <ng-container *ngIf="loggedIn && userAccList.length > 1">
                          
            <div class="ontario-application-subheader__users-container">
              <button class="ontario-header__language-toggler ontario-header-button ontario-header-button--without-outline"
                id="ontario-header-users-toggler"
                aria-controls="users-switch-nav"
                aria-label="switch user"
                (click)="switchUser()">
                <abbr id="user-email" title="{{ userEmail }}" class="ontario-show-for-small-only"> {{ userEmail }} </abbr>
                <span id="user-email" class="ontario-show-for-medium"> {{ userEmail }} </span>
                <svg *ngIf="!isSelectDialogPopup" class="ontario-icon ontario-padding-left-4-!" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                  <use xlink:href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-down"></use>
                </svg>
                <svg *ngIf="isSelectDialogPopup" class="ontario-icon ontario-padding-left-4-!" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                  <use xlink:href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-close"></use>
                </svg>
              </button>
            </div>              
             
            <!--  
            <select class=" ontario-input ontario-dropdown" id="ontario-header-users-select" style="width: revert-layer;margin-top: 2.5rem;" (change)="onSelectChange($event)" >
              <option *ngFor="let acc of this.userAccList " [value]="acc.loginEmail" [selected]="acc.loginEmail == userEmail">
                {{ acc.loginEmail }}
            </select>
           -->
          </ng-container>

          <div class="ontario-padding-right-8-!"></div>

          <button *ngIf="i18nEnabled" (click)="toggleLanguage()" href="" class="ontario-header__language-toggler ontario-header-button ontario-header-button--without-outline">
            <abbr title="{{ langLabel }}" class="ontario-show-for-small-only"> {{ langLabelAbbr | uppercase }} </abbr>
            <span class="ontario-show-for-medium"> {{ langLabel }} </span>
          </button>

          <button
            *ngIf="searchEnabled"
            class="ontario-header__search-toggler ontario-header-button ontario-header-button--without-outline ontario-hide-for-large"
            id="ontario-header-search-toggler"
            aria-controls="ontario-search-form-container"
            type="button"
            aria-label="Search">
            <svg class="ontario-icon" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <use xlink:href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-search"></use>
            </svg>
            <span class="ontario-show-for-medium ontario-show"> Search </span>
          </button>
        </div>
      </div>
    </header>

    <div class="ontario-application-subheader-menu__container">
      <section class="ontario-application-subheader">
        <div class="ontario-row ontario-row-without-max">
          <div class="ontario-columns ontario-small-12 ontario-application-subheader__container">
            <p class="ontario-application-subheader__heading ontario-large-12 ontario-medium-10 ontario-small-8">
              <a href="#">{{ title }}</a>
            </p>

            <div class="ontario-application-subheader__menu-container">
              <ul class="ontario-application-subheader__menu">
                <li>
                  <a [routerLink]="['/home']">{{ "menu.home.text" | translate | async }}</a>
                </li>
              </ul>
            </div>

            <div class="ontario-application-subheader__menu-container">
              <button
                class="ontario-header__menu-toggler ontario-header-button ontario-header-button--with-outline menu-button-width-fix"
                [class.hideMenu]="isNavOpened"
                id="ontario-header-menu-toggler"
                aria-controls="ontario-navigation"
                aria-label="open menu"
                (click)="toggleNav('open')"
                #openMenuBtn
                type="button">
                <svg class="ontario-icon" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                  <use xlink:href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-menu"></use>
                </svg>
                <span>{{ "menu.intake.text" | translate | async }}</span>
              </button>

              <button
                class="ontario-header__menu-toggler ontario-header-button ontario-header-button--with-outline menu-button-width-fix"
                [class.hideMenu]="!isNavOpened"
                id="ontario-header-nav-toggler"
                aria-controls="ontario-navigation"
                (click)="toggleNav('close')"
                #closeMenuBtn
                aria-label="close">
                <svg class="ontario-icon" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                  <use xlink:href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-close"></use>
                </svg>
                <span>{{ "menu.intake.text" | translate | async }}</span>
              </button>
            </div>
          </div>
        </div>
      </section>

      <nav *ngIf="menuEnabled" #mainNav class="ontario-navigation ontario-row-without-max" id="ontario-navigation" [class.ontario-navigation--is-ready]="menuEnabled" [class.ontario-navigation--open]="isNavOpened">
        <div class="ontario-navigation__container ontario-navigation__container_fix">
          <ul>
            <!--
            <li>
              <a [routerLink]="['/inquiry-form']" (click)="toggleNav('close')">{{ "menu.inquiry.text" | translate | async }}</a>
            </li>
            <li>
              <a [routerLink]="['/search-warrant-form']" (click)="toggleNav('close')">{{ "menu.searchWarrant.text" | translate | async }}</a>
            </li>
            -->
            <li>
              <a [routerLink]="['/production-order-form']" (click)="toggleNav('close')">{{ "menu.productionOrder.text" | translate | async }}</a>
            </li>
            <!-- temp buttons
            <li>
              <a [routerLink]="['/profile', userId]" (click)="toggleNav('close')">{{ "menu.profile.text" | translate | async }}</a>
            </li>
            <li>
              <a [routerLink]="['/users']" (click)="toggleNav('close')">{{ "menu.users.text" | translate | async }}</a>
            </li>
            <li>
              <a [routerLink]="['/messages']" (click)="toggleNav('close')">{{ "menu.messages.text" | translate | async }}</a>
            </li>
             -->
            <li>
              <a (click)="logout(); toggleNav('close')" (keypress)="logout(); toggleNav('close')" tabindex="0">{{ "menu.logout.text" | translate | async }}</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
  <div class="ontario-overlay"></div>
</div>
