import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppService } from '@app/core';
import { AuthApiService, UserApiService, UserService } from '@app/core/auth';
import { AppTopNavComponent } from '@app/core/components';
import { BaseViewComponent } from '@app/view';
import { Language } from '@shared/language';
import { LoadingService } from '@shared/loading';
import { Preference } from '@shared/preference';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { distinctUntilChanged, filter, forkJoin, interval, map, mergeMap, of, switchMap, takeWhile, tap } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseViewComponent implements OnInit {
  @ViewChild(AppTopNavComponent) topNav: AppTopNavComponent;

  title = '';
  pageReady = false;
  buildVersion = 'BUILDVERSION';
  displaySpinner = false;
  preference: Preference;

  moduleReady = false;

  constructor(
    private injector: Injector,
    private loadingService: LoadingService,
    private httpClient: HttpClient,
    private appService: AppService,
    private authApiService: AuthApiService,
    private authService: OidcSecurityService,
    private userApiService: UserApiService,
    private userService: UserService,
    private eventService: PublicEventsService
  ) {
    super(injector);
  }

  override ngOnInit() {
    super.ngOnInit();

    this.register$([this.onLoadingStatusChange]);
    this.loadPublicResources()
      .pipe(
        takeWhile(() => this.alive),
        tap(() => this.moduleReady = true),
        switchMap(() => this.authService.checkAuth().pipe(
          tap(res => this.userService.setLoginInfo(res)),
          tap(() => this.userService.userEmail$.pipe(
            distinctUntilChanged(),
            tap(() => this.userService.setUserRoles().subscribe()),
            tap(() => this.userService.setAuthorizedService().subscribe()),
            tap(() => this.loadPrivateResources().subscribe()),
          ).subscribe())
        )),
        tap(() => this.renderApp())
      )
      .subscribe(() => this.updateView());

    this.eventService
      .registerForEvents()
      .pipe(filter((notification) => notification.type === EventTypes.SilentRenewFailed))
      .subscribe(() => this.appService.goToLogoutPage());
  }

  private loadPublicResources() {
    const resources = [
      this.appService.loadI18n()
    ];
    return forkJoin(resources);
  }

  private loadPrivateResources() {
    const resources = this.userService.loggedIn ? [
      this.appService.createAPICache(this.buildVersion),
      this.appService.loadUser()
    ] : [ of(null) ];
    return forkJoin(resources);
  }

  protected override register$(subscriptions) {
    this.subscriptions = subscriptions.map(s => s?.subscribe(() => this.changeDetectorRef.detectChanges()));
    return this.subscriptions;
  }

  protected override updateView() {
    return this.changeDetectorRef.markForCheck();
  }

  private renderApp() {
    this.appCache.buildVersion = this.buildVersion;
    this.loadingService.stopLoading();
    this.pageReady = true;
    this.appService.pageReady = true;
  }

  toggleLanguage() {
    const language = this.nextLanguage;
    this.appService
      .setUserPrefLang(language)
      .pipe(takeWhile(() => this.alive))
      .subscribe(() => this.updateView());
  }

  private get onLoadingStatusChange() {
    return this.loadingService.loading$.pipe(
      takeWhile(() => this.alive),
      distinctUntilChanged(),
      tap((loading: boolean) => (this.displaySpinner = loading))
    );
  }

  get nextLanguage() {
    const preference = this.appCache.preference;
    return preference?.language === Language.English ? Language.French : Language.English;
  }

  get langLabel() {
    return this.appCache?.[this.nextLanguage]?.[this.nextLanguage];
  }

  get loggedIn$() {
    return this.userService.loggedIn$;
  }

  get userEmail$() {
    return this.userService.userEmail$;
  }

  userSwitched() {
    this.userService.userEmail$.subscribe(()=>this.updateView())
  }
}
