import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/modules';
import { InquiryFormRefactorRoutingModule } from './inquiry-form-refactor-routing.module';
import { InquiryFormRefactorComponent } from './inquiry-form-refactor.component';
import { AppCoreModule } from '@app/core';

const components = [InquiryFormRefactorComponent];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, InquiryFormRefactorRoutingModule, AppCoreModule, SharedModule],
  exports: [...components]
})
export class InquiryFormRefactorModule {}
