<ng-container *ngIf="view.isReady">
  <ng-container *ngIf="isReadOnlyView">
    <div class="ontario-row">
      <h1>{{ "profile.readonly.title" | translate | async }}</h1>
    </div>
    <!-- header -->
    <div class="ontario-row profile-header">
      <div class="ontario-columns ontario-large-11 ontario-medium-11 ontario-small-11 ontario-padding-left-0-! profile-name">
        <div class="ontario-form-group ontario-margin-bottom-0-!">
          <h1 class="ontario-margin-bottom-0-!">{{ formCtrl('firstName').value }} {{ formCtrl('lastName').value }}</h1>
        </div>
      </div>

      <div class="ontario-columns ontario-large-1 ontario-medium-1 ontario-small-1 ontario-padding-left-0-! profile-edit-button">
        <div class="ontario-form-group ontario-margin-bottom-0-!">
          <a class="ontario-__link mat-column-actions-btn" [routerLink]="['/profile/',user?.model?.id,'edit']" aria-label="Edit My Profile">
            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-edit"></use>
            </svg>
          </a>
        </div>
      </div>
    </div>
    <hr class="ontario-row ontario-margin-top-16-!" />

    <!-- form input errors from server -->
    <ng-container *ngIf="shouldShowErrors" [ngTemplateOutlet]="errorNotificationTemplate"></ng-container>

    <!-- form input success from server -->
    <ng-container *ngIf="shouldShowSuccess" [ngTemplateOutlet]="successNotificationTemplate"></ng-container>

    <div *ngIf="user?.form" class="ontario-row">
      <ng-container *ngTemplateOutlet="formTemplate;context:{readonly:true}"></ng-container>
    </div>

    <!-- buttons -->
    <div class="ontario-row">
      <button (click)="printForm()" class="ontario-button ontario-button--secondary">{{ "profile.printForm.btn" | translate | async }}</button>
    </div>
  </ng-container>

  <ng-container *ngIf="isEditView">
    <div class="ontario-row">
      <h1>{{ "profile.update.title" | translate | async }}</h1>
    </div>

    <!-- back to profile button -->
    <ng-container [ngTemplateOutlet]="formTemplateProfileNavigater"></ng-container>

    <!-- header -->
    <div class="ontario-row profile-header">
      <div class="ontario-columns ontario-large-11 ontario-medium-11 ontario-small-11 ontario-padding-left-0-! profile-name">
        <div class="ontario-form-group ontario-margin-bottom-0-!">
          <h1 class="ontario-margin-bottom-0-!">{{ formCtrl('firstName').value }} {{ formCtrl('lastName').value }}</h1>
        </div>
      </div>

      <div class="ontario-columns ontario-large-1 ontario-medium-1 ontario-small-1 ontario-padding-left-0-! profile-edit-button"></div>
    </div>
    <hr class="ontario-margin-top-16-!" />

    <!-- form input errors from server -->
    <ng-container *ngIf="shouldShowErrors" [ngTemplateOutlet]="errorNotificationTemplate"></ng-container>

    <!-- form input success from server -->
    <ng-container *ngIf="shouldShowSuccess" [ngTemplateOutlet]="successNotificationTemplate"></ng-container>

    <div *ngIf="user?.form" class="ontario-row">
      <ng-container *ngTemplateOutlet="formTemplate;context:{editing:true}"></ng-container>
    </div>

    <!-- buttons -->
    <div class="ontario-row">
      <button (click)="sendForm()" class="ontario-button ontario-button--primary" [disabled]="!canSubmit">
        {{ "profile.update.btn" | translate | async }} <span *ngIf="!canSubmit">({{ "text.disabled" | translate |
          async }})</span>
      </button>
      <!-- <button (click)="resetForm()" class="ontario-button ontario-button--secondary">{{ "profile.resetForm.btn" |
        translate | async }}</button> -->
      <button [routerLink]="['/profile/',user?.model?.id]" class="ontario-button ontario-button--secondary">{{ "profile.cancel.btn" | translate | async }}</button>
    </div>
  </ng-container>
</ng-container>

<ng-template #formTemplate let-readonly="readonly" let-editing="editing">
  <div class="ontario-row">
    <form novalidate [formGroup]="user.form" class="ontario-row">
      <ng-container *ngIf="editing">
        <div class="ontario-row">
          <!-- court region code -->
          <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('courtRegionCode').required" formCtrlId="{{ field('courtRegionCode').id }}">
                <span name>{{ field('courtRegionCode').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
  
              <odsf-error *ngIf="!readonly && field('courtRegionCode').required && formCtrl('courtRegionCode')?.hasError('required')" formCtrlId="{{ field('courtRegionCode').id }}">
                {{ field('courtRegionCode').getValidator("required").message | translate : [field('courtRegionCode').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-select formCtrlId="{{ field('courtRegionCode').id }}" formCtrlName="{{ field('courtRegionCode').name }}" [formCtrl]="formCtrl('courtRegionCode')" [required]="field('courtRegionCode').required">
                <option value=""></option>
                <option *ngFor="let item of optionItems('courtRegion') | orderBy: 'displayOrder'; let i = index; trackBy: trackByFn" [value]="item.id">
                  {{ item.name[lang$ | async] }}
                </option>
              </odsf-select>
            </div>
          </div>

          <!-- rank -->
          <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('rank').required" formCtrlId="{{ field('rank').id }}">
                <span name>{{ field('rank').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
  
              <odsf-error
                *ngIf="!readonly && field('rank').required && formCtrl('rank')?.hasError('required')"
                formCtrlId="{{ field('rank').id }}">
                {{ field('rank').getValidator("required").message | translate : [field('rank').label |
                translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-error
                *ngIf="!readonly && field('rank').maxLength && formCtrl('rank')?.hasError('maxlength')"
                formCtrlId="{{ field('rank').id }}">
                {{ field('rank').getValidator("maxLength").message | translate : [field('rank').label |
                translate | async, formCtrl('rank')?.errors["maxlength"].requiredLength] | async }}
              </odsf-error>
  
              <odsf-input formCtrlId="{{ field('rank').id }}" formCtrlName="{{ field('rank').name }}"
                [formCtrl]="formCtrl('rank')" [required]="field('rank').required">
              </odsf-input>
            </div>
          </div>
  
          <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>

        <div class="ontario-row">
          <!-- title -->
          <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('titleCode').required" formCtrlId="{{ field('titleCode').id }}">
                <span name>{{ field('titleCode').label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>
  
              <odsf-error
                *ngIf="!readonly && field('titleCode').required && formCtrl('titleCode')?.hasError('required')"
                formCtrlId="{{ field('titleCode').id }}">
                {{ field('titleCode').getValidator("required").message | translate : [field('titleCode').label |
                translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-error
                *ngIf="!readonly && field('titleCode').maxLength && formCtrl('titleCode')?.hasError('maxlength')"
                formCtrlId="{{ field('titleCode').id }}">
                {{ field('titleCode').getValidator("maxLength").message | translate : [field('titleCode').label |
                translate | async, formCtrl('titleCode')?.errors["maxlength"].requiredLength] | async }}
              </odsf-error>
  
              <odsf-input formCtrlId="{{ field('titleCode').id }}" formCtrlName="{{ field('titleCode').name }}"
                [formCtrl]="formCtrl('titleCode')" [required]="field('titleCode').required">
              </odsf-input>
            </div>
          </div>

          <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!"></div>

          <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>

        <div class="ontario-row">
          <!-- receive notification checkbox -->
          <div class="ontario-columns ontario-large-10 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-error *ngIf="!readonly && field('receiveNotification').required && formCtrl('receiveNotification')?.hasError('required')" formCtrlId="{{ field('receiveNotification').id }}">
                {{ field('receiveNotification').getValidator("required").message | translate : [field('receiveNotification').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-checkbox formCtrlId="{{ field('receiveNotification').id }}" formCtrlName="{{ field('receiveNotification').name }}" [formCtrl]="formCtrl('receiveNotification')" [required]="field('receiveNotification').required">
                {{ field('receiveNotification').label | translate | async }}
              </odsf-checkbox>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="readonly">
        <div class="ontario-row">
          <!-- first name -->
          <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label formCtrlId="{{ field('firstName').id }}">
                <span name>{{ field('firstName').label | translate | async }}</span>
              </odsf-label>
  
              <span>{{ formCtrl('firstName').value }}</span>
            </div>
          </div>
  
          <!-- last name -->
          <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div *ngIf="readonly" class="ontario-form-group">
              <odsf-label formCtrlId="{{ field('lastName').id }}">
                <span name>{{ field('lastName').label | translate | async }}</span>
              </odsf-label>
  
              <span>{{ formCtrl('lastName').value }}</span>
            </div>
          </div>
  
          <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>
  
        <div class="ontario-row">
          <!-- email -->
          <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div *ngIf="readonly" class="ontario-form-group">
              <odsf-label formCtrlId="{{ field('loginEmail').id }}">
                <span name>{{ field('loginEmail').label | translate | async }}</span>
              </odsf-label>
  
              <span>{{ formCtrl('loginEmail').value }}</span>
            </div>
          </div>
  
          <!-- rank -->
          <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div *ngIf="readonly" class="ontario-form-group">
              <odsf-label formCtrlId="{{ field('rank').id }}">
                <span name>{{ field('rank').label | translate | async }}</span>
              </odsf-label>
  
              <span>{{ formCtrl('rank').value }}</span>
            </div>
          </div>
  
          <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>
  
        <div class="ontario-row">
          <!-- title -->
          <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div *ngIf="readonly" class="ontario-form-group">
              <odsf-label formCtrlId="{{ field('titleCode').id }}">
                <span name>{{ field('titleCode').label | translate | async }}</span>
              </odsf-label>
  
              <span>{{ formCtrl('titleCode').value }}</span>
            </div>
          </div>
  
          <!-- role -->
          <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
            <div *ngIf="readonly" class="ontario-form-group">
              <odsf-label formCtrlId="{{ field('serviceRole').id }}">
                <span name>{{ field('serviceRole').label | translate | async }}</span>
              </odsf-label>
  
              <span>{{ formCtrl('serviceRole').value }}</span>
            </div>
          </div>
  
          <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>
  
        <div class="ontario-row">
          <!-- badge number -->
          <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label formCtrlId="{{ field('badgeNumber').id }}">
                <span name>{{ field('badgeNumber').label | translate | async }}</span>
              </odsf-label>
  
              <span>{{ formCtrl('badgeNumber').value }}</span>
            </div>
          </div>
  
          <!-- agency -->
          <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label formCtrlId="{{ field('agency').id }}">
                <span name>{{ field('agency').label | translate | async }}</span>
              </odsf-label>
  
              <span>{{ formCtrl('agency').value }}</span>
            </div>
          </div>
  
          <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>
  
        <div class="ontario-row">
          <!-- court region code -->
          <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label formCtrlId="{{ field('courtRegionCode').id }}">
                <span name>{{ field('courtRegionCode').label | translate | async }}</span>
              </odsf-label>
  
              <span>{{ formCtrl('courtRegionCode').value }}</span>
            </div>
          </div>
  
          <!-- receive notification checkbox -->
          <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label formCtrlId="{{ field('receiveNotification').id }}">
                <span name>{{ field('receiveNotification').label | translate | async }}</span>
              </odsf-label>
  
              <span>{{ formCtrl('receiveNotification').value }}</span>
            </div>
          </div>
  
          <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>
      </ng-container>
    </form>
  </div>
</ng-template>

<ng-template #errorNotificationTemplate>
  <div class="ontario-row">
    <div
      class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
      <ods-page-alert role="error">
        <ng-container title>{{ "notification.error.title" | translate | async }}</ng-container>
        <ng-container body>
          <p>{{ "notification.error.content.invalidFormFields" | translate | async }}</p>
          <ul>
            <li *ngFor="let msg of notifications?.error?.data; let i = index; trackBy: trackByFn">
              <a href="#">{{ msg }}</a>
            </li>
          </ul>
        </ng-container>
      </ods-page-alert>
    </div>

    <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0"></div>
  </div>
</ng-template>

<ng-template #successNotificationTemplate>
  <div class="ontario-row">
    <div class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
      <ods-page-alert role="success">
        <ng-container title>{{ notifications?.success?.title | translate | async }}</ng-container>
        <ng-container body>
          <p [innerHtml]="notifications?.success?.content | translate | async | safe : 'html'"></p>
        </ng-container>
      </ods-page-alert>
    </div>

    <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0"></div>
  </div>
</ng-template>

<ng-template #formTemplateProfileNavigater>
  <div class="ontario-row navigator">
    <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
      <div class="ontario-footer__links-container--inline">
        <span (click)="goToProfilePage()" (keypress)="goToProfilePage()" tabindex="0"><a><h3>{{"profile.back.btn" | translate | async}}</h3></a></span>
      </div>
    </div>
    <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
    </div>
  </div>

  <div class="ontario-row raw-data">
    <div class='ontario-accordion' id='accordion-1'>
      <h3 class='ontario-accordion-heading' [class.ontario-expander--active]="isAccordionOpened">
        <button class='ontario-accordion__button' id='accordion-button-id-1' aria-controls='accordion-content-1'
          [attr.aria-expanded]='isAccordionOpened' data-toggle='ontario-collapse'
          (click)="isAccordionOpened = !isAccordionOpened">
          <span>Raw Data</span>
          <span class='ontario-accordion__button-icon--close'>
            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary"
              viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-up"></use>
            </svg>
          </span>
          <span class='ontario-accordion__button-icon--open'>
            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary"
              viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <use href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-chevron-down"></use>
            </svg>
          </span>
        </button>
      </h3>
      <section class='ontario-accordion__content' [class.ontario-expander__content--opened]="isAccordionOpened"
        id='accordion-content-1' aria-labelledby='accordion-button-id-1' [attr.aria-hidden]='!isAccordionOpened'
        data-toggle='ontario-expander-content'>
        <pre *ngIf="user && user?.model">{{ user?.model | json }}</pre>
        <br />
      </section>
    </div>
  </div>
  <hr class="ontario-margin-bottom-0-!">
</ng-template>