import { AUTH } from '@/src/environments/environment';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import cloneDeep from 'lodash/cloneDeep';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { AuthApiService, UserApiService } from '.';
import { environment } from '@environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { AppCacheService } from '../cache';
import { ChooseLoginEmaildialogComponent } from '../../view';
import isEmpty from 'lodash/isEmpty';

@Injectable({ providedIn: 'root' })
export class UserService {
  private _list: any[] = [];
  private listSubject = new BehaviorSubject(this._list);
  private _list$: Observable<any[]> = this.listSubject.asObservable();

  // private _selected: UserModel = null;
  // private selectedSubject = new BehaviorSubject<UserModel>(this._selected);
  // private _selected$: Observable<UserModel> = this.selectedSubject.asObservable();

  private _selected: any = null;
  private selectedSubject = new BehaviorSubject<any>(this._selected);
  private _selected$: Observable<any> = this.selectedSubject.asObservable();

  private _roles = [];
  private rolesSubject = new BehaviorSubject<string[]>(this._roles);
  private _roles$ = this.rolesSubject.asObservable();
  private _authorizedServices = [];
  private authorizedServicesSubject = new BehaviorSubject<any[]>(this._authorizedServices);
  private _authorizedServices$ = this.authorizedServicesSubject.asObservable();
  private _loggedIn = false;
  private loggedInSubject = new BehaviorSubject<boolean>(this._loggedIn);
  private _loggedIn$ = this.loggedInSubject.asObservable();
  private _userEmail = '';
  private userEmailSubject = new BehaviorSubject<string>(this._userEmail);
  private _userEmail$ = this.userEmailSubject.asObservable();
  private _userId = '';
  private userIdSubject = new BehaviorSubject<string>(this._userId);
  private _userId$ = this.userIdSubject.asObservable();
  
  private _userAccList : any[] = [];
  private userAccListSubject = new BehaviorSubject<any[]>(this._userAccList);
  private _userAccList$: Observable<any[]> = this.userAccListSubject.asObservable();

  private _currentUser = {};
  private currentUserSubject = new BehaviorSubject<object>(this._currentUser);
  private _currentUser$ = this.currentUserSubject.asObservable();


  private _delegatesList: any[] = [];
  private delegatesListSubject = new BehaviorSubject<any[]>(this._delegatesList);
  private _delegatesList$: Observable<any[]> = this.delegatesListSubject.asObservable();


  constructor(
    private authApiService: AuthApiService, 
    private authService: OidcSecurityService, 
    private userApiService: UserApiService,
    private dialog: MatDialog,
    private appCache: AppCacheService
    ) { 
  }

  add(item) {
    this.list = cloneDeep(this._list).concat(item);
    return this._list$;
  }

  remove(id) {
    this.list = cloneDeep(this._list).filter(s => s?.id !== id);
    return this._list$;
  }

  get list() {
    return this._list;
  }

  set list(list: any[]) {
    this._list = list;
    this.listSubject.next(this._list);
  }

  get list$() {
    return this._list$;
  }

  get selected(): any {
    return this._selected;
  }

  set selected(item: any) {
    this._selected = item;
    this.selectedSubject.next(this._selected);
  }

  get selected$(): Observable<any> {
    return this._selected$;
  }

  get roles() {
    return this._roles;
  }

  set roles(data: any) {
    this._roles = data;
    console.log('user roles: ', this._roles);
    this.rolesSubject.next(this._roles);
  }

  get roles$() {
    return this._roles$;
  }

  get authorizedServices() {
    return this._authorizedServices;
  }

  set authorizedServices(data: any) {
    this._authorizedServices = data;
    console.log('authorized services: ', this._authorizedServices); 
    this.authorizedServicesSubject.next(this._authorizedServices);
  }

  get authorizedServices$() {
    return this._authorizedServices$;
  }

  get loggedIn() {
    return this._loggedIn;
  }

  set loggedIn(data: any) {
    this._loggedIn = data;
    this.loggedInSubject.next(this._loggedIn);
  }

  get loggedIn$() {
    return this._loggedIn$;
  }

  get userEmail() {
    return this._userEmail;
  }

  set userEmail(data: any) {
    this._userEmail = data;
    console.log('user email: ', this._userEmail);
    this.userEmailSubject.next(this._userEmail);
  }

  get userEmail$() {
    return this._userEmail$;
  }

  get userAccList() {
    return this._userAccList;
  }

  set userAccList(data: any) {
    this._userAccList = data;
    console.log('user accounts list : ', this._userAccList);
    this.userAccListSubject.next(this._userAccList);
  }

  get userAccList$() {
    return this._userAccList$;
  }

  get userId() {
    return this._userId;
  }

  set userId(data: any) {
    this._userId = data;
    console.log('user Id: ', this._userId); 
    this.userIdSubject.next(this._userId);
  }

  get userId$() {
    return this._userId$;
  }

  get isAuthorized() {
    return this.roles && this.roles.length;
  }

  hasAccess(serviceName: string) {
    return this.authorizedServices.filter((s: any) => s.serviceCode === serviceName)?.length > 0;
  }

  revokeAccess() {
    this.roles = [];
    this.authorizedServices = [];
    this.loggedIn = false;
    this.userEmail = '';
    localStorage.removeItem(`0-${AUTH.CLIENT_ID}`);
  }

  logOffAndRevokeAccess() {
    return this.authService.logoff().pipe(tap(() => this.revokeAccess()));
  }

  setLoginInfo({ isAuthenticated, userData, accessToken }) {
    console.log('app authenticated', isAuthenticated);
    console.log(`Current access token is '${accessToken}'`);
    console.log('user data: ', userData);
    environment.authToken = accessToken;
    this.loggedIn = isAuthenticated;
    if (this.loggedIn) {
      let emailsString = userData?.multiemaildelimited || userData?.email || "";
      // for test
      //emailsString = emailsString + ":adam.gong@torontopolice.on.ca" + ":adam.gong.ca@gmail.com";
      //Updated: attributes “multiemaildelimited” and “multiemails” added, multiemaildelimited have “:”as a separator 
      console.log("emailsString : ", emailsString);
      //if (emailsString.indexOf(",") < 0){
      //  emailsString = emailsString.toLowerCase().replaceAll('.ca','.ca,').replaceAll('.com','.com,');
      //}
      // remove the above codes once email string includes delimiter.
      const emails = emailsString.split(":").filter(x => !isEmpty(x));
      this.selectUser(emails, userData?.sub?.toLowerCase(), userData?.sub?.toLowerCase());
    } else 
      this.userEmail = null;
  }

  setUserRoles() {
    return this.loggedIn && this.userEmail ?
      this.authApiService.isServiceAuthorized(this.userEmail, true)
        .pipe(tap(roles => this.roles = roles))
      : of(null);
  }

  setAuthorizedService() {
    return this.loggedIn && this.userEmail ?
      this.userApiService.getByEmail(this.userEmail, true)
        .pipe(tap((user: any) => {
          this.userId = user?.id;
          this.authorizedServices = user?.services;
          this.currentUser = user;
        }))
      : of(null);
  }

  get currentUser() {
    return this._currentUser;
  }

  set currentUser(data: any) {
    this._currentUser = data;
    console.log('current user: ', this._currentUser); 
    this.currentUserSubject.next(this._currentUser);
  }

  get currentUser$() {
    return this._currentUser$;
  }

  get delegatesList() {
    return this._delegatesList;
  }

  set delegatesList(data: any) {
    this._delegatesList = data;
    this.delegatesListSubject.next(this._delegatesList);
  }

  get delegatesList$() {
    return this._delegatesList$;
  }

  getAllUsers(certEmail: string = null) : Observable<any> {
    return this.loggedIn ?
      this.userApiService.getAllAccountByCertEmail(certEmail? certEmail : this.userEmail, true)
        .pipe(tap((users: any) => {
          console.log('linked users', users);
        }))
      : of(null);
  } 

  // eslint-disable-next-line @typescript-eslint/ban-types
  selectUser(emails: string[], certEmail: string,  currEmail: string, callbackFn: Function = null) {
    console.log("emails : ", emails);
    if (emails && emails.length > 1) {
      this.dialog.open(ChooseLoginEmaildialogComponent,{
        disableClose: true,
        width: '80rem',
        data: {
          title: this.appCache.getPropertyItem('choose.login.email.title'), 
          emails, 
          currEmail, 
          certEmail,
          close: this.appCache.getPropertyItem('text.close')
        }
        }).afterClosed().subscribe(res => {
          this.userEmail = res?.selectedEmail || currEmail || certEmail || null;
          this.userAccList = res?.usersList;
          if (callbackFn) callbackFn(res);
        });
    } else 
      this.userEmail = currEmail || certEmail|| null;
  }
}
